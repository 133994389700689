import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Table, Typography } from '@material-ui/core';
import Search from 'src/components/common/filters/Search';
import SMJListTableBody from './SMJListTableBody';

import appStyle from 'src/consts/appStyle';

// import { editSMJ, enableSMJ } from 'src/reducers/smartjourney';
// import utils from 'src/utils/utils';
import Headers from './Headers';

import filtrable from 'src/components/common/hocs/filtrable';

import columnConfig from './columnsConfig';
import utils from 'src/utils/utils';

const styles = theme => ({
  createBtn: {
    zIndex: 1,
    backgroundColor: appStyle.bgColor1,
    color: appStyle.txtColor2,
    '&:hover': {
      background: appStyle.bgColor1,
      opacity: 0.9,
    },
  },
  appBar: {
    position: 'relative',
    backgroundColor: appStyle.bgColor1,
  },
  flex: { flex: 1 },
  search: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  tableWrapper: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    overflow: 'auto',
    flex: 1,
  },
});

const SMJItemList = ({ classes, filteredData, editSmj, smjModelById, deleteSmj }) => {
  const [sortConfig, setSortConfig] = useState({
    orderBy: 'creationDate',
    order: 'desc',
  });
  const [quickFilter, setQuickFilter] = useState('');

  const { columnNames, columnsSorters } = columnConfig;

  const filteredColumNames = columnNames.filter(
    n => utils.isNodeMapped() || ['nodename'].indexOf(n) === -1
  );

  const updateQuickFilter = useCallback(filterValue => {
    setQuickFilter(filterValue);
  }, []);

  const doSort = useCallback(
    column => {
      let order = 'asc';
      if (sortConfig.orderBy === column) {
        order = sortConfig.order === 'asc' ? 'desc' : 'asc';
      }
      setSortConfig({ order, orderBy: column });
    },
    [sortConfig.order, sortConfig.orderBy]
  );

  function quickFiltered() {
    if (quickFilter) {
      return filteredData.filter(smj => smj.name.toLowerCase().includes(quickFilter.toLowerCase()));
    }
    return filteredData;
  }

  return filteredData.length ? (
    <div style={{ flex: 1, overflow: 'auto' }}>
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          overflow: 'hidden',
          textAlign: 'left',
          // height: '100%',
        }}
      >
        <div style={{ margin: '8px' }}>
          <Search
            updateQuickFilter={updateQuickFilter}
            filterProps={{
              filteredData,
              // doFilter,
              // addFilter,
              // removeFilter,
              // clearFilters,
              selectedFilters: [],
            }}
          />
        </div>
        <div className={`${classes.tableWrapper}`}>
          <Table>
            <Headers
              columnNames={filteredColumNames}
              sortConfig={sortConfig}
              doSort={doSort}
              // addFilter={addFilter}
            />
            <SMJListTableBody
              smjModelById={smjModelById}
              smjList={[...quickFiltered()].sort(
                columnsSorters[sortConfig.orderBy][sortConfig.order]
              )}
              columnNames={filteredColumNames}
              // getOptions={['edit', 'enable', 'disable']}
              options={['edit', 'delete' /* , 'enable', 'disable' */]}
              actions={{
                delete: deleteSmj,
                edit: editSmj,
                // enable: () => enableSmj(false),
                // disable: () => enableSmj(true),
              }}
            />
          </Table>
        </div>
      </div>
    </div>
  ) : (
    <div
      style={{
        flex: 1,
        textAlign: 'center',
        paddingTop: '24px',
        backgroundColor: appStyle.bgColor4,
      }}
    >
      <Typography
        variant="h6"
        style={{
          color: appStyle.txtColor3,
          // margin: 'auto 24px',
          // textAlign: 'center',
        }}
      >
        {utils.getLang('smartmessaging.campaigns.grouped.grouplist.empty')}
      </Typography>
    </div>
  );
};

SMJItemList.propTypes = {
  classes: PropTypes.object.isRequired,
  filteredData: PropTypes.array.isRequired,
  editSmj: PropTypes.func.isRequired,
  smjModelById: PropTypes.object.isRequired,
  deleteSmj: PropTypes.func.isRequired,
};

const filterCfg = props => ({
  filterFn: () => () => true,
  filtrableData: props.smjList,
});

const FiltrableList = filtrable(filterCfg)(SMJItemList);

export default withStyles(styles)(FiltrableList);
