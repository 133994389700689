import React from 'react';
import PropTypes from 'prop-types';
import utils from 'src/utils/utils';
import FilterType from 'src/enums/parameterType';
import FKValue from './FKUsedFilterValue';
import DefaultValue from './DefaultUsedFilterValue';

const UsedFilterValue = ({ filter, filtersByKey }) => {
  switch (filter.typeId) {
    case FilterType.FOREIGN_KEY:
    case FilterType.N_FOREIGN_KEY:
      return <FKValue filter={filter} filtersByKey={filtersByKey} />;
    case FilterType.ENUM:
      return (
        <FKValue
          filter={filter}
          filtersByKey={filtersByKey}
          data={utils.getDataListFromEnum(filtersByKey[filter.key].enumName)}
        />
      );
    default:
      return <DefaultValue filter={filter} filtersByKey={filtersByKey} />;
  }
};
UsedFilterValue.propTypes = {
  filter: PropTypes.object.isRequired,
  filtersByKey: PropTypes.object.isRequired,
};

export default UsedFilterValue;
