import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { AppBar, Fade, Toolbar, Typography } from '@material-ui/core';

import utils from 'src/utils/utils';
import appStyle from 'src/consts/appStyle';
import getApiCaller from 'src/utils/apiClientCaller';
import apiClient from 'src/utils/apiClient';
import withDataResolver from 'src/components/common/withDataResolver';

import ActionItem from './ActionItem';
import ActionBtn from 'src/components/common/ActionBtn';
import CsvExportButton from '../requestResultList/MassActionCsvExportButton';

const appBarStyles = {
  appBar: {
    position: 'relative',
    backgroundColor: appStyle.bgColor1,
    textAlign: 'left',
  },
  flex: { flex: 1 },
};
const AppBarRender = ({ classes, currentName, massActionId }) => (
  <AppBar className={classes.appBar}>
    <Toolbar style={{ paddingLeft: '8px' }}>
      <div className={classes.flex}>
        <Typography variant="h5" color="inherit">
          {utils.getLang(`smartmessaging.massAction.contentSelector.title`)}
        </Typography>
        <Typography variant="body2" color="inherit">
          {currentName}
          {!!massActionId && ` (${massActionId})`}
        </Typography>
      </div>
      <div
        style={{
          boxShadow: 'inset 0.3px 0px 0px 0px #ffffffd5, inset -0.3px 0px 0px 0px #ffffffd5',
          marginLeft: '8px',
          marginRight: '8px',
          padding: '0 8px',
        }}
      >
        <CsvExportButton />
      </div>
    </Toolbar>
  </AppBar>
);

AppBarRender.propTypes = {
  classes: PropTypes.object.isRequired,
  currentName: PropTypes.string.isRequired,
  massActionId: PropTypes.number.isRequired,
};

const StyledAppBar = withStyles(appBarStyles)(AppBarRender);

const MassActionContentSelector = ({
  isWritable,
  contents,
  setContents,
  goToStep,
  currentName,
  massActionId,
}) => (
  <div style={{ display: 'flex', flex: 1, flexDirection: 'column', height: '100%' }}>
    <StyledAppBar
      goToStep={goToStep}
      currentName={currentName}
      isWritable={isWritable}
      massActionId={massActionId}
    />
    <Fade in timeout={500}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            margin: '48px 15% 0',
          }}
        >
          <div
            // elevation={0}
            style={{
              overflow: 'hidden',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <div
              style={{
                display: 'inline-flex',
              }}
            >
              {Object.entries(contents).map(([actionTypeId, content]) => (
                <ActionItem
                  isWritable={isWritable}
                  key={actionTypeId}
                  actionTypeId={parseInt(actionTypeId, 10)}
                  action={content}
                  updateContents={updates => {
                    setContents({ ...contents, ...updates });
                  }}
                  // rmId={currentRmId}
                  goToStep={goToStep}
                />
              ))}
            </div>
          </div>
          <div style={{ textAlign: 'right' }}>
            <ActionBtn
              cVariant="reverse"
              style={{ margin: '2px' }}
              onClick={e => {
                e.preventDefault();
                goToStep(3);
              }}
            >
              {utils.getLang('smartmessaging.buttonLabel.previous')}
            </ActionBtn>
          </div>
        </div>
      </div>
    </Fade>
  </div>
);

const resolvers = {
  resolve: props => async callApi => {
    const allContents = await callApi(apiClient.loadCampaignActions, [props.massActionId]);
    const availaibleActions = await callApi(apiClient.listAvailableActionsForRequestModel, [
      props.massActionRequestModelId,
    ]);
    const contents = {};

    // if (availaibleActions.filter(aa => aa.id === ActionType.EMAIL.id).length) {
    //   const mailActionContent = allContents.filter(
    //     content => content.actionTypeId === ActionType.EMAIL.id
    //   );
    //   contents[ActionType.EMAIL.id] = mailActionContent[0] || null;
    // }

    // if (availaibleActions.filter(aa => aa.id === ActionType.SMS.id).length) {
    //   const smsActionContent = allContents.filter(
    //     content => content.actionTypeId === ActionType.SMS.id
    //   );
    //   contents[ActionType.SMS.id] = smsActionContent[0] || null;
    // }

    availaibleActions.forEach(aa => {
      contents[aa.id] = allContents.filter(content => content.actionTypeId === aa.id)[0] || null;
    });

    return { contents };
  },
  onResolved: props => async resolvedData => {
    props.setContents(resolvedData.contents);
  },
};

MassActionContentSelector.propTypes = {
  isWritable: PropTypes.bool.isRequired,
  contents: PropTypes.object.isRequired,
  setContents: PropTypes.func.isRequired,
  goToStep: PropTypes.func.isRequired,
  currentName: PropTypes.string.isRequired,
  massActionId: PropTypes.number.isRequired,
};

const WithData = withDataResolver(resolvers)(MassActionContentSelector);

function Handler(props) {
  const [contents, setContents] = useState(null);
  return <WithData {...props} {...{ contents, setContents }} />;
}

const mapStateToProps = state => ({
  massActionRequestModelId: state.massAction.requestModel.id,
  massActionId: state.massAction.currentMassActionId,
  currentName: state.massAction.currentName,
});

const actionCreators = {
  doGetApiCaller: () => getApiCaller,
};

export default connect(mapStateToProps, actionCreators)(Handler);
