import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment, Tooltip, IconButton, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import DoneIcon from '@material-ui/icons/Done';
import WarningIcon from '@material-ui/icons/Warning';
// import NumberFormat from 'react-number-format';
import SendIcon from '@material-ui/icons/Send';

import PreviousIcon from '@material-ui/icons/ChevronLeft';
import MuiReactSelect from './MuiReactSelect';
import withTestFormController from './hocs/withTestFormController';
import utils from 'src/utils/utils';
import appStyle from 'src/consts/appStyle';
import { ActionTypeById } from 'src/enums/actionType';

const styles = {
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: appStyle.txtColor7,
    border: '1px solid #dadde9',
    fontSize: 14,
    maxWidth: 'none',
    paddingRight: '24px',
  },
};

const MemoInput = ({ fKey, value, ...others }) => {
  const getIngut = useCallback(() => {
    switch (fKey) {
      case 'clubId':
        return <ClubSelect value={value} {...others} />;
      default:
        return <TestFormInput value={value} {...others} />;
    }
  }, [fKey, others, value]);

  return useMemo(() => getIngut(), [getIngut]);
};

const StyledTooltip = withStyles(styles)(Tooltip);

const ValidityAdornment = ({ validity }) => (
  <InputAdornment style={{ height: 'unset', margin: '0 4px 0 0' }} position="start">
    {validity.isValid && <DoneIcon style={{ color: appStyle.txtColor1 }} />}
    {!validity.isValid && (
      <StyledTooltip
        title={
          <ul>
            {validity.messages.map(msg => (
              <li key={msg}>{msg}</li>
            ))}
          </ul>
        }
        placement="bottom"
      >
        <WarningIcon style={{ color: appStyle.txtColor6 }} />
      </StyledTooltip>
    )}
  </InputAdornment>
);

ValidityAdornment.propTypes = { validity: PropTypes.object.isRequired };

const ClubSelect = ({ clubs, value, onChange }) => (
  <MuiReactSelect
    TextFieldProps={{
      label: utils.getLang('smartmessaging.campaignEditor.testActionForm.clubSender'),
      InputLabelProps: {
        shrink: true,
      },
      InputProps: {
        endAdornment: (
          <ValidityAdornment
            validity={
              !value
                ? {
                    isValid: false,
                    messages: [utils.getLang('smartmessaging.fieldValidity.mandatory')],
                  }
                : { isValid: true, messages: [] }
            }
          />
        ),
      },
    }}
    closeMenuOnSelect
    onChange={v => onChange(v)}
    value={value ? [value] : []}
    options={clubs.map(c => ({ label: c.name, value: c.id }))}
    isMulti={false}
  />
);

ClubSelect.propTypes = {
  clubs: PropTypes.array.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

ClubSelect.defaultProps = { value: null };

const inputStyles = () => ({
  label: {
    color: appStyle.txtColor3,
    whiteSpace: 'nowrap',
    fontWeight: 400,
    '&.invalidField': { color: appStyle.txtColor6 },
  },
});

const TextInputRender = ({ label, onChange, value, validity, InputProps = {}, type = '' }) => (
  <div
    style={{
      display: 'flex',
      marginTop: '8px',
      marginBottom: '16px',
    }}
  >
    <TextField
      type={type}
      label={label}
      InputLabelProps={{
        shrink: true,
      }}
      style={{
        flex: '1',
      }}
      InputProps={{
        style: {
          fontSize: '0.875rem',
          color: appStyle.txtColor3,
        },
        endAdornment: <ValidityAdornment validity={validity} />,
        ...InputProps,
      }}
      onChange={event => {
        event.preventDefault();
        onChange(event.target.value);
      }}
      value={value || ''}
    />
  </div>
);

TextInputRender.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  validity: PropTypes.object.isRequired,
  InputProps: PropTypes.any,
  type: PropTypes.string,
};

TextInputRender.defaultProps = { value: null, InputProps: null, type: null };

const TextInput = withStyles(inputStyles)(TextInputRender);

const TestFormInput = ({ onChange, fieldType, label, value, validity, ...otherProps }) => {
  switch (fieldType) {
    case 'IMAGE':
    case 'TABLE': {
      return null;
    }
    case 'INTEGER': {
      return (
        <TextInput
          onChange={v => onChange(v)}
          value={value}
          label={label}
          validity={validity}
          type="number"
        />
      );
    }
    default: {
      return (
        <TextInput
          onChange={v => onChange(v)}
          value={value}
          label={label}
          validity={validity}
          {...otherProps}
        />
      );
    }
  }
};
TestFormInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  fieldType: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  validity: PropTypes.object.isRequired,
};

TestFormInput.defaultProps = { value: null };

TextInputRender.defaultProps = { value: null, InputProps: null, type: null };

const TestFormRender = ({
  fields,
  values,
  clubs,
  back,
  notifyChange,
  // memoizeInput,
  // memoizeSenderInput,
  formIsValid,
  senderValues,
  submit,
  showAlert,
  actionTypeId,
  colors,
}) => (
  <div style={{ overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 8px',
        // borderBottom: appStyle.border1,
        zIndex: 1,
        alignItems: 'center',
        background: colors.appBar,
        boxShadow:
          'rgba(0, 0, 0, 0.2) 0px 0px 0px -1px, rgba(0, 0, 0, 0.14) 0px 0px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px',
      }}
    >
      {!!back && (
        <IconButton
          style={{
            position: 'relative',
            right: '9px',
          }}
          // style={{ margin: '2px' }}
          onClick={e => {
            e.preventDefault();
            back();
          }}
        >
          <PreviousIcon style={{ color: appStyle.txtColor3 }} />
        </IconButton>
      )}
      <div style={{ flex: 1 }}>
        <Typography style={{ color: appStyle.txtColor3 }}>
          {`${utils.getLang('smartmessaging.textActionForm.title')} ${utils.getLang(
            `smartmessaging.actionType.${ActionTypeById[actionTypeId]}`
          )}`}
        </Typography>
        <Typography style={{ color: appStyle.txtColor3 }} variant="body2">
          {utils.getLang('smartmessaging.textActionForm.subtitle')}
        </Typography>
      </div>
      <IconButton
        style={{ margin: '2px' }}
        disabled={!formIsValid}
        onClick={e => {
          e.preventDefault();
          submit(() =>
            showAlert({ msg: utils.getLang('smartmessaging.campaignEditor.testAction.success') })
          );
        }}
      >
        <SendIcon style={{ color: appStyle.txtColor3 }} />
      </IconButton>
    </div>
    <div style={{ overflow: 'auto', paddingBottom: '16px', marginBottom: '4px' }}>
      <div style={{ margin: '8px' }}>
        <MemoInput
          clubs={clubs}
          onChange={v => {
            notifyChange('clubId', v);
          }}
          value={senderValues.clubId.value}
          validity={senderValues.clubId.validity}
          fKey="clubId"
        />
        <MemoInput
          value={senderValues.recipient.value}
          onChange={v => {
            notifyChange('recipient', v);
          }}
          fieldType={actionTypeId === 2 ? 'EMAIL' : 'PHONENUMBER'}
          label={utils.getLang('smartmessaging.campaignEditor.testActionForm.recipient')}
          validity={senderValues.recipient.validity}
          fKey="recipient"
        />
        {Object.keys(fields).map(key => (
          <MemoInput
            key={key}
            fKey={key}
            value={values[key].value}
            onChange={v => notifyChange(key, v)}
            fieldType={fields[key]}
            label={utils.getLang(`smartmessaging.resultfield.${key}`)}
            validity={values[key].validity}
          />
        ))}
      </div>
    </div>
  </div>
);

TestFormRender.propTypes = {
  notifyChange: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  clubs: PropTypes.array.isRequired,
  back: PropTypes.func,
  formIsValid: PropTypes.bool.isRequired,
  senderValues: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  showAlert: PropTypes.func.isRequired,
  actionTypeId: PropTypes.number.isRequired,
  colors: PropTypes.object,
};

TestFormRender.defaultProps = { back: null, colors: { appBar: appStyle.bgColor4 } };

export default withTestFormController(TestFormRender);
