import unescapeHtml from 'src/utils/unescapehtml';
import utils from 'src/utils/utils';

const Gsm7Bit = {
  data(message, isGsm7Bit, min) {
    const me = Gsm7Bit;
    const data = {};
    data.isUnicode = !isGsm7Bit; // ! Resa.Sms.Gsm7Bit.isGsm7Bit(message);
    data.invalidChar = isGsm7Bit ? me.invalidGsm7BitChar(message) : [];
    data.numOfByte = data.isUnicode ? message.length : this.numOfBytes(message);
    if (min) data.numOfByte += min;
    data.monoPartLength = data.isUnicode ? 70 : 160;
    data.multiPartLength = data.isUnicode ? 67 : 153;
    data.numOfPart = me.numOfPart(data.numOfByte, data.monoPartLength, data.multiPartLength);
    return data;
  },

  isGsm7Bit(message) {
    // const regexp = new RegExp(
    //   '^[A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!"#$%&\'()*+,\\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]*$'
    // );
    const regexp = new RegExp(
      '^[A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅåáíóúñ\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!"#$%&\'()*+,\\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]*$'
    );
    return regexp.test(message);
  },

  numOfPart(nByte, max, part) {
    if (nByte > max) {
      return Math.ceil(nByte / part);
    }
    return 1;
  },

  numOfBytes(message) {
    const regexp = new RegExp('[{}\\\\\\[~\\]|€^]', 'g');

    return (message.match(regexp) ? message.match(regexp).length : 0) + message.length;
  },

  invalidGsm7BitChar(message) {
    const regexp = new RegExp(
      '[^A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅåáíóúñ\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!"#$%&\'()*+,\\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]',
      'g'
    );
    const matches = message.match(regexp);
    if (!matches) {
      return [];
    }
    const uniqueMap = {};
    const uniqueList = [];
    for (let i = 0; i < matches.length; i += 1) {
      for (let j = 0; j < matches[i].length; j += 1) {
        uniqueMap[matches[i][j]] = true;
      }
    }
    Object.keys(uniqueMap).forEach(key => {
      uniqueList.push(key);
    });

    return uniqueList;
  },
};

const getContentSMS = pVal => {
  let contentSms = pVal;
  contentSms = contentSms.replace(/<div>/g, '');
  contentSms = contentSms.replace(/<\/div>/g, '');
  contentSms = contentSms.replace(/<br>/g, '\n');
  contentSms = contentSms.replace(/&nbsp;/g, ' ');
  contentSms = contentSms.replace(/^\n+|\n+$/g, '');

  contentSms = contentSms.split(/<br \/>/).join('\n');
  return contentSms;
};

const htmlToFreemarkerSms = val => {
  let goodSms = getContentSMS(val);

  goodSms = goodSms.replace(
    /<a(.*?) model="(.*?)"(.*?) alt="internalLink"(.*?)>(.*?)<\/a>/g,
    '<@$2 track="false"/>'
  );
  goodSms = goodSms.replace(
    /<a(.*?) alt="internalLink"(.*?) model="(.*?)"(.*?)>(.*?)<\/a>/g,
    '<@$3 />'
  );

  // get mailto
  goodSms = goodSms.replace(
    /<a(.+?)href="mailto:(.+?)"(.*?)>(.+?)<\/a>/g,
    '<@emailLink text="$4" mailto="$2" track="true" format="brut" $3/>'
  );

  // get external link
  // goodSms = goodSms.replace(
  //   /<a(.+?)href="(.+?)"(.+?)>(.+?)<\/a>/g,
  //   '<@externalLink title="$3" text="$3" url="$2" track="true" format="brut" $3/>'
  // );
  goodSms = goodSms.replace(
    /<a.+?href="(.+?)".+?>(.+?)<\/a>/g,
    '<@externalLink url="$1" text="$2"/>'
  );

  // get tables
  // goodSms = goodSms.replace(
  //   /<input alt="(.+?)" table="true" type="button" value="(.+?)" \/>/g,
  //   '<@$1 tableTitle="$2"/>'
  // );
  // CONTACT CENTER FIELDS
  goodSms = goodSms.replace(
    /<input alt=['|"](contactCenterMail|contactCenterPhone)['|"](.+?)type=['|"]button['|"](.+?)value=['|"](.+?)['|"](.+?)\/>/g,
    `<@$1 />`
  );
  // get tags
  // eslint-disable-next-line
  goodSms = goodSms.replace(/<input alt="(.+?)" type="button" value="(.+?)" \/>/g, '${$1!""}');
  // eslint-disable-next-line
  goodSms = goodSms.replace(/<input alt='(.+?)' type='button' value='(.+?)' \/>/g, '${$1!""}');

  goodSms = unescapeHtml(goodSms);

  // goodSms = goodSms.replace(/\n/g, '');
  const tmpDiv = document.createElement('DIV');
  tmpDiv.innerHTML = goodSms.replace(/<br\/?>/gi, '\n');
  const smsText = (tmpDiv.innerText || tmpDiv.textContent)
    .replace(/\s+$/g, '')
    .replace(/\u00A0/g, ' ');
  return smsText;
};

const getCount = (pVal, min) => {
  let val = getContentSMS(pVal);
  val = val.replace(/<input alt="(.+?)" type="button" value="(.+?)" \/>/gi, '1234567890');
  val = val.replace(/<input alt='(.+?)' type='button' value='(.+?)' \/>/gi, '1234567890');

  const tmpDiv = document.createElement('DIV');

  tmpDiv.innerHTML = val.replace(/\n/g, '1');
  const smsText = (tmpDiv.innerText || tmpDiv.textContent)
    .replace(/\s+$/g, '')
    .replace(/\u00A0/g, ' ');
  const smsDetect = Gsm7Bit.data(smsText, true, min);
  const rez = {
    length: smsDetect.numOfByte,
    numOfPart: smsDetect.numOfPart,
    total:
      smsDetect.numOfPart === 1
        ? smsDetect.monoPartLength
        : `${smsDetect.multiPartLength * smsDetect.numOfPart}`,
    invalidChar: smsDetect.invalidChar,
  };

  return rez;
};

const checkSMSContentValidity = (content, min) => {
  let isValid = true;
  const invalidities = [];
  const gsm7BitValidation = getCount(content, min);
  const hasInvalidChars = !!gsm7BitValidation.invalidChar.length;

  if (hasInvalidChars) {
    isValid = false;
    invalidities.push(utils.getLang('smartmessaging.contentEditor.sms.content.invalidChars'));
  }

  if (!content) {
    isValid = false;
    invalidities.push(utils.getLang('smartmessaging.contentEditor.sms.emptyContent'));
  }

  return { isValid, invalidities, gsm7BitValidation, isEmpty: !content };
};

export default { getContentSMS, htmlToFreemarkerSms, getCount, Gsm7Bit, checkSMSContentValidity };
