import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import AppStyle from 'src/consts/appStyle';
import utils from 'src/utils/utils';

import MenuButton from './MenuButton';

const styles = () => ({
  popover: { padding: 0, margin: 0 },
  menuItem: {
    color: AppStyle.txtColor3,
    outline: 'none',
    cursor: 'pointer',
    // margin: '1px 0px',
    fontSize: '0.9rem',
    // borderBottom: AppStyle.border1,
    display: 'block',
    textAlign: 'left',
    transition: 'background 0.5s ease, background 0.5s ease',
    padding: '12px 16px 12px 24px',
    '&.selected': {
      background: AppStyle.bgColor3,
      // color: AppStyle.txtColor3,
      fontWeight: 450,
      borderLeft: `4px solid ${AppStyle.bgColor1}`,
    },
    // fontSize: '1rem',
    '&:hover': {
      // background: AppStyle.bgColor1,
      // color: AppStyle.txtColor2,
      // outline: `4px solid ${AppStyle.bgColor3}`,
      background: AppStyle.bgColor2,
      // opacity: 0.8,
      // fontSize: '0.85rem',
    },
    '&:hover.selected': {
      background: AppStyle.bgColor3,
      opacity: 0.9,
      // outline: `4px solid ${AppStyle.bgColor1}`,
      // fontSize: '0.85rem',
    },
  },
  input: {
    backgroundColor: AppStyle.bgColor9,
    color: AppStyle.txtColor3,
    textAlign: 'left',
    padding: '6px 24px 6px 4px',
  },
});

const MenuSelect = ({
  classes,
  selectMenuValue,
  onSelectChange,
  actionCoActivated,
  parametersActivated,
  automatedActivated,
  customTemplateActivated,
}) => (
  <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
    {automatedActivated && (
      <MenuButton
        className={classes.menuItem}
        selected={selectMenuValue === 0}
        title={utils.getLang('smartmessaging.mainMenu.campaigns')}
        onSelect={() => {
          onSelectChange(0);
        }}
      />
    )}

    {actionCoActivated && (
      <MenuButton
        className={classes.menuItem}
        selected={selectMenuValue === 2}
        title={utils.getLang('smartmessaging.mainMenu.massAction')}
        onSelect={() => {
          onSelectChange(2);
        }}
      />
    )}
    {customTemplateActivated && (
      <MenuButton
        className={classes.menuItem}
        selected={selectMenuValue === 3}
        title={utils.getLang('smartmessaging.mainMenu.customTemplates')}
        onSelect={() => {
          onSelectChange(3);
        }}
      />
    )}
    {parametersActivated && (
      <MenuButton
        className={classes.menuItem}
        selected={selectMenuValue === 1}
        title={utils.getLang('smartmessaging.mainMenu.config')}
        onSelect={() => {
          onSelectChange(1);
        }}
      />
    )}
    <MenuButton
      className={classes.menuItem}
      selected={selectMenuValue === 4}
      title={utils.getLang('smartmessaging.mainMenu.sentMessages')}
      onSelect={() => {
        onSelectChange(4);
      }}
    />
  </div>
);

MenuSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  selectMenuValue: PropTypes.number.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  actionCoActivated: PropTypes.bool.isRequired,
  parametersActivated: PropTypes.bool.isRequired,
  automatedActivated: PropTypes.bool.isRequired,
  customTemplateActivated: PropTypes.bool.isRequired,
};

const mapDispatchToProps = dispatch => ({
  onSelectChange: option => dispatch({ type: 'SWITCH_MAIN_CONTENT', value: option }),
});

const mapStateToProps = state => ({
  selectMenuValue: state.selectMenuValue,
  actionCoActivated: state.app.config['smartm-punctual:allow'],
  parametersActivated: state.app.config['smartm-param:allow'],
  automatedActivated:
    state.app.config['smartm-automation:allow'] || state.app.config['smartm-smartjourney:allow'],
  customTemplateActivated: state.app.config['smartm-template:allow'],
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MenuSelect));
