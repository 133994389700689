import { createReducer } from '@acemarke/redux-starter-kit';

const initialState = {
  order: 'asc',
  orderBy: 'name',
  filter: '',
  sortFns: false,
};

const getSortedCampaigns = state => {
  const campaignList = Object.values(Object.assign({}, state.campaignList.campaignListById));
  const { orderBy, order, sortFns } = state.campaignListView;

  if (!sortFns) {
    return order === 'asc'
      ? campaignList.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1))
      : campaignList.sort((a, b) => (a[orderBy] < b[orderBy] ? 1 : -1));
  }

  return order === 'asc' ? campaignList.sort(sortFns.asc) : campaignList.sort(sortFns.desc);
};

const sortCampaigns = (state, action) => ({
  ...state,
  orderBy: action.value.orderBy,
  order: action.value.orderBy === state.orderBy && state.order === 'desc' ? 'asc' : 'desc',
  sortFns: action.value.sortFns || false,
});

const filterCampaigns = state => state;

export default createReducer(initialState, {
  SORT_CAMPAIGNS: sortCampaigns,
  FILTER_CAMPAIGNS: filterCampaigns,
});
export { getSortedCampaigns };
