/* eslint-disable no-underscore-dangle */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/styles';
import {
  Switch,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Table,
  Fade,
  Fab,
  Tooltip,
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import WarningIcon from '@material-ui/icons/Warning';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import CampaignListHead from './CampaignListHead';
import CampaignItemMenu from './CampaignItemMenu';
import Search from 'src/components/common/filters/Search';
// import CampaignEditor from '../campaignEditor/CampaignEditor';
import { mapRequestModelByRMTypeId } from 'src/reducers/requestModelList';
import { getSortedCampaigns } from 'src/reducers/campaignListView';
import {
  deleteCampaign,
  loadInitialDatas,
  activateCampaign,
  getCsv,
} from 'src/reducers/campaignList';
import { editCampaign } from 'src/reducers/campaignEditor';
import utils from 'src/utils/utils';
import Loader from 'src/components/common/Loader';
import appStyle from 'src/consts/appStyle';
import { filterFn, quickFilterFn } from 'src/components/common/filters/campaignFiltersUtils';

import filtrable from 'src/components/common/hocs/filtrable';

const pendingEl = () => <i>{utils.getLang('smartmessaging.campaignList.pendingElement')}</i>;

const SpanedTableCell = ({ children, style }) => (
  <TableCell style={style}>
    <span className="cellspan">{children}</span>
  </TableCell>
);

SpanedTableCell.propTypes = {
  children: PropTypes.any.isRequired,
  style: PropTypes.object.isRequired,
};

const CampaignItem = connect(() => ({}), {
  doDeleteCampaign: deleteCampaign,
  doEditCampaign: editCampaign,
  doActivateCampaign: activateCampaign,
  doGetCsv: getCsv,
  // copyCampaign: campaign => ({ type: 'DUPLICATE_CAMPAIGN', value: campaign }),
})(
  ({
    campaign,
    doDeleteCampaign,
    doGetCsv,
    doEditCampaign, // copyCampaign,
    requestModelByRMTypeId,
    recipesById,
    doActivateCampaign,
    selected,
    selectRow,
    goToCmpStep,
    isNodeMapped,
  }) => (
    // // <Fade in timeout={500}>
    // <CSSTransition timeout={500} classNames="item">
    <TableRow
      hover
      selected={selected}
      onClick={e => {
        e.preventDefault();
        selectRow(campaign.id);
      }}
      onDoubleClick={e => {
        e.preventDefault();
        const asyncEdit = async () => {
          await doEditCampaign(campaign);
          goToCmpStep(2, { initialStep: 0, backToMain: () => goToCmpStep(0) });
        };
        asyncEdit();
      }}
    >
      <SpanedTableCell style={{ color: appStyle.txtColor3 }}>
        <CampaignItemMenu
          options={[
            {
              name: utils.getLang('smartmessaging.campaignList.action.edit'),
              action: async () => {
                await doEditCampaign(campaign);
                goToCmpStep(2, { initialStep: 0, backToMain: () => goToCmpStep(0) });
              },
            },
            {
              name: utils.getLang('smartmessaging.campaignList.action.csv'),
              action: () => doGetCsv(campaign),
              disabled: !campaign.recipeId,
              hidden: campaign.eventDriven,
            },
            {
              name: utils.getLang('smartmessaging.campaignList.action.delete'),
              action: () => doDeleteCampaign(campaign),
            },
            {
              name: utils.getLang('smartmessaging.campaignList.action.showHistory'),
              hidden: !(
                campaign.requestModelTypeId &&
                requestModelByRMTypeId[campaign.requestModelTypeId] &&
                requestModelByRMTypeId[campaign.requestModelTypeId].id
              ),
              action: () =>
                goToCmpStep(1, {
                  campaign,
                  rmId:
                    campaign.requestModelTypeId &&
                    requestModelByRMTypeId[campaign.requestModelTypeId] &&
                    requestModelByRMTypeId[campaign.requestModelTypeId].id,
                  backToMain: () => goToCmpStep(0),
                }),
            },
          ]}
          selectRow={selectRow}
          campaign={campaign}
          // editCampaign={doEditCampaign}

          // editCampaign={() =>
          //   goToStep(2, { campaign, initialStep: 0, backToMain: () => goToStep(0) })
          // }
          // deleteCampaign={doDeleteCampaign}
          // showHistory={() => goToStep(1, { campaign, backToMain: () => goToStep(0) })}
          // getCsv={doGetCsv}
        />
      </SpanedTableCell>
      <TableCell scope="row" style={{ color: appStyle.txtColor3, fontWeight: 'bold' }}>
        <div style={{ display: 'flex' }}>
          {!!campaign.duplicates.length && (
            <div style={{ display: 'flex', alignItems: 'center', margin: '2px' }}>
              <Tooltip title={utils.getLang('smartmessaging.campaignAction.duplicateWarning')}>
                <WarningIcon style={{ color: appStyle.txtColor6 }} />
              </Tooltip>
            </div>
          )}
          <div style={{ display: 'flex', alignItems: 'center', margin: '2px' }}>
            {campaign.name}
          </div>
        </div>
      </TableCell>
      <SpanedTableCell style={{ color: appStyle.txtColor3 }}>
        {(campaign.recipeId &&
          requestModelByRMTypeId[recipesById[campaign.recipeId].requestModelTypeId] &&
          utils.getLang(
            `smartmessaging.requestmodel.label.${
              requestModelByRMTypeId[recipesById[campaign.recipeId].requestModelTypeId].name
            }`
          )) ||
          pendingEl()}
      </SpanedTableCell>
      <SpanedTableCell style={{ color: appStyle.txtColor3 }}>
        {(campaign.eventDriven === false && utils.getLang('smartmessaging.campaignTrigger.time')) ||
          (campaign.eventDriven === true &&
            utils.getLang('smartmessaging.campaignTrigger.action')) ||
          pendingEl() //
        }
      </SpanedTableCell>
      <SpanedTableCell style={{ color: appStyle.txtColor3 }}>
        {campaign.actionType ? campaign.actionType : pendingEl()}
      </SpanedTableCell>
      <SpanedTableCell style={{ color: appStyle.txtColor3 }}>
        {new Date(campaign.creationDate).toLocaleDateString()}
      </SpanedTableCell>
      {isNodeMapped && (
        <SpanedTableCell style={{ color: appStyle.txtColor3 }}>
          {campaign.networkNodeName || '--'}
        </SpanedTableCell>
      )}
      <SpanedTableCell style={{ color: appStyle.txtColor3 }}>
        {campaign.creationUserName || '--'}
      </SpanedTableCell>
      <SpanedTableCell style={{ color: appStyle.txtColor3 }}>
        {campaign.isPlanned
          ? utils.getLang('smartmessaging.campaignList.item.isPlanned')
          : utils.getLang('smartmessaging.campaignList.item.isNotPlanned')}
      </SpanedTableCell>
      <SpanedTableCell padding="checkbox" style={{ color: appStyle.txtColor3 }}>
        <Switch
          value={campaign.enabled ? '1' : '0'}
          checked={campaign.enabled}
          onChange={() => {
            doActivateCampaign(campaign);
          }}
        />
      </SpanedTableCell>
    </TableRow>
    // </CSSTransition>
    // </Fade>
  )
);

CampaignItem.propTypes = {
  campaign: PropTypes.object.isRequired,
  requestModelByRMTypeId: PropTypes.object,
  recipesById: PropTypes.object,
  classes: PropTypes.object,
  selected: PropTypes.bool.isRequired,
  selectRow: PropTypes.func.isRequired,
};

const styles = theme => ({
  root: {
    width: '100%',
  },
  tableWrapper: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  campaignContainer: {
    // position: 'relative',
    // margin: '4px',
    // marginTop: '24px',
    overflow: 'auto',
  },
  editorBtn: {
    zIndex: 2,
    backgroundColor: appStyle.bgColor1,
    color: appStyle.txtColor2,
    '&:hover': {
      background: appStyle.bgColor1,
      opacity: 0.9,
    },
    position: 'absolute',
    right: '10px',
    top: '40px',
  },
});

class CampaignList extends Component {
  constructor(props) {
    super(props);
    this.select = this.select.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.updateQuickFilter = this.updateQuickFilter.bind(this);
    this.quickFiltered = this.quickFiltered.bind(this);
  }

  state = {
    isLoading: true,
    selected: null,
    quickFilterValue: '',
  };

  async componentDidMount() {
    this._isMounted = true;
    const { doLoadInitialDatas } = this.props;
    await doLoadInitialDatas();
    if (this._isMounted) this.setLoading(false);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setLoading(loaded) {
    this.setState({ isLoading: loaded });
  }

  select(id) {
    this.setState({ selected: id });
  }

  updateQuickFilter(value) {
    this.setState({ quickFilterValue: value });
  }

  quickFiltered() {
    const quickFilters = [
      { key: 'name', value: this.state.quickFilterValue },
      {
        key: 'recipeId',
        value: this.state.quickFilterValue,
        type: 'TEXT',
        convertValue: recipeId =>
          (recipeId &&
            this.props.requestModelByRMTypeId[
              this.props.recipesById[recipeId].requestModelTypeId
            ] &&
            utils.getLang(
              `smartmessaging.requestmodel.label.${
                this.props.requestModelByRMTypeId[
                  this.props.recipesById[recipeId].requestModelTypeId
                ].name
              }`
            )) ||
          utils.getLang('smartmessaging.campaignList.pendingElement'),
      },
    ];
    if (this.state.quickFilterValue) {
      return this.props.filteredData.filter(quickFilterFn(quickFilters));
    }
    return this.props.filteredData;

    // if (quickFilterValue) return filteredData;
    // // const quickFitrableColumns = ['name'];
    // return filteredData.filter(filterFn({ key: 'name', value }));
  }

  render() {
    const {
      classes,
      order,
      orderBy,
      sortCampaigns,
      goToCmpStep,
      filteredData,
      doFilter,
      addFilter,
      requestModelByRMTypeId,
      recipesById,
      removeFilter,
      clearFilters,
      selectedFilters,
      createCampaign,
    } = this.props;
    const { isLoading, selected } = this.state;
    return (
      <Fragment>
        <Fab
          onClick={e => {
            e.preventDefault();
            createCampaign();
            goToCmpStep(2, { initialStep: 0, backToMain: () => goToCmpStep(0) });
          }}
          className={classes.editorBtn}
          // variant="fab"
          aria-label="add"
        >
          <AddIcon />
        </Fab>
        <Fade in timeout={1000}>
          <div style={{ textAlign: 'left', margin: '8px' }}>
            <Search
              updateQuickFilter={this.updateQuickFilter}
              filterProps={{
                filteredData,
                doFilter,
                addFilter,
                removeFilter,
                clearFilters,
                selectedFilters,
              }}
            />
          </div>
        </Fade>
        <Fade in timeout={1000}>
          <div className={`${classes.campaignContainer}`}>
            <Paper className={classes.root} elevation={0}>
              <div className={classes.tableWrapper}>
                <Table aria-labelledby="tableTitle">
                  <CampaignListHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={sortCampaigns}
                    addFilter={addFilter}
                    isNodeMapped={utils.isNodeMapped()}
                  />
                  <TransitionGroup component={TableBody}>
                    {this.quickFiltered().map(campaign => (
                      <CSSTransition timeout={300} classNames="fade" key={campaign.id}>
                        <CampaignItem
                          campaign={campaign}
                          selected={selected === campaign.id}
                          selectRow={this.select}
                          goToCmpStep={goToCmpStep}
                          requestModelByRMTypeId={requestModelByRMTypeId}
                          recipesById={recipesById}
                          isNodeMapped={utils.isNodeMapped()}
                        />
                      </CSSTransition>
                    ))}
                  </TransitionGroup>
                </Table>
              </div>
            </Paper>
          </div>
        </Fade>
        <Fade in={isLoading}>
          <div className={`${classes.campaignContainer}`} style={{ flex: 1 }}>
            <Loader
              wrapperClass="ghostWrapperClass"
              text={utils.getLang('smartmessaging.campaigns.mainDataLoading')}
            />
          </div>
        </Fade>
      </Fragment>
    );
  }
}

CampaignList.propTypes = {
  classes: PropTypes.object.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  filteredData: PropTypes.array.isRequired,
  sortCampaigns: PropTypes.func.isRequired,
  doLoadInitialDatas: PropTypes.func.isRequired,
  goToCmpStep: PropTypes.func.isRequired,
  doFilter: PropTypes.func.isRequired,
  addFilter: PropTypes.func.isRequired,
  requestModelByRMTypeId: PropTypes.object.isRequired,
  recipesById: PropTypes.object.isRequired,
  removeFilter: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  selectedFilters: PropTypes.array.isRequired,
  createCampaign: PropTypes.func.isRequired,
};

const FiltrableCampaignList = filtrable(props => ({
  filterFn,
  filtrableData: props.sortedData,
}))(CampaignList);

const mapStateToProps = state => ({
  campaignListById: state.campaignList.campaignListById,
  order: state.campaignListView.order,
  orderBy: state.campaignListView.orderBy,
  sortedData: getSortedCampaigns(state),
  requestModelByRMTypeId: mapRequestModelByRMTypeId(state),
  recipesById: state.recipeList.recipeListById,
});

const actionCreators = {
  onCampaignListLoaded: campaignList => ({ type: 'RECEIVE_CAMPAIGNS', value: campaignList }),
  doLoadInitialDatas: loadInitialDatas,
  sortCampaigns: (orderBy, sortFns) => ({ type: 'SORT_CAMPAIGNS', value: { orderBy, sortFns } }),
  // clearCurrentAction: () => ({ type: 'CLEAR_CURRENT_ACTION' }),
  createCampaign: () => ({ type: 'CREATE_CAMPAIGN' }),
};

// const ConnectedComponent = connect(mapStateToProps, actionCreators)(
//   withStyles(styles)(FiltrableCampaignList)
// );

// const CampaignListWithEditor = props => (
//   <Fragment>
//     <CampaignEditor buttonStyle={{ position: 'absolute', right: '10px', top: '40px' }} />
//     <ConnectedComponent {...props} />
//   </Fragment>
// );

export default connect(mapStateToProps, actionCreators)(withStyles(styles)(FiltrableCampaignList));
