import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { IconButton, TablePagination } from '@material-ui/core';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import utils from 'src/utils/utils';

const paginationActionsStyles = {
  root: {
    flexShrink: 0,
  },
};

const PaginationActions = ({ classes, count, page, rowsPerPage, onPageChange }) => (
  <div className={classes.root}>
    <IconButton onClick={e => onPageChange(e, 0)} disabled={page === 0}>
      <FirstPageIcon />
    </IconButton>
    <IconButton onClick={e => onPageChange(e, page - 1)} disabled={page === 0}>
      <KeyboardArrowLeft />
    </IconButton>
    {utils.getLang('smartmessaging.massAction.requestResultList.pagination.pageNumber')}
    <input
      type="number"
      style={{ margin: '0 4px', textAlign: 'right' }}
      value={page + 1}
      min="1"
      max={Math.max(0, Math.ceil(count / rowsPerPage))}
      onChange={e => {
        // Math.max(0, Math.ceil(count / rowsPerPage)) est le nombre de pages
        if (!(e.target.value === '')) {
          let p = parseInt(e.target.value, 10) - 1;
          if (p > Math.max(0, Math.ceil(count / rowsPerPage) - 1)) {
            p = Math.max(0, Math.ceil(count / rowsPerPage) - 1);
          }
          if (p < 0) p = 0;
          onPageChange(e, p);
        }
      }}
    />
    {`/${Math.max(0, Math.ceil(count / rowsPerPage))}`}
    <IconButton
      onClick={e => onPageChange(e, page + 1)}
      disabled={page >= Math.ceil(count / rowsPerPage) - 1}
    >
      <KeyboardArrowRight />
    </IconButton>
    <IconButton
      onClick={e => onPageChange(e, Math.max(0, Math.ceil(count / rowsPerPage) - 1))}
      disabled={page >= Math.ceil(count / rowsPerPage) - 1}
    >
      <LastPageIcon />
    </IconButton>
  </div>
);

PaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

PaginationActions.defaultProps = {
  onPageChange: null,
};
const StyledActions = withStyles(paginationActionsStyles)(PaginationActions);

const Pagination = ({ pages, rowsPerPage, page, setPage, setRowsPerPage }) => (
  <TablePagination
    labelRowsPerPage={utils.getLang('smartmessaging.table.rowsPerPage')}
    labelDisplayedRows={({ from, to, count }) =>
      utils.stringFormat(
        utils.getLang('smartmessaging.massAction.requestResultList.paginationFromTo'),
        [from, to, count]
      )
    }
    rowsPerPageOptions={[20, 50, 100, 200]}
    colSpan={1}
    count={pages}
    rowsPerPage={rowsPerPage}
    page={page}
    SelectProps={{
      native: true,
    }}
    onPageChange={(e, p) => {
      setPage(p);
    }}
    onRowsPerPageChange={ev => {
      setPage(0);
      setRowsPerPage(parseInt(ev.target.value, 10));
    }}
    ActionsComponent={StyledActions}
  />
);

Pagination.propTypes = {
  pages: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
};

export default Pagination;
