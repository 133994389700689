import React from 'react';
import PropTypes from 'prop-types';
import { Select, Input } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import OperatorType from 'src/enums/operatorType';
import appStyle from 'src/consts/appStyle';
import Utils from 'src/utils/utils';

const styles = {
  operatorSelectInput: { color: appStyle.txtColor3, fontSize: '0.975rem' },
  popover: { padding: 0, margin: 0 },
  menuItem: {
    color: appStyle.txtColor3,
    outline: 'none',
    cursor: 'pointer',
    fontSize: '0.975rem',
    padding: '8px',
    '&:hover': {
      background: appStyle.bgColor1,
      opacity: 0.9,
      color: appStyle.txtColor2,
    },
  },
};

const OperatorSelectRender = ({ classes, value, allowedOperators, onChange, disabled }) => (
  <Select
    MenuProps={{
      MenuListProps: { style: { padding: 0, margin: 0 } },
      PopoverClasses: {
        paper: classes.popover,
      },
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
      getContentAnchorEl: null,
      marginThreshold: 0,
    }}
    input={
      <Input
        inputProps={{
          'aria-label': 'Description',
          className: classes.operatorSelectInput,
        }}
      />
    }
    disabled={disabled}
    onChange={event => {
      event.preventDefault();
      onChange(event.target.value);
    }}
    value={value || 0}
  >
    {allowedOperators.map(op => (
      <div key={op} value={OperatorType[op]} className={classes.menuItem}>
        {Utils.getLang(`smartmessaging.recipeSetupOperator.${op}`)}
      </div>
    ))}
  </Select>
);

OperatorSelectRender.propTypes = {
  allowedOperators: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.number,
};
OperatorSelectRender.defaultProps = {
  value: null,
  disabled: false,
};

export default withStyles(styles)(OperatorSelectRender);
