import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import utils from 'src/utils/utils';
// import HelperCard from 'src/components/common/HelperCard';
import ActionBtn from 'src/components/common/ActionBtn';
import SMJModelItem from './SMJModelItem';
import appStyle from 'src/consts/appStyle';

const InfosCardRender = ({ title, content, classes }) => (
  <Card elevation={1} className={classes.card}>
    <CardContent className={classes.content} classes={{ root: classes.contentRoot }}>
      {title && (
        <Typography className={classes.title} variant="body2">
          {title}
        </Typography>
      )}
      {content && (
        <Typography className={classes.description} variant="body2">
          {content}
        </Typography>
      )}
    </CardContent>
  </Card>
);

InfosCardRender.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  content: PropTypes.any.isRequired,
};

InfosCardRender.defaultProps = {
  title: null,
};

const InfosCard = withStyles(theme => ({
  content: {
    '&.selectable': {
      cursor: 'pointer',
      opacity: 0.7,
      '&:hover': {
        opacity: 1,
      },
    },
    flex: 1,
    overflow: 'auto',
  },
  contentRoot: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: `${theme.spacing(2)}px !important`,
  },
  card: {
    textAlign: 'left',
    marginBottom: theme.spacing(1),
    border: appStyle.border1,
    display: 'flex',
    // backgroundColor: AppStyle.bgColor2,
    // flex: 1,
  },
  title: {
    color: appStyle.txtColor4,
    margin: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: theme.spacing(1),
  },
  description: {
    color: appStyle.txtColor4,
    margin: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))(InfosCardRender);

InfosCard.propTypes = {
  groupModel: PropTypes.object.isRequired,
  setupPremadeGroup: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  rmByRmTypeId: PropTypes.number.isRequired,
};

const PremadeGroupDesc = ({ groupModel, setupPremadeGroup, name, rmByRmTypeId }) => (
  <InfosCard
    title={utils.getLang('smartmessaging.campaigns.grouped.createMode.withModel.description.title')}
    content={
      <div>
        <SMJModelItem disabledSelect smjModel={groupModel} />
        <div>
          {utils.getLang(`smartmessaging.campaigns.grouped.gropdescription.availableCampaigns`)}
        </div>
        <ul>
          {groupModel.campaigns.map(c => (
            <li key={c.key}>
              <span style={{ fontWeight: 500 }}>
                {utils.getLang(`smartmessaging.requestmodel.label.${rmByRmTypeId[c.type].name}`)}
              </span>
              <br />
              {utils.getLang(`smartmessaging.campaigns.grouped.campaignModel.name.${c.key}`)}
            </li>
          ))}
        </ul>
        <div style={{ textAlign: 'right' }}>
          <ActionBtn
            disabled={!name}
            onClick={() => {
              setupPremadeGroup();
            }}
          >
            {utils.getLang('smartmessaging.campaigns.grouped.setupPremadeGroup')}
          </ActionBtn>
        </div>
      </div>
    }
  />
);
PremadeGroupDesc.propTypes = {
  groupModel: PropTypes.object.isRequired,
  setupPremadeGroup: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  rmByRmTypeId: PropTypes.object.isRequired,
};

const Content = ({ mode, groupModel, name, createEmptyGroup, setupPremadeGroup, rmByRmTypeId }) => {
  switch (mode) {
    case 'empty':
      return (
        <InfosCard
          classes={null}
          // title={utils.getLang('smartmessaging.campaigns.grouped.createMode.empty.description')}
          content={
            <div>
              <div style={{ marginBottom: '8px' }}>
                {/* {utils.getLang('smartmessaging.campaigngroup.creator.createEmptyGroup.description')} */}
                {utils.getLang('smartmessaging.campaigns.grouped.createMode.empty.description')}
              </div>
              <div style={{ textAlign: 'right' }}>
                <ActionBtn
                  disabled={!name}
                  onClick={() => {
                    createEmptyGroup();
                  }}
                >
                  {utils.getLang('smartmessaging.campaigns.grouped.createGroup')}
                </ActionBtn>
              </div>
            </div>
          }
        />
      );
    case 'premade':
      // return 'UNDERBUILD';
      return groupModel ? (
        <PremadeGroupDesc
          groupModel={groupModel}
          setupPremadeGroup={setupPremadeGroup}
          name={name}
          rmByRmTypeId={rmByRmTypeId}
        />
      ) : null;
    default:
      return (
        <InfosCard
          // title={utils.getLang('smartmessaging.campaigns.grouped.createMode.empty.description')}
          content={utils.getLang('smartmessaging.campaigngroup.creator.selectCreateOption')}
        />
      );
  }
};

Content.propTypes = {
  groupModel: PropTypes.object,
  setupPremadeGroup: PropTypes.func,
  name: PropTypes.string,
  rmByRmTypeId: PropTypes.object.isRequired,
  mode: PropTypes.string,
  createEmptyGroup: PropTypes.func.isRequired,
};

Content.defaultProps = {
  mode: null,
  groupModel: null,
  setupPremadeGroup: null,
  name: 'null',
};

const CreatorInfos = ({ createEmptyGroup, setupPremadeGroup, newGroupSetup, rmByRmTypeId }) => (
  <div
    style={{
      display: 'flex',
      flex: 1,
      padding: '8px 8px 8px 8px',
      flexDirection: 'column',
      // padding: '16px 24px',
      overflow: 'hidden',
    }}
  >
    <Content
      groupModel={newGroupSetup.groupModel}
      name={newGroupSetup.name}
      mode={newGroupSetup.mode}
      createEmptyGroup={createEmptyGroup}
      setupPremadeGroup={setupPremadeGroup}
      rmByRmTypeId={rmByRmTypeId}
    />
  </div>
);

CreatorInfos.propTypes = {
  newGroupSetup: PropTypes.object.isRequired,
  setupPremadeGroup: PropTypes.func.isRequired,
  rmByRmTypeId: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  createEmptyGroup: PropTypes.func.isRequired,
};

export default CreatorInfos;
