import React from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment } from '@material-ui/core';

import LabelIcon from '@material-ui/icons/Label';
import { makeStyles } from '@material-ui/core/styles';

import appStyle from 'src/consts/appStyle';

const useStyles = makeStyles(() => ({
  input: { fontSize: '1rem', paddingLeft: '8px' },
  invalid: { background: appStyle.bgColor5 },
}));

function NameInput({ value, ...props }) {
  const classes = useStyles();
  return (
    <TextField
      {...props}
      value={value}
      InputProps={{
        classes: { input: `${classes.input} ${!value && classes.invalid}` },
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position="start">
            <LabelIcon style={{ fill: '#b6b6b6' }} />
          </InputAdornment>
        ),
      }}
    />
  );
}

NameInput.propTypes = { value: PropTypes.string };
NameInput.defaultProps = { value: '' };

export default NameInput;
