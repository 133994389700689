import React, { Fragment, useCallback } from 'react';
import { Button, Menu } from '@material-ui/core';
import PropTypes from 'prop-types';

import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

function MenuButton({ children, label, buttonClassName, buttonStyle }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <Fragment>
      <Button
        style={buttonStyle}
        className={buttonClassName}
        onClick={handleClick}
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
      >
        {label}
        <ArrowDropDown style={{ marginLeft: '4px' }} />
      </Button>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: 200,
          },
        }}
      >
        <div style={{ display: 'none' }} />
        {React.cloneElement(children, { closeMenu: handleClose })}
      </Menu>
    </Fragment>
  );
}

MenuButton.propTypes = {
  children: PropTypes.any.isRequired,
  label: PropTypes.string,
  buttonClassName: PropTypes.string,
  buttonStyle: PropTypes.object,
};

MenuButton.defaultProps = { label: 'Actions', buttonClassName: '', buttonStyle: {} };

export default MenuButton;
