import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/styles';
import { Fade } from '@material-ui/core';
import NotFound from '../common/NotFound';

import CustomTemplateContent from '../customTemplate/CustomTemplateContent';
import MassActionContent from '../massAction/MassActionContent';
import ParametersContent from '../parameters/ParametersContent';
import SentMessages from '../sentMessages/SentMessages';
import SmartjourneyContent from '../smartjourney/SmartjourneyContent';
import { clearData } from 'src/reducers/app';
import AutoCampaigns from '../autoCampaigns/AutoCampaigns';
import { AutomatedStep } from 'src/consts/consts';

const styles = () => ({
  content: {
    overflow: 'hidden',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    // opacity: 0,
    // border: '2px solid red',
  },
});

const Faded = withStyles(styles)(({ classes, children }) => (
  <Fade in timeout={500}>
    <div className={classes.content}>{children}</div>
  </Fade>
));

const getContent = (
  key,
  appConfig,
  doClearData,
  automatedActivated,
  smjActivated,
  getInitialStep
) => {
  switch (key) {
    case 0:
      return (
        <Faded>
          <AutoCampaigns
            {...{ automatedActivated, smjActivated, doClearData }}
            initialStep={getInitialStep()}
          />
        </Faded>
      );
    case 1:
      return (
        <Faded>
          <ParametersContent />
        </Faded>
      );
    case 2:
      return (
        <Faded>
          <MassActionContent doClearData={doClearData} />
        </Faded>
      );
    case 3:
      return (
        <Faded>
          <CustomTemplateContent />
        </Faded>
      );
    case 4:
      return (
        <Faded>
          <SentMessages appConfig={appConfig} />
        </Faded>
      );
    case 5:
      return (
        <Faded>
          <SmartjourneyContent doClearData={doClearData} />
        </Faded>
      );
    default:
      return <NotFound />;
  }
};

function MainContent({
  selectMenuValue,
  appConfig,
  doClearData,
  automatedActivated,
  smjActivated,
}) {
  useEffect(() => {
    doClearData();
  }, [doClearData]);
  function getInitialStep() {
    if (automatedActivated) return AutomatedStep.SINGLES;
    if (smjActivated) return AutomatedStep.GROUPS;
    return AutomatedStep.SINGLES;
  }

  return getContent(
    selectMenuValue,
    appConfig,
    doClearData,
    automatedActivated,
    smjActivated,
    getInitialStep
  );
}

MainContent.propTypes = {
  classes: PropTypes.object.isRequired,
  selectMenuValue: PropTypes.number.isRequired,
};

const actionCreators = { doClearData: clearData };

const mapStateToProps = state => ({
  selectMenuValue: state.selectMenuValue,
  appConfig: state.app.config,
  actionCoActivated: state.app.config['smartm-punctual:allow'],
  parametersActivated: state.app.config['smartm-param:allow'],
  automatedActivated: state.app.config['smartm-automation:allow'],
  smjActivated: state.app.config['smartm-smartjourney:allow'],
  customTemplateActivated: state.app.config['smartm-template:allow'],
});

export default connect(mapStateToProps, actionCreators)(withStyles(styles)(MainContent));
