import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { InputAdornment, Tooltip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import WarningIcon from '@material-ui/icons/Warning';
import appStyle from 'src/consts/appStyle';

const styles = {
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: appStyle.txtColor7,
    border: '1px solid #dadde9',
    fontSize: 14,
    maxWidth: 'none',
    paddingRight: '24px',
  },
};

const getTooltips = messages => {
  let k = 0;
  return messages.map(tt => {
    k += 1;
    return <li key={k}>{tt}</li>;
  });
};

const StyledTooltip = withStyles(styles)(Tooltip);

const ValidityAdornment = ({ validity }) => (
  <InputAdornment style={{ height: 'unset', margin: '0 4px 0 0' }} position="start">
    {validity.isValid && <DoneIcon style={{ color: appStyle.txtColor1 }} />}
    {!validity.isValid && (
      <StyledTooltip title={<ul>{getTooltips(validity.messages)}</ul>} placement="bottom">
        <WarningIcon style={{ color: appStyle.txtColor6 }} />
      </StyledTooltip>
    )}
  </InputAdornment>
);

ValidityAdornment.propTypes = {
  validity: PropTypes.object.isRequired,
};

export default ValidityAdornment;
