import React from 'react';
import PropTypes from 'prop-types';
import { Typography, FormControlLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Checkbox from 'src/components/common/Checkbox';
import CustomCard from 'src/components/common/Card';
import appStyle from 'src/consts/appStyle';

const SMJCampaignItem = ({ onSelect, title, description, checked, type }) => (
  <div style={{ textAlign: 'left' }}>
    <FormControlLabel
      checked={checked}
      control={
        <Checkbox
          style={{ pointerEvents: 'auto' }}
          onChange={e => {
            onSelect(e.target.checked);
          }}
        />
      }
      label={
        <CustomCard
          title={
            <Typography style={{ color: appStyle.txtColor4, fontWeight: 200 }}>{title}</Typography>
          }
        >
          <Typography variant="body2" style={{ color: appStyle.txtColor3, fontWeight: 200 }}>
            {type}
          </Typography>
          <Typography variant="body2" style={{ color: appStyle.txtColor3, fontWeight: 200 }}>
            {description}
          </Typography>
        </CustomCard>
      }
    />
  </div>
);

SMJCampaignItem.propTypes = {
  onSelect: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  type: PropTypes.string,
};

SMJCampaignItem.defaultProps = {
  onSelect: () => {},
  title: null,
  description: null,
  type: null,
};

const styles = theme => ({
  content: {
    '&.selectable': {
      cursor: 'pointer',
      opacity: 0.7,
      '&:hover': {
        opacity: 1,
      },
    },
    flex: 1,
  },
  contentRoot: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: `${theme.spacing(2)}px !important`,
  },
  card: {
    textAlign: 'left',
    marginBottom: theme.spacing(1),
    backgroundColor: appStyle.bgColor4,
    // flex: 1,
  },
  title: {
    color: appStyle.txtColor1,
    margin: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: theme.spacing(1),
  },
  description: {
    color: appStyle.txtColor4,
    margin: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export default withStyles(styles)(SMJCampaignItem);
