import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { Typography, IconButton, Paper } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import {
  checkContentFieldsAndSelectedColumns,
  resetContentEditorFlowData,
} from 'src/reducers/massAction';

import utils from 'src/utils/utils';
import appStyle from 'src/consts/appStyle';
import CustCard from 'src/components/common/ActionCard';

const ActionCreator = ({ actionTypeId, classes, goToStep, doResetContentEditorFlowData, lang }) => (
  <CustCard title={utils.getLang(lang.title[actionTypeId])}>
    <Paper style={{ height: '200px', width: '160px', display: 'inline-block' }}>
      <Typography
        style={{
          color: appStyle.txtColor1,
          wordWrap: 'normal',
          padding: '8px',
        }}
      >
        {utils.getLang(lang.createText[actionTypeId])}
      </Typography>

      <IconButton
        onClick={e => {
          e.preventDefault();
          doResetContentEditorFlowData();
          goToStep(4, {
            actionType: actionTypeId,
            initialStep: 0,
          });
        }}
        className={classes.addBtn}
        size="medium"
      >
        <AddIcon />
      </IconButton>
    </Paper>
  </CustCard>
);

ActionCreator.propTypes = {
  classes: PropTypes.object.isRequired,
  actionTypeId: PropTypes.number.isRequired,
  goToStep: PropTypes.func.isRequired,
  doResetContentEditorFlowData: PropTypes.func.isRequired,
  lang: PropTypes.object.isRequired,
};

ActionCreator.defaultProps = {
  // openEditor: () => {},
};

const styles = () => ({
  addBtn: {
    // zIndex: 2,
    backgroundColor: appStyle.bgColor2,
    color: appStyle.txtColor2,
    '&:hover': {
      // background: appStyle.bgColor1,
      opacity: 0.9,
    },
  },
});

const mapStateToProps = () => ({});

const actionCreators = {
  doResetContentEditorFlowData: resetContentEditorFlowData,
  doCheckContentFieldsAndSelectedColumns: checkContentFieldsAndSelectedColumns,
};

export default connect(mapStateToProps, actionCreators)(withStyles(styles)(ActionCreator));
