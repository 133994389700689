import { createReducer } from '@acemarke/redux-starter-kit';

const initialState = {
  notifications: [],
};

const onRemoveSnackbar = (state, { key }) => ({
  ...state,
  notifications: state.notifications.filter(notification => notification.key !== key),
});

const onEnqueueSnackbar = (state, { notification }) => ({
  ...state,
  notifications: [
    ...state.notifications,
    {
      ...notification,
    },
  ],
});

export default createReducer(initialState, {
  REMOVE_SNACKBAR: onRemoveSnackbar,
  ENQUEUE_SNACKBAR: onEnqueueSnackbar,
});

export const enqueueSnackbar = notification => ({
  type: 'ENQUEUE_SNACKBAR',
  notification: {
    key: new Date().getTime() + Math.random(),
    ...notification,
  },
});

export const notify = n => dispatch => {
  dispatch(enqueueSnackbar(n));
};
