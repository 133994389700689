import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import paramSelectors from 'src/selectors/parameters';
import SmConfigTextField from './SmConfigTextField';
import SmConfigImageField from './SmConfigImageField';
import TimeInput from '../common/timeInput/TimeInput';
import CustomCard from '../common/Card';
import appStyle from 'src/consts/appStyle';
import utils from 'src/utils/utils';
import parametersCfg from 'src/reducers/utils/parameters';

const SmartMessagingConfig = ({
  currentParams,
  onSmConfigFieldChange,
  imageMaxWeight,
  defaultSmsHours,
  invalidities,
}) => (
  <div
    style={{
      flex: 1,
      overflow: 'auto',
      padding: '8px',
    }}
  >
    <div
      style={{
        overflow: 'auto',
        width: 'max-content',
      }}
    >
      <CustomCard
        title={utils.stringFormat(utils.getLang(`smartmessaging.config.sectionLabel.smsHours`), [
          defaultSmsHours,
        ])}
      >
        <div
          style={{
            marginTop: '8px',
            marginBottom: '8px',
            textAlign: 'left',
            padding: '0 24px',
            overflow: 'hidden',
            gridGap: '16px 24px',
            display: 'grid',
            // could come from parametersCfg using parametersCfg.textParamKeys
            gridTemplateAreas: '"openingtime closingtime"',
            justifyContent: 'start',
            gridTemplateColumns: '480px 480px',
          }}
        >
          {parametersCfg.timeParamKeys.map(timeParamKey => (
            <TimeInput
              initTime={currentParams[timeParamKey]}
              onTimeChange={value => {
                onSmConfigFieldChange(value, timeParamKey);
              }}
              validity={
                invalidities[timeParamKey] || { key: timeParamKey, isValid: true, messages: [] }
              }
              key={timeParamKey}
              paramKey={timeParamKey}
              label={utils.getLang(`smartmessaging.config.field.${timeParamKey}`)}
            />
          ))}
        </div>
      </CustomCard>
      <CustomCard title={utils.getLang(`smartmessaging.config.sectionLabel.contentData`)}>
        <div
          style={{
            marginBottom: '8px',
            textAlign: 'left',
            padding: '0 24px',
            overflow: 'hidden',
            gridGap: '16px 24px',
            display: 'grid',
            // could come from parametersCfg using parametersCfg.textParamKeys
            gridTemplateAreas:
              '"smsSender googleplayUrl" \n' +
              '"emailSender appstoreUrl" \n' +
              '"resamaniaUrl instagramUrl" \n' +
              '"bookingUrl facebookUrl" \n' +
              '"salelUrl twitterUrl" \n' +
              '"unsubscribeUrl youtubeUrl" \n' +
              '"passwordforgottenUrl googlePlusUrl" \n' +
              '"clubUrl contactCenterMail" \n' +
              '"0 contactCenterPhone"',
            justifyContent: 'start',
            gridTemplateColumns: '480px 480px',
          }}
        >
          {parametersCfg.textParamKeys.map(textParamKey => (
            <SmConfigTextField
              onChange={value => {
                onSmConfigFieldChange(value, textParamKey);
              }}
              paramKey={textParamKey}
              key={textParamKey}
              value={currentParams[textParamKey] || ''}
              label={utils.getLang(`smartmessaging.config.field.${textParamKey}`)}
              validity={
                invalidities[textParamKey] || { key: textParamKey, isValid: true, messages: [] }
              }
            />
          ))}
        </div>
      </CustomCard>
      <CustomCard title={utils.getLang(`smartmessaging.config.sectionLabel.headerFooter`)}>
        <SmConfigImageField
          label={
            <div style={{ textAlign: 'left', marginBottom: '8px' }}>
              <span style={{ marginRight: '8px' }}>
                {utils.getLang('smartmessaging.config.field.headerStoredfile')}
              </span>
              <span style={{ fontWeight: 400, color: appStyle.txtColor3 }}>
                {utils.stringFormat(
                  utils.getLang('smartmessaging.config.field.headerAndFooterAdvice'),
                  [imageMaxWeight]
                )}
              </span>
            </div>
          }
          paramKey="headerStoredfileId"
          storedFileId={
            (currentParams.headerStoredfileId && parseInt(currentParams.headerStoredfileId, 10)) ||
            null
          }
        />
        <SmConfigImageField
          label={
            <div style={{ textAlign: 'left', marginBottom: '8px' }}>
              <span style={{ marginRight: '8px' }}>
                {utils.getLang('smartmessaging.config.field.footerStoredfile')}
              </span>
              <span style={{ fontWeight: 400, color: appStyle.txtColor3 }}>
                {utils.stringFormat(
                  utils.getLang('smartmessaging.config.field.headerAndFooterAdvice'),
                  [imageMaxWeight]
                )}
              </span>
            </div>
          }
          paramKey="footerStoredfileId"
          storedFileId={
            (currentParams.footerStoredfileId && parseInt(currentParams.footerStoredfileId, 10)) ||
            null
          }
        />
      </CustomCard>
    </div>
  </div>
);

SmartMessagingConfig.propTypes = {
  currentParams: PropTypes.object.isRequired,
  onSmConfigFieldChange: PropTypes.func.isRequired,
  imageMaxWeight: PropTypes.string.isRequired,
  defaultSmsHours: PropTypes.string.isRequired,
  invalidities: PropTypes.object.isRequired,
};

SmartMessagingConfig.defaultProps = {};

const mapStateToProps = state => ({
  currentParams: paramSelectors.getCurrentParams(state),
  invalidities: state.parameters.invalidities,
  imageMaxWeight: state.app.imageMaxSize,
  defaultSmsHours: state.app.config.defaultSmsHours,
});

const actionCreators = {
  onSmConfigFieldChange: (value, textParamKey) => ({
    type: 'ON_SM_CFG_FIELD_CHANGE',
    value: { value, key: textParamKey },
  }),
};

export default connect(mapStateToProps, actionCreators)(SmartMessagingConfig);
