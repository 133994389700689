import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/styles';
import { TableRow, TableCell, TableHead, TableSortLabel } from '@material-ui/core';
import appStyle from 'src/consts/appStyle';

import utils from 'src/utils/utils';

const tableSortLabelStyle = {
  active: { color: appStyle.txtColor1 },
  root: {
    color: appStyle.txtColor1,
    '&$active': { color: appStyle.txtColor1, '& svg path': { color: appStyle.txtColor1 } },
  },
};

const StyledTableSortLabelRender = ({ classes, children, ...otherProps }) => (
  <TableSortLabel classes={{ root: classes.root, active: classes.active }} {...otherProps}>
    {children}
  </TableSortLabel>
);

StyledTableSortLabelRender.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
};

const StyledTableSortLabel = withStyles(tableSortLabelStyle)(StyledTableSortLabelRender);

const HistoryListHead = ({ columns, sortConfig, sort, sortable }) => (
  <TableHead>
    <TableRow>
      {columns.map(column => (
        <TableCell
          key={column}
          style={{
            padding: '4px',
            position: 'sticky',
            top: 0,
            background: '#ffffff',
            zIndex: 1,
            color: appStyle.txtColor1,
          }}
        >
          {sortable.indexOf(column) !== -1 ? (
            <StyledTableSortLabel
              active={sortConfig.orderBy === column}
              direction={sortConfig.order}
              onClick={() => {
                sort(column);
              }}
            >
              {utils.getLang(`smartmessaging.history.column.${column}`)}
            </StyledTableSortLabel>
          ) : (
            utils.getLang(`smartmessaging.history.column.${column}`)
          )}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

HistoryListHead.propTypes = {
  sort: PropTypes.func.isRequired,
  sortConfig: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  sortable: PropTypes.array.isRequired,
};

export default HistoryListHead;
