import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import appStyle from 'src/consts/appStyle';
import ValidityAdornment from './fields/ValidityAdornment';
import utils from 'src/utils/utils';

const SMJTitleField = ({ classes, value, isValid, onChange }) => (
  <div style={{ display: 'flex' }}>
    <TextField
      style={{ flex: 1 }}
      value={value}
      InputProps={{
        className: classes.textFieldInput,
        id: 'cmp-name-ipt',
      }}
      className={classes.textField}
      onChange={e => {
        onChange(e.target.value);
      }}
    />
    <ValidityAdornment
      position="start"
      validity={{
        isValid,
        messages: [utils.getLang('smartmessaging.smartjourney.creator.smjNameRequired')],
      }}
    />
  </div>
);

SMJTitleField.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.string,
  isValid: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

SMJTitleField.defaultProps = {
  value: null,
};

const styles = () => ({
  textField: {
    // margin: theme.spacing(3),
    display: 'flex',
    margin: '8px',
  },
  textFieldInput: {
    color: appStyle.txtColor3,
    fontSize: '0.9rem',
  },
});

export default withStyles(styles)(SMJTitleField);
