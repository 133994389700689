import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import utils from 'src/utils/utils';
import appStyle from 'src/consts/appStyle';

import ValidityAdornment from '../ValidityAdornment';

const EmailContentLabel = ({ title, contentValidity }) => (
  <div style={{ display: 'flex' }}>
    <Typography
      style={{
        margin: '5px 10px',
        color: !contentValidity.contentIsValid ? appStyle.txtColor6 : appStyle.txtColor3,
      }}
      variant="subtitle2"
      noWrap
    >
      {title}
    </Typography>
    <ValidityAdornment
      valid={contentValidity.contentIsValid}
      message={
        !contentValidity.contentIsValid
          ? contentValidity.invalidities.content.map(msg => (
              <ul>
                <li>{msg}</li>
              </ul>
            ))
          : ''
      }
    />
    {!contentValidity.hasOptoutLink && (
      <ValidityAdornment
        valid={false}
        message={
          <ul>
            <li>{utils.getLang('smartmessaging.contentEditor.mail.optoutLinkMissing')}</li>
          </ul>
        }
      />
    )}
  </div>
);

EmailContentLabel.propTypes = {
  contentValidity: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

export default EmailContentLabel;
