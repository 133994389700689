import React from 'react';
import PropTypes from 'prop-types';

import { FormControlLabel, Typography, Tooltip } from '@material-ui/core';
import appStyle from 'src/consts/appStyle';
import utils from 'src/utils/utils';

import CustomCard from './Card';
import Checkbox from './Checkbox';

const Selector = ({
  title,
  selectable,
  selection,
  select,
  unselect,
  selectAll,
  clearAll,
  disabled,
}) => (
  <CustomCard
    sticky
    title={
      <div style={{ paddingLeft: '4px' }}>
        <FormControlLabel
          control={
            <Tooltip
              title={
                (selection.length === selectable.length &&
                  utils.getLang('smartmessaging.clearAll')) ||
                utils.getLang('smartmessaging.selectAll')
              }
            >
              <span>
                <Checkbox
                  checked={selection.length === selectable.length}
                  disabled={disabled}
                  onChange={() => {
                    if (selection.length === selectable.length) clearAll();
                    else selectAll();
                  }}
                />
              </span>
            </Tooltip>
          }
          label={title}
        />
      </div>
    }
  >
    {selectable.map(s => (
      <div key={s.value} style={{ textAlign: 'left' }}>
        <FormControlLabel
          control={
            <Checkbox
              disabled={disabled}
              checked={selection.map(selected => selected.value).indexOf(s.value) !== -1}
              onChange={e => {
                if (e.target.checked) select([s]);
                else {
                  unselect([s]);
                }
              }}
            />
          }
          label={
            <Typography variant="body2" style={{ color: appStyle.txtColor3 }}>
              {s.label}
            </Typography>
          }
        />
      </div>
    ))}
  </CustomCard>
);

Selector.propTypes = {
  title: PropTypes.string.isRequired,
  select: PropTypes.func.isRequired,
  unselect: PropTypes.func.isRequired,
  selectable: PropTypes.array.isRequired,
  selection: PropTypes.array.isRequired,
  selectAll: PropTypes.func.isRequired,
  clearAll: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default Selector;
