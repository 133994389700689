import React from 'react';

import PropTypes from 'prop-types';
import ActionEditor from './ActionEditor';
import ActionCreator from './ActionCreator';
import ActionType from 'src/enums/actionType';

const lang = {
  title: {
    [ActionType.SMS.id]: 'smartmessaging.campaignAction.cardTitle.SMS',
    [ActionType.EMAIL.id]: 'smartmessaging.campaignAction.cardTitle.EMAIL',
    [ActionType.EMAIL_COACH.id]: 'smartmessaging.campaignAction.cardTitle.EMAIL',
    [ActionType.MEMBER_NOTIFICATION.id]:
      'smartmessaging.campaignAction.cardTitle.MEMBER_NOTIFICATION',
  },
  createText: {
    [ActionType.SMS.id]: 'smartmessaging.campaignAction.createTitle.SMS',
    [ActionType.EMAIL.id]: 'smartmessaging.campaignAction.createTitle.EMAIL',
    [ActionType.EMAIL_COACH.id]: 'smartmessaging.campaignAction.createTitle.EMAIL',
    [ActionType.MEMBER_NOTIFICATION.id]:
      'smartmessaging.campaignAction.createTitle.MEMBER_NOTIFICATION',
  },
};

const ActionItem = ({ actionTypeId, rmId, goToStep, isWritable, action, updateContents }) => (
  <div style={{ margin: '10px' }}>
    {(action && (
      <ActionEditor
        updateContents={updateContents}
        goToStep={goToStep}
        action={action}
        actionTypeId={actionTypeId}
        isWritable={isWritable}
        lang={lang}
      />
    )) ||
      (isWritable && (
        <ActionCreator
          isWritable={isWritable}
          actionTypeId={actionTypeId}
          rmId={rmId}
          goToStep={goToStep}
          updateContents={updateContents}
          lang={lang}
        />
      ))}
  </div>
);

ActionItem.propTypes = {
  actionTypeId: PropTypes.number.isRequired,
  isWritable: PropTypes.bool.isRequired,
  rmId: PropTypes.number,
  goToStep: PropTypes.func.isRequired,
  action: PropTypes.object,
  updateContents: PropTypes.func.isRequired,
};

ActionItem.defaultProps = {
  rmId: null,
  action: null,
};

export default ActionItem;
