import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  FormLabel,
  FormControl,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import MoreVertIcon from '@material-ui/icons/MoreVert';
// import RecipeField from './RecipeField';
import utils from 'src/utils/utils';
import appStyle from 'src/consts/appStyle';

import Filter from './Filter';

const ActiveFilterCtnRender = ({
  fields,
  criteriaKey,
  showRecipeSingleParamMenu,
  singleParamMenuAnchor,
  classes,
  removeActiveField,
  addActiveField,
  addGroupSubFieldLine,
  mandatory,
  invalidities,
  isWritable,
}) => (
  <Card elevation={0} style={{ overflow: 'visible' }}>
    <FormControl style={{ display: 'block' }} required={mandatory}>
      <CardHeader
        className={classes.cardHeader}
        classes={{
          // root: classes.root,
          // action: classes.action,
          title: classes.title,
          action: classes.action,
          root: classes.cardHeaderTitleRoot,
        }}
        title={
          <FormLabel className={classes.label}>
            {utils.getLang(`smartmessaging.availableRecipeField.${criteriaKey}`)}
          </FormLabel>
        }
        action={
          isWritable &&
          ((fields.length && fields.length < fields[0].model.allowedOperators.length) ||
            !mandatory) && (
            <div>
              <IconButton
                onClick={event => {
                  event.preventDefault();
                  showRecipeSingleParamMenu(criteriaKey, event.currentTarget);
                }}
                aria-owns={
                  (singleParamMenuAnchor.key === criteriaKey && `menu-${criteriaKey}`) || null
                }
                aria-haspopup="true"
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id={`menu-${criteriaKey}`}
                anchorEl={
                  (singleParamMenuAnchor.key === criteriaKey && singleParamMenuAnchor.anchorEl) ||
                  null
                }
                open={Boolean(
                  singleParamMenuAnchor.key && singleParamMenuAnchor.key === criteriaKey
                )}
                onClose={event => {
                  event.preventDefault();
                  showRecipeSingleParamMenu(false, false);
                }}
                PaperProps={{
                  style: {
                    minWidth: 150,
                  },
                }}
              >
                <MenuItem disabled className={classes.menuItem}>
                  <div style={{ textAlign: 'center' }}>
                    {utils.getLang(`smartmessaging.availableRecipeField.${criteriaKey}`)}
                  </div>
                </MenuItem>
                {fields.length && fields.length < fields[0].model.allowedOperators.length && (
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => {
                      if (!fields[0].parentKey) {
                        addActiveField(fields[0].model);
                      } else {
                        addGroupSubFieldLine({
                          model: fields[0].model,
                          parentKey: fields[0].parentKey,
                        });
                      }
                      showRecipeSingleParamMenu(false, false);
                    }}
                  >
                    {utils.getLang('smartmessaging.campaignEditor.recipeConfig.addLine')}
                  </MenuItem>
                )}
                {!mandatory && !fields[0].parentKey && (
                  <MenuItem
                    className={classes.menuItem}
                    onClick={event => {
                      event.preventDefault();
                      removeActiveField(fields[0].model.key);
                    }}
                  >
                    {utils.getLang('smartmessaging.campaignEditor.recipeConfig.removeCriteria')}
                  </MenuItem>
                )}
              </Menu>
            </div>
          )
        }
      />
      <CardContent className={classes.cardContent}>
        {fields.map(field => (
          <Filter
            key={`fieldKey-${fields.indexOf(field)}`}
            isWritable={isWritable}
            removableLine={Boolean(isWritable && fields.length > 1)}
            fieldConfig={field}
            invalidities={invalidities}
          />
        ))}
      </CardContent>
    </FormControl>
  </Card>
);

ActiveFilterCtnRender.defaultProps = {
  singleParamMenuAnchor: { key: null, anchorEl: null },
};

ActiveFilterCtnRender.propTypes = {
  fields: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  criteriaKey: PropTypes.string.isRequired,
  showRecipeSingleParamMenu: PropTypes.func.isRequired,
  singleParamMenuAnchor: PropTypes.object,
  removeActiveField: PropTypes.func.isRequired,
  addActiveField: PropTypes.func.isRequired,
  addGroupSubFieldLine: PropTypes.func.isRequired,
  isWritable: PropTypes.bool.isRequired,
  mandatory: PropTypes.bool.isRequired,
  invalidities: PropTypes.array.isRequired,
};

export default connect(
  state => ({
    singleParamMenuAnchor: state.campaignEditorView.recipeSingleParamMenuAnchor,
  }),
  {
    showRecipeSingleParamMenu: (fieldKey, target) => ({
      type: 'SHOW_SINGLE_PARAM_MENU',
      value: { key: fieldKey, anchorEl: target },
    }),
    removeActiveField: key => ({ type: 'REMOVE_ACTIVE_FIELD', value: key }),
    addActiveField: fieldModel => ({ type: 'ADD_ACTIVE_FIELD', value: fieldModel }),
    addGroupSubFieldLine: fieldModel => ({ type: 'ADD_GROUP_FIELD_LINE', value: fieldModel }),
  }
)(
  withStyles({
    menuItem: {
      color: appStyle.txtColor3,
      fontSize: '0.98rem',
      outline: 'none',
      cursor: 'pointer',
      padding: '4px 8px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
      '&:hover': {
        background: appStyle.bgColor1,
        opacity: 0.9,
        color: appStyle.txtColor2,
      },
    },

    root: { background: appStyle.bgColor10, padding: '0px 8px 2px', margin: '0  -4px 4px' },
    label: { color: appStyle.txtColor3, fontSize: '0.875rem', fontWeight: 400 },
    title: { fontSize: '1rem', color: appStyle.txtColor3 },
    cardHeader: { backgroundColor: appStyle.bgColor2 },
    cardContent: { padding: '0', display: 'flex', flexDirection: 'column', flex: 1 },
    action: { marginTop: 0 },
    cardHeaderTitleRoot: { padding: '10px 15px' },
  })(ActiveFilterCtnRender)
);
