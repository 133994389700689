/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/styles';
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  IconButton,
  Collapse,
} from '@material-ui/core';
import FilterIcon from '@material-ui/icons/FilterList';

import FilterInput from 'src/components/common/filters/FilterInput';

import appStyle from 'src/consts/appStyle';
import massActionListFilterConfigs from './filter/utils';

import utils from 'src/utils/utils';

const columnLabels = {
  name: () => utils.getLang('smartmessaging.campaignList.column.name'),
  creationDate: () => utils.getLang('smartmessaging.campaignList.column.createDate'),
  creationUserName: () => utils.getLang('smartmessaging.campaignList.column.cretionUserName'),
  networkNodeName: () => utils.getLang('smartmessaging.campaignList.column.networkNodeName'),
};

const tableSortLabelStyle = {
  active: { color: appStyle.txtColor1 },
  root: {
    color: appStyle.txtColor1,
    '&$active': { color: appStyle.txtColor1, '& svg path': { color: appStyle.txtColor1 } },
  },
};

const StyledTableSortLabelRender = ({ classes, children, ...otherProps }) => (
  <TableSortLabel classes={{ root: classes.root, active: classes.active }} {...otherProps}>
    {children}
  </TableSortLabel>
);

const StyledTableSortLabel = withStyles(tableSortLabelStyle)(StyledTableSortLabelRender);

StyledTableSortLabelRender.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
};

function WithFilterSortLabel({ column, addFilter, visibleInputs, showInput, ...props }) {
  return (
    <Fragment>
      <div style={{ display: 'flex' }}>
        <StyledTableSortLabel {...props} />
        <Tooltip title={utils.getLang('smartmessaging.tooltip.filters')}>
          <IconButton aria-label="filter" onClick={() => showInput(!visibleInputs)}>
            <FilterIcon />
          </IconButton>
        </Tooltip>
      </div>
      <Collapse in={visibleInputs}>
        <FilterInput column={column} addFilter={addFilter} />
      </Collapse>
    </Fragment>
  );
}

WithFilterSortLabel.propTypes = {
  column: PropTypes.object.isRequired,
  addFilter: PropTypes.func.isRequired,
  visibleInputs: PropTypes.bool.isRequired,
  showInput: PropTypes.func.isRequired,
};

function Headers({ columnNames, sortConfig, doSort, addFilter }) {
  const [iVisible, showInputs] = useState(false);
  return (
    <TableHead>
      <TableRow>
        <TableCell
          style={{
            position: 'sticky',
            top: 0,
            background: '#fafafa',
            zIndex: 1,
          }}
        />
        {columnNames.map(column => (
          <TableCell
            key={column}
            style={{
              position: 'sticky',
              top: 0,
              background: '#fafafa',
              padding: '4px',
            }}
          >
            {!massActionListFilterConfigs[column] && (
              <StyledTableSortLabel
                key={column}
                active={sortConfig.orderBy === column}
                direction={sortConfig.order}
                onClick={() => {
                  doSort(column);
                }}
              >
                {columnLabels[column]()}
              </StyledTableSortLabel>
            )}
            {massActionListFilterConfigs[column] && (
              <WithFilterSortLabel
                visibleInputs={iVisible}
                key={column}
                active={sortConfig.orderBy === column}
                direction={sortConfig.order}
                onClick={() => {
                  doSort(column);
                }}
                column={{ filterConfig: massActionListFilterConfigs[column] }}
                addFilter={addFilter}
                showInput={showInputs}
              >
                {columnLabels[column]()}
              </WithFilterSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

Headers.propTypes = {
  columnNames: PropTypes.array.isRequired,
  addFilter: PropTypes.func.isRequired,
  doSort: PropTypes.func.isRequired,
  sortConfig: PropTypes.object.isRequired,
};

export default Headers;
