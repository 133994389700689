import React from 'react';
import PropTypes from 'prop-types';
import { Typography, CardContent, Card } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import AppStyle from 'src/consts/appStyle';
import utils from 'src/utils/utils';

const SMJModelItemRender = ({ onSelect, disabledSelect, smjModel, classes }) => (
  <Card
    onClick={event => {
      event.preventDefault();
      if (!disabledSelect) onSelect();
    }}
    elevation={1}
    className={classes.card}
  >
    <CardContent
      className={`${classes.content} ${!disabledSelect ? 'selectable' : ''}`}
      classes={{ root: classes.contentRoot }}
    >
      <Typography className={classes.title} variant="body2">
        {utils.getLang(`smartmessaging.campaigns.grouped.groupModel.name.${smjModel.key}`)}
      </Typography>
      <Typography className={classes.description} variant="caption">
        {utils.getLang(`smartmessaging.campaigns.grouped.groupModel.description.${smjModel.key}`)}
      </Typography>
    </CardContent>
  </Card>
);

SMJModelItemRender.propTypes = {
  smjModel: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  classes: PropTypes.object.isRequired,
  disabledSelect: PropTypes.bool,
};

SMJModelItemRender.defaultProps = {
  disabledSelect: false,
  onSelect: () => {},
};

const SMJModelItem = withStyles(theme => ({
  content: {
    '&.selectable': {
      cursor: 'pointer',
      opacity: 0.7,
      '&:hover': {
        opacity: 1,
      },
    },
    flex: 1,
  },
  contentRoot: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: `${theme.spacing(2)}px !important`,
  },
  card: {
    textAlign: 'left',
    marginBottom: theme.spacing(1),
    backgroundColor: AppStyle.bgColor4,
    // flex: 1,
  },
  title: {
    color: AppStyle.txtColor1,
    margin: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: theme.spacing(1),
  },
  description: {
    color: AppStyle.txtColor4,
    margin: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))(SMJModelItemRender);

export default SMJModelItem;
