import React from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import IntegerFormat from './IntegerFormat';

const IntegerField = ({ style, value, onChange, InputProps, ...otherProps }) => (
  <TextField
    style={style}
    onChange={event => {
      event.preventDefault();
      onChange(event.target.value);
    }}
    InputProps={{ inputComponent: IntegerFormat, 'aria-label': 'Description', ...InputProps }}
    value={value}
    {...otherProps}
  />
);

IntegerField.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object,

  InputProps: PropTypes.object,
};
IntegerField.defaultProps = {
  style: { width: '100%' },
  InputProps: {},
};

export default IntegerField;
