import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Toolbar, Typography, AppBar } from '@material-ui/core';
import MenuButton from 'src/components/common/MenuButton';
import appStyle from 'src/consts/appStyle';
import utils from 'src/utils/utils';

import BackBtn from './HistoryBackBtn';

const styles = () => ({
  appBar: {
    position: 'relative',
    backgroundColor: appStyle.bgColor1,
  },
  flex: { flex: 1 },
});

function HistoryAppBar({ classes, actionButtons, title, onBack, campaignName, barButtons }) {
  return (
    <AppBar className={classes.appBar}>
      <Toolbar style={{ paddingLeft: '8px' }}>
        {onBack && <BackBtn onClick={onBack} />}
        <div className={classes.flex}>
          <Typography variant="h5" color="inherit">
            {title}
          </Typography>
          <Typography variant="body2" color="inherit">
            {campaignName}
          </Typography>
        </div>
        {barButtons}
        {actionButtons && (
          <MenuButton
            buttonStyle={{ margin: '2px', color: appStyle.txtColor2 }}
            label={utils.getLang('smartmessaging.massAction.navButton.actions')}
          >
            {actionButtons}
          </MenuButton>
        )}
      </Toolbar>
    </AppBar>
  );
}

HistoryAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  actionButtons: PropTypes.any,
  onBack: PropTypes.func,
  title: PropTypes.string,
  campaignName: PropTypes.string.isRequired,
  barButtons: PropTypes.any,
};

HistoryAppBar.defaultProps = {
  actionButtons: null,
  onBack: null,
  title: 'Historique',
  barButtons: null,
};

export default withStyles(styles)(HistoryAppBar);
