import { createReducer } from '@acemarke/redux-starter-kit';

const initialState = {
  recipeFieldMenuAnchorEl: null,
  recipeSingleParamMenuAnchor: { key: null, anchorEl: null },
};

const hideOrShowFieldsMenu = (state, action) => ({
  ...state,
  recipeFieldMenuAnchorEl: action.value,
});
const showRecipeSingleParamMenu = (state, action) => ({
  ...state,
  recipeSingleParamMenuAnchor: { key: action.value.key, anchorEl: action.value.anchorEl },
});

const clearSingleParameMenuState = state => ({
  ...state,
  recipeSingleParamMenuAnchor: { key: null, anchorEl: null },
});

const clearFieldMenuState = state => ({
  ...state,
  recipeFieldMenuAnchorEl: null,
});

export default createReducer(initialState, {
  HIDE_OR_SHOW_FIELDS_MENU: hideOrShowFieldsMenu,
  SHOW_SINGLE_PARAM_MENU: showRecipeSingleParamMenu,
  REMOVE_ACTIVE_FIELD: clearSingleParameMenuState,
  ADD_ACTIVE_FIELD: clearFieldMenuState,
});
