import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { Modal } from '@material-ui/core';
import appStyle from 'src/consts/appStyle';

const mapStateToProps = state => ({
  stacks: state.loader.stacks,
});

const propTypes = {
  stacks: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
};

const styles = () => ({
  '@keyframes beat': {
    '33%': {
      transform: 'scale(0.5)',
      opacity: 0.2,
    },
    '66%': {
      transform: 'scale(0.75)',
      opacity: 0.5,
    },
    '100%': { transform: ' scale(1)', opacity: 1 },
  },
  dot: {
    display: 'inline-block',
    backgroundColor: appStyle.bgColor1,
    width: `25px`,
    height: `25px`,
    margin: `15px 10px`,
    borderRadius: '100%',
    animation: `beat 0.7s 0s infinite linear`,
    animationFillMode: 'both',
  },
  timer1: {
    animation: `beat 1.4s 0s infinite linear`,
  },
  timer2: {
    animation: `beat 1.4s 0.42s infinite linear`,
  },
  timer3: {
    animation: `beat 1.4s 0.84s infinite linear`,
  },
});

const ModalLoader = ({ classes, stacks }) =>
  !!stacks && (
    <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open>
      <div>
        <div
          style={{
            margin: 0,
            position: 'absolute',
            top: '45%',
            left: '50%',
            msTransform: 'translate(-50%, -50%)',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <div className={`${classes.dot} ${classes.timer1}`} />
            <div className={`${classes.dot} ${classes.timer2}`} />
            <div className={`${classes.dot} ${classes.timer3}`} />
          </div>
        </div>
      </div>
    </Modal>
  );
ModalLoader.propTypes = propTypes;

export default connect(mapStateToProps, {})(withStyles(styles)(ModalLoader));
