import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import uuid from 'uuid';
import { withStyles } from '@material-ui/styles';
import ClickedLinkHistoryItem from './ClickedLinkHistoryItem';
import utils from '../../../utils/utils';
import appStyle from '../../../consts/appStyle';

const styles = () => ({
  table: {
    tableLayout: 'fixed',
  },
});

function ClickedLinkHistory({ classes, clickedLinks }) {
  return (
    <Fragment>
      {!!clickedLinks.length && (
        <Table aria-labelledby="tableTitle" className={classes.table}>
          <TableHead>
            <TableRow style={{ width: '70px' }}>
              {['linktype', 'url', 'count'].map(column => (
                <TableCell key={column} style={{ color: appStyle.txtColor1, padding: '4px' }}>
                  {utils.getLang(`smartmessaging.clickHistory.column.${column}`)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {clickedLinks.map(clickedLink => (
              <Fragment key={uuid()}>
                <ClickedLinkHistoryItem
                  item={clickedLink[0]}
                  rowSpanCols={['linktype']}
                  rowSpan={clickedLink.length || 1}
                  columns={['linktype', 'url', 'count']}
                />
                {clickedLink.slice(1).map(i => (
                  <ClickedLinkHistoryItem
                    key={uuid()}
                    item={i}
                    columns={['url', 'count']}
                    rowSpanCols={[]}
                    rowSpan={clickedLink.length || 1}
                  />
                ))}
              </Fragment>
            ))}
          </TableBody>
        </Table>
      )}
      {/* </div> */}
      {/* </Paper> */}
    </Fragment>
  );
}

ClickedLinkHistory.propTypes = {
  clickedLinks: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ClickedLinkHistory);
