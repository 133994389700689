import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import { withStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from '@material-ui/core';
import appStyle from 'src/consts/appStyle';
import hUtils from './utils';
import utils from 'src/utils/utils';

const styles = () => ({
  title: { fontSize: '1rem', color: appStyle.txtColor3 },
  cardHeader: { borderBottom: appStyle.border1 },
  cardContent: { padding: '0', display: 'flex', flexDirection: 'column', flex: 1 },
  action: { marginTop: 0 },
  root: { paddingBottom: '12px', paddingLeft: 0 },
});

const DetailsHistoryItem = ({ classes, items, columns, rowSpan, rowSpanCols, specificRender }) => (
  <Card elevation={0}>
    <CardHeader
      className={classes.cardHeader}
      classes={{
        title: classes.title,
        action: classes.action,
        root: classes.root,
      }}
      title={`${utils.getLang('smartmessaging.historyDetails.statsData')} ${new Date(
        items[0].date
      ).toLocaleDateString()}`}
    />

    <CardContent style={{ overflow: 'auto', paddingLeft: 0, paddingTop: 0 }}>
      <Table aria-labelledby="tableTitle">
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell key={column} style={{ color: appStyle.txtColor1, padding: '4px' }}>
                {utils.getLang(`smartmessaging.history.column.${column}`)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {columns.map(column => (
              <TableCell
                key={column}
                style={{ padding: '4px', color: appStyle.txtColor3 }}
                rowSpan={rowSpanCols.indexOf(column) === -1 ? 1 : rowSpan}
              >
                {specificRender[column]
                  ? specificRender[column](column, items[0])
                  : hUtils.renderCell(column, items[0])}
              </TableCell>
            ))}
          </TableRow>
          {items.slice(1).map(i => (
            <TableRow key={uuid()}>
              {columns
                .filter(c => rowSpanCols.indexOf(c) === -1)
                .map(column => (
                  <TableCell key={column} style={{ padding: '4px', color: appStyle.txtColor3 }}>
                    {specificRender[column]
                      ? specificRender[column](column, i)
                      : hUtils.renderCell(column, i)}
                  </TableCell>
                ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </CardContent>
  </Card>
);

DetailsHistoryItem.propTypes = {
  items: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  rowSpanCols: PropTypes.array.isRequired,
  rowSpan: PropTypes.number.isRequired,
  specificRender: PropTypes.object,
};

DetailsHistoryItem.defaultProps = {
  specificRender: {},
};

export default withStyles(styles)(DetailsHistoryItem);
