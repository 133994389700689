import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import appStyle from 'src/consts/appStyle';
import utils from 'src/utils/utils';

// TODO : withState instead

const mapStateToProps = state => ({
  alertCfg: state.alert.cfg,
});

const actionCreators = {
  closeAlert: () => ({ type: 'CLOSE_ALERT' }),
};

const defaultProps = {
  alertCfg: null,
};

const styles = theme => ({
  alertBtn: { margin: '0px', color: appStyle.txtColor1, background: 'none' },
  alertCancelBtn: { margin: '0px', color: appStyle.txtColor7, background: 'none' },
  title: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    color: appStyle.txtColor4,
  },
  content: { padding: `${theme.spacing(2)}px` },
  warningTitle: {
    background: appStyle.bgColor5,
  },
  // closeButton: {
  //   position: 'absolute',
  //   right: theme.spacing(1),
  //   top: theme.spacing(1),
  // },
  actionsRoot: {
    margin: `${theme.spacing(0.5)}px 0px ${theme.spacing(0.5)}px 0px`,
    padding: `0px`,
  },
  mainDialog: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(0.5)}px 0px ${theme.spacing(0.5)}px`,
  },
});

const Alert = ({ alertCfg, closeAlert, classes }) =>
  alertCfg && (
    <Dialog open onClose={closeAlert} classes={{ paper: classes.mainDialog }} scroll="paper">
      <DialogTitle
        disableTypography
        classes={{ root: classes.title }}
        className={`${alertCfg.type ? ` ${classes[`${alertCfg.type}Title`]}` : ''}`}
      >
        {alertCfg.title || 'Informations'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          className={`${classes.content}`}
          dangerouslySetInnerHTML={{ __html: alertCfg.msg }}
        />
      </DialogContent>
      <DialogActions classes={{ root: classes.actionsRoot }}>
        {alertCfg.onDismiss && (
          <Button
            onClick={e => {
              e.preventDefault();
              alertCfg.onDismiss();
            }}
            classes={{
              root: classes.alertCancelBtn,
            }}
          >
            {utils.getLang('smartmessaging.alerts.dismiss')}
          </Button>
        )}
        {alertCfg.onConfirm && (
          <Button
            onClick={e => {
              e.preventDefault();
              alertCfg.onConfirm();
            }}
            classes={{
              root: classes.alertBtn,
            }}
          >
            {utils.getLang('smartmessaging.alerts.confirm')}
          </Button>
        )}
        {!alertCfg.onDismiss && !alertCfg.onConfirm && (
          <Button
            onClick={e => {
              e.preventDefault();
              closeAlert();
            }}
            classes={{
              root: classes.alertBtn,
            }}
          >
            {utils.getLang('smartmessaging.alerts.done')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );

Alert.propTypes = {
  alertCfg: PropTypes.object,
  closeAlert: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};
Alert.defaultProps = defaultProps;

export default connect(mapStateToProps, actionCreators)(withStyles(styles)(Alert));
