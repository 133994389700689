import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CascadingMenuItem from 'src/components/common/CascadingMenuItem';
import utils from 'src/utils/utils';

const mapCriterias = (criteriaGroup, selectCriteria, isWritable) =>
  criteriaGroup.map(criteria => ({
    ...criteria,
    onClick: isWritable
      ? e => {
          e.preventDefault();
          selectCriteria(criteria);
        }
      : e => {
          e.preventDefault();
        },
    label: utils.getLang(`smartmessaging.massAction.filterLabel.${criteria.key}`),
  }));

const CriteriaMenu = ({ criteriaGroup, selectCriteria, label, isWritable }) => (
  <CascadingMenuItem
    caption={label}
    menuItems={mapCriterias(criteriaGroup, selectCriteria, isWritable)}
  />
);

CriteriaMenu.propTypes = {
  criteriaGroup: PropTypes.array.isRequired,
  selectCriteria: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  isWritable: PropTypes.bool.isRequired,
};

const mapStateToProps = () => ({});
const actionCreators = {
  selectCriteria: criteria => ({ type: 'ADD_CRITERIA_TO_SELECTION', value: criteria }),
};

export default connect(mapStateToProps, actionCreators)(CriteriaMenu);
