import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, Fade, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
// import RecipeFieldContainer from './RecipeFieldContainer';
import {
  getDistinctActiveFieldKeys,
  mapActiveFieldsByKey,
  loadEmptyRecipeConfig,
} from 'src/reducers/campaignEditor';
import withDataResolver from 'src/components/common/withDataResolver';

import AvailableFilter from './AvailableFilter';
import ActiveFilterCtn from './ActiveFilterCtn';

import appStyle from 'src/consts/appStyle';
import utils from 'src/utils/utils';

const FilterListRender = ({
  inactiveFields,
  classes,
  addActiveField,
  distinctActiveFieldKeysList,
  activeFieldsByKey,
  recipeCfgValidity,
  editedCampaign,
}) => (
  <Fade in timeout={500}>
    <Grid
      container
      direction="row"
      style={{
        flexWrap: 'nowrap',
        overflow: 'hidden',
        flex: 1,
        border: `1px solid ${appStyle.bgColor2}`,
      }}
    >
      <Grid
        item
        style={{
          flexDirection: 'column',
          display: 'flex',
          width: 'fit-content',
          maxWidth: '360px',
          // borderLeft: `1px solid ${appStyle.bgColor2}`,
        }}
      >
        <div
          style={{
            overflow: 'auto',
            display: 'flex',
            flex: 1,
            height: '100%',
          }}
        >
          <Card
            elevation={0}
            style={{
              borderRadius: 0,
              textAlign: 'left',
              flexDirection: 'column',
              display: 'flex',
              flex: 1,
            }}
          >
            <CardHeader
              className={classes.cardHeader}
              classes={{
                title: classes.title,
                action: classes.action,
                root: classes.cardHeaderTitleRoot,
              }}
              title={utils.getLang('smartmessaging.massAction.availableFilters')}
            />
            <CardContent
              // className="hoverflow"
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                paddingTop: 0,
                flex: 1,
                marginRight: '2px',
                overflow: 'auto',
              }}
            >
              {inactiveFields.map(inactiveField => (
                <AvailableFilter
                  key={inactiveField.key}
                  label={utils.getLang(`smartmessaging.availableRecipeField.${inactiveField.key}`)}
                  filter={inactiveField}
                  addCriteria={() => {
                    if (editedCampaign.isWritable) addActiveField(inactiveField);
                  }}
                />
              ))}
            </CardContent>
          </Card>
        </div>
      </Grid>
      <Grid
        item
        style={{
          flex: 3,
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          borderLeft: `1px solid ${appStyle.bgColor2}`,
        }}
      >
        <div
          style={{
            overflow: 'auto',
            display: 'flex',
            flex: 1,
            height: '100%',
          }}
        >
          <Card
            elevation={0}
            style={{
              minWidth: '600px',
              flex: 1,
              textAlign: 'left',
              borderRadius: 0,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
          >
            <CardHeader
              className={classes.cardHeader}
              classes={{
                title: classes.title,
                action: classes.action,
                root: classes.cardHeaderTitleRoot,
              }}
              title={utils.getLang('smartmessaging.massAction.selectedFilters')}
            />
            <CardContent
              // className="hoverflow"
              style={{
                overflow: 'auto',
                flex: 1,
                padding: '8px',
              }}
            >
              {distinctActiveFieldKeysList.map(activeKey => (
                <ActiveFilterCtn
                  mandatory={activeFieldsByKey[activeKey][0].model.mandatory}
                  fields={activeFieldsByKey[activeKey]}
                  key={activeKey}
                  criteriaKey={activeKey}
                  invalidities={recipeCfgValidity.invalidFields}
                  isWritable={editedCampaign.isWritable}
                />
              ))}
            </CardContent>
          </Card>
        </div>
      </Grid>
    </Grid>
  </Fade>
);

FilterListRender.propTypes = {
  inactiveFields: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  addActiveField: PropTypes.func.isRequired,
  distinctActiveFieldKeysList: PropTypes.array.isRequired,
  activeFieldsByKey: PropTypes.object.isRequired,
  recipeCfgValidity: PropTypes.object.isRequired,
  editedCampaign: PropTypes.object.isRequired,
};

const FilterList = connect(
  state => ({
    editedCampaign: state.campaignEditor.editedCampaign,
    selectedType: state.campaignEditor.selectedType,
    inactiveFields: state.campaignEditor.recipeFieldsConfig.inactiveFields,
    distinctActiveFieldKeysList: getDistinctActiveFieldKeys(state), // todo: remove and use Object.keys(activeFieldsByKey)
    activeFieldsByKey: mapActiveFieldsByKey(state),
    anchorEl: state.campaignEditorView.recipeFieldMenuAnchorEl,
    loadedRecipe: state.campaignEditor.recipeFieldsConfig.loaded,
  }),
  {
    hideOrShowMenu: anchorEl => ({ type: 'HIDE_OR_SHOW_FIELDS_MENU', value: anchorEl }),
    addActiveField: fieldModel => ({ type: 'ADD_ACTIVE_FIELD', value: fieldModel }),
    doLoadRecipeConfig: loadEmptyRecipeConfig,
  }
)(
  withDataResolver(
    {
      resolve: props => async () => {
        const { editedCampaign, doLoadRecipeConfig, selectedType, loadedRecipe } = props;
        if (!loadedRecipe)
          await doLoadRecipeConfig(
            editedCampaign.requestModelTypeId || selectedType.requestModelTypeId
          );
      },
      onResolved: () => async () => {},
    },
    {
      loaderWrapper: {
        class: 'ghostWrapperClass',
        style: { position: 'relative', height: '96px' },
      },
    }
  )(FilterListRender)
);

export default withStyles({
  disabledBtn: {
    color: `${appStyle.txtColor2} !important`,
    backgroundColor: appStyle.bgColor1,
    opacity: 0.7,
  },
  title: { fontSize: '0.9rem', color: appStyle.txtColor3 },
  cardHeader: { backgroundColor: appStyle.bgColor2, margin: '2px 2px 0 2px' },
  cardContent: { padding: '0', display: 'flex', flexDirection: 'column' },
  action: { marginTop: 0 },
  cardHeaderTitleRoot: { padding: '10px 15px' },
  appBar: {
    position: 'relative',
    backgroundColor: appStyle.bgColor1,
    textAlign: 'left',
  },
  flex: { flex: 1 },
  // /////////////
  menuItem: {
    color: appStyle.txtColor3,
    fontSize: '0.98rem',
    outline: 'none',
    cursor: 'pointer',
    padding: '4px 8px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
    '&:hover': {
      background: appStyle.bgColor1,
      opacity: 0.9,
      color: appStyle.txtColor2,
    },
  },
  root: { padding: '10px 15px' },
  addCatBtn: {
    backgroundColor: appStyle.bgColor1,
    color: appStyle.txtColor2,
    '&:hover': {
      background: appStyle.bgColor1,
    },
  },
})(FilterList);
