import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { FormControl, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import utils from 'src/utils/utils';

const CampaignSchedulerItem = ({
  classes,
  period,
  removePeriod,
  onPeriodChange,
  index,
  editedCampaign,
}) => (
  <FormControl className={classes.container}>
    <DatePicker
      disabled={!editedCampaign.isWritable}
      variant="inline"
      className={classes.textField}
      autoOk
      format="dd/MM/yyyy"
      label={utils.getLang('smartmessaging.campaignEditor.scheduler.beginDate')}
      value={(utils.isValidDate(new Date(period.startDate)) && new Date(period.startDate)) || null}
      onChange={date => {
        onPeriodChange('startDate', index, date);
      }}
      InputLabelProps={{
        shrink: true,
        required: true,
      }}
    />

    <DatePicker
      disabled={!editedCampaign.isWritable}
      variant="inline"
      className={classes.textField}
      autoOk
      format="dd/MM/yyyy"
      label={utils.getLang('smartmessaging.campaignEditor.scheduler.endDate')}
      value={
        (period.endDate &&
          utils.isValidDate(new Date(period.endDate)) &&
          new Date(period.endDate)) ||
        null
      }
      onChange={date => {
        if (!date || utils.isValidDate(date)) onPeriodChange('endDate', index, date);
      }}
      InputLabelProps={{
        shrink: true,
      }}
    />
    {!editedCampaign.eventDriven && (
      <TimePicker
        disabled={!editedCampaign.isWritable}
        variant="inline"
        ampm={false}
        format="HH:mm"
        label={utils.getLang('smartmessaging.campaignEditor.scheduler.time')}
        required
        value={(period.time && utils.getNewDateWithTime(period.time)) || null}
        onChange={time => {
          onPeriodChange('time', index, time.toLocaleTimeString().slice(0, 5));
        }}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />
    )}
    {editedCampaign.isWritable && (
      <IconButton
        onClick={e => {
          e.preventDefault();
          removePeriod(index);
        }}
      >
        <DeleteIcon />
      </IconButton>
    )}
  </FormControl>
);

const actionCreators = {
  removePeriod: index => ({ type: 'REMOVE_PERIOD', value: index }),
  onPeriodChange: (key, index, value) => ({
    type: 'ON_PERIOD_CHANGE',
    value: { key, index, newValue: value },
  }),
};

CampaignSchedulerItem.propTypes = {
  classes: PropTypes.object.isRequired,
  period: PropTypes.object.isRequired,
  removePeriod: PropTypes.func.isRequired,
  onPeriodChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  editedCampaign: PropTypes.object.isRequired,
};

CampaignSchedulerItem.defaultProps = {};

export default connect(
  null,
  actionCreators
)(
  withStyles(theme => ({
    container: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
    },
    textField: {
      flex: 1,
      fontSize: '0.975rem',
      marginLeft: `${theme.spacing(1)}px!important`,
      marginRight: `${theme.spacing(1)}px!important`,
    },
  }))(CampaignSchedulerItem)
);
