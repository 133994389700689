import { createReducer } from '@acemarke/redux-starter-kit';
import apiClient from '../utils/apiClient';
import getApiCaller from '../utils/apiClientCaller';

const initialState = {
  recipeListById: {},
  recipeIdList: [],
};

const sortRecipes = recipeList => ({
  recipeListById: recipeList.reduce((acc, recipe) => ({ ...acc, [recipe.id]: { ...recipe } }), {}),
  recipeIdList: recipeList.map(recipe => parseInt(recipe.id, 10)),
});

const receiveRecipes = (state, { value }) => ({ ...state, ...sortRecipes(value) });

const addRecipe = (state, { value }) => ({
  ...state,
  recipeListById: { ...state.recipeListById, [value.id]: value },
  recipeIdList:
    (state.recipeIdList.indexOf(value.id === -1) && [...state.recipeIdList, value.id]) ||
    state.recipeIdList,
});

export const loadRecipes = () => async (dispatch, getState) => {
  const recipeList = await getApiCaller(dispatch, getState)(apiClient.loadRecipes);
  dispatch({ type: 'RECEIVE_RECIPES', value: recipeList });
};

export default createReducer(initialState, {
  RECEIVE_RECIPES: receiveRecipes,
  ADD_RECIPE: addRecipe,
});
