import React from 'react';
import { withState } from 'recompose';
import { FormControl, FormLabel } from '@material-ui/core';

import PropTypes from 'prop-types';
import MuiReactSelect from 'src/components/common/MuiReactSelect';
import OperatorSelect from './OperatorSelect';
import apiClient from 'src/utils/apiClient';
import { getBeanTypeById } from 'src/enums/beantype';
import withDataResolver from 'src/components/common/withDataResolver';
import utils from 'src/utils/utils';
import ValidityAdornment from './ValidityAdornment';

const SelectSMJField = ({
  classes,
  dataList,
  descriptor,
  value,
  onChange,
  onOperatorChange,
  validity,
  mapValue,
  getValue,
  selectAll,
}) => (
  <FormControl required={descriptor.mandatory}>
    <div className={classes.fieldContainer} style={{ alignItems: 'center' }}>
      <ValidityAdornment validity={validity} />
      <FormLabel
        style={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '0.875rem',
        }}
        component="div"
      >
        {utils.getLang(`smartmessaging.recipeFieldName.${descriptor.key}`)}
      </FormLabel>
      <div
        style={{
          marginLeft: '16px',
          marginRight: '16px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <OperatorSelect
          fieldConfig={{ model: descriptor, value: { operatorId: null } }}
          allowedOperators={descriptor.allowedOperators}
          value={value.operatorValue}
          onChange={v => onOperatorChange(v)}
          // disabled={!isWritable}
        />
      </div>
      <div
        style={{
          fontSize: '0.875rem',
          flex: 1,
        }}
      >
        <MuiReactSelect
          // isDisabled={!isWritable}
          selectAll={selectAll}
          mapValue={mapValue}
          getValue={getValue}
          onChange={v => {
            onChange(v);
          }}
          value={value.value || []}
          options={dataList}
        />
      </div>
    </div>
  </FormControl>
);

const resolvers = {
  resolve: props => async callApi => {
    if (props.data)
      return props.data.map(dataItem => ({
        value: dataItem.id,
        label: dataItem.name,
      }));
    let rez;
    let getLabel;
    if (props.descriptor.type === 'CLUBS') {
      const promises = [];
      props.descriptor.descriptors.forEach(fkdesc => {
        promises.push(
          callApi(apiClient.getDataList, [fkdesc]).then(list => ({
            label: utils.getLang(
              `smartmessaging.beanGrouptitle.${getBeanTypeById(fkdesc.beantypeId).key}`
            ),
            options: list.map(listItem => {
              // eslint-disable-next-line prefer-destructuring
              getLabel = getBeanTypeById(fkdesc.beantypeId).getLabel;
              return {
                ...listItem,
                key: fkdesc.key,
                beantypeId: fkdesc.beantypeId,
                value: JSON.stringify({
                  beantypeId: fkdesc.beantypeId,
                  id: listItem.id,
                  key: fkdesc.key,
                }),
                label: getLabel(listItem),
              };
            }),
          }))
        );
      });

      rez = await Promise.all(promises);
      return rez;
    }
    if (props.descriptor.beantypeId) {
      const eBeantype = getBeanTypeById(props.descriptor.beantypeId);
      // eslint-disable-next-line prefer-destructuring
      getLabel = eBeantype.getLabel;
    } else {
      getLabel = ({ name }) => name;
    }

    rez = await callApi(apiClient.getDataList, [props.descriptor]);
    return rez.map(dataItem => ({
      value: dataItem.id,
      label: getLabel(dataItem),
    }));
  },
  onResolved: props => async resolvedData => {
    props.setDataList(resolvedData);
  },
};

SelectSMJField.propTypes = {
  descriptor: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  onOperatorChange: PropTypes.func.isRequired,
  dataList: PropTypes.array.isRequired,
  validity: PropTypes.object.isRequired,
  value: PropTypes.object,
  mapValue: PropTypes.func,
  getValue: PropTypes.func,
  selectAll: PropTypes.func,
};

SelectSMJField.defaultProps = {
  mapValue: null,
  value: null,
  getValue: null,
  selectAll: null,
};

const WithData = withDataResolver(resolvers, {
  loaderWrapper: {
    class: 'ghostWrapperClass',
    style: { position: 'relative', height: '96px' },
  },
})(SelectSMJField);

export default withState('dataList', 'setDataList', () => [])(WithData);
