import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { Typography, IconButton, Paper, Dialog, AppBar, Toolbar } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/EditOutlined';
import {
  selectMailMessage,
  deleteCmpAction,
  selectSMSMessage,
  selectNotifyMessage,
} from 'src/reducers/campaignEditor';
import utils from 'src/utils/utils';
import getApiCaller from 'src/utils/apiClientCaller';
import appStyle from 'src/consts/appStyle';
import { loaderActions } from 'src/reducers/loader';

import CustCard from './ActionCard';
import TestForm from 'src/components/common/TestForm';

import ActionBtn from 'src/components/common/ActionBtn';
import ActionType from 'src/enums/actionType';

const mapStateToProps = state => ({
  rmTypeId: state.campaignEditor.editedCampaign.requestModelTypeId,
});

const actionCreators = {
  // not in propTypes to avoid eslint unused prop warn...it is used in recompose handler : handleSelectMessageModel
  selectSmsMessage: () => {},
  doDeleteCmpAction: deleteCmpAction,
  doSelectMailMessage: selectMailMessage,
  doSelectSMSMessage: selectSMSMessage,
  doSelectNotifyMessage: selectNotifyMessage,
  setLoader: visible => loaderActions.setLoader(visible),
  doGetApiCaller: () => getApiCaller,
};

const styles = () => ({
  appBar: {
    position: 'relative',
    backgroundColor: appStyle.bgColor1,
  },
  testBtn: { margin: '2px' },
  editBtn: {
    // zIndex: 2,
    backgroundColor: '#000000',
    color: appStyle.txtColor2,
    margin: '2px',
    '&:hover': {
      backgroundColor: '#000000',
      opacity: 0.9,
    },
  },
  deleteBtn: {
    // zIndex: 2,
    margin: '2px',
    backgroundColor: appStyle.bgColor8,
    color: appStyle.txtColor2,
    '&:hover': {
      backgroundColor: appStyle.bgColor8,
      opacity: 0.9,
    },
  },
});

const propTypes = {
  loadFieldsAndContent: PropTypes.func.isRequired,
  handleMessageSelect: PropTypes.func.isRequired,
  doDeleteCmpAction: PropTypes.func.isRequired,
  action: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  setLoader: PropTypes.func.isRequired,
  goToCmpMsgStep: PropTypes.func.isRequired,
  testFormIsVisible: PropTypes.bool.isRequired,
  showTestForm: PropTypes.func.isRequired,
  actionTypeId: PropTypes.number.isRequired,
  rmId: PropTypes.number.isRequired,
  isWritable: PropTypes.bool.isRequired,
  lang: PropTypes.object.isRequired,
  // rmTypeId: PropTypes.number.isRequired,
};

const ActionEditor = ({
  classes,
  loadFieldsAndContent,
  action,
  handleMessageSelect,
  doDeleteCmpAction,
  setLoader,
  goToCmpMsgStep,
  testFormIsVisible,
  showTestForm,
  actionTypeId,
  rmId,
  isWritable,
  lang,
  // rmTypeId,
}) => (
  <Fragment>
    <Dialog open={testFormIsVisible} fullWidth>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h5" color="inherit" style={{ flex: 1 }}>
            {utils.getLang('smartmessaging.campaignEditor.testAction.popupTitle')}
          </Typography>
          <IconButton
            aria-label="clear"
            onClick={e => {
              e.preventDefault();
              showTestForm(false);
            }}
            style={{ color: appStyle.txtColor2 }}
          >
            <DeleteIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          minHeight: '300px',
        }}
      >
        <TestForm
          // forcedFields={{ field0: 'INTEGER', ...contentFields }}
          rmId={rmId}
          includeWizVille
          campaignActionId={action && action.id}
          actionTypeId={actionTypeId}
        />
      </div>
    </Dialog>
    <CustCard title={utils.getLang(lang.title[actionTypeId])}>
      <Paper style={{ height: '200px', width: '160px', display: 'flex', flexDirection: 'column' }}>
        <Typography
          style={{
            // wordBreak: 'break-word',
            color: appStyle.txtColor1,
            wordWrap: 'normal',
            padding: '8px',
          }}
        >
          {utils.getLang(lang.createText[actionTypeId])}
        </Typography>
        <div style={{ flex: 1 }}>
          <IconButton
            onClick={event => {
              event.preventDefault();
              setLoader(true);
              loadFieldsAndContent(async processedFields => {
                // the load content fn to be passed as a callback
                await handleMessageSelect(processedFields.fieldsModels).finally(() =>
                  setLoader(false)
                );
                goToCmpMsgStep(2, { fields: processedFields, actionTypeId: action.actionTypeId });
              });
            }}
            className={classes.editBtn}
            // variant="fab"
            size="small"
            // aria-label="add"
          >
            <EditIcon />
          </IconButton>
          {isWritable && (
            <IconButton
              onClick={event => {
                event.preventDefault();
                doDeleteCmpAction(action.id);
              }}
              className={classes.deleteBtn}
              // variant="fab"
              size="small"
              // aria-label="add"
            >
              <DeleteIcon />
            </IconButton>
          )}
        </div>
        {[ActionType.MEMBER_NOTIFICATION.id].indexOf(actionTypeId) === -1 && (
          <div style={{ padding: '12px', display: 'grid' }}>
            <ActionBtn
              // className={classes.testBtn}
              onClick={event => {
                event.preventDefault();
                showTestForm(true);
                // displayTestForm();
                // goToTest();
              }}
            >
              {utils.getLang('smartmessaging.contentEditor.testContent')}
            </ActionBtn>
          </div>
        )}
      </Paper>
    </CustCard>
  </Fragment>
);

ActionEditor.propTypes = propTypes;

function ActionEditorHandler(props) {
  async function handleMessageSelect(fieldsModels) {
    const { action, doSelectSMSMessage, doSelectMailMessage, doSelectNotifyMessage } = props;
    switch (action.actionTypeId) {
      case ActionType.SMS.id:
        await doSelectSMSMessage(action, fieldsModels);
        break;
      case ActionType.EMAIL.id:
      case ActionType.EMAIL_COACH.id:
      case ActionType.EMAIL_SPONSORSHIP.id:
      case ActionType.EMAIL_INVITATION.id:
        await doSelectMailMessage(action, fieldsModels, action.actionTypeId);
        break;
      case ActionType.MEMBER_NOTIFICATION.id:
        await doSelectNotifyMessage(action, fieldsModels, action.actionTypeId);
        break;
      default:
        break;
    }
  }

  const [testFormIsVisible, showTestForm] = useState(false);

  return <ActionEditor {...props} {...{ testFormIsVisible, showTestForm, handleMessageSelect }} />;
}

ActionEditorHandler.propTypes = {
  doSelectSMSMessage: PropTypes.func.isRequired,
  doSelectMailMessage: PropTypes.func.isRequired,
  doSelectNotifyMessage: PropTypes.func.isRequired,
  action: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, actionCreators)(withStyles(styles)(ActionEditorHandler));
