import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { getSmsCount } from 'src/reducers/campaignEditor';
import utils from 'src/utils/utils';
import appStyle from 'src/consts/appStyle';

const SmsValidity = ({ smsCount }) => (
  <Typography
    style={{ margin: '5px 10px', color: appStyle.txtColor3, overflow: 'visible' }}
    variant="body1"
    color="inherit"
    noWrap
  >
    {`${utils.getLang('smartmessaging.contentEditor.sms.content.messageLength')} : `}
    {(smsCount && smsCount.length) || 0}
    <br />
    {`${utils.getLang(
      'smartmessaging.contentEditor.sms.content.numberOfSms'
    )}:${smsCount.numOfPart || 1}`}
    <br />
    {`${utils.getLang('smartmessaging.contentEditor.sms.content.invalidChars')} : ${smsCount &&
      smsCount.invalidChar &&
      smsCount.invalidChar.length &&
      smsCount.invalidChar}` || 0}
  </Typography>
);

SmsValidity.propTypes = {
  smsCount: PropTypes.any.isRequired,
};

SmsValidity.defaultProps = {};

const mapStateToProps = state => ({
  smsCount: getSmsCount(state),
});

export default connect(mapStateToProps, {})(SmsValidity);
