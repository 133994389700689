import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { InputAdornment, Tooltip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import WarningIcon from '@material-ui/icons/Warning';
import appStyle from 'src/consts/appStyle';
// import utils from 'src/utils/utils';

const styles = {
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: appStyle.txtColor7,
    border: '1px solid #dadde9',
    fontSize: 14,
    maxWidth: 'none',
    paddingRight: '24px',
  },
};

const StyledTooltip = withStyles(styles)(Tooltip);

const ValidityAdornment = ({ valid, message }) => (
  <InputAdornment style={{ height: 'unset', margin: '0 4px 0 0' }} position="start">
    {valid && <DoneIcon style={{ color: appStyle.txtColor1 }} />}
    {!valid && (
      <StyledTooltip title={message} placement="bottom">
        <WarningIcon style={{ color: appStyle.txtColor6 }} />
      </StyledTooltip>
    )}
  </InputAdornment>
);

ValidityAdornment.propTypes = {
  valid: PropTypes.bool.isRequired,
  message: PropTypes.any,
};

ValidityAdornment.defaultProps = {
  message: '',
};

export default ValidityAdornment;
