import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { Button, Typography, AppBar, Toolbar, IconButton, Tooltip } from '@material-ui/core';
import PreviousIcon from '@material-ui/icons/ChevronLeft';
import { saveParams } from 'src/reducers/parameters';
import appStyle from 'src/consts/appStyle';
import utils from 'src/utils/utils';

const ParamListBtn = ({ onClick }) => (
  <Tooltip title="Editer les paramètres pour un autre club">
    <IconButton
      aria-label="clear"
      onClick={e => {
        e.preventDefault();
        onClick();
      }}
      style={{ color: appStyle.txtColor2 }}
    >
      <PreviousIcon />
    </IconButton>
  </Tooltip>
);

ParamListBtn.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const ParametersAppBar = ({
  doSaveParams,
  isValid,
  clubName,
  classes,
  showList,
  selectionMode,
  multiParams,
}) => (
  <AppBar
    style={{
      position: 'relative',
      backgroundColor: appStyle.bgColor1,
    }}
  >
    <Toolbar style={{ paddingLeft: multiParams && !selectionMode ? '8px' : '16px' }}>
      {!selectionMode && multiParams && <ParamListBtn onClick={showList} />}
      {!!selectionMode && (
        <Typography variant="h5" color="inherit">
          {utils.getLang('smartmessaging.config.appBarTitle.paramsList')}
        </Typography>
      )}

      {!selectionMode && (
        <Typography variant="h5" color="inherit">
          {(clubName === 'default' &&
            ((multiParams && utils.getLang('smartmessaging.config.appBarTitle.default')) ||
              utils.getLang('smartmessaging.config.appBarTitle.singleClub'))) ||
            utils.stringFormat(utils.getLang('smartmessaging.config.appBarTitle'), [clubName])}
        </Typography>
      )}

      {!selectionMode && (
        <Button
          style={{ marginLeft: 'auto' }}
          disabled={!isValid}
          className={classes.button}
          onClick={e => {
            e.preventDefault();
            doSaveParams();
          }}
        >
          {utils.getLang('smartmessaging.config.save')}
        </Button>
      )}
    </Toolbar>
  </AppBar>
);

ParametersAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  clubName: PropTypes.string,
  isValid: PropTypes.bool.isRequired,
  selectionMode: PropTypes.bool.isRequired,
  doSaveParams: PropTypes.func.isRequired,
  showList: PropTypes.func.isRequired,
  multiParams: PropTypes.bool.isRequired,
};

ParametersAppBar.defaultProps = {
  clubName: '',
};

const mapStateToProps = state => ({
  multiParams: Object.keys(state.parameters.clubParams).length > 1,
  clubName:
    (state.parameters.clubParams[state.parameters.currentParams] &&
      state.parameters.clubParams[state.parameters.currentParams].clubName) ||
    '',
  isValid: !Object.values(state.parameters.invalidities).length,
});

const actionCreators = {
  doSaveParams: saveParams,
};

const styles = theme => ({
  button: {
    margin: `${theme.spacing(0.5)}px`,
    backgroundColor: appStyle.bgColor1,
    color: appStyle.txtColor2,
    '&:hover': {
      background: appStyle.bgColor1,
      opacity: 0.9,
    },
  },
});

export default connect(mapStateToProps, actionCreators)(withStyles(styles)(ParametersAppBar));
