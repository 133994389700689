import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import appStyle from 'src/consts/appStyle';

const styles = () => ({
  hRoot: { paddingBottom: 0, paddingLeft: 0, paddingTop: 0 },
  sticky: { position: 'sticky', top: 0, backgroundColor: '#ffffff', zIndex: 1 },
  visibleOverflow: { overflow: 'visible' },
  title: { fontSize: '1rem', color: appStyle.txtColor3, textAlign: 'left' },
  cardHeader: { borderBottom: appStyle.border1 },
  cardContent: { padding: '0', display: 'flex', flexDirection: 'column', flex: 1 },
  action: { marginTop: 0 },
});

const CustomCard = ({
  classes,
  action,
  title,
  children,
  avatar,
  sticky,
  contentStyle,
  rootStyle,
}) => (
  <Card elevation={0} classes={{ root: classes.visibleOverflow }} style={rootStyle}>
    {title !== undefined && (
      <CardHeader
        className={classes.cardHeader}
        classes={{
          title: classes.title,
          action: classes.action,
          root: `${classes.hRoot} ${sticky ? classes.sticky : ''}`,
        }}
        action={action}
        avatar={avatar}
        title={title}
      />
    )}
    <CardContent style={{ /* overflow: 'auto', */ padding: '8px', ...contentStyle }}>
      {children}
    </CardContent>
  </Card>
);

CustomCard.propTypes = {
  action: PropTypes.any,
  avatar: PropTypes.any,
  children: PropTypes.any,
  title: PropTypes.any.isRequired,
  classes: PropTypes.object.isRequired,
  sticky: PropTypes.bool,
  contentStyle: PropTypes.object,
  rootStyle: PropTypes.object,
};
CustomCard.defaultProps = {
  action: null,
  avatar: null,
  sticky: false,
  children: null,
  contentStyle: {},
  rootStyle: {},
};

export default withStyles(styles)(CustomCard);
