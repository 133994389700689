import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableHead,
  TableSortLabel,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  AppBar,
  Toolbar,
  TableFooter,
  Fade,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import uuid from 'uuid';
import { connect } from 'react-redux';
import utils from 'src/utils/utils';
import appStyle from 'src/consts/appStyle';
import withDataResolver from 'src/components/common/withDataResolver';
import massActionApi from 'src/utils/api/massActionApi';
import massActionSelectors from 'src/selectors/massAction';

import CsvExportButton from './MassActionCsvExportButton';
import Pagination from 'src/components/common/pagination/Pagination';
import ActionBtn from 'src/components/common/ActionBtn';

const tableSortLabelStyle = {
  active: { color: appStyle.txtColor1 },
  root: {
    color: appStyle.txtColor1,
    '&$active': { color: appStyle.txtColor1, '& svg path': { color: appStyle.txtColor1 } },
  },
};

const StyledTableSortLabelRender = ({ classes, children, ...props }) => (
  <TableSortLabel classes={{ root: classes.root, active: classes.active }} {...props}>
    {children}
  </TableSortLabel>
);

StyledTableSortLabelRender.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
};

const StyledTableSortLabel = withStyles(tableSortLabelStyle)(StyledTableSortLabelRender);

const appBarStyles = {
  appBar: {
    position: 'relative',
    backgroundColor: appStyle.bgColor1,
    textAlign: 'left',
  },
  flex: { flex: 1 },
};

const AppBarRender = ({ classes, currentName, currentMassActionId }) => (
  <AppBar className={classes.appBar}>
    <Toolbar style={{ paddingLeft: '8px' }}>
      <div className={classes.flex}>
        <Typography variant="h5" color="inherit">
          {utils.getLang(`smartmessaging.massAction.requestResultList.title`)}
        </Typography>
        <Typography variant="body2" color="inherit">
          {currentName}
          {!!currentMassActionId && ` (${currentMassActionId})`}
        </Typography>
      </div>
      <div
        style={{
          boxShadow: 'inset 0.3px 0px 0px 0px #ffffffd5, inset -0.3px 0px 0px 0px #ffffffd5',
          marginLeft: '8px',
          marginRight: '8px',
          padding: '0 8px',
        }}
      >
        <CsvExportButton />
      </div>
    </Toolbar>
  </AppBar>
);

AppBarRender.propTypes = {
  classes: PropTypes.object.isRequired,
  currentName: PropTypes.string.isRequired,
  currentMassActionId: PropTypes.number.isRequired,
};

const StyledAppBar = withStyles(appBarStyles)(AppBarRender);

const sorters = {
  default: orderBy => ({
    asc: (a, b) => {
      if (a[orderBy] < b[orderBy]) return -1;
      if (a[orderBy] > b[orderBy]) return 1;
      return 0;
    },
    desc: (a, b) => {
      if (a[orderBy] > b[orderBy]) return -1;
      if (a[orderBy] < b[orderBy]) return 1;
      return 0;
    },
  }),
  STRING: orderBy => ({
    asc: (a, b) => {
      if (a[orderBy].toLowerCase().trim() < b[orderBy].toLowerCase().trim()) return -1;
      if (a[orderBy].toLowerCase().trim() > b[orderBy].toLowerCase().trim()) return 1;
      return 0;
    },
    desc: (a, b) => {
      if (a[orderBy].toLowerCase().trim() > b[orderBy].toLowerCase().trim()) return -1;
      if (a[orderBy].toLowerCase().trim() < b[orderBy].toLowerCase().trim()) return 1;
      return 0;
    },
  }),
  INTEGER: orderBy => ({
    asc: (a, b) => {
      if (parseInt(a[orderBy], 10) < parseInt(b[orderBy], 10)) return -1;
      if (parseInt(a[orderBy], 10) > parseInt(b[orderBy], 10)) return 1;
      return 0;
    },
    desc: (a, b) => {
      if (parseInt(a[orderBy], 10) > parseInt(b[orderBy], 10)) return -1;
      if (parseInt(a[orderBy], 10) < parseInt(b[orderBy], 10)) return 1;
      return 0;
    },
  }),
  DATE: orderBy => ({
    asc: (a, b) => {
      const aDate = new Date(a[orderBy]);
      const bDate = new Date(b[orderBy]);
      if (!aDate && !bDate) return 0;
      if (!aDate) return -1;
      if (!bDate) return 1;
      if (aDate.getTime() < bDate.getTime()) return -1;
      if (aDate.getTime() > bDate.getTime()) return 1;
      return 0;
    },
    desc: (a, b) => {
      const aDate = new Date(a[orderBy]);
      const bDate = new Date(b[orderBy]);
      if (!aDate && !bDate) return 0;
      if (!aDate) return 1;
      if (!bDate) return -1;
      if (aDate.getTime() < bDate.getTime()) return 1;
      if (aDate.getTime() > bDate.getTime()) return -1;
      return 0;
    },
  }),
};

const getSorter = ({ order, orderBy, type }) =>
  sorters[type] ? sorters[type](orderBy)[order] : sorters.default(orderBy)[order];

const entryRenders = {
  field5: value => utils.getLang(`smartmessaging.exportEntry.civility.${value}`), // field5 = Civility
};

const renderEntry = (entry, col) =>
  entryRenders[col] ? entryRenders[col](entry[col]) : entry[col];

function MassActionRequestResultRender({
  result,
  // contents,
  goToStep,
  currentName,
  idColumnIsSelected,
  ungroupedAvailableField,
  currentMassActionId,
  isWritable,
}) {
  const [sortConfig, setSortConfig] = useState({
    order: 'asc',
    orderBy: (result.length && Object.keys(result[0])[0]) || 'id',
  });

  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);
  const cols = (result && result.length && Object.keys(result[0])) || [];

  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', height: '100%' }}>
      <StyledAppBar
        goToStep={goToStep}
        currentName={currentName}
        currentMassActionId={currentMassActionId}
        isWritable={isWritable}
      />
      <Fade in timeout={500}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            overflow: 'hidden',
            padding: '10px ',
          }}
        >
          <div
            style={{
              margin: '2px 0 0 4px',
              overflow: 'auto',
              flex: 1,
              height: '100%',
            }}
          >
            {!!result && !!result.length && (
              <Table>
                <TableHead>
                  <TableRow>
                    {cols
                      .filter(column => idColumnIsSelected || column !== 'field0')
                      .map(c => (
                        <TableCell
                          key={c}
                          style={{
                            position: 'sticky',
                            top: 0,
                            background: '#ffffff',
                            padding: '4px',
                          }}
                        >
                          <StyledTableSortLabel
                            key={c}
                            active={sortConfig.orderBy === c}
                            direction={sortConfig.order}
                            onClick={() => {
                              setSortConfig({
                                order: sortConfig.order === 'asc' ? 'desc' : 'asc',
                                orderBy: c,
                              });
                            }}
                          >
                            {utils.getLang(`smartmessaging.massAction.columnLabel.${c}`)}
                          </StyledTableSortLabel>
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {result
                    .sort(
                      getSorter({
                        order: sortConfig.order,
                        orderBy: sortConfig.orderBy,
                        type: ungroupedAvailableField[sortConfig.orderBy] || 'default',
                      })
                    )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(singleEntry => (
                      <TableRow key={uuid()} hover>
                        {cols
                          .filter(column => idColumnIsSelected || column !== 'field0')
                          .map(c => (
                            <TableCell style={{ padding: '4px' }} key={uuid()}>
                              {renderEntry(singleEntry, c)}
                            </TableCell>
                          ))}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            )}
            {(!result || !result.length) && (
              <Typography>{utils.getLang('smartmessaging.massAction.result.empty')}</Typography>
            )}
          </div>
          <Table>
            <TableFooter>
              <TableRow>
                <Pagination
                  pages={result.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  setPage={setPage}
                  setRowsPerPage={setRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
          <div style={{ textAlign: 'right', paddingTop: '12px' }}>
            <ActionBtn
              cVariant="reverse"
              style={{ margin: '2px' }}
              onClick={e => {
                e.preventDefault();
                goToStep(2);
              }}
            >
              {utils.getLang('smartmessaging.buttonLabel.previous')}
            </ActionBtn>
            <ActionBtn
              style={{ margin: '2px' }}
              onClick={e => {
                e.preventDefault();
                goToStep(6);
              }}
            >
              {utils.getLang('smartmessaging.buttonLabel.next')}
            </ActionBtn>
          </div>
        </div>
      </Fade>
    </div>
  );
}

MassActionRequestResultRender.propTypes = {
  result: PropTypes.array.isRequired,
  goToStep: PropTypes.func.isRequired,
  currentName: PropTypes.string.isRequired,
  currentMassActionId: PropTypes.number.isRequired,
  idColumnIsSelected: PropTypes.bool.isRequired,
  ungroupedAvailableField: PropTypes.object.isRequired,
  isWritable: PropTypes.bool.isRequired,
};

const resolvers = {
  resolve: props => async callApi => {
    const result = await callApi(massActionApi.getMassActionExport, [
      props.recipeId,
    ]).catch(() => []);

    return { result };
  },
  onResolved: props => async resolvedData => {
    props.setResult(resolvedData.result);
  },
  getLoaderText: () => utils.getLang('smartmessaging.massAction.requestExecutionPending'),
};

const WithData = withDataResolver(resolvers)(MassActionRequestResultRender);

function MassActionRequestResult({ recipeId, massActionId, goToStep, ...other }) {
  const [result, setResult] = useState([]);
  const [contents, setContents] = useState(null);
  return (
    <WithData
      result={result}
      setResult={setResult}
      contents={contents}
      setContents={setContents}
      recipeId={recipeId}
      massActionId={massActionId}
      goToStep={goToStep}
      {...other}
    />
  );
}

MassActionRequestResult.propTypes = {
  recipeId: PropTypes.number.isRequired,
  massActionId: PropTypes.number.isRequired,
  goToStep: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  recipeId: state.massAction.currentRecipeId,
  massActionRequestModelId: state.massAction.requestModel.id,
  massActionId: state.massAction.currentMassActionId,
  idColumnIsSelected: massActionSelectors.isIdColumnSelected(state),
  currentName: state.massAction.currentName,
  currentMassActionId: state.massAction.currentMassActionId,
  ungroupedAvailableField: massActionSelectors.getUngroupedAvailaibleFields(state),
});

export default connect(mapStateToProps, {})(MassActionRequestResult);
