// import app from './app';
import campaignList from './campaignList';
import campaignListView from './campaignListView';
import campaignEditorView from './campaignEditorView';
import recipeList from './recipeList';
import requestModelList from './requestModelList';
import selectMenuValue from './selectMenuValue';
import campaignEditor from './campaignEditor';
import parameters from './parameters';
import notifyR from './notify';
import app from './app';
import auth from './auth';
import alert from './alert';
import loader from './loader';
import defaultCampaign from './defaultCampaign';
import massAction from './massAction/massAction';
import customTemplates from './customTemplates';
import smartjourney from './smartjourney/smartjourney';

export default {
  app,
  auth,
  alert,
  campaignEditor,
  campaignEditorView,
  campaignList,
  campaignListView,
  loader,
  notify: notifyR,
  selectMenuValue,
  recipeList,
  requestModelList,
  parameters,
  defaultCampaign,
  massAction,
  customTemplates,
  smartjourney,
};
