export default {
  bgColor1: '#27bbc5',
  bgColor2: '#f1f1f1',
  bgColor3: '#0000001f',
  bgColor4: '#fafafa',
  bgColor5: '#fce8e6',
  bgColor6: '#ffffff',
  bgColor7: '#f50057',
  bgColor8: '#d93025',
  bgColor9: '#ffffffd5',
  bgColor10: '#fbfbfb',
  txtColor1: '#27bbc5',
  txtColor2: '#ffffff',
  txtColor3: '#737373',
  txtColor4: '#585858',
  txtColor5: '#000000de',
  txtColor6: '#d93025',
  txtColor7: '#0000008a',
  txtColor8: '#737373c0',
  border1: '1px solid #0000001f',
};
