/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/styles';
import { TextField, Tooltip, IconButton } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import { DatePicker } from '@material-ui/pickers';
import appStyle from 'src/consts/appStyle';
import utils from 'src/utils/utils';

const TextFilterInput = ({ value, onChange, endAdornment, onEnter, setInputRef }) => (
  <div>
    <TextField
      inputProps={{ ref: setInputRef, style: { background: '#ffffff' } }}
      InputProps={{
        endAdornment,
        disableUnderline: true,
      }}
      onKeyPress={e => {
        if (e.key === 'Enter') onEnter();
      }}
      value={value}
      onChange={e => onChange(e.target.value)}
    />
  </div>
);

TextFilterInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onEnter: PropTypes.func.isRequired,
  setInputRef: PropTypes.func,
  endAdornment: PropTypes.any.isRequired,
};

TextFilterInput.defaultProps = {
  value: '',
  setInputRef: null,
};

const SelectFilterInputRender = ({ classes, options, onChange }) => (
  <div style={{ display: 'flex' }}>
    <TextField
      select
      style={{ flex: 1 }}
      onChange={e => {
        e.preventDefault();
        onChange(e.target.value);
      }}
      value=""
      SelectProps={{
        MenuProps: {
          MenuListProps: { style: { padding: 0, margin: 0 } },
          PopoverClasses: {
            paper: classes.popover,
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          marginThreshold: 0,
        },
      }}
      InputProps={{
        // endAdornment,
        disableUnderline: true,
        classes: {
          input: classes.input,
        },
      }}
    >
      {options.map(o => (
        <div key={o.label} value={o.value} className={classes.menuItem}>
          {o.label}
        </div>
      ))}
    </TextField>
  </div>
);

SelectFilterInputRender.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

const SelectFilterInput = withStyles(() => ({
  popover: { padding: 0, margin: 0 },
  menuItem: {
    color: appStyle.txtColor3,
    outline: 'none',
    cursor: 'pointer',
    padding: '4px 8px',
    '&:hover': {
      background: appStyle.bgColor1,
      color: appStyle.txtColor2,
      opacity: 0.9,
    },
  },
  input: {
    backgroundColor: appStyle.bgColor9,
    color: appStyle.txtColor3,
    textAlign: 'left',
    padding: '6px 24px 6px 4px',
  },
}))(SelectFilterInputRender);

function DateFilterInput({ value, onChange, endAdornment, onEnter }) {
  return (
    <div style={{ display: 'flex' }}>
      <DatePicker
        inputProps={{ style: { background: '#ffffff' } }}
        autoOk
        format="dd/MM/yyyy"
        placeholder={utils.getLang('smartmessaging.campaignList.filterLabel.creationDate.from')}
        value={value[0] || null}
        variant="inline"
        onChange={v => {
          onChange(v, 'start');
        }}
        InputProps={{
          disableUnderline: true,
          onKeyPress: e => {
            if (e.key === 'Enter') onEnter();
          },
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <div style={{ margin: '0 1px' }} />
      <DatePicker
        inputProps={{ style: { background: '#ffffff' } }}
        autoOk
        format="dd/MM/yyyy"
        placeholder={utils.getLang('smartmessaging.campaignList.filterLabel.creationDate.to')}
        value={value[1] || null}
        variant="inline"
        onChange={v => {
          onChange(v, 'end');
        }}
        InputProps={{
          endAdornment,
          disableUnderline: true,
          onKeyPress: e => {
            if (e.key === 'Enter') onEnter();
          },
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </div>
  );
}

DateFilterInput.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onEnter: PropTypes.func.isRequired,
  endAdornment: PropTypes.any.isRequired,
};

DateFilterInput.defaultProps = {
  value: [],
};

const AddFilterBtn = ({ addFilter, disabled }) => (
  <Tooltip title={utils.getLang('smartmessaging.tooltip.addFilter')}>
    <span>
      <IconButton
        disabled={disabled}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          addFilter();
        }}
        edge="end"
      >
        <DoneIcon style={{ fill: '#b6b6b6' }} />
      </IconButton>
    </span>
  </Tooltip>
);

AddFilterBtn.propTypes = {
  addFilter: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

AddFilterBtn.defaultProps = {
  disabled: false,
};

function FilterInput({ addFilter, column, setInputRef }) {
  const [value, setValue] = useState('');
  const { filterConfig } = column;
  function isValid() {
    return filterConfig.inputConfig.isValid ? filterConfig.inputConfig.isValid(value) : true;
  }
  switch (filterConfig.inputConfig.type) {
    case 'TEXT':
      return (
        <TextFilterInput
          setInputRef={setInputRef}
          onEnter={() => {
            if (isValid()) {
              addFilter({ ...filterConfig.model, value }, true);
              setValue('');
            }
          }}
          endAdornment={
            <AddFilterBtn
              disabled={!isValid()}
              addFilter={() => {
                addFilter({ ...filterConfig.model, value }, true);
                setValue('');
              }}
            />
          }
          onChange={pValue => setValue(pValue)}
          value={value}
        />
      );
    case 'DATE':
      return (
        <DateFilterInput
          onEnter={() => {
            if (isValid()) {
              addFilter({ ...filterConfig.model, value }, true);
              setValue([]);
            }
          }}
          endAdornment={
            <AddFilterBtn
              disabled={!isValid()}
              addFilter={() => {
                addFilter({ ...filterConfig.model, value }, true);
                setValue([]);
              }}
            />
          }
          onChange={(pValue, bound) => {
            switch (bound) {
              case 'start':
                setValue([pValue, (value.length === 2 && value[1]) || '']);
                break;
              case 'end':
                setValue([value[0] || '', pValue]);
                break;
              default:
                break;
            }
          }}
          value={(value.length && value) || []}
        />
      );
    case 'SELECT':
      return (
        <SelectFilterInput
          onChange={pValue => {
            addFilter({ ...column.filterConfig.model, value: pValue }, true);
          }}
          options={column.filterConfig.inputConfig.getOptions()}
        />
      );
    default:
      return <TextField disabled />;
    // return (
    //   <TextFilterInput
    //     // setInputRef={setInputRef}
    //     onEnter={() => {
    //       if (isValid()) {
    //         addFilter({ ...filterConfig.model, value }, true);
    //         setValue('');
    //       }
    //     }}
    //     endAdornment={
    //       <AddFilterBtn
    //         disabled={!isValid()}
    //         addFilter={() => {
    //           addFilter({ ...filterConfig.model, value }, true);
    //           setValue('');
    //         }}
    //       />
    //     }
    //     onChange={pValue => setValue(pValue)}
    //     value={value}
    //   />
    // );
  }
}

FilterInput.propTypes = {
  isValid: PropTypes.func,
  addFilter: PropTypes.func.isRequired,
  column: PropTypes.object.isRequired,
  setInputRef: PropTypes.func,
};

FilterInput.defaultProps = { isValid: () => true, setInputRef: () => {} };

export default FilterInput;
