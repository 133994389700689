import React from 'react';
import { SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';
import appStyle from 'src/consts/appStyle';

const defs = {
  default:
    'M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z',
  emailSender:
    'M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 4.99L4 6h16zm0 12H4V8l8 5 8-5v10z',
  smsSender:
    'M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM9 11H7V9h2v2zm4 0h-2V9h2v2zm4 0h-2V9h2v2z',
  facebookUrl:
    'M17.525,9H14V7c0-1.032,0.084-1.682,1.563-1.682h1.868v-3.18C16.522,2.044,15.608,1.998,14.693,2 C11.98,2,10,3.657,10,6.699V9H7v4l3-0.001V22h4v-9.003l3.066-0.001L17.525,9z',
  googlePlusUrl:
    'M12,2C6.477,2,2,6.477,2,12c0,5.523,4.477,10,10,10s10-4.477,10-10C22,6.477,17.523,2,12,2z M10,16c-2.209,0-4-1.791-4-4 c0-2.209,1.791-4,4-4c1.042,0,1.982,0.408,2.694,1.061l-1.093,1.034C11.167,9.729,10.613,9.5,10,9.5c-1.381,0-2.5,1.119-2.5,2.5 c0,1.381,1.119,2.5,2.5,2.5c1.209,0,2.218-0.859,2.45-2H10V11h3.859C13.942,11.321,14,11.653,14,12C14,14.209,12.209,16,10,16z M18,12v1.5h-1V12h-1.5v-1H17V9.5h1V11h1.5v1H18z M20,12c0,4.418-3.582,8-8,8s-8-3.582-8-8s3.582-8,8-8S20,7.582,20,12z',
  instagramUrl:
    'M 8 3 C 5.243 3 3 5.243 3 8 L 3 16 C 3 18.757 5.243 21 8 21 L 16 21 C 18.757 21 21 18.757 21 16 L 21 8 C 21 5.243 18.757 3 16 3 L 8 3 z M 8 5 L 16 5 C 17.654 5 19 6.346 19 8 L 19 16 C 19 17.654 17.654 19 16 19 L 8 19 C 6.346 19 5 17.654 5 16 L 5 8 C 5 6.346 6.346 5 8 5 z M 17 6 A 1 1 0 0 0 16 7 A 1 1 0 0 0 17 8 A 1 1 0 0 0 18 7 A 1 1 0 0 0 17 6 z M 12 7 C 9.243 7 7 9.243 7 12 C 7 14.757 9.243 17 12 17 C 14.757 17 17 14.757 17 12 C 17 9.243 14.757 7 12 7 z M 12 9 C 13.654 9 15 10.346 15 12 C 15 13.654 13.654 15 12 15 C 10.346 15 9 13.654 9 12 C 9 10.346 10.346 9 12 9 z',
  twitterUrl:
    'M 5 3 C 3.897 3 3 3.897 3 5 L 3 19 C 3 20.103 3.897 21 5 21 L 19 21 C 20.103 21 21 20.103 21 19 L 21 5 C 21 3.897 20.103 3 19 3 L 5 3 z M 5 5 L 19 5 L 19.001953 19 L 5 19 L 5 5 z M 14.566406 7.1132812 C 13.194406 7.1132812 12.080078 8.2286094 12.080078 9.5996094 C 12.080078 9.8566094 12.166016 10.028219 12.166016 10.199219 C 10.109016 10.114219 8.309375 9.0859063 7.109375 7.6289062 C 6.852375 7.9719062 6.765625 8.399125 6.765625 8.828125 C 6.765625 9.685125 7.1948594 10.372656 7.8808594 10.972656 C 7.4528594 10.886656 7.108625 10.799906 6.765625 10.628906 C 6.765625 11.828906 7.6223281 12.772297 8.7363281 13.029297 C 8.4793281 13.115297 8.3077812 13.115234 8.0507812 13.115234 C 7.9647813 13.115234 7.7920938 13.029297 7.6210938 13.029297 C 7.9640937 13.972297 8.8215469 14.742188 9.9355469 14.742188 C 9.0785469 15.342187 7.9636094 15.771484 6.8496094 15.771484 L 6.25 15.771484 C 7.364 16.456484 8.6504844 16.886719 10.021484 16.886719 C 14.564484 16.886719 17.050781 13.114422 17.050781 9.8574219 L 17.050781 9.5136719 C 17.479781 9.1706719 17.906953 8.7425625 18.251953 8.2265625 C 17.737953 8.4845625 17.308922 8.57025 16.794922 8.65625 C 17.308922 8.31425 17.737203 7.8851562 17.908203 7.2851562 C 17.479203 7.5421562 16.965234 7.7987656 16.365234 7.8847656 C 15.936234 7.3707656 15.252406 7.1132812 14.566406 7.1132812 z',
  youtubeUrl:
    'M 12 4 C 12 4 5.7455469 3.9999687 4.1855469 4.4179688 C 3.3245469 4.6479688 2.6479687 5.3255469 2.4179688 6.1855469 C 1.9999687 7.7455469 2 12 2 12 C 2 12 1.9999687 16.254453 2.4179688 17.814453 C 2.6479687 18.675453 3.3255469 19.352031 4.1855469 19.582031 C 5.7455469 20.000031 12 20 12 20 C 12 20 18.254453 20.000031 19.814453 19.582031 C 20.674453 19.352031 21.352031 18.674453 21.582031 17.814453 C 22.000031 16.254453 22 12 22 12 C 22 12 22.000031 7.7455469 21.582031 6.1855469 C 21.352031 5.3255469 20.674453 4.6479688 19.814453 4.4179688 C 18.254453 3.9999687 12 4 12 4 z M 12 6 C 14.882 6 18.490875 6.1336094 19.296875 6.3496094 C 19.465875 6.3946094 19.604391 6.533125 19.650391 6.703125 C 19.891391 7.601125 20 10.342 20 12 C 20 13.658 19.891391 16.397875 19.650391 17.296875 C 19.605391 17.465875 19.466875 17.604391 19.296875 17.650391 C 18.491875 17.866391 14.882 18 12 18 C 9.119 18 5.510125 17.866391 4.703125 17.650391 C 4.534125 17.605391 4.3956094 17.466875 4.3496094 17.296875 C 4.1086094 16.398875 4 13.658 4 12 C 4 10.342 4.1086094 7.6011719 4.3496094 6.7011719 C 4.3946094 6.5331719 4.533125 6.3946094 4.703125 6.3496094 C 5.508125 6.1336094 9.118 6 12 6 z M 10 8.5351562 L 10 15.464844 L 16 12 L 10 8.5351562 z',
  resamaniaUrl: '',
  bookingUrl:
    'M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V10h16v11zm0-13H4V5h16v3z',
  salelUrl:
    'M15.55 13c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.37-.66-.11-1.48-.87-1.48H5.21l-.94-2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2h7.45zM6.16 6h12.15l-2.76 5H8.53L6.16 6zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z',
  passwordforgottenUrl:
    'M22 19h-6v-4h-2.68c-1.14 2.42-3.6 4-6.32 4-3.86 0-7-3.14-7-7s3.14-7 7-7c2.72 0 5.17 1.58 6.32 4H24v6h-2v4zm-4-2h2v-4h2v-2H11.94l-.23-.67C11.01 8.34 9.11 7 7 7c-2.76 0-5 2.24-5 5s2.24 5 5 5c2.11 0 4.01-1.34 4.71-3.33l.23-.67H18v4zM7 15c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3zm0-4c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1z',
  unsubscribeUrl:
    'M20.99 14.04V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h10.05c.28 1.92 2.1 3.35 4.18 2.93 1.34-.27 2.43-1.37 2.7-2.71.25-1.24-.16-2.39-.94-3.18zm-2-9.04L12 8.5 5 5h13.99zm-3.64 10H5V7l7 3.5L19 7v6.05c-.16-.02-.33-.05-.5-.05-1.39 0-2.59.82-3.15 2zm5.15 2h-4v-1h4v1z',
  clubUrl: 'M12 5.69l5 4.5V18h-2v-6H9v6H7v-7.81l5-4.5M12 3L2 12h3v8h6v-6h2v6h6v-8h3L12 3z',
  googleplayUrl:
    'M73.8,0l-4.3,0.6h-3.7h-0.6h-1.8c-2.2,0.5-4.2,1.3-6.2,2.5c-2.1,1.1-4,2.5-5.5,4.3c-7.7,6.5-12.3,16.5-12.3,25.9v448.8   c0,9.7,4.3,21.4,14.8,27.1l2.5,0.6l1.8,0.6c9.4,3.2,19.2,1.3,26.5-3.1h0.6c8.5-5.2,185.3-113.3,185.3-113.3l94.2-56.6   c0,0,79.9-48.3,90.5-54.8c9-5.5,17.4-15.9,17.2-28.3c-0.2-12.5-8.8-21.7-17.2-26.5c-2.7-1.5-26.4-15.8-48-28.9   c-20.7-12.6-38.9-23.6-40.6-24.6L365,173l-94.2-57.3c0,0-171.4-104-181.6-110.2C84.6,2.8,79.2,0.6,73.8,0z M78.7,61l185.9,193.9   L78.7,448.8V61z M173.5,102.8c34.8,21.1,77,46.8,77,46.8l72.7,44.3l-31.4,32.6L173.5,102.8z M357.6,214.9   c7.5,4.6,14.5,8.7,29.6,17.9c16.5,10,27.9,16.4,37.6,22.2c-18.1,10.9-52.4,32.1-66.5,40.6l-38.8-40.6L357.6,214.9z M291.7,283.8   l31.4,32.6l-72.7,44.3c0,0-43.2,25.9-77.6,46.8L291.7,283.8z',
  appstoreUrlTop:
    'M151.379,82.354c0.487,0.015,0.977,0.022,1.464,0.022c0.001,0,0.001,0,0.002,0c17.285,0,36.041-9.745,47.777-24.823   C212.736,42.011,218.24,23.367,215.723,6.4c-0.575-3.875-4.047-6.662-7.943-6.381c-17.035,1.193-36.32,11.551-47.987,25.772   c-12.694,15.459-18.51,34.307-15.557,50.418C144.873,79.684,147.848,82.243,151.379,82.354z M171.388,35.309   c7.236-8.82,18.949-16.106,29.924-19.028c-0.522,14.924-8.626,27.056-12.523,32.056c-7.576,9.732-19.225,16.735-30.338,18.566   C158.672,52.062,168.14,39.265,171.388,35.309z',
  appstoreUrlBottom:
    'M282.608,226.332c-0.794-1.91-2.343-3.407-4.279-4.137c-30.887-11.646-40.56-45.12-31.807-69.461   c4.327-12.073,12.84-21.885,24.618-28.375c1.938-1.068,3.306-2.938,3.737-5.109c0.431-2.171-0.12-4.422-1.503-6.149   c-15.654-19.536-37.906-31.199-59.525-31.199c-15.136,0-25.382,3.886-34.422,7.314c-6.659,2.525-12.409,4.706-19.001,4.706   c-7.292,0-13.942-2.382-21.644-5.141c-9.003-3.225-19.206-6.88-31.958-6.88c-24.577,0-49.485,14.863-65.013,38.803   c-5.746,8.905-9.775,19.905-11.98,32.708c-6.203,36.422,4.307,79.822,28.118,116.101c13.503,20.53,30.519,41.546,54.327,41.749   l0.486,0.002c9.917,0,16.589-2.98,23.041-5.862c6.818-3.045,13.258-5.922,24.923-5.98l0.384-0.001   c11.445,0,17.681,2.861,24.283,5.89c6.325,2.902,12.866,5.903,22.757,5.903l0.453-0.003c23.332-0.198,41.002-22.305,55.225-43.925   c8.742-13.391,12.071-20.235,18.699-35.003C283.373,230.396,283.402,228.242,282.608,226.332z M251.281,259.065   c-11.329,17.222-26.433,37.008-42.814,37.148l-0.318,0.001c-6.615,0-10.979-2.003-16.503-4.537   c-7.046-3.233-15.815-7.256-30.538-7.256l-0.463,0.001c-14.819,0.074-23.77,4.072-30.961,7.285   c-5.701,2.547-10.204,4.558-16.923,4.558l-0.348-0.001c-16.862-0.145-31.267-18.777-41.929-34.987   c-21.78-33.184-31.45-72.565-25.869-105.332c1.858-10.789,5.155-19.909,9.79-27.093c12.783-19.708,32.869-31.951,52.419-31.951   c10.146,0,18.284,2.915,26.9,6.001c8.262,2.96,16.805,6.02,26.702,6.02c9.341,0,16.956-2.888,24.32-5.681   c8.218-3.117,16.717-6.34,29.104-6.34c14.739,0,30.047,7.097,42.211,19.302c-11.002,8.02-19.102,18.756-23.655,31.461   c-11.872,33.016,2.986,69.622,33.334,85.316C261.229,242.764,258.024,248.734,251.281,259.065z',
};

const SmConfigIcon = ({ property }) => {
  let icon;
  switch (property) {
    case 'resamaniaUrl':
      icon = (
        <div
          style={{
            backgroundImage: 'url(/resources/img/logo_resa.png)',
            backgroundSize: 'contain',
            height: '24px',
            width: '24px',
            backgroundColor: appStyle.txtColor8,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            borderRadius: '50%',
          }}
        />
      );
      break;
    case 'googleplayUrl':
      icon = (
        <SvgIcon viewBox="0 0 512 512">
          <path fill={appStyle.txtColor8} d={(property && defs[property]) || defs.default} />
        </SvgIcon>
      );
      break;
    case 'appstoreUrl':
      icon = (
        <SvgIcon viewBox="0 0 311.265 311.265">
          <path fill={appStyle.txtColor8} d={defs.appstoreUrlTop} />
          <path fill={appStyle.txtColor8} d={defs.appstoreUrlBottom} />
        </SvgIcon>
      );
      break;
    default:
      icon = (
        <SvgIcon>
          <path fill={appStyle.txtColor8} d={(property && defs[property]) || defs.default} />
        </SvgIcon>
      );
  }
  return icon;
};

SmConfigIcon.propTypes = { property: PropTypes.string };

SmConfigIcon.defaultProps = { property: null };

export default SmConfigIcon;
