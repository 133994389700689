import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withState } from 'recompose';
import { connect } from 'react-redux';
import utils from 'src/utils/utils';
import CKEditor, { defaultCkeHtml } from 'src/components/common/ReactCKEditor';
import EmailContentLabel from './EmailContentLabel';
import EmailObjectLabel from './EmailObjectLabel';
import CtnLang from './CtnLang';

const EmailEditorRender = ({
  currentAction,
  fields,
  onMailObjectChange,
  onMailContentChange,
  onMailEditorLoaded,
  nodeRef,
  rendered,
  isWritable,
}) => (
  <div style={{ height: '85%' }} ref={nodeRef}>
    {rendered && (
      <div style={{ width: '100%', display: 'flex' }}>
        <EmailObjectLabel title={utils.getLang('smartmessaging.contentEditor.mail.object')} />
        <div style={{ marginRight: 0, marginLeft: 'auto' }}>
          <CtnLang />
        </div>
      </div>
    )}
    {rendered && (
      <CKEditor
        name="objectEditor"
        config={{
          readOnly:
            !!currentAction.content.object && currentAction.messageModel
              ? !isWritable || !currentAction.messageModel.generic
              : !isWritable ||
                (currentAction.customTemplate && !currentAction.customTemplate.generic),
          fields: fields.fields.objectFields,
          removeButtons:
            'Underline,Subscript,Superscript,' +
            'Source,Anchor,Maximize,About,Copy,Paste,PasteText,PasteFromWord,' +
            'Cut,Scayt,Undo,Redo,Form,Checkbox,Radio,TextField,Textarea,Select,Button,' +
            'ImageButton,HiddenField,Bold,Italic,Underline,Strike,Subscript,Superscript,' +
            'RemoveFormat,NumberedList,BulletedList,Outdent,Indent,Blockquote,CreateDiv,' +
            'JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,' +
            'BidiLtr,BidiRtl,CreatePlaceholder,' +
            'Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,InsertPre,Styles,Format,Font,FontSize,Unlink,Link',
          extraPlugins: 'strinsert',
          removePlugins: 'colorbutton,elementspath',
          autoParagraph: false,
          enterMode: 2,
          height: 10 * (nodeRef.current.clientHeight / 100),
        }}
        events={{
          change: event => {
            onMailObjectChange(event.editor.getData());
          },
        }}
        content={currentAction.content.object}
      />
    )}
    {rendered && (
      <EmailContentLabel title={utils.getLang('smartmessaging.contentEditor.mail.content')} />
    )}
    {rendered && (
      <CKEditor
        name="htmlEditor"
        config={{
          readOnly: currentAction.messageModel
            ? !isWritable || !currentAction.messageModel.generic
            : !isWritable ||
              (currentAction.customTemplate && !currentAction.customTemplate.generic),
          fullPage: true,
          allowedContent: true,
          fields: fields.fields.contentFields,
          removeButtons:
            'Underline,Subscript,Superscript,Anchor,About,Copy,Paste,PasteText,PasteFromWord,Cut,Scayt',
          disallowedContent: 'img{border*,margin*,float*,width,height}; table{width,height}',
          extraAllowedContent: 'img[width,height]; table[width,height]',
          extraPlugins: 'strinsert,uploadimage',
          imageUploadUrl: `${process.env.REACT_APP_API_BASE_URL}/storedfile/fileUpload/`,
          filebrowserImageUploadUrl: `${process.env.REACT_APP_API_BASE_URL}/storedfile/fileUpload/`,
          removePlugins: 'elementspath',
          autoParagraph: false,
          enterMode: 2,
          height: 70 * (nodeRef.current.clientHeight / 100),
        }}
        events={{
          change: event => {
            onMailContentChange(event.editor.getData());
          },
          loaded: ({ editor }) => {
            onMailEditorLoaded(editor);
          },
          instanceReady: ({ editor }) => {
            if (currentAction.content.content) editor.setData(currentAction.content.content);
            else editor.setData(defaultCkeHtml);
            // contentEditorUtils.processCKEditorDocTags(editor.document);
          },
          fileUploadRequest: event => {
            const { xhr, file, fileName } = event.data.fileLoader;
            const smStore = JSON.parse(sessionStorage.getItem('sm-store'));
            xhr.setRequestHeader('Authorization', `Bearer ${smStore.security.access_token}`);
            xhr.setRequestHeader(
              'X-User-Club-Id',
              `/${smStore.client}/clubs/${sessionStorage.getItem('club')}`
            );

            const networknode = sessionStorage.getItem('networknode');
            if (networknode)
              xhr.setRequestHeader(
                'X-User-Network-Node-Id',
                `/${smStore.client}/network_nodes/${networknode}`
              );
            const formData = new FormData();
            formData.append('file', file, fileName);
            xhr.send(formData);
            event.stop();
          },
          fileUploadResponse: event => {
            event.stop();
            const { data } = event;
            const { responseText } = event.data.fileLoader.xhr;
            data.url = `${process.env.REACT_APP_API_BASE_URL}/media/${responseText}`;
          },
        }}
      />
    )}
  </div>
);

EmailEditorRender.propTypes = {
  currentAction: PropTypes.object,
  fields: PropTypes.any.isRequired,
  onMailObjectChange: PropTypes.func.isRequired,
  onMailContentChange: PropTypes.func.isRequired,
  onMailEditorLoaded: PropTypes.func.isRequired,
  nodeRef: PropTypes.object.isRequired,
  rendered: PropTypes.bool.isRequired,
  isWritable: PropTypes.bool.isRequired,
};

EmailEditorRender.defaultProps = {
  currentAction: null,
};
/** Used to render ckeditors after containers are rendered for ckeditors height
 *  cause it's value is not state bound (to avoid rerenders causing ckeditors layout issues)
 */
class EmailEditorWithRef extends Component {
  constructor(props) {
    super(props);
    this.myNodeRef = React.createRef();
    this.onCKEditorDialogDefintion = this.onCKEditorDialogDefintion.bind(this);
  }

  componentDidMount() {
    this.onCKEditorDialogDefintion();
    this.props.setRendered(true);
  }

  onCKEditorDialogDefintion() {
    window.CKEDITOR.on('dialogDefinition', ev => {
      // Take the dialog name and its definition from the event
      const dialogName = ev.data.name;
      const dialogDefinition = ev.data.definition;

      // vérifie qu'on est sur la popup des liens

      if (dialogName === 'link') {
        if (this.props.fields.fields.linkFields && this.props.fields.fields.linkFields.length) {
          // liste des champs de fusion
          const strings = this.props.fields.fields.linkFields; // du type ${monchamps!""}
          // dialogDefinition.removeContents('target');
          const infoTab = dialogDefinition.getContents('info');
          infoTab.remove('emailBody');
          infoTab.remove('emailSubject');
          const fusionField = infoTab.get('customLinks');
          // ON crée la liste déroulante si elle n'existe pas
          if (!fusionField) {
            infoTab.add({
              id: 'customLinks',
              type: 'select',
              label: utils.getLang('resamania.crm.campaign.model.email.addValue'),
              default: '',
              items: [],
              onLoad() {
                for (let i = 0, len = strings.length; i < len; i += 1) {
                  const string = strings[i];
                  // If there is no value, make a group header using the name.
                  if (string.value) {
                    // If no name provided, use the value for the name.
                    if (!string.name) {
                      string.name = string.value;
                    }
                    // If no label provided, use the name for the label.
                    if (!string.label) {
                      string.label = string.name;
                    }
                    this.add(string.name, string.value, string.label);
                  }
                }
              },
              onChange() {
                const d = window.CKEDITOR.dialog.getCurrent();
                d.setValueOf('info', 'url', d.getValueOf('info', 'url') + this.getValue());
              },
            });
          }
        }
      }
      if (dialogName === 'image') {
        const linkInfoTab = dialogDefinition.getContents('Link');
        linkInfoTab.remove('cmbTarget');
        linkInfoTab.remove('browse');
        const txtUrl = linkInfoTab.get('txtUrl');
        // txtUrl.default = 'https://';
        txtUrl.onChange = function onChange() {
          let c = this.getValue();
          if (c && !/^http:\/\/|https:\/\//g.test(c)) {
            c = `http://${c}`;
            this.setValue(c);
          }
        };
      }
    });
  }

  render() {
    return <EmailEditorRender {...this.props} nodeRef={this.myNodeRef} />;
  }
}

EmailEditorWithRef.propTypes = {
  setRendered: PropTypes.func.isRequired,
  rendered: PropTypes.bool.isRequired,
  currentAction: PropTypes.object,
  fields: PropTypes.object,
  availableLanguages: PropTypes.array.isRequired,
};

EmailEditorWithRef.defaultProps = {
  currentAction: null,
  fields: { fields: {} },
};

const mapStateToProps = state => ({
  currentAction: state.campaignEditor.actions.currentAction,
  availableLanguages: state.app.availableLanguages,
  // currentRequestModelId: getCurrentRequestModelId(state),
});

const actionCreators = {
  onMailObjectChange: data => ({ type: 'ON_MAIL_OBJECT_CHANGE', value: data }),
  onMailContentChange: data => ({ type: 'ON_MAIL_CONTENT_CHANGE', value: data }),
  onMailEditorLoaded: editor => ({ type: 'ON_MAIL_EDITOR_LOADED', value: editor }),
};

export default withState(
  'rendered',
  'setRendered',
  () => false
)(connect(mapStateToProps, actionCreators)(EmailEditorWithRef));
