import React from 'react';
import { compose } from 'redux';
import { withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { Button, InputLabel } from '@material-ui/core';
import { sendSmConfigImage, logState } from 'src/reducers/parameters';

import FileUploaderBtn from '../common/FileUploaderBtn';
import appStyle from 'src/consts/appStyle';
import utils from 'src/utils/utils';
import getApiCaller from 'src/utils/apiClientCaller';
import { showAlertDispatcher, closeAlertDispatcher } from 'src/reducers/alert';

const loadedImgStyles = {
  loadedImgOuter: {
    width: '200px',
    fontSize: '0.8rem',
    color: appStyle.txtColor5,
    textAlign: 'center',
    padding: '2px',
  },
  loadedImgInner: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    height: '100px',
    width: '200px',
    backgroundPosition: 'center',
  },
};

const LoadedImgRender = ({ classes, file, dataUrl }) => (
  <div className={classes.loadedImgOuter}>
    <div
      className={classes.loadedImgInner}
      style={{
        backgroundImage: `url(${dataUrl})`,
      }}
    />
    <span>
      {file.name} ({(file.size / 1000).toFixed(1)} Ko)
    </span>
  </div>
);

LoadedImgRender.propTypes = {
  dataUrl: PropTypes.string.isRequired,
  file: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

const LoadedImg = withStyles(loadedImgStyles)(LoadedImgRender);

const styles = theme => ({
  savedImgCtn: {
    backgroundSize: 'contain',

    backgroundRepeat: 'no-repeat',
    height: '100px',
    width: '200px',
    backgroundPosition: 'center',
  },
  deleteBtn: {
    margin: `${theme.spacing(0.5)}px`,
    backgroundColor: appStyle.bgColor5,
    color: appStyle.txtColor6,
    '&:hover': {
      background: appStyle.bgColor5,
      opacity: 0.9,
    },
  },
  button: {
    margin: `${theme.spacing(0.5)}px`,
    backgroundColor: appStyle.bgColor1,
    color: appStyle.txtColor2,
    '&:hover': {
      background: appStyle.bgColor1,
      opacity: 0.9,
    },
  },
});

const SmConfigImageField = ({
  // style,
  classes,
  label,
  storedFileId,
  dataUrl,
  onFileChange,
  paramKey,
  doSendSmConfigImage,
  onFieldChange,
  doGetApiCaller,
}) => (
  <div
    style={{
      margin: '24px 24px 0',
      // display: 'inline-block',
      textAlign: 'left',
    }}
  >
    <InputLabel
      shrink
      style={{ display: 'inline-flex', color: appStyle.txtColor3, fontWeight: 500 }}
    >
      {label}
    </InputLabel>
    {!storedFileId && (
      <FileUploaderBtn
        selectFileLabel={utils.getLang('smartmessaging.config.imagefield.selectFile')}
        sendFileLabel={utils.getLang('smartmessaging.config.imagefield.send')}
        cancelLabel={utils.getLang('smartmessaging.config.imagefield.cancel')}
        displayCmp={file => <LoadedImg file={file} dataUrl={dataUrl} />}
        accept="image/*"
        onFileChange={onFileChange}
        onSend={file => {
          doSendSmConfigImage(paramKey, file);
        }}
      />
    )}
    {storedFileId && (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          className={classes.savedImgCtn}
          style={{
            backgroundImage: `url(${utils.link(storedFileId)})`,
          }}
        />

        <Button
          className={classes.deleteBtn}
          onClick={e => {
            e.preventDefault();
            onFieldChange(paramKey, null);
          }}
        >
          {utils.getLang('smartmessaging.config.imagefield.delete')}
        </Button>
        <Button
          className={classes.button}
          onClick={() => {
            utils.downloadStoredFile(storedFileId, doGetApiCaller());
          }}
        >
          {utils.getLang('smartmessaging.config.imagefield.download')}
        </Button>
      </div>
    )}
  </div>
);

SmConfigImageField.propTypes = {
  classes: PropTypes.object.isRequired,
  storedFileId: PropTypes.number,
  dataUrl: PropTypes.string,
  onFileChange: PropTypes.func.isRequired,
  label: PropTypes.any,
  paramKey: PropTypes.string.isRequired,
  doSendSmConfigImage: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  doGetApiCaller: PropTypes.func.isRequired,
};

SmConfigImageField.defaultProps = {
  storedFileId: null,
  dataUrl: '',
  label: '',
};

const mapStateToProps = () => ({});

const actionCreators = {
  doSendSmConfigImage: sendSmConfigImage,
  onFieldChange: (key, value) => ({ type: 'ON_SM_CFG_FIELD_CHANGE', value: { key, value } }),
  doLogState: logState,
  doShowAlert: showAlertDispatcher,
  doCloseAlert: closeAlertDispatcher,
  doGetApiCaller: () => getApiCaller,
};

const handlers = {
  onFileChange: props => (e, pFile) => {
    const { setDataUrl } = props;
    if (!e && !pFile) {
      setDataUrl('');
      return false;
    }
    const file = pFile || e.target.files[0];
    if (!file) {
      setDataUrl('');
      return false;
    }
    const pattern = /image-*/;
    const reader = new FileReader();

    if (!file.type.match(pattern)) {
      return false;
    }

    reader.onload = async () => {
      setDataUrl(reader.result);
    };

    reader.readAsDataURL(file);
    return true;
  },
};

export default compose(
  connect(mapStateToProps, actionCreators),
  withStyles(styles),
  withState('dataUrl', 'setDataUrl', () => ''),
  withHandlers(handlers)
)(SmConfigImageField);
