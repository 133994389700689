import React from 'react';
import { IconButton, InputAdornment, TextField, FormLabel, FormControl } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import IntegerFormat from 'src/components/common/IntegerFormat';
import OperatorSelect from 'src/components/common/OperatorSelect';
import ValidityAdornment from './ValidityAdornment';
import utils from 'src/utils/utils';

// labelCmp endAdornment startAdornment
const IntegerBoundRecipeFieldRender = ({
  fieldConfig,
  onChange,
  removeLine,
  removable,
  onOperatorChange,
  classes,
  validity,
  disabled,
}) => (
  <FormControl required={fieldConfig.model.mandatory}>
    <div className={classes.fieldContainer}>
      <ValidityAdornment required={fieldConfig.model.mandatory} validity={validity} />
      <FormLabel
        style={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '0.875rem',
        }}
        component="div"
      >
        {utils.getLang(`smartmessaging.recipeFieldName.${fieldConfig.model.key}`)}
      </FormLabel>
      <div style={{ marginLeft: '16px', marginRight: '16px', display: 'flex' }}>
        <OperatorSelect disabled={disabled} fieldConfig={fieldConfig} onChange={onOperatorChange} />
      </div>
      <div style={{ width: '100%', display: 'flex' }}>
        <TextField
          style={{ flex: '1' }}
          disabled={disabled}
          onChange={event => {
            const val = [
              event.target.value,
              (fieldConfig.value &&
                fieldConfig.value.values &&
                fieldConfig.value.values.length > 1 &&
                fieldConfig.value.values[1]) ||
                '',
            ];
            onChange(fieldConfig, val);
          }}
          value={
            (fieldConfig.value &&
              fieldConfig.value.values &&
              fieldConfig.value.values.length &&
              fieldConfig.value.values[0]) ||
            ''
          }
          InputProps={{ inputComponent: IntegerFormat, 'aria-label': 'Description' }}
        />
        <InputAdornment position="start">
          {utils.getLang('smartmessaging.inputs.AND')}
        </InputAdornment>
        <TextField
          disabled={disabled}
          style={{ flex: '1' }}
          onChange={event => {
            const val = [
              (fieldConfig.value &&
                fieldConfig.value.values &&
                fieldConfig.value.values.length &&
                fieldConfig.value.values[0]) ||
                '',
              event.target.value,
            ];
            onChange(fieldConfig, val);
          }}
          value={
            (fieldConfig.value &&
              fieldConfig.value.values &&
              fieldConfig.value.values.length > 1 &&
              fieldConfig.value.values[1]) ||
            ''
          }
          InputProps={{ inputComponent: IntegerFormat, 'aria-label': 'Description' }}
        />
        {removable && (
          <InputAdornment position="start">
            <IconButton aria-label="clear" onClick={() => removeLine(fieldConfig)}>
              <DeleteIcon />
            </IconButton>
          </InputAdornment>
        )}
      </div>
    </div>
  </FormControl>
);

IntegerBoundRecipeFieldRender.propTypes = {
  fieldConfig: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  removeLine: PropTypes.func.isRequired,
  removable: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onOperatorChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  validity: PropTypes.object.isRequired,
};

export default IntegerBoundRecipeFieldRender;
