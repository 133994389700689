import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { Fade } from '@material-ui/core';
import CampaignSchedulerItem from './CampaignSchedulerItem';
import utils from 'src/utils/utils';
import { activateEditedCampaign, getUnremovedPeriods } from 'src/reducers/campaignEditor';
import appStyle from 'src/consts/appStyle';
import ActionBtn from 'src/components/common/ActionBtn';
import CustCard from '../campaignContent/ActionCard';

const CampaignScheduler = ({
  periods,
  addNewPeriod,
  classes,
  campaignValidity,
  doActivateEditedCampaign,
  editedCampaign,
  unRemovePeriods,
  goToEditorStep,
}) => (
  <Fade in timeout={500}>
    <div style={{ textAlign: 'center', margin: '24px 15% 8px' }}>
      <CustCard title={utils.getLang('smartmessaging.campaigns.schedule.title')}>
        <div style={{ paddingTop: '24px' }}>
          {unRemovePeriods.map(
            period =>
              !period.removed && (
                <CampaignSchedulerItem
                  key={periods.indexOf(period)}
                  index={periods.indexOf(period)}
                  period={period}
                  editedCampaign={editedCampaign}
                />
              )
          )}
          {editedCampaign.isWritable && (
            <ActionBtn
              cVariant="reverse"
              // variant=""
              onClick={addNewPeriod}
              style={{ color: appStyle.txtColor1 }}
            >
              {utils.getLang('smartmessaging.campaignSchedule.addPeriod')}
            </ActionBtn>
          )}
          <div style={{ textAlign: 'center', marginTop: '24px' }}>
            <ActionBtn
              style={{ margin: '2px' }}
              cVariant="reverse"
              // variant=""
              onClick={e => {
                e.preventDefault();
                goToEditorStep(2);
              }}
            >
              {utils.getLang('smartmessaging.buttonLabel.previous')}
            </ActionBtn>
            {editedCampaign.isWritable && (
              <ActionBtn
                style={{ margin: '2px' }}
                className={(!!editedCampaign.enabled && classes.disableBtn) || classes.activateBtn}
                variant="contained"
                disabled={
                  !editedCampaign.enabled &&
                  (!unRemovePeriods || !unRemovePeriods.length || !campaignValidity.isValid)
                }
                onClick={() => {
                  doActivateEditedCampaign();
                }}
              >
                {!editedCampaign.enabled && utils.getLang('smartmessaging.button.activateCampaign')}
                {editedCampaign.enabled && utils.getLang('smartmessaging.button.disableCampaign')}
              </ActionBtn>
            )}
          </div>
        </div>
      </CustCard>
    </div>
  </Fade>
);

const mapStateToProps = state => ({
  periods: state.campaignEditor.schedule.editedCmpPeriods,
  // campaignValidity: checkCampaignValidity(state),
  unRemovePeriods: getUnremovedPeriods(state),
});

const actionCreators = {
  addNewPeriod: () => ({ type: 'ADD_NEW_PERIOD' }),
  doActivateEditedCampaign: activateEditedCampaign,
};

CampaignScheduler.propTypes = {
  periods: PropTypes.array,
  addNewPeriod: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  campaignValidity: PropTypes.object.isRequired,
  editedCampaign: PropTypes.object.isRequired,
  doActivateEditedCampaign: PropTypes.func.isRequired,
  goToEditorStep: PropTypes.func.isRequired,
  unRemovePeriods: PropTypes.array,
};

CampaignScheduler.defaultProps = {
  periods: [],
  unRemovePeriods: [],
};

const styles = {
  activateBtn: { backgroundColor: appStyle.bgColor1, color: appStyle.txtColor2 },
  disableBtn: {
    backgroundColor: appStyle.bgColor5,
    color: appStyle.txtColor6,
    '&:hover': {
      background: appStyle.bgColor5,
      opacity: 0.9,
    },
  },
};

export default connect(mapStateToProps, actionCreators)(withStyles(styles)(CampaignScheduler));
