import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { Fade, Toolbar, Typography, AppBar, IconButton, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Loader from 'src/components/common/Loader';
// import SMJListDataResolver from './SMJListDataResolver';
import withDataResolver from 'src/components/common/withDataResolver';
import withApiCaller from 'src/components/common/hocs/withApiCaller';
import withAlertAndLoader from 'src/components/common/hocs/withAlertAndLoader';

import appStyle from 'src/consts/appStyle';

import utils from 'src/utils/utils';
import SmartjourneyItemList from './SmartjourneyItemList';
import smartjourneyApi from 'src/utils/api/smartjourneyApi';
import smjSelectors from 'src/selectors/smartjourney';

const SMJListDataResolver = withDataResolver({
  resolve: props => async () => {
    await props.doLoadSMJs();
  },
  loader: <Loader />,
  onResolved: () => async () => {},
})(({ children }) => children);

const styles = theme => ({
  createBtn: {
    zIndex: 1,
    backgroundColor: appStyle.bgColor1,
    color: appStyle.txtColor2,
    '&:hover': {
      background: appStyle.bgColor1,
      opacity: 0.9,
    },
  },
  appBar: {
    position: 'relative',
    backgroundColor: appStyle.bgColor6,
  },
  flex: { flex: 1 },
  uppercase: { textTransform: 'uppercase' },
  search: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  tableWrapper: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    overflow: 'auto',
    flex: 1,
  },
});

const SMJList = ({ classes, goToSMJStep, doLoadSMJs, campaignGroups, smjModelById, deleteSmj }) => (
  <div
    style={{
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      overflow: 'hidden',
      textAlign: 'left',
      height: '100%',
    }}
  >
    <AppBar className={classes.appBar}>
      <Toolbar>
        <Typography color="textSecondary" className={classes.uppercase}>
          {utils.getLang(`smartmessaging.campaigns.grouped.list.title`)}
        </Typography>
        <div
          style={{
            flex: 1,
            marginLeft: '16px',
          }}
        />
        <Tooltip title={utils.getLang('smartmessaging.tooltip.campaigns.grouped.newGroup')}>
          <IconButton
            aria-label="clear"
            onClick={e => {
              e.preventDefault();
              // clearEditedData();
              goToSMJStep(1);
            }}
            // style={{ color: appStyle.txtColor1, backgroundColor: appStyle.bgColor10 }}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>

    <Fade in timeout={500}>
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          overflow: 'hidden',
          textAlign: 'left',
          margin: '8px',
          // margin: '24px 1% 8px',
          // padding: '24px',
          // height: '100%',
        }}
      >
        <SMJListDataResolver doLoadSMJs={doLoadSMJs}>
          <SmartjourneyItemList
            editSmj={smj => {
              goToSMJStep(2, { smartjourney: smj });
            }}
            deleteSmj={pSmj => {
              deleteSmj(pSmj);
            }}
            enableSmj={() => {}}
            smjList={campaignGroups}
            smjModelById={smjModelById}
          />
        </SMJListDataResolver>
      </div>
    </Fade>
  </div>
);

SMJList.propTypes = {
  classes: PropTypes.object.isRequired,
  goToSMJStep: PropTypes.func.isRequired,
  doLoadSMJs: PropTypes.func.isRequired,
  campaignGroups: PropTypes.array.isRequired,
  smjModelById: PropTypes.object.isRequired,
  deleteSmj: PropTypes.func.isRequired,
  // smjListsByModel: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  // smjListsByModel: state.smartjourney.smjListsByModel,
  campaignGroups: smjSelectors.getSMJListWithInfos(state.smartjourney),
});

const actionCreators = {
  // clearEditedData: () => ({
  //   type: 'CLEAR_EDITED_DATA',
  // }),
  updateSmjList: list => ({ type: 'RECEIVE_SMJ_LIST', value: list }),
};

const Handler = ({
  callApi,
  setLoader,
  showAlert,
  closeAlert,
  notify,
  updateSmjList,
  campaignGroups,
  ...otherProps
}) => {
  const deleteSmj = useCallback(
    smj => {
      showAlert({
        type: 'warning',
        title: utils.getLang('smartmessaging.campaignEditor.confirmDeleteCampaign.title'),
        msg: `[${smj.name}]: ${utils.getLang(
          'smartmessaging.smartjourney.confirmDeleteSmartjourney'
        )}`,
        async onConfirm() {
          closeAlert();
          setLoader(true);
          const response = await callApi(smartjourneyApi.deleteSmj, [smj.id]).finally(() =>
            setLoader(false)
          );
          if (response.isSuccess) {
            updateSmjList(campaignGroups.filter(c => c.id !== smj.id));
            notify({
              message: `[${smj.name}] ${utils.getLang(
                'smartmessaging.notifications.deletedSmartjourney'
              )}`,
              options: {
                variant: 'info',
              },
            });
          } else {
            showAlert({
              title: `${utils.getLang(`smartmessaging.errorMessages.warning.title`)}`,
              msg: utils.getLang(`smartmessaging.errorMessages.smartjourney.${response.error}`),
              type: 'warning',
            });
          }
        },
        onDismiss() {
          closeAlert();
        },
      });
    },
    [callApi, setLoader, showAlert, closeAlert, notify, updateSmjList, campaignGroups]
  );

  return <SMJList {...otherProps} deleteSmj={deleteSmj} campaignGroups={campaignGroups} />;
};

Handler.propTypes = {
  callApi: PropTypes.func.isRequired,
  setLoader: PropTypes.func.isRequired,
  showAlert: PropTypes.func.isRequired,
  closeAlert: PropTypes.func.isRequired,
  notify: PropTypes.func.isRequired,
  updateSmjList: PropTypes.func.isRequired,
  campaignGroups: PropTypes.array.isRequired,
};

export default connect(
  mapStateToProps,
  actionCreators
)(withStyles(styles)(withAlertAndLoader(withApiCaller(Handler))));
