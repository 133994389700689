import React from 'react';
import { connect } from 'react-redux';
import { Fade } from '@material-ui/core';
import HistoryPage from 'src/components/common/history/HistoryPage';

const MassActionHistory = props => (
  <Fade in timeout={500}>
    <HistoryPage
      {...props}
      excludedProperties={['targetWithOptin', 'targetTotal']}
      summaryExcludedProperties={['launchedBy', 'status']}
      rowSpanCols={['clubName', 'date', 'targetNumber', 'optinType']}
      sortable={['clubName', 'date', 'targetNumber', 'optinType']}
      receiveHistory={history =>
        Object.entries(history.globalStatsList).reduce(
          (m, [k, e]) => [
            ...m,
            {
              id: k,
              items: e.map(i => ({
                ...i,
                setupValue: JSON.parse(i.setupValue),
                setupColumns: JSON.parse(i.setupColumns),
              })),
            },
          ],
          []
        )
      }
    />
  </Fade>
);

const mapStateToProps = state => ({
  filtersByKey: state.massAction.criteriaByKey,
});

export default connect(mapStateToProps, {})(MassActionHistory);
