import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { FormControl } from '@material-ui/core';
// import DeleteIcon from '@material-ui/icons/Delete';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import utils from 'src/utils/utils';
import ValidityAdornment from './fields/ValidityAdornment';

const SchedulerField = ({ classes, period, onChange, isValid }) => (
  <FormControl className={classes.container}>
    <ValidityAdornment
      validity={{
        isValid,
        messages: [utils.getLang('smartmessaging.smartjourney.invalidPeriod')],
      }}
    />
    <DatePicker
      variant="inline"
      className={classes.textField}
      autoOk
      format="dd/MM/yyyy"
      label={utils.getLang('smartmessaging.campaignEditor.scheduler.beginDate')}
      value={(utils.isValidDate(new Date(period.start)) && new Date(period.start)) || null}
      onChange={date => {
        onChange('start', date);
      }}
      InputLabelProps={{
        shrink: true,
        required: true,
      }}
    />
    <DatePicker
      variant="inline"
      className={classes.textField}
      autoOk
      format="dd/MM/yyyy"
      label={utils.getLang('smartmessaging.campaignEditor.scheduler.endDate')}
      value={
        (period.end && utils.isValidDate(new Date(period.end)) && new Date(period.end)) || null
      }
      onChange={date => {
        if (!date || utils.isValidDate(date)) onChange('end', date);
      }}
      InputLabelProps={{
        shrink: true,
      }}
    />
    <TimePicker
      variant="inline"
      ampm={false}
      format="HH:mm"
      label={utils.getLang('smartmessaging.campaignEditor.scheduler.time')}
      required
      value={(period.time && utils.getNewDateWithTime(period.time)) || null}
      onChange={time => {
        onChange('time', time.toLocaleTimeString().slice(0, 5));
      }}
      className={classes.textField}
      InputLabelProps={{
        shrink: true,
      }}
    />
  </FormControl>
);

SchedulerField.propTypes = {
  classes: PropTypes.object.isRequired,
  period: PropTypes.object.isRequired,
  isValid: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  // editedCampaign: PropTypes.object.isRequired,
};

export default withStyles(theme => ({
  container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
  },
  textField: {
    flex: 1,
    fontSize: '0.975rem',
    marginLeft: `${theme.spacing(1)}px!important`,
    marginRight: `${theme.spacing(1)}px!important`,
  },
}))(SchedulerField);
