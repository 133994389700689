import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { MenuItem, Typography } from '@material-ui/core';

import appStyle from 'src/consts/appStyle';

const useStyles = makeStyles({
  root: {
    color: appStyle.txtColor3,
    '&:hover': {
      backgroundColor: 'transparent',
      color: appStyle.txtColor1,
    },
  },
});

const AvailableField = ({ addCriteria, label }) => {
  const classes = useStyles();
  return (
    <MenuItem
      onClick={() => {
        addCriteria();
      }}
      classes={{
        root: classes.root,
      }}
      style={{
        overflow: 'visible',
        height: 'auto',
        justifyContent: 'flex-end',
        width: 'fit-content',
        paddingRight: 0,
      }}
    >
      <Typography
        variant="body2"
        style={{
          color: 'inherit',
          wordBreak: 'break-word',
          whiteSpace: 'normal',
          textAlign: 'right',
          // fontSize: '1rem',
        }}
      >
        {label}
      </Typography>
    </MenuItem>
  );
};

AvailableField.propTypes = {
  addCriteria: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default AvailableField;
