import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Typography } from '@material-ui/core';
import utils from 'src/utils/utils';
import appStyle from 'src/consts/appStyle';

import ValidityAdornment from './ValidityAdornment';

const NotifyContentLabel = ({ contentValidity }) => (
  <div style={{ display: 'flex' }}>
    <Typography
      style={{
        margin: '5px 10px',
        color: contentValidity.contentIsEmpty ? appStyle.txtColor6 : appStyle.txtColor3,
      }}
      variant="subtitle2"
      noWrap
    >
      {utils.getLang('smartmessaging.contentEditor.notify.content')}
    </Typography>
    <ValidityAdornment
      valid={!contentValidity.contentIsEmpty}
      message={utils.getLang('smartmessaging.contentEditor.notify.contentIsEmpty')}
    />
  </div>
);

NotifyContentLabel.propTypes = {
  contentValidity: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  // clubParams: paramSelectors.getCurrentParams(state),

  contentValidity: state.massAction.contentValidity,
});

export default connect(mapStateToProps, {})(NotifyContentLabel);
