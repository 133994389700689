import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IconButton, Tooltip } from '@material-ui/core';

import DownloadIcon from '@material-ui/icons/VerticalAlignBottom';

import utils from 'src/utils/utils';
import { massActionCsvExport } from 'src/reducers/massAction';
import appStyle from 'src/consts/appStyle';

const CsvExportButton = ({ doExport }) => (
  <Tooltip title={utils.getLang('smartmessaging.massAction.navButton.csvExport')}>
    <IconButton
      aria-label="clear"
      onClick={e => {
        e.preventDefault();
        doExport();
      }}
      style={{ color: appStyle.txtColor2 }}
    >
      <DownloadIcon />
    </IconButton>
  </Tooltip>
);

CsvExportButton.propTypes = {
  doExport: PropTypes.func.isRequired,
};

const actionCreators = { doExport: massActionCsvExport };

export default connect(() => ({}), actionCreators)(CsvExportButton);

// <MenuItem
// // style={{ margin: '2px', color: appStyle.txtColor4 }}
// className={classes.menuItem}
// onClick={e => {
//   e.preventDefault();
//   closeMenu();
//   doExport();
// }}
// >
// <DownloadIcon style={{ marginRight: '4px' }} />
// {utils.getLang('smartmessaging.massAction.navButton.csvExport')}
// </MenuItem>
