import uuid from 'uuid';
import utils from 'src/utils/utils';
import smsUtils from 'src/components/common/contentEditor/utils/smsUtils';
import ParameterType from 'src/enums/parameterType';
import OperatorType from 'src/enums/operatorType';
import ActionType from 'src/enums/actionType';
import contentEditorUtils from 'src/components/common/contentEditor/utils/contentEditorUtils';

const mapCriteriasByKey = criteriaList =>
  criteriaList.reduce(
    (currentResult, criteria) => ({ ...currentResult, [criteria.key]: criteria }),
    {}
  );

const mapAvailableCriterias = criteriaList =>
  criteriaList.reduce((lastResult, criteria) => {
    const newResult = { ...lastResult };

    switch (criteria.type) {
      case 'GROUP':
        if (!newResult[criteria.groupType]) {
          newResult[criteria.groupType] = [];
        }
        criteria.descriptors.forEach(groupedCriteria =>
          newResult[criteria.groupType].push({
            ...groupedCriteria,
            parentDescriptor: { ...criteria },
            groupMandatories: criteria.descriptors.reduce(
              (acc, current) =>
                current.mandatory
                  ? [
                      ...acc,
                      {
                        ...current,
                        parentDescriptor: { ...criteria },
                      },
                    ]
                  : acc,
              []
            ),
          })
        );

        break;
      default:
        if (!newResult[criteria.groupType]) {
          newResult[criteria.groupType] = [];
        }
        newResult[criteria.groupType].push(criteria);
    }

    return newResult;
  }, {});

const mapMandatoryCriterias = criteriaList =>
  criteriaList.filter(criteria => criteria.mandatory).map(filteredCriteria => filteredCriteria.key);

/**  */
const getSelectedCriteriasFromValue = (setupValue, criteriaByKey) =>
  setupValue.reduce((lastResult, singleValue) => {
    const newResult = { ...lastResult };
    const model = criteriaByKey[singleValue.key];
    if (!newResult[model.groupType]) newResult[model.groupType] = {};
    switch (model.type) {
      case 'GROUP':
        singleValue.values.forEach(groupedSingleValue => {
          if (!newResult[model.groupType][groupedSingleValue.key]) {
            newResult[model.groupType][groupedSingleValue.key] = {
              model: {
                ...model.descriptors.filter(d => d.key === groupedSingleValue.key)[0],
                parentDescriptor: model,
              },
              criteriaValues: {},
            };
          }
          newResult[model.groupType][groupedSingleValue.key].criteriaValues[
            uuid()
          ] = groupedSingleValue;
        });
        break;
      default:
        if (!newResult[model.groupType][singleValue.key]) {
          newResult[model.groupType][singleValue.key] = { model, criteriaValues: {} };
        }
        newResult[model.groupType][singleValue.key].criteriaValues[uuid()] = singleValue;
    }

    return newResult;
  }, {});

const getSelectedColumnsFromList = (fieldList, groupedAvailableFields) => {
  const groupsByFields = Object.entries(groupedAvailableFields).reduce(
    (acc, [group, availaibleFields]) => {
      const currentGroupByFields = availaibleFields.reduce(
        (subAcc, field) => ({ ...subAcc, [field.value]: { group, type: field.type } }),
        {}
      );

      return { ...acc, ...currentGroupByFields };
    },
    {}
  );

  const fieldSelection = fieldList.reduce((acc, current) => {
    const reduced = { ...acc };
    if (!reduced[groupsByFields[current].group]) {
      reduced[groupsByFields[current].group] = [];
    }
    reduced[groupsByFields[current].group].push({
      label: utils.getLang(`smartmessaging.resultfield.${current}`),
      value: current,
      type: groupsByFields[current].type,
    });
    return reduced;
  }, {});

  return fieldSelection;
};

const addSingleSelectedCriteria = (value, state) => {
  const operatorId =
    (value.allowedOperators.length === 1 && OperatorType[value.allowedOperators[0]]) || null;
  const uniqueId = uuid();
  const rez = {
    selectedCriterias: state.selectedCriterias[value.groupType] // Si le groupe est déjà présent dans les criteres sélectionnés
      ? {
          ...state.selectedCriterias,
          [value.groupType]: {
            ...state.selectedCriterias[value.groupType], // state.selectedCriterias[value.groupType],
            [value.key]: state.selectedCriterias[value.groupType][value.key] // Si le critere a déjà été sélectionné et existe déjà dans ce groupe de critères sélectionnés
              ? {
                  // on rajoute seulement une valeur vide aux valeurs du critere
                  // ...massActionSelectors.getSelectedCriteriaGroup(state, value.groupType)[
                  // value.key
                  // ],
                  ...state.selectedCriterias[value.groupType][value.key],
                  criteriaValues: {
                    ...state.selectedCriterias[value.groupType][value.key].criteriaValues,
                    [uniqueId]: { operatorId, values: [] },
                  },
                }
              : // sinon on ajoute une entrée pour ce critère dans le groupe sélectionné, avec une valeur vide dans les valeurs pour ce critère
                { model: value, criteriaValues: { [uniqueId]: { operatorId, values: [] } } },
          },
        }
      : {
          // Si le groupe n'est pas présent dans les criteres sélectionnés, on l'ajoute et lui ajoute une entrée pour ce critere avec son modele et une valeur vide
          ...state.selectedCriterias,
          [value.groupType]: {
            [value.key]: {
              model: value,
              criteriaValues: { [uniqueId]: { operatorId, values: [] } },
            },
          },
        },
  };
  return rez.selectedCriterias;
};

const addGroupedCriteria = (value, state) => {
  const rez = { selectedCriterias: { ...state.selectedCriterias } };
  const selectedFilterGroupForValue = state.selectedCriterias[value.groupType];
  const addMandatories = !selectedFilterGroupForValue;

  if (addMandatories) {
    value.groupMandatories.forEach(m => {
      rez.selectedCriterias = {
        ...addSingleSelectedCriteria(m, {
          ...state,
          selectedCriterias: { ...state.selectedCriterias, ...rez.selectedCriterias },
        }),
      };
    });
  }
  if (!value.mandatory || !addMandatories) {
    rez.selectedCriterias = addSingleSelectedCriteria(value, {
      ...state,
      selectedCriterias: { ...state.selectedCriterias, ...rez.selectedCriterias },
    });
  }

  return rez.selectedCriterias;
};

const addSelectedCriteria = (value, state) => {
  if (value.parentDescriptor) {
    return addGroupedCriteria(value, state);
  }
  return addSingleSelectedCriteria(value, state);
};

const addAllMandatories = state => {
  const { mandatoryCriterias, criteriaByKey } = state;
  const list = mandatoryCriterias.map(mc => criteriaByKey[mc]);
  let selectedList = {};
  list.forEach(criteria => {
    selectedList = {
      ...selectedList,
      ...addSelectedCriteria(criteria, { selectedCriterias: selectedList }),
    };
  });
  return selectedList;
};

const changeDateValue = ({ newValue, uniqueId, model, groupName }, selectedCriterias) => ({
  ...selectedCriterias,
  [groupName]: {
    ...selectedCriterias[groupName],
    [model.key]: {
      ...selectedCriterias[groupName][model.key],
      criteriaValues: {
        ...selectedCriterias[groupName][model.key].criteriaValues,
        [uniqueId]: {
          ...selectedCriterias[groupName][model.key].criteriaValues[uniqueId],
          values: [].concat(newValue),
        },
      },
    },
  },
});

const changeStringValue = ({ newValue, uniqueId, model, groupName }, selectedCriterias) => ({
  ...selectedCriterias,
  [groupName]: {
    ...selectedCriterias[groupName],
    [model.key]: {
      ...selectedCriterias[groupName][model.key],
      criteriaValues: {
        ...selectedCriterias[groupName][model.key].criteriaValues,
        [uniqueId]: {
          ...selectedCriterias[groupName][model.key].criteriaValues[uniqueId],
          values: [].concat(newValue),
        },
      },
    },
  },
});

const changeClubsValue = ({ newValue, uniqueId, model, groupName }, selectedCriterias) => ({
  ...selectedCriterias,
  [groupName]: {
    ...selectedCriterias[groupName],
    [model.key]: {
      ...selectedCriterias[groupName][model.key],
      criteriaValues: {
        ...selectedCriterias[groupName][model.key].criteriaValues,
        [uniqueId]: {
          ...selectedCriterias[groupName][model.key].criteriaValues[uniqueId],
          values: (() => {
            let result = [
              ...selectedCriterias[groupName][model.key].criteriaValues[uniqueId].values,
            ];
            const valsByKey = newValue.reduce(
              (m, val) => ({
                ...m,
                [val.key]: m[val.key]
                  ? [...m[val.key], JSON.parse(val.value).id]
                  : [JSON.parse(val.value).id],
              }),
              {}
            );
            const newValueFieldKeys = Object.keys(valsByKey);

            const fieldKeys = model.descriptors.map(d => d.key);
            const clearedKeys = fieldKeys.filter(fk => newValueFieldKeys.indexOf(fk) === -1);

            clearedKeys.forEach(fKey => {
              result = result.filter(fkVal => fkVal.key !== fKey);
            });
            newValueFieldKeys.forEach(k => {
              const isNewKey = !result.find(v => v.key === k);
              if (isNewKey) {
                const descriptor = model.descriptors.find(d => d.key === k);
                result.push({
                  key: descriptor.key,
                  values: valsByKey[k],
                  java: descriptor.javaParameter,
                  typeId: ParameterType[descriptor.type],
                  operatorId:
                    selectedCriterias[groupName][model.key].criteriaValues[uniqueId].operatorId,
                });
              } else {
                result = result.map(fkVal =>
                  fkVal.key === k
                    ? {
                        ...fkVal,
                        values: valsByKey[k],
                      }
                    : fkVal
                );
              }
            });
            return result;
          })(),
        },
      },
    },
  },
});

const changeIntegerValue = ({ newValue, uniqueId, model, groupName }, selectedCriterias) =>
  changeStringValue({ newValue, uniqueId, model, groupName }, selectedCriterias);

const changeEnumValue = ({ newValue, uniqueId, model, groupName }, selectedCriterias) => ({
  ...selectedCriterias,
  [groupName]: {
    ...selectedCriterias[groupName],
    [model.key]: {
      ...selectedCriterias[groupName][model.key],
      criteriaValues: {
        ...selectedCriterias[groupName][model.key].criteriaValues,
        [uniqueId]: {
          ...selectedCriterias[groupName][model.key].criteriaValues[uniqueId],
          values: newValue,
        },
      },
    },
  },
});

const changeCriteriaValue = ({ newValue, uniqueId, model, groupName }, selectedCriterias) => {
  switch (model.type) {
    case 'INTEGER':
      return changeIntegerValue({ newValue, uniqueId, model, groupName }, selectedCriterias);
    case 'ENUM':
    case 'FOREIGN_KEY':
      return changeEnumValue({ newValue, uniqueId, model, groupName }, selectedCriterias);
    case 'DATE':
      return changeDateValue({ newValue, uniqueId, model, groupName }, selectedCriterias);
    case 'CLUBS':
      return changeClubsValue({ newValue, uniqueId, model, groupName }, selectedCriterias);
    default:
      return changeIntegerValue({ newValue, uniqueId, model, groupName }, selectedCriterias);
  }
};

const changeOperatorValue = ({ operatorValue, uniqueId, model, groupName }, selectedCriterias) => ({
  ...selectedCriterias,
  [groupName]: {
    ...selectedCriterias[groupName],
    [model.key]: {
      ...selectedCriterias[groupName][model.key],
      criteriaValues: {
        ...selectedCriterias[groupName][model.key].criteriaValues,
        [uniqueId]:
          [OperatorType.NOT_NULL, OperatorType.NULL, OperatorType.FALSE, OperatorType.TRUE].indexOf(
            operatorValue
          ) !== -1
            ? {
                ...selectedCriterias[groupName][model.key].criteriaValues[uniqueId],
                operatorId: operatorValue,
                values:
                  [OperatorType.NOT_NULL, OperatorType.NULL].indexOf(operatorValue) !== -1
                    ? []
                    : (operatorValue === OperatorType.TRUE && ['true']) || ['false'],
              }
            : {
                ...selectedCriterias[groupName][model.key].criteriaValues[uniqueId],
                operatorId: operatorValue,
                values:
                  model.type === 'CLUBS'
                    ? selectedCriterias[groupName][model.key].criteriaValues[uniqueId].values.map(
                        v => ({
                          ...v,
                          operatorId: operatorValue,
                        })
                      )
                    : selectedCriterias[groupName][model.key].criteriaValues[uniqueId].values,
              },
      },
    },
  },
});

const removeGroupedMandatoryCriteria = (
  { model, groupName },
  selectedCriterias,
  removeCriteria
) => {
  let currentResult = { ...selectedCriterias };
  const selectedGroup = selectedCriterias[groupName];
  const parentCriteriaKey = model.parentDescriptor.key;
  const criteriasToRemove = Object.values(selectedGroup).reduce((acc, criteriaEntry) => {
    if (
      criteriaEntry.model.parentDescriptor &&
      criteriaEntry.model.parentDescriptor.key === parentCriteriaKey
    ) {
      return [
        ...acc,
        ...Object.keys(criteriaEntry.criteriaValues).reduce(
          (acc2, uId) => [...acc2, { uniqueId: uId, model: criteriaEntry.model, groupName }],
          []
        ),
      ];
    }
    return acc;
  }, []);

  criteriasToRemove.forEach(ctr => {
    currentResult = removeCriteria(
      { uniqueId: ctr.uniqueId, model: ctr.model, groupName: ctr.groupName },
      currentResult,
      true
    );
  });

  return currentResult;
};

const removeCriteria = (
  { uniqueId, model, groupName },
  selectedCriterias,
  ignoreGroupTypeConfig
) => {
  if (model.mandatory && !ignoreGroupTypeConfig && model.parentDescriptor) {
    const isUnique =
      Object.keys(selectedCriterias[groupName][model.key].criteriaValues).length <= 1;

    if (isUnique)
      return removeGroupedMandatoryCriteria(
        { model, groupName },
        selectedCriterias,
        removeCriteria
      );
  }

  const criteriaName = model.key;
  let removeCriteriaEntryInGroupTest = false;
  let removeGroupTest = false;
  if (Object.keys(selectedCriterias[groupName][criteriaName].criteriaValues).length === 1)
    removeCriteriaEntryInGroupTest = true;

  if (removeCriteriaEntryInGroupTest && Object.keys(selectedCriterias[groupName]).length === 1) {
    removeGroupTest = true;
  }

  // //////////////
  const removeCriteriaGroup =
    removeGroupTest &&
    (() =>
      Object.entries(selectedCriterias).reduce((acc, [currentGroupName, value]) => {
        if (currentGroupName !== groupName) return { ...acc, [currentGroupName]: value };
        return acc;
      }, {}));
  if (removeGroupTest) return removeCriteriaGroup();
  // /////////////

  // /////////////
  const removeCriteriaEntryInGroup =
    removeCriteriaEntryInGroupTest &&
    (() =>
      Object.entries(selectedCriterias[groupName]).reduce((acc, [currentCriteriaName, value]) => {
        if (currentCriteriaName === criteriaName) return acc;
        return { ...acc, [currentCriteriaName]: value };
      }, {}));

  if (removeCriteriaEntryInGroupTest)
    return {
      ...selectedCriterias,
      [groupName]: removeCriteriaEntryInGroup(),
    };
  // ////////////

  // ///////////
  const removeCriteriaSingleValue = () =>
    Object.entries(selectedCriterias[groupName][criteriaName].criteriaValues).reduce(
      (acc, [id, value]) => {
        if (id === uniqueId) return acc;
        return { ...acc, [id]: value };
      },
      {}
    );

  return {
    ...selectedCriterias,
    [groupName]: {
      ...selectedCriterias[groupName],
      [criteriaName]: {
        ...selectedCriterias[groupName][criteriaName],
        criteriaValues: removeCriteriaSingleValue(),
      },
    },
  };
};

const mapAvailableResultfields = rfList =>
  Object.entries(rfList).reduce(
    (acc, [groupKey, group]) => ({
      ...acc,
      [groupKey]: Object.entries(group).map(([fieldKey, type]) => ({
        label: utils.getLang(`smartmessaging.resultfield.${fieldKey}`),
        value: fieldKey,
        type,
      })),
    }),
    {}
  );

const getSetup = massActionState => {
  const { selectedCriterias, selectedResultfields, currentSetupId } = massActionState;

  function proceedSingleField(model, field) {
    return {
      key: model.key,
      values: field.values.map(v =>
        model.type === 'DATE'
          ? new Date(v)
              .toISOString()
              .substring(0, 10)
              .replace(/-/g, '/')
          : v
      ),
      java: !!model.java,
      typeId: ParameterType[model.type],
      operatorId: field.operatorId,
    };
  }

  function proceedGroupedCriterias() {
    const groupedCriterias = Object.values(selectedCriterias).reduce(
      (all, current) =>
        [...all].concat(Object.values(current).filter(c => !!c.model.parentDescriptor)),
      []
    );

    const result = groupedCriterias.reduce((acc, gc) => {
      const copy = { ...acc };
      if (!copy[gc.model.parentDescriptor.key]) {
        copy[gc.model.parentDescriptor.key] = {
          key: gc.model.parentDescriptor.key,
          values: [],
          javaParameter: !!gc.model.parentDescriptor.javaParameter,
          typeId: ParameterType[gc.model.parentDescriptor.type],
          operatorId: null,
        };
      }

      const toPush = Object.values(gc.criteriaValues).map(cv => ({
        ...cv,
        ...proceedSingleField(gc.model, cv),
      }));

      copy[gc.model.parentDescriptor.key].values = copy[
        gc.model.parentDescriptor.key
      ].values.concat(toPush);

      return copy;
    }, {});

    return Object.values(result);
  }

  function proceedSelectedCriterias() {
    const groupValues = Object.values(selectedCriterias);

    return groupValues.reduce(
      (acc, groupValue) =>
        [...acc].concat(
          Object.values(groupValue).reduce(
            (acc2, criteriaValue) =>
              (!criteriaValue.model.parentDescriptor &&
                [...acc2].concat(
                  Object.values(criteriaValue.criteriaValues).reduce(
                    (acc3, singleValue) =>
                      [...acc3].concat(proceedSingleField(criteriaValue.model, singleValue)),
                    []
                  )
                )) ||
              acc2,
            []
          )
        ),
      []
    );
    // should reorder according to the same order it is in the state bacause now all the grouped filters are place at the end of the setup
    // OR ignore the order in the setup and manage the display order -->NOT GOOD
    // manage the display order when add a filter AND reorder fetching state order before saving AND display as the order is in setup when editing
  }

  function proceedSelectedResultfields() {
    const selectedResultfieldsGroups = Object.values(selectedResultfields);
    return Object.values(selectedResultfieldsGroups).reduce(
      (acc, resultFieldList) =>
        [...acc].concat(resultFieldList.map(resultfield => resultfield.value)),
      []
    );
  }

  return {
    id: currentSetupId,
    value: JSON.stringify(proceedSelectedCriterias().concat(proceedGroupedCriterias())),
    columnList: JSON.stringify(proceedSelectedResultfields()),
  };
};

const getRecipe = massActionState => {
  const { currentSetupId, currentRecipeId, currentName, requestModel } = massActionState;
  return {
    id: currentRecipeId,
    name: currentName,
    setupId: currentSetupId,
    requestModelTypeId: requestModel.requestModelTypeId,
  };
};

const getMassActionCampaign = massActionState => {
  const {
    currentMassActionId,
    currentRecipeId,
    currentName,
    currentCreationDate,
    currentByPass,
    currentReplay,
  } = massActionState;
  return {
    id: currentMassActionId,
    recipeId: currentRecipeId,
    name: currentName,
    creationDate: currentCreationDate || new Date(),
    enabled: true,
    replay: currentReplay,
    byPass: currentByPass,
  };
};

// const contentIsValid = ({ object, content, actionType }) => {
//   function checkMailValidity() {
//     return !!object && !!content;
//   }
//   function checkSmsValidity() {
//     return !!content;
//   }
//   switch (actionType) {
//     case ActionType.EMAIL.id:
//       return checkMailValidity();
//     case ActionType.SMS.id:
//       return checkSmsValidity();
//     default:
//       return false;
//   }
// };

const getContentValidity = ({ object, content, actionType, min }) => {
  switch (actionType) {
    case ActionType.EMAIL.id:
    case ActionType.EMAIL_COACH.id:
    case ActionType.MEMBER_NOTIFICATION.id:
    case ActionType.EMAIL_SPONSORSHIP.id:
    case ActionType.EMAIL_INVITATION.id: {
      return contentEditorUtils.checkMailContentValidity({ object, content });
    }
    case ActionType.SMS.id:
      return smsUtils.checkSMSContentValidity(content, min);
    default:
      return { isValid: true, invalidities: [] };
  }
};

const ungroupSelectedCriterias = groupedSelection =>
  Object.values(groupedSelection).reduce(
    (acc, criteriaGroup) =>
      [...acc].concat(
        Object.values(criteriaGroup).reduce(
          (acc2, singleCriteriaObject) =>
            [...acc2].concat(
              Object.entries(singleCriteriaObject.criteriaValues).map(
                ([uniqueId, criteriaValue]) => ({
                  ...criteriaValue,
                  model: singleCriteriaObject.model,
                  uniqueId,
                })
              )
            ),
          []
        )
      ),
    []
  );

const criteriaValidity = (criteria, selection) => {
  const validity = { isValid: true, messages: [] };
  const twinCriterias = selection.filter(pCrit => pCrit.model.key === criteria.model.key);

  if (criteria.model.mandatory && twinCriterias.length > 1)
    validity.messages.push(utils.getLang('smartmessaging.massAction.tooltip.mandatoryFilter'));
  const usedOperators = twinCriterias
    .map(rCrit => rCrit.operatorId)
    .filter(opId => opId === 0 || !!opId);
  const opCount = usedOperators.reduce(
    (count, op) => (op !== criteria.operatorId ? count : count + 1),
    0
  );
  if (opCount > 1) {
    validity.isValid = false;
    validity.messages.push(utils.getLang('smartmessaging.massAction.tooltip.duplicateOperator'));
  }

  switch (criteria.operatorId) {
    case OperatorType.NULL:
    case OperatorType.NOT_NULL:
      validity.isValid = validity.isValid && true;
      break;
    case OperatorType.BETWEEN:
    case OperatorType.NOT_BETWEEN:
      if (criteria.values.length < 2 || !criteria.values[0] || !criteria.values[1]) {
        validity.isValid = false;
        validity.messages.push(utils.getLang('smartmessaging.massAction.tooltip.noEmptyFilter'));
      }
      break;
    default:
      if (criteria.operatorId === undefined || criteria.operatorId === null) {
        validity.isValid = false;
        validity.messages.push(utils.getLang('smartmessaging.massAction.tooltip.requiredOperator'));
      }
      if (!criteria.values[0]) {
        validity.isValid = false;
        validity.messages.push(utils.getLang('smartmessaging.massAction.tooltip.noEmptyFilter'));
      }
      break;
  }
  return validity;
};

const selectionIsValid = selection => {
  const validity = { isValid: true, validities: {} };
  if (!selection) return false;
  const ungroupedCriterias = ungroupSelectedCriterias(selection);
  ungroupedCriterias.forEach(crit => {
    // eslint-disable-next-line prefer-destructuring
    const critValidity = criteriaValidity(crit, ungroupedCriterias);
    if (!critValidity.isValid) {
      validity.isValid = critValidity.isValid;
      validity.validities[crit.uniqueId] = critValidity;
    }
  });
  return validity;
};

export default {
  addSelectedCriteria,
  addAllMandatories,
  changeCriteriaValue,
  changeOperatorValue,
  getContentValidity,
  mapAvailableCriterias,
  mapAvailableResultfields,
  mapCriteriasByKey,
  mapMandatoryCriterias,
  removeCriteria,
  getSetup,
  getRecipe,
  getMassActionCampaign,
  getSelectedCriteriasFromValue,
  getSelectedColumnsFromList,
  selectionIsValid,
};
