import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import { Typography, Card, CardHeader, CardContent, IconButton, Tooltip } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/VerticalAlignBottom';
import { withStyles } from '@material-ui/styles';
import HistoryAppBar from './HistoryAppBar';
import DefaultUsedFilter from './UsedFilter';
import DetailsHistoryItem from './DetailsHistoryItem';
import hUtils from './utils';
import appStyle from 'src/consts/appStyle';
import utils from 'src/utils/utils';
import ClickedLinkHistory from './ClickedLinkHistory';

const styles = theme => ({
  cRoot: { display: 'inline-block' },
  hRoot: { paddingBottom: 0, paddingLeft: 0, paddingTop: 0 },
  title: { fontSize: '1rem', color: appStyle.txtColor3 },
  cardHeader: { borderBottom: appStyle.border1 },
  cardContent: { padding: '0', display: 'flex', flexDirection: 'column', flex: 1 },
  action: { marginTop: 0 },
  root: {
    width: '100%',
    overflow: 'visible',
  },
  table: {
    overflow: 'auto',
  },
  tableWrapper: {
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
  campaignContainer: {
    position: 'relative',
    overflow: 'auto',
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
    flex: 1,
  },
  position: 'relative',
  overflow: 'auto',
  margin: '8px',
  marginTop: '24px',
  flex: 1,
  menuItem: {
    color: appStyle.txtColor3,
    display: 'flex',
    fontSize: '0.98rem',
    outline: 'none',
    cursor: 'pointer',
    padding: '4px',
    minHeight: 'unset',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '240px',
    '&:hover': {
      background: appStyle.bgColor1,
      opacity: 0.9,
      color: appStyle.txtColor2,
    },
  },
});

const DetailsColumnsValue = ({ columns }) => (
  <Typography
    style={{
      marginTop: '12px',
      color: appStyle.txtColor3,
      fontSize: '0.815rem',
    }}
  >
    {utils.getLang('smartmessaging.historyDetail.usedColumns')}
    {': '}
    {columns
      .map(column => utils.getLang(`smartmessaging.massAction.columnLabel.${column}`))
      .join(', ')}
  </Typography>
);

DetailsColumnsValue.propTypes = { columns: PropTypes.array.isRequired };

const DetailsRender = ({
  classes,
  filters,
  historyItem,
  filtersByKey,
  // exportExecutionResult,
  UsedFilter,
}) => (
  <Card elevation={0} classes={{ root: classes.cRoot }}>
    <CardHeader
      className={classes.cardHeader}
      classes={{
        title: classes.title,
        action: classes.action,
        root: classes.hRoot,
      }}
      title={utils.getLang(`smartmessaging.historyDetail.config`)}
    />

    <CardContent style={{ overflow: 'auto', padding: '8px' }}>
      <div style={{ textAlign: 'left' }}>
        {Object.values(filters).map(list =>
          list.map(filter => (
            <UsedFilter key={uuid()} filter={filter} filtersByKey={filtersByKey} />
          ))
        )}

        {!!(historyItem[0].setupColumns && historyItem[0].setupColumns.length) && (
          <DetailsColumnsValue columns={historyItem[0].setupColumns} />
        )}
      </div>
    </CardContent>
  </Card>
);

DetailsRender.propTypes = {
  filters: PropTypes.object.isRequired,
  filtersByKey: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  historyItem: PropTypes.array.isRequired,
  UsedFilter: PropTypes.any,
};

DetailsRender.defaultProps = {
  UsedFilter: null,
};

const Details = ({
  classes,
  campaign,
  filters,
  historyItem,
  goToHistoryStep,
  filtersByKey,
  exportExecutionResult,
  summaryExcludedProperties,
  excludedProperties,
  rowSpanCols,
  clickedLinkList,
  UsedFilter,
}) => (
  <div
    style={{
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      overflow: 'hidden',
      textAlign: 'left',
      height: '100%',
    }}
  >
    <HistoryAppBar
      title={utils.getLang('smartmessaging.historyDetail.title')}
      campaignName={campaign.name}
      onBack={() => goToHistoryStep(0, { campaign })}
    />
    <div className={classes.campaignContainer}>
      <DetailsHistoryItem
        specificRender={{
          sentMessageNumber: (column, item) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {item.sentMessageNumber}
              {!!item.sentMessageNumber && (
                <Tooltip title={utils.getLang('smartmessaging.history.executionExport')}>
                  <IconButton
                    style={{ padding: '4px', opacity: 0.4 }}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      exportExecutionResult(item.executionLogByClubId, item.actionType);
                    }}
                  >
                    <DownloadIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          ),
        }}
        items={historyItem}
        columns={hUtils.getColumns(historyItem[0], [
          ...excludedProperties,
          ...summaryExcludedProperties,
        ])}
        rowSpanCols={rowSpanCols}
        rowSpan={historyItem.length || 1}
      />
      <DetailsRender
        filters={filters}
        filtersByKey={filtersByKey}
        classes={classes}
        historyItem={historyItem}
        exportExecutionResult={exportExecutionResult}
        UsedFilter={UsedFilter || DefaultUsedFilter}
      />
      <ClickedLinkHistory clickedLinks={clickedLinkList} />
    </div>
  </div>
);

Details.propTypes = {
  classes: PropTypes.object.isRequired,
  campaign: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  goToHistoryStep: PropTypes.func.isRequired,
  exportExecutionResult: PropTypes.func.isRequired,
  historyItem: PropTypes.array.isRequired,
  filtersByKey: PropTypes.object.isRequired,
  excludedProperties: PropTypes.array.isRequired,
  summaryExcludedProperties: PropTypes.array.isRequired,
  UsedFilter: PropTypes.any,
  rowSpanCols: PropTypes.array.isRequired,
  clickedLinkList: PropTypes.array.isRequired,
};

Details.defaultProps = { UsedFilter: null };

export default withStyles(styles)(Details);
