import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Paper, Fade, FormLabel, TextField, Typography, FormControlLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import WarningIcon from '@material-ui/icons/Warning';
import Checkbox from 'src/components/common/Checkbox';

import IntegerField from 'src/components/common/IntegerField';
import ActionBtn from 'src/components/common/ActionBtn';
import CampaignType from '../CampaignType';
import utils from 'src/utils/utils';
import appStyle from 'src/consts/appStyle';

const styles = theme => ({
  popover: { padding: 0, margin: 0 },
  menuItem: {
    color: appStyle.txtColor3,
    outline: 'none',
    cursor: 'pointer',
    padding: '12px 8px',
    // textAlign: 'center',
    '&:hover': {
      background: appStyle.bgColor1,
      color: appStyle.txtColor2,
      opacity: 0.9,
    },
  },
  textField: {
    // margin: theme.spacing(3),
    display: 'flex',
    margin: '8px',
  },
  textFieldInput: {
    color: appStyle.txtColor3,
    fontSize: '0.9rem',
  },
  textFieldInputUnderline: {
    '&:after': {
      backgroundColor: appStyle.bgColor3,
      borderBottom: 'none',
    },
    '&:before': {
      backgroundColor: appStyle.bgColor3,
      borderBottom: 'none',
    },
  },
  button: {
    backgroundColor: appStyle.bgColor1,
    margin: `${theme.spacing(2)}px`,
    color: appStyle.txtColor2,
    '&:hover': {
      background: appStyle.bgColor1,
      opacity: 0.9,
    },
  },
});

const CampaignInfosRender = ({
  editedCampaign,
  selectedType,
  requestModelsByRequestModelTypeId,
  recipesById,
  updateEditedCampaign,
  wizVilleEntryActivated,
  wizVilTypes,
  doSaveCampaign,
  goToEditorStep,
  infosValidity,
  requestModelsByCategoryId,
  sortedCategoryList,
  selectCampaignType,
  selectOptinType,
  getDefaultOptin,
  //  recipeCfgLoaded,
  classes,
}) => (
  <Fade in timeout={500}>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
        // width: '70%',
        // alignItems: 'stretch',
      }}
    >
      <div
        style={{
          flex: 1,
          // border: '1px solid red',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          margin: '24px 15% 8px',
        }}
      >
        <Paper
          elevation={0}
          style={{
            // margin: '20px 8px 4px',
            overflow: 'hidden',
            display: 'flex',
            // flexDirection: 'column',
          }}
        >
          <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <FormLabel
              required
              style={{ fontSize: '0.975rem', fontWeight: 400 }}
              htmlFor="cmp-name-ipt"
            >
              {utils.getLang('smartmessaging.campaignInfos.label.name')}
            </FormLabel>
            <TextField
              disabled={!editedCampaign.isWritable}
              InputProps={{
                className: classes.textFieldInput,
                id: 'cmp-name-ipt',
              }}
              className={classes.textField}
              value={editedCampaign.name}
              onChange={event => {
                updateEditedCampaign({ name: event.target.value });
              }}
            />

            {wizVilleEntryActivated &&
              ((selectedType && wizVilTypes.indexOf(selectedType.requestModelTypeId) !== -1) ||
                (editedCampaign &&
                  editedCampaign.recipeId &&
                  wizVilTypes.indexOf(recipesById[editedCampaign.recipeId].requestModelTypeId) !==
                    -1)) && (
                <Fragment>
                  <hr style={{ marginBottom: '8px', border: 'none' }} />
                  <FormLabel required htmlFor="wizId" style={{ fontWeight: 400 }}>
                    {utils.getLang('smartmessaging.wizVille.idLabel')}
                  </FormLabel>
                  <IntegerField
                    disabled={!editedCampaign.isWritable}
                    InputProps={{
                      className: classes.textFieldInput,
                      id: 'wizId',
                    }}
                    className={classes.textField}
                    value={editedCampaign.wizVilleId || ''}
                    onChange={value => {
                      updateEditedCampaign({ wizVilleId: value });
                    }}
                  />
                  <hr style={{ marginBottom: '8px', border: 'none' }} />
                  <FormLabel required htmlFor="wizBrand" style={{ fontWeight: 400 }}>
                    {utils.getLang('smartmessaging.wizVille.brandLabel')}
                  </FormLabel>
                  <TextField
                    disabled={!editedCampaign.isWritable}
                    style={{ marginBottom: '8px' }}
                    InputProps={{
                      className: classes.textFieldInput,
                      id: 'wizBrand',
                    }}
                    className={classes.textField}
                    value={editedCampaign.wizVilleBrand}
                    onChange={e => {
                      updateEditedCampaign({ wizVilleBrand: e.target.value });
                    }}
                  />
                </Fragment>
              )}

            <hr style={{ marginBottom: '8px', border: 'none' }} />
            <CampaignType
              {...{
                editedCampaign,
                selectedType,
                requestModelsByRequestModelTypeId,
                recipesById,
                requestModelsByCategoryId,
                sortedCategoryList,
                selectCampaignType,
              }}
            />
            <hr style={{ marginBottom: '8px', border: 'none' }} />

            {(editedCampaign.recipeId || selectedType) && (
              <Fragment>
                <FormLabel
                  required
                  style={{ fontSize: '0.975rem', fontWeight: 400 }}
                  htmlFor="cmp-optin-ipt"
                >
                  {utils.getLang('smartmessaging.campaignInfos.label.optin')}
                </FormLabel>
                <TextField
                  className={classes.textField}
                  disabled={!editedCampaign.isWritable}
                  onChange={e => {
                    selectOptinType(e.target.value);
                  }}
                  select
                  style={{ flex: 1 }}
                  InputProps={{
                    className: classes.textFieldInput,
                    id: 'cmp-optin-ipt',
                  }}
                  SelectProps={{
                    MenuProps: {
                      MenuListProps: { style: { padding: 0, margin: 0 } },
                      PopoverClasses: {
                        paper: classes.popover,
                      },
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                      marginThreshold: 0,
                    },
                  }}
                  value={editedCampaign.optinType}
                >
                  {['legal', 'advertising', 'news'].map(o => (
                    <div key={o} value={o} className={classes.menuItem}>
                      {utils.getLang(`smartmessaging.diffusionType.${o}`)}
                    </div>
                  ))}
                </TextField>
                {(getDefaultOptin() !== editedCampaign.optinType ||
                  [['legal', 'news']].indexOf(editedCampaign.optinType) !== -1) && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <WarningIcon style={{ color: appStyle.txtColor6, margin: '4px' }} />
                    <ul>
                      {getDefaultOptin() !== editedCampaign.optinType && (
                        <li>
                          <Typography
                            style={{
                              marginTop: '8px',
                              color: appStyle.txtColor3,
                            }}
                            variant="body2"
                          >
                            {`${utils.getLang(
                              'smartmessaging.campaignInfos.defaultOptinWarning'
                            )} : ${utils.getLang(
                              `smartmessaging.diffusionType.${getDefaultOptin()}`
                            )}`}
                          </Typography>
                        </li>
                      )}
                      {['legal', 'news'].indexOf(editedCampaign.optinType) !== -1 && (
                        <li>
                          <Typography
                            style={{
                              marginTop: '8px',
                              color: appStyle.txtColor3,
                            }}
                            variant="body2"
                          >
                            {utils.stringFormat(
                              utils.getLang('smartmessaging.contentEditor.marketingWarningContent'),
                              [
                                utils.getLang(
                                  `smartmessaging.diffusionType.${editedCampaign.optinType ||
                                    getDefaultOptin()}`
                                ),
                              ]
                            )}
                          </Typography>
                        </li>
                      )}
                    </ul>
                  </div>
                )}
                <hr style={{ marginBottom: '8px', border: 'none' }} />
              </Fragment>
            )}
            <FormLabel style={{ fontSize: '0.975rem', fontWeight: 400 }}>
              {utils.getLang('smartmessaging.campaignInfos.label.outOfHoursSms')}
            </FormLabel>
            <FormControlLabel
              control={<Checkbox style={{ pointerEvents: 'auto' }} />}
              style={{ pointerEvents: 'none', margin: '8px 0px 0px 0px' }}
              // disabled={!!editedCampaign.byPass}
              disabled={!editedCampaign.isWritable}
              checked={!!editedCampaign.replay}
              onChange={() => {
                if (!editedCampaign.replay && editedCampaign.byPass) {
                  updateEditedCampaign({ replay: !editedCampaign.replay, byPass: false });
                } else {
                  updateEditedCampaign({ replay: !editedCampaign.replay });
                }
              }}
              label={
                <Typography variant="body2" style={{ color: appStyle.txtColor3 }}>
                  {utils.getLang('smartmessaging.campaignInfos.replaySms')}
                </Typography>
              }
            />
            <FormControlLabel
              control={<Checkbox style={{ pointerEvents: 'auto' }} />}
              disabled={!editedCampaign.isWritable}
              style={{ pointerEvents: 'none', margin: '0' }}
              checked={!!editedCampaign.byPass}
              onChange={() => {
                if (editedCampaign.byPass) updateEditedCampaign({ byPass: !editedCampaign.byPass });
                else {
                  updateEditedCampaign({ byPass: !editedCampaign.byPass, replay: false });
                }
              }}
              label={
                <Typography variant="body2" style={{ color: appStyle.txtColor3 }}>
                  {utils.getLang('smartmessaging.campaignInfos.bypassSmsHours')}
                </Typography>
              }
            />
            <hr style={{ marginBottom: '8px', border: 'none' }} />
          </div>
        </Paper>
        <div style={{ textAlign: 'right' }}>
          <ActionBtn
            // className={classes.button}
            onClick={e => {
              e.preventDefault();
              // if (!recipeCfgLoaded)
              if (editedCampaign.isWritable) doSaveCampaign(true);
              goToEditorStep(1);
            }}
            disabled={
              !infosValidity.isValid || (!editedCampaign.requestModelTypeId && !selectedType)
            }
          >
            {utils.getLang('smartmessaging.buttonLabel.next')}
          </ActionBtn>
        </div>
      </div>
    </div>
  </Fade>
);

CampaignInfosRender.propTypes = {
  editedCampaign: PropTypes.object.isRequired,
  selectedType: PropTypes.object,
  requestModelsByRequestModelTypeId: PropTypes.object.isRequired,
  recipesById: PropTypes.object.isRequired,
  updateEditedCampaign: PropTypes.func.isRequired,
  wizVilleEntryActivated: PropTypes.bool.isRequired,
  wizVilTypes: PropTypes.array.isRequired,
  doSaveCampaign: PropTypes.func.isRequired,
  goToEditorStep: PropTypes.func.isRequired,
  infosValidity: PropTypes.object.isRequired,
  requestModelsByCategoryId: PropTypes.object.isRequired,
  sortedCategoryList: PropTypes.array.isRequired,
  selectCampaignType: PropTypes.func.isRequired,
  selectOptinType: PropTypes.func.isRequired,
  getDefaultOptin: PropTypes.func.isRequired,
  //  recipeCfgLoaded:PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

CampaignInfosRender.defaultProps = {
  selectedType: null,
};

function CampaignInfos(props) {
  function getDefaultOptin() {
    const { editedCampaign, selectedType, requestModelsByRequestModelTypeId, recipesById } = props;
    let selectedRM = null;

    if (editedCampaign.recipeId)
      selectedRM =
        requestModelsByRequestModelTypeId[recipesById[editedCampaign.recipeId].requestModelTypeId];
    else selectedRM = selectedType;
    return selectedRM.optinType;
  }

  function preventReplayChangeEvent() {}

  return <CampaignInfosRender {...props} {...{ getDefaultOptin, preventReplayChangeEvent }} />;
}
CampaignInfos.propTypes = {
  editedCampaign: PropTypes.object.isRequired,
  selectedType: PropTypes.object,
  requestModelsByRequestModelTypeId: PropTypes.object.isRequired,
  recipesById: PropTypes.object.isRequired,
};

CampaignInfos.defaultProps = {
  selectedType: null,
};

export default withStyles(styles)(CampaignInfos);
