import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';

import PreviousIcon from '@material-ui/icons/ChevronLeft';
import appStyle from 'src/consts/appStyle';

const HistoryBackBtn = ({ onClick }) => (
  <IconButton
    style={{ margin: '2px', color: appStyle.txtColor2 }}
    onClick={e => {
      e.preventDefault();
      onClick();
    }}
  >
    <PreviousIcon />
  </IconButton>
);

HistoryBackBtn.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default HistoryBackBtn;
