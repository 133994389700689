import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import ArrowRight from '@material-ui/icons/ArrowRight';
import { TableRow, TableCell, IconButton, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import appStyle from 'src/consts/appStyle';
import hUtils from './utils';
import utils from 'src/utils/utils';

const HistoryItem = ({
  classes,
  goToDetails,
  item,
  columnNames,
  // exportExecutionResult,
  rowSpanCols,
  rowSpan,
  specificRender,
  addGoToDetailColum,
}) => (
  <TableRow
    // hover
    className={classes.row}
    onDoubleClick={e => {
      e.preventDefault();
      goToDetails();
    }}
  >
    {columnNames.map(column => (
      <TableCell
        key={uuid()}
        style={{ padding: '4px', color: appStyle.txtColor3 }}
        rowSpan={rowSpanCols.indexOf(column) === -1 ? 1 : rowSpan}
      >
        {specificRender[column]
          ? specificRender[column](column, item)
          : hUtils.renderCell(column, item)}
      </TableCell>
    ))}
    {addGoToDetailColum && (
      <TableCell className={classes.row} rowSpan={rowSpan}>
        <Tooltip title={utils.getLang('smartmessaging.history.showDetails')}>
          <IconButton
            style={{ padding: '4px' }}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              goToDetails();
            }}
          >
            <ArrowRight />
          </IconButton>
        </Tooltip>
      </TableCell>
    )}
  </TableRow>
);

HistoryItem.propTypes = {
  item: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  goToDetails: PropTypes.func.isRequired,
  columnNames: PropTypes.array.isRequired,
  rowSpanCols: PropTypes.array,
  specificRender: PropTypes.object,
  rowSpan: PropTypes.number,
  addGoToDetailColum: PropTypes.bool,
};

HistoryItem.defaultProps = {
  rowSpanCols: [],
  specificRender: {},
  addGoToDetailColum: false,
  rowSpan: 1,
};

const styles = {
  row: {
    '&:hover': {
      // background: appStyle.bgColor1,
      '& .hovershow': { opacity: 0.8 },
      cursor: 'pointer',
    },
  },
  hovershow: { opacity: 0 },
};

export default withStyles(styles)(HistoryItem);
