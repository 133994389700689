import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, IconButton, InputAdornment } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/ClearOutlined';
import { DatePicker } from '@material-ui/pickers';
import { withStyles } from '@material-ui/styles';
import utils from '../../../utils/utils';

const DateFilterInputs = ({
  classes,
  addStartDate,
  addEndDate,
  selectedFilters,
  removeStartDate,
  removeEndDate,
}) => {
  function getStartDate() {
    const rez =
      selectedFilters && selectedFilters.length
        ? selectedFilters.find(e => e.key === 'startDate') || null
        : null;
    return rez ? rez.value : rez;
  }
  function getEndDate() {
    const rez =
      selectedFilters && selectedFilters.length
        ? selectedFilters.find(e => e.key === 'endDate') || null
        : null;
    return rez ? rez.value : rez;
  }
  return (
    <FormControl className={classes.container}>
      <DatePicker
        variant="inline"
        className={classes.textField}
        autoOk
        format="dd/MM/yyyy"
        label={utils.getLang('du')}
        value={getStartDate()}
        onChange={date => {
          addStartDate(date);
        }}
        // InputLabelProps={{
        //   shrink: true,
        //   required: true,
        // }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <IconButton
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  removeStartDate();
                }}
                edge="end"
              >
                <ClearIcon style={{ fill: '#b6b6b6' }} />
              </IconButton>
            </InputAdornment>
          ),
          // disableUnderline: true,
        }}
      />
      <DatePicker
        variant="inline"
        className={classes.textField}
        autoOk
        format="dd/MM/yyyy"
        label={utils.getLang('au')}
        value={getEndDate()}
        onChange={date => {
          addEndDate(date);
        }}
        // InputLabelProps={{
        //   shrink: true,
        // }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <IconButton
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  removeEndDate();
                }}
                edge="end"
              >
                <ClearIcon style={{ fill: '#b6b6b6' }} />
              </IconButton>
            </InputAdornment>
          ),
          // disableUnderline: true,
        }}
      />
    </FormControl>
  );
};

DateFilterInputs.propTypes = {
  classes: PropTypes.object.isRequired,
  addStartDate: PropTypes.func.isRequired,
  addEndDate: PropTypes.func.isRequired,
  // removeAllDate: PropTypes.func.isRequired,
  removeStartDate: PropTypes.func.isRequired,
  removeEndDate: PropTypes.func.isRequired,
  selectedFilters: PropTypes.array.isRequired,
};

export default withStyles(theme => ({
  container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    width: '50%',
    maxWidth: '800px',
    // marginLeft: '24px',
  },
  textField: {
    flex: 1,
    fontSize: '0.975rem',
    marginLeft: `${theme.spacing(1)}px!important`,
    marginRight: `${theme.spacing(1)}px!important`,
  },
}))(DateFilterInputs);
