import React from 'react';
import smartjourneyApi from 'src/utils/api/smartjourneyApi';
import apiClient from 'src/utils/apiClient';
import Loader from 'src/components/common/Loader';

import withDataResolver from 'src/components/common/withDataResolver';

const resolvers = {
  resolve: props => async callApi => {
    const data = await callApi(smartjourneyApi.loadSMJCampaigns, [props.smartjourney.id]);
    const recipes = await callApi(apiClient.loadRecipes);
    props.receiveRecipes(recipes);
    // les cmp n'ont pas de reqModTypeId
    // => soit on fetch avec les recipes
    // => soit on enhance dans le back

    // const result = data.reduce((m, cmp) => {
    //   const r = recipes.filter(pr => pr.id === cmp.recipeId)[0];
    //   const rmTypeId = r.requestModelTypeId;
    //   if (!m[rmTypeId]) return { ...m, [rmTypeId]: [{ ...cmp, requestModelTypeId: rmTypeId }] };
    //   m[rmTypeId].push(cmp);
    //   return m;
    // }, {});

    const result = data.map(cmp => {
      const r = recipes.filter(pr => pr.id === cmp.recipeId)[0];
      const rmTypeId = r.requestModelTypeId; // peut etre plus necessaire
      return { ...cmp, duplicates: JSON.parse(cmp.duplicates), requestModelTypeId: rmTypeId };
    });
    return result;
  },
  loader: <Loader />,
  onResolved: () => async () => {},
};

const WithData = ({ children, ...rest }) => React.cloneElement(children, { ...rest });

export default withDataResolver(resolvers)(WithData);
