import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Card, CardContent, CardHeader, FormControl, FormLabel } from '@material-ui/core';

import appStyle from 'src/consts/appStyle';
import utils from 'src/utils/utils';
import SelectedCriteriaList from './SelectedCriteriaList';

const styles = () => ({
  title: { fontSize: '1rem', color: appStyle.txtColor3 },
  cardHeader: { backgroundColor: appStyle.bgColor2 },
  cardContent: { padding: '0', display: 'flex', flexDirection: 'column', flex: 1 },
  action: { marginTop: 0 },
  cardHeaderTitleRoot: { padding: '10px 15px' },
});

const SelectedCriteriaGroupContainer = ({ groupName, classes, isWritable }) => (
  <Card style={{ overflow: 'visible' }} elevation={0}>
    <FormControl style={{ display: 'block' }}>
      <CardHeader
        className={classes.cardHeader}
        classes={{
          title: classes.title,
          action: classes.action,
          root: classes.cardHeaderTitleRoot,
        }}
        title={
          <FormLabel style={{ fontSize: '0.875rem' }}>
            {utils.getLang(`smartmessaging.massAction.groupFilterBy.${groupName}`)}
          </FormLabel>
        }
      />
      <CardContent className={classes.cardContent}>
        <SelectedCriteriaList groupName={groupName} isWritable={isWritable} />
      </CardContent>
    </FormControl>
  </Card>
);

SelectedCriteriaGroupContainer.propTypes = {
  groupName: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  isWritable: PropTypes.bool.isRequired,
};

export default withStyles(styles)(SelectedCriteriaGroupContainer);
