import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  CampaignTarget,
  CampaignInfos,
  CampaignMessage,
  EditorAppBar,
  CampaignSchedule,
} from './editorComponents';
import withSteps from 'src/components/common/hocs/withSteps';
import { mapRequestModelByRMTypeId } from 'src/reducers/requestModelList';
import {
  saveCampaign,
  checkCmpInfosValidity,
  checkCampaignValidity,
} from 'src/reducers/campaignEditor';

import { getSortedCategoryList } from 'src/enums/campaignCategory';
import { deleteCampaign } from 'src/reducers/campaignList';
// import appStyle from 'src/consts/appStyle';

const mapStateToProps = state => ({
  editedCampaign: state.campaignEditor.editedCampaign,
  requestModelsByRequestModelTypeId: mapRequestModelByRMTypeId(state),
  recipesById: state.recipeList.recipeListById,
  selectedType: state.campaignEditor.selectedType,
  requestModelsByCategoryId: state.requestModelList.requestModelsByCategoryId,
  sortedCategoryList: getSortedCategoryList(),
  recipeCfgLoaded: state.campaignEditor.recipeFieldsConfig.loaded,
  campaignActions: state.campaignEditor.actions.campaignActions,
  infosValidity: checkCmpInfosValidity(state),
  campaignValidity: checkCampaignValidity(state),
  wizVilTypes: state.app.wizVilleRequestModels,
  wizVilleEntryActivated: state.app.config.wizVilleEntryActivated,
  periods: state.campaignEditor.schedule.editedCmpPeriods,
});

const actionCreators = {
  updateEditedCampaign: changes => ({ type: 'UPDATE_EDITED_CAMPAIGN', value: changes }),
  createCampaign: () => ({ type: 'CREATE_CAMPAIGN' }),
  // clearEditorData: () => ({ type: 'CLEAR_EDITOR_DATA' }),

  doSaveCampaign: saveCampaign,
  doDeleteCampaign: deleteCampaign,
  selectCampaignType: requestModel => ({
    type: 'SELECT_CAMPAIGN_TYPE',
    value: requestModel,
  }),
  selectOptinType: optinType => ({
    type: 'SELECT_OPTIN_TYPE',
    value: optinType,
  }),
};

const StepHandler = ({ currentStep, goToStep, ...otherProps }) => {
  switch (currentStep) {
    case 0:
      otherProps.showBtns(true);
      return <CampaignInfos {...otherProps} goToEditorStep={goToStep} />;

    case 1:
      otherProps.showBtns(true);
      return <CampaignTarget {...otherProps} goToEditorStep={goToStep} />;

    case 2:
      return <CampaignMessage {...otherProps} goToEditorStep={goToStep} />;
    case 3:
      otherProps.showBtns(true);
      return <CampaignSchedule {...otherProps} goToEditorStep={goToStep} />;
    default:
      otherProps.showBtns(true);
      return <CampaignInfos {...otherProps} goToEditorStep={goToStep} />;
  }
};

StepHandler.propTypes = {
  currentStep: PropTypes.number.isRequired,
  goToStep: PropTypes.func.isRequired,
};

function WithAppBar({ goToStep, doDeleteCampaign, doSaveCampaign, backToList, ...props }) {
  const [visibleBtns, showBtns] = useState(true);
  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflow: 'hidden',
        textAlign: 'left',
        height: '100%',
        // alignItems: 'center',
      }}
    >
      <EditorAppBar
        {...props}
        deleteCampaign={doDeleteCampaign}
        saveCampaign={doSaveCampaign}
        goToEditorStep={goToStep}
        visibleBtns={visibleBtns}
        backToList={backToList}
      />
      <StepHandler {...{ ...props, doSaveCampaign, goToStep, showBtns }} />
    </div>
  );
}

WithAppBar.propTypes = {
  goToStep: PropTypes.func.isRequired,
  doDeleteCampaign: PropTypes.func.isRequired,
  doSaveCampaign: PropTypes.func.isRequired,
  backToList: PropTypes.func.isRequired,
};

// const Connected = ;

export default withSteps(connect(mapStateToProps, actionCreators)(WithAppBar));
