/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Loader from './Loader';

import getApiCaller from 'src/utils/apiClientCaller';

class DataResolverCmp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resolved: false,
      data: null,
      // isFetching: false,
      // failure: false,
    };
    this.setResolved = this.setResolved.bind(this);
    this.setData = this.setData.bind(this);
    this.update = this.update.bind(this);
    // this.onResolveFailure = this.onResolveFailure.bind(this);
  }

  componentDidMount() {
    const me = this;
    me._isMounted = true;
    this.update();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // onResolveFailure() {
  //   this.setState({ failure: true });
  // }

  setResolved(resolved) {
    this.setState({ resolved });
  }

  setData(data) {
    this.setState({ data });
  }

  update() {
    const { resolve, onResolved, doGetApiCaller } = this.props;
    this.setResolved(false);
    const apiCall = doGetApiCaller();
    resolve(apiCall).then(
      async data => {
        await onResolved(data).then(() => {
          if (this._isMounted) {
            this.setData(data);
            this.setResolved(true);
          }
        });
      }
      // err => {
      //   console.log('failure', err);
      //   this.onResolveFailure();
      // }
    );
  }

  render() {
    const { resolved /* failure */ } = this.state;
    const {
      loaderWrapperClass,
      loaderWrapperStyle,
      loaderText,
      getLoaderText,
      children,
      loader,
      doGetApiCaller,
    } = this.props;
    return resolved
      ? children({
          ...this.state,
          update: this.update,
          setData: this.setData,
          callApi: doGetApiCaller(),
        })
      : ((loader === true || loader === undefined) && (
          <Loader
            text={loaderText || (getLoaderText && getLoaderText()) || ''}
            wrapperClass={loaderWrapperClass}
            wrapperStyle={loaderWrapperStyle}
          />
        )) ||
          (loader && loader) ||
          null;
  }
}

DataResolverCmp.propTypes = {
  // onResolved: PropTypes.func.isRequired,
  // resolve: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
  // cmpProps: PropTypes.object,
  loaderWrapperClass: PropTypes.string,
  loaderWrapperStyle: PropTypes.object,
  loaderText: PropTypes.string,
  loader: PropTypes.any,
  getLoaderText: PropTypes.func,
  // doGetApiCaller: PropTypes.func.isRequired,
  resolve: PropTypes.func.isRequired,
  onResolved: PropTypes.func.isRequired,
  doGetApiCaller: PropTypes.func.isRequired,
};

DataResolverCmp.defaultProps = {
  // Cmp: null,
  // cmpProps: {},
  loaderWrapperClass: null,
  loaderWrapperStyle: null,
  getLoaderText: null,
  loaderText: '',
  loader: true,
};

export default connect(() => ({}), {
  doGetApiCaller: () => getApiCaller,
})(DataResolverCmp);
