/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import { withHandlers } from 'recompose';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/styles';
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  IconButton,
  Collapse,
} from '@material-ui/core';
import FilterIcon from '@material-ui/icons/FilterList';

import FilterInput from 'src/components/common/filters/FilterInput';

import { mapRequestModelByRMTypeId } from 'src/reducers/requestModelList';
import appStyle from 'src/consts/appStyle';

import utils from 'src/utils/utils';

const tableSortLabelStyle = {
  active: { color: appStyle.txtColor1 },
  root: {
    color: appStyle.txtColor1,
    '&$active': { color: appStyle.txtColor1, '& svg path': { color: appStyle.txtColor1 } },
  },
};

const StyledTableSortLabelRender = ({ classes, children, ...props }) => (
  <TableSortLabel classes={{ root: classes.root, active: classes.active }} {...props}>
    {children}
  </TableSortLabel>
);

StyledTableSortLabelRender.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
};

const StyledTableSortLabel = withStyles(tableSortLabelStyle)(StyledTableSortLabelRender);

function WithFilterSortLabel({
  column,
  addFilter,
  visibleInputs,
  showInput,
  setInputRef,
  input,
  ...props
}) {
  return (
    <Fragment>
      <div style={{ display: 'flex' }}>
        <StyledTableSortLabel {...props} />
        <Tooltip title={utils.getLang('smartmessaging.tooltip.filters')}>
          <IconButton
            aria-label="filter"
            onClick={() => {
              showInput(!visibleInputs);
              if (!visibleInputs) {
                if (input && input.focus) setTimeout(() => input.focus(), 300);
              }
            }}
          >
            <FilterIcon />
          </IconButton>
        </Tooltip>
      </div>
      <Collapse in={visibleInputs}>
        <FilterInput column={column} addFilter={addFilter} setInputRef={setInputRef} />
      </Collapse>
    </Fragment>
  );
}

WithFilterSortLabel.propTypes = {
  column: PropTypes.object.isRequired,
  addFilter: PropTypes.func.isRequired,
  visibleInputs: PropTypes.bool.isRequired,
  showInput: PropTypes.func.isRequired,
  setInputRef: PropTypes.func.isRequired,
  input: PropTypes.any,
};

WithFilterSortLabel.defaultProps = { input: null };

class WithRefAndFilterSortLabel extends React.Component {
  constructor(props) {
    super(props);
    this.input = null;
    this.setInputRef = element => {
      this.input = element;
    };
  }

  render() {
    return (
      <WithFilterSortLabel {...this.props} input={this.input} setInputRef={this.setInputRef} />
    );
  }
}

function CampaignListHead(props) {
  const { getColumnData, order, orderBy, onRequestSort, addFilter } = props;
  const isNodeMapped = utils.isNodeMapped();
  const [iVisible, showInputs] = useState(false);
  return (
    <TableHead>
      <TableRow>
        <TableCell
          style={{
            position: 'sticky',
            top: 0,
            background: '#fafafa',
            zIndex: 1,
          }}
        />
        {getColumnData(props)
          .filter(c => (isNodeMapped ? true : ['networkNodeName'].indexOf(c.id) === -1))
          .map(column => (
            <TableCell
              key={column.id}
              style={{
                position: 'sticky',
                top: 0,
                background: '#fafafa',
                zIndex: 1,
              }}
            >
              {!column.filtrable && (
                <StyledTableSortLabel
                  key={column.id}
                  active={orderBy === column.id}
                  direction={order}
                  onClick={() => {
                    onRequestSort(column.id, column.sortFns || false);
                  }}
                >
                  {column.label}
                </StyledTableSortLabel>
              )}
              {column.filtrable && (
                <WithRefAndFilterSortLabel
                  visibleInputs={iVisible}
                  key={column.id}
                  active={orderBy === column.id}
                  direction={order}
                  showInput={showInputs}
                  onClick={() => {
                    onRequestSort(column.id, column.sortFns || false);
                  }}
                  column={column}
                  addFilter={addFilter}
                >
                  {column.label}
                </WithRefAndFilterSortLabel>
              )}
            </TableCell>
          ))}
        <TableCell
          style={{
            position: 'sticky',
            top: 0,
            background: '#fafafa',
            zIndex: 1,
          }}
        />
      </TableRow>
    </TableHead>
  );
}
CampaignListHead.propTypes = {
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  getColumnData: PropTypes.func.isRequired,
  addFilter: PropTypes.func.isRequired,
};

const Connected = connect(state => ({
  requestModelByRMTypeId: mapRequestModelByRMTypeId(state),
  recipesById: state.recipeList.recipeListById,
}))(CampaignListHead);

export default withHandlers({
  getColumnData: () => props => [
    {
      id: 'name',
      numeric: false,
      label: utils.getLang('smartmessaging.campaignList.column.name'),
      filtrable: true,
      filterConfig: {
        model: {
          key: 'name',
          renderLabel: () => utils.getLang('smartmessaging.campaignList.column.name'),
          renderValue: value => value,
        },
        inputConfig: {
          type: 'TEXT',
          isValid: value => !!value,
        },
      },
      sortFns: {
        asc(a, b) {
          const sortValueA = a.name.toLowerCase();
          const sortValueB = b.name.toLowerCase();

          return sortValueA < sortValueB ? -1 : 1;
        },
        desc(a, b) {
          const sortValueA = a.name.toLowerCase();
          const sortValueB = b.name.toLowerCase();
          return sortValueA > sortValueB ? -1 : 1;
        },
      },
    },
    {
      id: 'recipeId',
      filtrable: true,
      filterConfig: {
        model: {
          key: 'recipeId',
          convertValue: recipeId =>
            recipeId ? props.recipesById[recipeId].requestModelTypeId : -1,
          renderLabel: () => utils.getLang('smartmessaging.campaignList.column.type'),
          renderValue: value =>
            props.requestModelByRMTypeId[value]
              ? utils.getLang(
                  `smartmessaging.requestmodel.label.${props.requestModelByRMTypeId[value].name}`
                )
              : utils.getLang(`smartmessaging.campaignList.pendingElement`),
        },
        inputConfig: {
          type: 'SELECT',
          isValid: value => !!value,
          getOptions() {
            return [
              {
                label: utils.getLang(`smartmessaging.campaignList.pendingElement`),
                value: -1,
              },
            ].concat(
              Object.values(props.requestModelByRMTypeId)
                .map(requestModel => ({
                  label: utils.getLang(`smartmessaging.requestmodel.label.${requestModel.name}`),
                  value: requestModel.requestModelTypeId,
                }))
                .sort((a, b) => {
                  if (a.label < b.label) {
                    return -1;
                  }
                  if (a.label > b.label) {
                    return 1;
                  }
                  return 0;
                })
            );
          },
        },
      },
      sortFns: {
        asc(a, b) {
          if (!a.recipeId && !b.recipeId) return 0;
          if (!a.recipeId) return 1;
          if (!b.recipeId) return -1;
          if (!props.recipesById[a.recipeId]) return -1;
          if (!props.recipesById[b.recipeId]) return 1;
          const sortValueA = utils
            .getLang(
              `smartmessaging.requestmodel.label.${
                props.requestModelByRMTypeId[props.recipesById[a.recipeId].requestModelTypeId].name
              }`
            )
            .toLowerCase();
          const sortValueB = utils
            .getLang(
              `smartmessaging.requestmodel.label.${
                props.requestModelByRMTypeId[props.recipesById[b.recipeId].requestModelTypeId].name
              }`
            )
            .toLowerCase();

          return sortValueA < sortValueB ? -1 : 1;
        },
        desc(a, b) {
          if (!a.recipeId && !b.recipeId) return 0;
          if (!a.recipeId) return -1;
          if (!b.recipeId) return 1;
          if (!props.recipesById[a.recipeId]) return -1;
          if (!props.recipesById[b.recipeId]) return 1;
          const sortValueA = utils.getLang(
            `smartmessaging.requestmodel.label.${
              props.requestModelByRMTypeId[props.recipesById[a.recipeId].requestModelTypeId].name
            }`
          );
          const sortValueB = utils.getLang(
            `smartmessaging.requestmodel.label.${
              props.requestModelByRMTypeId[props.recipesById[b.recipeId].requestModelTypeId].name
            }`
          );

          return sortValueA > sortValueB ? -1 : 1;
        },
      },
      numeric: false,
      label: utils.getLang('smartmessaging.campaignList.column.type'),
    },
    {
      id: 'eventDriven',
      numeric: false,
      label: utils.getLang('smartmessaging.campaignList.column.trigger'),
      filtrable: true,
      filterConfig: {
        model: {
          key: 'eventDriven',
          renderLabel: () => utils.getLang('smartmessaging.campaignList.column.trigger'),
          renderValue: value =>
            value === true
              ? utils.getLang('smartmessaging.campaignTrigger.action')
              : (value === false && utils.getLang('smartmessaging.campaignTrigger.time')) || '',
        },
        inputConfig: {
          type: 'SELECT',
          isValid: value => value === true || value === false,
          getOptions() {
            return [
              { label: utils.getLang('smartmessaging.campaignTrigger.action'), value: true },
              { label: utils.getLang('smartmessaging.campaignTrigger.time'), value: false },
            ];
          },
        },
      },
    },
    {
      id: 'actionType',
      numeric: false,
      label: utils.getLang('smartmessaging.campaignList.column.actionTypes'),
      filtrable: true,
      filterConfig: {
        model: {
          key: 'actionType',
          convertValue: actionType =>
            actionType || utils.getLang('smartmessaging.campaignList.pendingElement'),
          renderLabel: () => utils.getLang('smartmessaging.campaignList.column.actionTypes'),
          renderValue: value => value,
        },
        inputConfig: {
          type: 'SELECT',
          isValid: value => value.length && value.length > 0,
          getOptions() {
            return [
              { label: 'sms', value: 'sms' },
              { label: 'email', value: 'email' },
              {
                label: utils.getLang('smartmessaging.campaignList.pendingElement'),
                value: utils.getLang('smartmessaging.campaignList.pendingElement'),
              },
            ];
          },
        },
      },
    },
    {
      id: 'creationDate',
      numeric: false,
      label: utils.getLang('smartmessaging.campaignList.column.createDate'),
      filtrable: true,
      filterConfig: {
        model: {
          key: 'creationDate',
          renderLabel: () => utils.getLang('smartmessaging.campaignList.column.createDate'),
          renderValue: value => {
            const start = !!value[0] && new Date(value[0]);
            const end = !!value[1] && new Date(value[1]);
            if (start && end) {
              return `du ${start.toLocaleDateString()} au ${end.toLocaleDateString()}`;
            } else if (start) {
              return `après le ${start.toLocaleDateString()}`;
            } else if (end) {
              return `avant le ${end.toLocaleDateString()}`;
            }
            return '';
          },
        },
        inputConfig: {
          type: 'DATE',
          isValid: value => value.length && value.length > 0 && (value[0] || value[1]),
        },
      },
    },
    {
      id: 'networkNodeName',
      numeric: false,
      label: utils.getLang('smartmessaging.campaignList.column.networkNodeName'),
      filtrable: true,
      filterConfig: {
        model: {
          key: 'networkNodeName',
          renderLabel: () => utils.getLang('smartmessaging.campaignList.column.networkNodeName'),
          renderValue: value => value,
        },
        inputConfig: {
          type: 'TEXT',
          isValid: value => !!value,
        },
      },
    },
    {
      id: 'creationUserName',
      numeric: false,
      label: utils.getLang('smartmessaging.campaignList.column.cretionUserName'),
      filtrable: true,
      filterConfig: {
        model: {
          key: 'creationUserName',
          renderLabel: () => utils.getLang('smartmessaging.campaignList.column.cretionUserName'),
          renderValue: value => value,
        },
        inputConfig: {
          type: 'TEXT',
          isValid: value => !!value,
        },
      },
    },
    {
      id: 'isPlanned',
      numeric: false,
      label: utils.getLang('smartmessaging.campaignList.column.isPlanned'),
    },
    {
      id: 'enabled',
      numeric: false,
      label: utils.getLang('smartmessaging.campaignList.column.status'),
    },
  ],
})(Connected);
