// import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// import { Typography } from '@material-ui/core';
// import utils from 'src/utils/utils';
// import appStyle from 'src/consts/appStyle';
import EmailContentLabel from 'src/components/common/contentEditor/EmailContentLabel';

// import ValidityAdornment from './ValidityAdornment';

// const EmailContentLabel = ({ contentValidity }) => (
//   <div style={{ display: 'flex' }}>
//     <Typography
//       style={{
//         margin: '5px 10px',
//         color: contentValidity.contentIsEmpty ? appStyle.txtColor6 : appStyle.txtColor3,
//       }}
//       variant="subtitle2"
//       noWrap
//     >
//       {utils.getLang('smartmessaging.contentEditor.mail.content')}
//     </Typography>
//     <ValidityAdornment
//       valid={!contentValidity.contentIsEmpty}
//       message={utils.getLang('smartmessaging.contentEditor.mail.contentIsEmpty')}
//     />
//     {!contentValidity.hasOptoutLink && (
//       <ValidityAdornment
//         valid={false}
//         message={
//           <ul>
//             {!contentValidity.hasOptoutLink && (
//               <li>{utils.getLang('smartmessaging.contentEditor.mail.optoutLinkMissing')}</li>
//             )}
//           </ul>
//         }
//       />
//     )}
//   </div>
// );

EmailContentLabel.propTypes = {
  contentValidity: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  contentValidity: state.massAction.contentValidity,
});

export default connect(mapStateToProps, {})(EmailContentLabel);
