import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  CampaignTarget,
  CampaignInfos,
  CampaignMessage,
  EditorAppBar,
  CampaignSchedule,
} from './editorComponents';
import withSteps from 'src/components/common/hocs/withSteps';
import { mapRequestModelByRMTypeId } from 'src/reducers/requestModelList';
import {
  saveCampaign,
  checkCmpInfosValidity,
  checkCampaignValidity,
} from 'src/reducers/campaignEditor';

import { getSortedCategoryList } from 'src/enums/campaignCategory';
// import appStyle from 'src/consts/appStyle';

const mapStateToProps = state => ({
  editedCampaign: state.campaignEditor.editedCampaign,
  requestModelsByRequestModelTypeId: mapRequestModelByRMTypeId(state),
  recipesById: state.recipeList.recipeListById,
  selectedType: state.campaignEditor.selectedType,
  requestModelsByCategoryId: state.requestModelList.requestModelsByCategoryId,
  sortedCategoryList: getSortedCategoryList(),
  recipeCfgLoaded: state.campaignEditor.recipeFieldsConfig.loaded,
  campaignActions: state.campaignEditor.actions.campaignActions,
  infosValidity: checkCmpInfosValidity(state),
  campaignValidity: checkCampaignValidity(state),
  wizVilTypes: state.app.wizVilleRequestModels,
  wizVilleEntryActivated: state.app.config.wizVilleEntryActivated,
  periods: state.campaignEditor.schedule.editedCmpPeriods,
});

const actionCreators = {
  updateEditedCampaign: changes => ({ type: 'UPDATE_EDITED_CAMPAIGN', value: changes }),
  selectOptinType: optinType => ({
    type: 'SELECT_OPTIN_TYPE',
    value: optinType,
  }),
  doSaveCampaign: saveCampaign,
  selectCampaignType: requestModel => ({
    type: 'SELECT_CAMPAIGN_TYPE',
    value: requestModel,
  }),
};

const StepHandler = ({ currentStep, goToStep, ...otherProps }) => {
  switch (currentStep) {
    case 0:
      otherProps.showBtns(true);
      return (
        <CampaignInfos
          {...otherProps}
          goToEditorStep={goToStep}
          onBack={() => {
            otherProps.goToSMJEditorStep(0, { smartjourney: otherProps.smartjourney });
          }}
        />
      );

    case 1:
      otherProps.showBtns(true);
      return <CampaignTarget {...otherProps} goToEditorStep={goToStep} />;

    case 2:
      return <CampaignMessage {...otherProps} goToEditorStep={goToStep} />;
    case 3:
      otherProps.showBtns(true);
      return <CampaignSchedule {...otherProps} goToEditorStep={goToStep} />;
    default:
      otherProps.showBtns(true);
      return <CampaignInfos {...otherProps} goToEditorStep={goToStep} />;
  }
};

StepHandler.propTypes = {
  goToStep: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
};

function WithAppBar({
  goToStep,
  doSaveCampaign,
  backToList,
  rmByRmTypeId,
  smartjourney,
  ...props
}) {
  const [visibleBtns, showBtns] = useState(true);
  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflow: 'hidden',
        textAlign: 'left',
        height: '100%',
        // alignItems: 'center',
      }}
    >
      <EditorAppBar
        {...props}
        // deleteCampaign={doDeleteCampaign}
        rmByRmTypeId={rmByRmTypeId}
        saveCampaign={doSaveCampaign}
        goToEditorStep={goToStep}
        visibleBtns={visibleBtns}
        smartjourney={smartjourney}
      />
      <StepHandler
        {...{ ...props, doSaveCampaign, goToStep, showBtns, rmByRmTypeId, smartjourney }}
      />
    </div>
  );
}

WithAppBar.propTypes = {
  goToStep: PropTypes.func.isRequired,
  // doDeleteCampaign: PropTypes.func.isRequired,
  doSaveCampaign: PropTypes.func.isRequired,
  backToList: PropTypes.func.isRequired,
  rmByRmTypeId: PropTypes.object.isRequired,
  smartjourney: PropTypes.object.isRequired,
};

const Connected = connect(mapStateToProps, actionCreators)(WithAppBar);

export default withSteps(Connected);
