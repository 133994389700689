import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
// import { withStyles } from '@material-ui/styles';

import appStyle from 'src/consts/appStyle';
import utils from 'src/utils/utils';

const SMJSelectedCmpList = ({ selection, rmByRmTypeId }) => (
  <Fragment>
    {Object.entries(selection).map(([rmId, list]) => (
      <div
        key={rmId}
        style={{
          display: 'inline-block',
          margin: '4px',
          borderBottom: appStyle.border1,
          width: '40%',
          maxWidth: '400px',
          minWidth: '150px',
        }}
      >
        <Typography style={{ color: appStyle.txtColor4, fontWeight: 200 }}>
          {utils.getLang(`smartmessaging.requestmodel.label.${rmByRmTypeId[rmId].name}`)}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {list.map(cmp => (
            <Typography
              key={cmp.key}
              variant="body2"
              style={{ color: appStyle.txtColor3, fontWeight: 200 }}
            >
              {utils.getLang(`smartmessaging.campaigns.grouped.campaignModel.name.${cmp.key}`)}
            </Typography>
          ))}
        </div>
      </div>
    ))}
  </Fragment>
);

SMJSelectedCmpList.propTypes = {
  selection: PropTypes.object.isRequired,
  rmByRmTypeId: PropTypes.object.isRequired,
  // smjModel: PropTypes.object.isRequired,
  // onSelect: PropTypes.func,
  // classes: PropTypes.object.isRequired,
  // disabledSelect: PropTypes.bool,
};

SMJSelectedCmpList.defaultProps = {
  // disabledSelect: false,
  // onSelect: () => {},
};

// const styles = theme => ({
//   content: {
//     '&.selectable': {
//       cursor: 'pointer',
//       opacity: 0.7,
//       '&:hover': {
//         opacity: 1,
//       },
//     },
//     flex: 1,
//   },
//   contentRoot: {
//     paddingLeft: theme.spacing(1),
//     paddingRight: theme.spacing(1),
//     paddingTop: theme.spacing(2),
//     paddingBottom: `${theme.spacing(2)}px !important`,
//   },
//   card: {
//     textAlign: 'left',
//     marginBottom: theme.spacing(1),
//     backgroundColor: appStyle.bgColor4,
//     // flex: 1,
//   },
//   title: {
//     color: appStyle.txtColor1,
//     margin: 'auto',
//     overflow: 'hidden',
//     textOverflow: 'ellipsis',
//     marginBottom: theme.spacing.unit,
//   },
//   description: {
//     color: appStyle.txtColor4,
//     margin: 'auto',
//     overflow: 'hidden',
//     textOverflow: 'ellipsis',
//   },
// });

export default SMJSelectedCmpList;
