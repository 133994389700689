import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

function IntegerFormat(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      onChange={e => {
        // TODO ---> leading 0 issue--->format and remove all 0
        // onChange({ ...e, target: { ...e.target, value: 0 } });
        onChange(e);
      }}
      decimalScale={0}
      // onValueChange={values => {
      //   other.onChange({
      //     target: {
      //       value: values.value,
      //     },
      //   });
      // }}
    />
  );
}

IntegerFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default IntegerFormat;
