import React, { useState, Fragment, useCallback } from 'react';

import PropTypes from 'prop-types';

import { Menu, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import utils from 'src/utils/utils';

import appStyle from 'src/consts/appStyle';

const styles = {
  menuItem: {
    color: appStyle.txtColor3,
    display: 'flex',
    fontSize: '0.98rem',
    outline: 'none',
    cursor: 'pointer',
    padding: '4px',
    minHeight: 'unset',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '240px',
    '&:hover': {
      background: appStyle.bgColor1,
      opacity: 0.9,
      color: appStyle.txtColor2,
    },
  },
  lng: {
    color: appStyle.txtColor3,
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '12px 10px',
    // borderLeft: '1px solid rgb(221, 221, 221)',
    transition: 'all 200ms ease-in-out 0s',
    display: 'inline-block',
    opacity: 0.8,
    '&:hover': { opacity: 1 },
  },
};

const MenuLangBtn = ({ anchorEl, classes, handleClose, selectLang, langs, currentLang }) => (
  <Menu
    value
    anchorEl={anchorEl}
    open={Boolean(anchorEl)}
    onClose={e => {
      e.preventDefault();
      handleClose();
    }}
    PaperProps={{
      style: {
        minWidth: 150,
      },
    }}
  >
    {langs.map(l => (
      <MenuItem
        className={classes.menuItem}
        selected={l === currentLang}
        disabled={l === currentLang}
        key={l}
        onClick={() => {
          handleClose();
          selectLang(l);
        }}
      >
        {utils.getLang(`smartmessaging.language.${l}`)}
      </MenuItem>
    ))}
  </Menu>
);

MenuLangBtn.propTypes = {
  classes: PropTypes.object.isRequired,
  anchorEl: PropTypes.any,
  handleClose: PropTypes.func.isRequired,
  selectLang: PropTypes.func.isRequired,
  langs: PropTypes.array.isRequired,
  currentLang: PropTypes.string.isRequired,
};

MenuLangBtn.defaultProps = {
  anchorEl: null,
};

function MessagesLangBtn({ classes, langs, currentLang, selectLang }) {
  const [anchorEl, setAnchorEl] = useState(null);

  function handleClose() {
    setAnchorEl(null);
  }

  const handleClick = useCallback(e => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  }, []);

  return (
    <Fragment>
      <div onClick={handleClick} className={classes.lng}>
        {currentLang}
      </div>
      <MenuLangBtn {...{ handleClose, anchorEl, classes, langs, currentLang, selectLang }} />
    </Fragment>
  );
}

MessagesLangBtn.propTypes = {
  classes: PropTypes.object.isRequired,
  selectLang: PropTypes.func.isRequired,
  langs: PropTypes.array.isRequired,
  currentLang: PropTypes.string.isRequired,
};

export default withStyles(styles)(MessagesLangBtn);
