import React from 'react';

import PropTypes from 'prop-types';
import { Dialog, DialogTitle, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import appStyle from 'src/consts/appStyle';

const ContentViewer = ({ onClose, content, open, object }) => {
  // let iframeRef = null;
  const writeHTML = frame => {
    if (!frame) {
      return;
    }
    // iframeRef = frame;
    const doc = frame.contentDocument;
    doc.open();
    doc.write(content);
    doc.close();
    // frame.style.width = '100%';
    // frame.style.height = `${frame.contentWindow.document.body.scrollHeight}px`;
  };
  return (
    <Dialog
      open={open}
      hideBackdrop
      onClose={onClose}
      PaperProps={{ style: { width: '50%', height: '60%' } }}
    >
      <DialogTitle style={{ padding: '16px' }}>
        <Typography style={{ fontSize: '1rem', color: appStyle.txtColor3, textAlign: 'left' }}>
          {object}
        </Typography>
        <IconButton
          aria-label="close"
          style={{
            position: 'absolute',
            right: '4px',
            top: '4px',
            // color: theme.palette.grey[500],
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {!!open && (
        <iframe
          title="content"
          src="about:blank"
          scrolling="yes"
          frameBorder="0"
          ref={writeHTML}
          style={{ height: '100%' }}
        />
      )}
    </Dialog>
  );
};

ContentViewer.propTypes = {
  onClose: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  object: PropTypes.string.isRequired,
};

export default ContentViewer;
