import { createReducer } from '@acemarke/redux-starter-kit';
import apiClient from '../utils/apiClient';
import getApiCaller from '../utils/apiClientCaller';
import globalVars from '../consts/globalVars';
import { loaderActions } from './loader';

const initialState = {
  status: {},
  imageMaxSize: 'undefined',
  wizVilleRequestModels: [],
  appHasContent: true,
  config: {
    'smartm-automation:allow': false,
    'smartm-punctual:allow': false,
    'smartm-param:allow': false,
    'smartm-template:allow': false,
    'smartm-private:filter': false,
    'smartm-planned:filter': false,
    defaultSmsHours: '',
  },
  currentLanguage: {},
  availableLanguages: [],
};

const RECEIVE_APP_STATUS = 'RECEIVE_APP_STATUS';
const RECEIVE_IMG_MAX_SIZE = 'RECEIVE_IMG_MAX_SIZE';
const RECEIVE_CLUBINFOS = 'RECEIVE_CLUBINFOS';
const RECEIVE_WIZV_RMS = 'RECEIVE_WIZV_RMS';
const RECEIVE_CLUB_ID = 'RECEIVE_CLUB_ID';
const APP_HAS_CONTENT = 'APP_HAS_CONTENT';
const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';

const RECEIVE_AVAILABLE_LANGUAGES = 'RECEIVE_AVAILABLE_LANGUAGES';

const actions = {
  receiveAppStatus: statusData => ({ type: RECEIVE_APP_STATUS, value: statusData }),
  receiveImageMaxSize: imageMaxSize => ({ type: RECEIVE_IMG_MAX_SIZE, value: imageMaxSize }),

  // receiveClubInfos: ({ actionCoActivated, wizVilleEntryActivated }) => ({
  // value: {
  //   actionCoActivated: !!actionCoActivated,
  //   wizVilleEntryActivated: !!wizVilleEntryActivated,
  // },
  receiveClubInfos: infos => ({
    type: RECEIVE_CLUBINFOS,
    value: infos,
  }),
  receiveWizVillRmTypeList: wizVilleRequestModels => ({
    type: RECEIVE_WIZV_RMS,
    value: [...wizVilleRequestModels],
  }),
  receiveClubId: clubId => ({
    type: RECEIVE_CLUB_ID,
    value: clubId,
  }),
};

export const loadAppStatus = () => async (dispatch, getState) => {
  const statusData = await getApiCaller(dispatch, getState)(apiClient.getAppStatus);
  dispatch(actions.receiveAppStatus(statusData));
};

export const loadClubInfos = () => async (dispatch, getState) => {
  const { clientToken } = getState().auth;
  const callApi = getApiCaller(dispatch, getState);
  const clubInfos = await callApi(
    apiClient.getClubInfos,
    [clientToken],
    () => ({})
    // specificErrorHandler
    // error,
    // doCall,
    // () => han
    // dispatch
  );

  if (clubInfos.wizVilleEntryActivated) {
    const wizVilleRequestModelTypes = await callApi(
      apiClient.getWizVillRequestModelTypes,
      [clientToken],
      () => ({})
      // specificErrorHandler
      // error,
      // doCall,
      // () => handleError(error, doCall, dispatch),
      // dispatch
    );
    dispatch(actions.receiveWizVillRmTypeList(wizVilleRequestModelTypes));
  }

  if (clubInfos['smartm-automation:allow'] || clubInfos['smartm-smartjourney:allow']) {
    dispatch({ type: 'SWITCH_MAIN_CONTENT', value: 0 });
  } else if (clubInfos['smartm-punctual:allow']) {
    dispatch({ type: 'SWITCH_MAIN_CONTENT', value: 2 });
  } else if (clubInfos['smartm-template:allow']) {
    dispatch({ type: 'SWITCH_MAIN_CONTENT', value: 1 });
  } else if (clubInfos['smartm-param:allow']) {
    dispatch({ type: 'SWITCH_MAIN_CONTENT', value: 3 });
  } else if (clubInfos['smartm-smartjourney:allow']) {
    dispatch({ type: 'SWITCH_MAIN_CONTENT', value: 5 });
  } else {
    dispatch({ type: 'APP_HAS_CONTENT', value: false });
  }
  dispatch(actions.receiveClubInfos(clubInfos));
};

const receiveAppStatus = (state, { value }) => ({
  ...state,
  status: value,
});

const receiveClubInfos = (state, { value }) => ({
  ...state,
  config: { ...state.config, ...value },
  // actionCoActivated: value.actionCoActivated,
  // wizVilleEntryActivated: value.wizVilleEntryActivated,
});

const receiveWizVillRmTypeList = (state, { value }) => ({
  ...state,
  wizVilleRequestModels: value,
});

const receiveClubId = (state, { value }) => ({
  ...state,
  clubId: value,
});

export const loadImageMaxSize = () => async (dispatch, getState) => {
  const imageMaxSize = await getApiCaller(dispatch, getState)(apiClient.getMaxImageWeight);
  dispatch(actions.receiveImageMaxSize(imageMaxSize));
};

const getLanguage = () => {
  const cookiesLanguage = '';
  const sessionLanguage = sessionStorage.getItem('locale');
  const browserLanguage = navigator.language || navigator.languages[0];
  return sessionLanguage || cookiesLanguage || browserLanguage || 'default';
};

export const loadLanguage = (code, callback) => async (dispatch, getState) => {
  const setLoader = visible => {
    dispatch(loaderActions.setLoader(visible));
  };
  const callApi = getApiCaller(dispatch, getState);
  setLoader(true);
  const contexts = await callApi(apiClient.getContexts, [code]).catch(() => setLoader(false));
  sessionStorage.setItem('locale', contexts.language.locale);
  globalVars.contexts = contexts.contextMap;
  setTimeout(() => {
    dispatch({ type: UPDATE_LANGUAGE, value: contexts.language });
    if (callback) callback();
    setLoader(false);
  }, 300);
};

export const initLang = () => async (dispatch, getState) => {
  const callApi = getApiCaller(dispatch, getState);
  const avLanguages = await callApi(apiClient.getAvailableLanguages);
  dispatch({ type: RECEIVE_AVAILABLE_LANGUAGES, value: avLanguages });
  await loadLanguage(getLanguage())(dispatch, getState);
};

const receiveImageMaxSize = (state, { value }) => ({
  ...state,
  imageMaxSize: value,
});

const setAppHasContent = (state, { value }) => ({
  ...state,
  appHasContent: value,
});

const updateLanguage = (state, { value }) => ({
  ...state,
  currentLanguage: value,
});

const receiveAvailabeLanguages = (state, { value }) => ({
  ...state,
  availableLanguages: value,
});

export const clearData = () => async dispatch => {
  dispatch({ type: 'CLEAR_CURRENT_ACTION' });
  dispatch({ type: 'CLEAR_EDITOR_DATA' }); // auto cmp
  dispatch({ type: 'CLEAR_EDITED_DATA' }); // mass action
};

export default createReducer(initialState, {
  [RECEIVE_APP_STATUS]: receiveAppStatus,
  [RECEIVE_IMG_MAX_SIZE]: receiveImageMaxSize,
  [RECEIVE_CLUBINFOS]: receiveClubInfos,
  [RECEIVE_WIZV_RMS]: receiveWizVillRmTypeList,
  [RECEIVE_CLUB_ID]: receiveClubId,
  [APP_HAS_CONTENT]: setAppHasContent,
  [UPDATE_LANGUAGE]: updateLanguage,
  [RECEIVE_AVAILABLE_LANGUAGES]: receiveAvailabeLanguages,
});

export const getImageMaxSize = state => state.app.imageMaxSize;
