import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

import appStyle from 'src/consts/appStyle';
import utils from 'src/utils/utils';

const CustomTemplateButtons = ({ classes, saveTemplate, closeTemplateEditor, template }) => (
  <div style={{ textAlign: 'right', borderTop: appStyle.border1 }} key={3}>
    <Button
      className={`${classes.buttonMargin} ${classes.cancelBtn}`}
      onClick={e => {
        e.preventDefault();
        closeTemplateEditor();
      }}
    >
      {utils.getLang('smartmessaging.button.contentEditor.cancel')}
    </Button>
    <Button
      className={`${classes.buttonMargin} ${classes.button}`}
      disabled={!template.isWritable || !template.name}
      onClick={e => {
        e.preventDefault();
        saveTemplate();
      }}
      variant="contained"
      style={{ textAlign: 'right', borderTop: appStyle.border1 }}
    >
      {utils.getLang('smartmessaging.button.contentEditor.save')}
    </Button>
  </div>
);

CustomTemplateButtons.propTypes = {
  closeTemplateEditor: PropTypes.func.isRequired,
  saveTemplate: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  template: PropTypes.object.isRequired,
};
const styles = theme => ({
  button: {
    backgroundColor: appStyle.bgColor1,
    margin: `${theme.spacing(0.5)}px`,
    color: appStyle.txtColor2,
    '&:hover': {
      background: appStyle.bgColor1,
      opacity: 0.9,
    },
  },
  buttonMargin: { margin: `${theme.spacing(0.5)}px` },
  cancelBtn: { color: appStyle.txtColor7 },
});

export default withStyles(styles)(CustomTemplateButtons);
