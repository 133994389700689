import React from 'react';
import PropTypes from 'prop-types';
import withSteps from 'src/components/common/hocs/withSteps';
import CampaignActions from './CampaignActions';
import MessageEditor from './MessageEditor';
import MessageModelSelector from './MessageModelSelector';

function StepHandler({ currentStep, goToStep, showBtns, ...otherProps }) {
  switch (currentStep) {
    case 0:
      showBtns(true);
      return <CampaignActions {...otherProps} goToCmpMsgStep={goToStep} />;

    case 1:
      showBtns(false);
      return <MessageModelSelector {...otherProps} goToCmpMsgStep={goToStep} />;

    case 2:
      showBtns(false);
      return <MessageEditor {...otherProps} goToCmpMsgStep={goToStep} />;
    default:
      showBtns(true);
      return <CampaignActions {...otherProps} goToCmpMsgStep={goToStep} />;
  }
}

StepHandler.propTypes = {
  currentStep: PropTypes.number.isRequired,
  goToStep: PropTypes.func.isRequired,
  showBtns: PropTypes.func.isRequired,
};

export default withSteps(StepHandler);
