import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { compose } from 'redux';
import { withState } from 'recompose';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Button,
  Accordion as ExpansionPanel,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@material-ui/core';

import utils from 'src/utils/utils';
import appStyle from 'src/consts/appStyle';

import MessageModelItem from 'src/components/common/MessageModelItem';
import FileUploaderBtn from 'src/components/common/FileUploaderBtn';
import Loader from 'src/components/common/Loader';

const CreatorRender = ({
  messageModels,
  hidden,
  classes,
  setHidden,
  expanded,
  setExpanded,
  loaded, // this is when messageModels are loaded
  loadData, // this function loads data to edit into ckeditor, and open the content editor
}) => (
  <div>
    <Button
      disabled={!hidden}
      onClick={e => {
        e.preventDefault();
        setHidden(false);
      }}
      className={classes.cmpActionBtn}
    >
      {utils.getLang('smartmessaging.contentEditor.chooseModel')}
    </Button>
    {!hidden && (
      <Button
        className={classes.cmpActionBtn}
        onClick={event => {
          event.preventDefault();
          setHidden(true);
        }}
      >
        {utils.getLang('smartmessaging.contentEditor.cancelModelSelection')}
      </Button>
    )}
    {!hidden &&
      ((!loaded && (
        <Loader
          wrapperClass="ghostWrapperClass"
          wrapperStyle={{ position: 'relative', height: '96px' }}
        />
      )) || (
        <div>
          {!!messageModels.readyModels.length && (
            <ExpansionPanel
              elevation={0}
              expanded={expanded === 'readyModel'}
              onChange={(event, value) => {
                setExpanded((value && 'readyModel') || '');
              }}
            >
              <AccordionSummary
                className={classes.expansionSummary}
                expandIcon={<ExpandMoreIcon className={classes.summary} />}
                classes={{ expanded: classes.expandedSummary }}
              >
                <Typography className={classes.summaryTypo}>
                  {utils.getLang('smartmessaging.contentEditor.selectResaTemplate')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.expansionPanelDetail}>
                {messageModels.readyModels.map(messageModel => (
                  <MessageModelItem
                    key={messageModel.id}
                    messageModel={messageModel}
                    onMessageModelSelect={() =>
                      loadData({ storedFileId: messageModel.storedFileId })
                    }
                    hideLang={!messageModel.key.slice(-3).match(/_[A-Z]{2}/)}
                    // lang={messageModel.key.slice(-2)}
                    lang={
                      messageModel.key.slice(-3).match(/_[A-Z]{2}/)
                        ? messageModel.key.slice(-2)
                        : null
                    }
                  />
                ))}
              </AccordionDetails>
            </ExpansionPanel>
          )}
          {messageModels.genericModels.length && (
            <ExpansionPanel
              elevation={0}
              expanded={expanded === 'chooseTemplate'}
              onChange={(event, value) => {
                setExpanded((value && 'chooseTemplate') || '');
              }}
            >
              <AccordionSummary
                className={classes.expansionSummary}
                expandIcon={<ExpandMoreIcon className={classes.summary} />}
                classes={{ expanded: classes.expandedSummary }}
              >
                <Typography className={classes.summaryTypo}>
                  {utils.getLang('smartmessaging.contentEditor.selectResaGenericTemplate')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.expansionPanelDetail}>
                {messageModels.genericModels.map(messageModel => (
                  <MessageModelItem
                    key={messageModel.id}
                    messageModel={messageModel}
                    onMessageModelSelect={() =>
                      loadData({ storedFileId: messageModel.storedFileId })
                    }
                    hideLang={!messageModel.key.slice(-3).match(/_[A-Z]{2}/)}
                    lang={
                      messageModel.key.slice(-3).match(/_[A-Z]{2}/)
                        ? messageModel.key.slice(-2)
                        : null
                    }
                  />
                ))}
              </AccordionDetails>
            </ExpansionPanel>
          )}
          <ExpansionPanel
            elevation={0}
            expanded={expanded === 'uploadTemplate'}
            onChange={(event, value) => {
              setExpanded((value && 'uploadTemplate') || '');
            }}
          >
            <AccordionSummary
              className={classes.expansionSummary}
              expandIcon={<ExpandMoreIcon className={classes.summary} />}
              classes={{ expanded: classes.expandedSummary }}
            >
              <Typography className={classes.summaryTypo}>
                {utils.getLang('smartmessaging.contentEditor.uploadTemplate')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.expansionPanelDetail}>
              <FileUploaderBtn
                selectFileLabel={utils.getLang(
                  'smartmessaging.contentEditor.customTemplate.chooseFile'
                )}
                sendFileLabel={utils.getLang(
                  'smartmessaging.contentEditor.customTemplate.sendFile'
                )}
                cancelLabel={utils.getLang('smartmessaging.contentEditor.customTemplate.cancel')}
                displayCmp={file => <Typography>{file.name}</Typography>}
                onFileChange={(e, pFile) => {
                  if (!e && !pFile) {
                    return false;
                  }
                  const file = pFile || e.target.files[0];
                  if (!file) {
                    return false;
                  }
                  return true;
                }}
                onSend={file => {
                  const reader = new FileReader();
                  reader.onload = () => {
                    loadData({ uploadedData: reader.result });
                  };
                  reader.readAsText(file);
                }}
              />
            </AccordionDetails>
          </ExpansionPanel>
        </div>
      ))}
  </div>
);

CreatorRender.propTypes = {
  messageModels: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  hidden: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  loadData: PropTypes.func.isRequired,
  setHidden: PropTypes.func.isRequired,
  expanded: PropTypes.string.isRequired,
  setExpanded: PropTypes.func.isRequired,
};

const styles = () => ({
  expansionPanelDetail: { display: 'block' },
  cmpActionItemLabel: {
    display: 'inline-block',
    width: '50px',
  },
  expansionSummary: {
    borderBottom: appStyle.border1,
  },
  expandedSummary: {
    backgroundColor: appStyle.bgColor2,
  },
  summary: { color: appStyle.txtColor3 },
  summaryTypo: { color: appStyle.txtColor3 },
});

const Creator = compose(
  withState('hidden', 'setHidden', () => true),
  withState('expanded', 'setExpanded', () => ''),
  withStyles(styles)
)(CreatorRender);

const EditorRender = ({ classes, template, loadData, updateEditedTemplate }) => (
  <div>
    <Button
      onClick={event => {
        event.preventDefault();
        loadData({ storedFileId: template.storedfileId });
      }}
    >
      {utils.getLang('smartmessaging.contentEditor.editContent')}
    </Button>
    {template.isWritable && (
      <Button
        className={classes.deleteBtn}
        onClick={event => {
          event.preventDefault();
          updateEditedTemplate({ storedfileId: null });
        }}
      >
        {utils.getLang('smartmessaging.contentEditor.deleteContent')}
      </Button>
    )}
  </div>
);

EditorRender.propTypes = {
  updateEditedTemplate: PropTypes.func.isRequired,
  loadData: PropTypes.func.isRequired,
  template: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

const editorStyles = theme => ({
  deleteBtn: {
    margin: `${theme.spacing(0.5)}px`,
    backgroundColor: appStyle.bgColor5,
    color: appStyle.txtColor6,
    '&:hover': {
      background: appStyle.bgColor5,
      opacity: 0.9,
    },
  },
  cmpActionItemLabel: {
    display: 'inline-block',
    width: '50px',
  },
});

const Editor = withStyles(editorStyles)(EditorRender);

const CustomContentEditorTrigger = ({
  template,
  messageModels,
  loaded, // this is when messageModels are loaded
  updateEditedTemplate,
  ...otherProps
}) =>
  !template.storedfileId ? (
    <Creator messageModels={messageModels} loaded={loaded} template={template} {...otherProps} />
  ) : (
    <Editor
      storedFileId={template.storedfileId}
      updateEditedTemplate={updateEditedTemplate}
      template={template}
      {...otherProps}
    />
  );

CustomContentEditorTrigger.propTypes = {
  loaded: PropTypes.bool,
  updateEditedTemplate: PropTypes.func.isRequired,
  template: PropTypes.object.isRequired,
  messageModels: PropTypes.object,
};

CustomContentEditorTrigger.defaultProps = {
  messageModels: null,
  loaded: false,
};

export default CustomContentEditorTrigger;
