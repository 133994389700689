import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import appStyle from 'src/consts/appStyle';

const styles = () => ({
  button: {
    backgroundColor: appStyle.bgColor1,
    // margin: `${theme.spacing(0.5)}px`,
    color: appStyle.txtColor2,
    '&:hover': {
      background: appStyle.bgColor1,
      opacity: 0.9,
    },
  },
  reverse: {
    // backgroundColor: appStyle.bgColor,
    // margin: `${theme.spacing(0.5)}px`,
    color: appStyle.txtColor1,
    '&:hover': {
      // background: appStyle.bgColor1,
      opacity: 0.9,
    },
  },
});

const ActionButton = ({ classes, children, cVariant, ...ottherProps }) => (
  <Button
    className={(cVariant && classes[cVariant]) || classes.button}
    variant="contained"
    {...ottherProps}
  >
    {children}
  </Button>
);

ActionButton.propTypes = {
  classes: PropTypes.object.isRequired,
  cVariant: PropTypes.string,
  children: PropTypes.any.isRequired,
};

ActionButton.defaultProps = {
  cVariant: null,
};

export default withStyles(styles)(ActionButton);
