import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Typography } from '@material-ui/core';
import utils from 'src/utils/utils';
import appStyle from 'src/consts/appStyle';

import ValidityAdornment from './ValidityAdornment';
import CtnLang from './CtnLang';

const NotifyObjectLabel = ({ contentValidity }) => (
  <div style={{ display: 'flex' }}>
    <Typography
      style={{
        margin: '5px 10px',
        color: contentValidity.objectIsEmpty ? appStyle.txtColor6 : appStyle.txtColor3,
      }}
      variant="subtitle2"
      noWrap
    >
      {utils.getLang('smartmessaging.contentEditor.notify.object')}
    </Typography>
    <ValidityAdornment
      valid={!contentValidity.objectIsEmpty}
      message={utils.getLang('smartmessaging.contentEditor.notify.objectIsEmpty')}
    />

    <div style={{ marginRight: 0, marginLeft: 'auto' }}>
      <CtnLang />
    </div>
  </div>
);

NotifyObjectLabel.propTypes = {
  contentValidity: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  contentValidity: state.massAction.contentValidity,
});

export default connect(mapStateToProps, {})(NotifyObjectLabel);
