import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import appStyle from 'src/consts/appStyle';

import ValidityAdornment from '../ValidityAdornment';

const EmailObjectLabel = ({ title, contentValidity }) => (
  <div style={{ display: 'flex' }}>
    <Typography
      style={{
        margin: '5px 10px',
        color: !contentValidity.objIsValid ? appStyle.txtColor6 : appStyle.txtColor3,
      }}
      variant="subtitle2"
      noWrap
    >
      {title}
    </Typography>
    <ValidityAdornment
      valid={contentValidity.objIsValid}
      message={
        !contentValidity.objIsValid
          ? contentValidity.invalidities.obj.map(msg => (
              <ul>
                <li>{msg}</li>
              </ul>
            ))
          : ''
      }
    />
  </div>
);

EmailObjectLabel.propTypes = {
  title: PropTypes.string.isRequired,
  contentValidity: PropTypes.object.isRequired,
};

export default EmailObjectLabel;
