/* eslint-disable no-underscore-dangle */
import React, { Fragment, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/styles';
import {
  Switch,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Table,
  Fade,
  Typography,
  Tooltip,
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import CampaignListHead from './CampaignListHead';
import CampaignItemMenu from './CampaignItemMenu';
import CmpListDataResolver from './CmpListDataResolver';
import SMJEditorAppBar from '../SMJEditorAppBar';
import Search from 'src/components/common/filters/Search';
import { activateCampaign, getCsv, deleteCampaign } from 'src/reducers/campaignList';
import { editCampaign } from 'src/reducers/campaignEditor';
// import { mapRequestModelByRMTypeId } from 'src/reducers/requestModelList';
// import smjEditorUtils from '../utils';
import utils from 'src/utils/utils';
import appStyle from 'src/consts/appStyle';
import { filterFn, quickFilterFn } from 'src/components/common/filters/campaignFiltersUtils';

import filtrable from 'src/components/common/hocs/filtrable';

const pendingEl = () => <i>{utils.getLang('smartmessaging.campaignList.pendingElement')}</i>;

const SpanedTableCell = ({ children, style }) => (
  <TableCell style={style}>
    <span className="cellspan">{children}</span>
  </TableCell>
);
SpanedTableCell.propTypes = {
  children: PropTypes.any.isRequired,
  style: PropTypes.object.isRequired,
};

const CampaignItem = connect(() => ({}), {
  doDeleteCampaign: deleteCampaign,
  doEditCampaign: editCampaign,
  doActivateCampaign: activateCampaign,
  doGetCsv: getCsv,
})(
  ({
    campaign,
    doGetCsv,
    doEditCampaign,
    doActivateCampaign,
    doDeleteCampaign,
    selected,
    selectRow,
    goToSMJEditorStep,
    rmByRmTypeId,
    recipesById,
    smartjourney,
    // updateList,
    updateCmp,
    removeCmp,
    groupIsWritable,
  }) => (
    <TableRow
      hover
      selected={selected}
      onClick={e => {
        e.preventDefault();
        selectRow(campaign.id);
      }}
      onDoubleClick={e => {
        e.preventDefault();
        const asyncEdit = async () => {
          await doEditCampaign(campaign);
          goToSMJEditorStep(1, { initialStep: 0, backToMain: () => goToSMJEditorStep(0) });
        };
        asyncEdit();
      }}
    >
      <SpanedTableCell style={{ color: appStyle.txtColor3 }}>
        <CampaignItemMenu
          options={[
            {
              name: utils.getLang('smartmessaging.campaignList.action.edit'),
              action: async () => {
                const asyncEdit = async () => {
                  await doEditCampaign(campaign);
                  goToSMJEditorStep(1, { initialStep: 0, backToMain: () => goToSMJEditorStep(0) });
                };
                asyncEdit();
              },
            },
            {
              name: utils.getLang('smartmessaging.campaignList.action.csv'),
              action: () => doGetCsv(campaign),
              disabled: !campaign.recipeId,
              hidden: campaign.eventDriven,
            },
            {
              name: utils.getLang('smartmessaging.campaignList.action.delete'),
              action: () => {
                doDeleteCampaign(campaign, { afterDelete: () => removeCmp(campaign) });
              },

              hidden: !groupIsWritable,
            },
            {
              name: utils.getLang('smartmessaging.campaignList.action.showHistory'),
              hidden: !campaign.requestModelTypeId,
              action: () =>
                goToSMJEditorStep(2, {
                  campaign,
                  rmId:
                    campaign.requestModelTypeId &&
                    rmByRmTypeId[campaign.requestModelTypeId] &&
                    rmByRmTypeId[campaign.requestModelTypeId].id,
                  backToMain: () =>
                    goToSMJEditorStep(0, {
                      smartjourney,
                    }),
                }),
            },
          ]}
          selectRow={selectRow}
          campaign={campaign}
          // getCsv={doGetCsv}
        />
      </SpanedTableCell>
      <TableCell scope="row" style={{ color: appStyle.txtColor3, fontWeight: 'bold' }}>
        <div style={{ display: 'flex' }}>
          {!!campaign.duplicates.length && (
            <div style={{ display: 'flex', alignItems: 'center', margin: '2px' }}>
              <Tooltip title={utils.getLang('smartmessaging.campaignAction.duplicateWarning')}>
                <WarningIcon style={{ color: appStyle.txtColor6 }} />
              </Tooltip>
            </div>
          )}
          <div style={{ display: 'flex', alignItems: 'center', margin: '2px' }}>
            {campaign.name}
          </div>
        </div>
      </TableCell>
      <SpanedTableCell style={{ color: appStyle.txtColor3 }}>
        {(campaign.recipeId &&
          rmByRmTypeId[recipesById[campaign.recipeId].requestModelTypeId] &&
          utils.getLang(
            `smartmessaging.requestmodel.label.${
              rmByRmTypeId[recipesById[campaign.recipeId].requestModelTypeId].name
            }`
          )) ||
          pendingEl()}
      </SpanedTableCell>
      <SpanedTableCell style={{ color: appStyle.txtColor3 }}>
        {(campaign.eventDriven === false && utils.getLang('smartmessaging.campaignTrigger.time')) ||
          (campaign.eventDriven === true &&
            utils.getLang('smartmessaging.campaignTrigger.action')) ||
          pendingEl() //
        }
      </SpanedTableCell>
      <SpanedTableCell style={{ color: appStyle.txtColor3 }}>
        {campaign.actionType ? campaign.actionType : pendingEl()}
      </SpanedTableCell>
      <SpanedTableCell style={{ color: appStyle.txtColor3 }}>
        {new Date(campaign.creationDate).toLocaleDateString()}
      </SpanedTableCell>
      {utils.isNodeMapped() && (
        <SpanedTableCell style={{ color: appStyle.txtColor3 }}>
          {campaign.networkNodeName || '--'}
        </SpanedTableCell>
      )}
      <SpanedTableCell style={{ color: appStyle.txtColor3 }}>
        {campaign.creationUserName || '--'}
      </SpanedTableCell>
      <SpanedTableCell style={{ color: appStyle.txtColor3 }}>
        {campaign.isPlanned
          ? utils.getLang('smartmessaging.campaignList.item.isPlanned')
          : utils.getLang('smartmessaging.campaignList.item.isNotPlanned')}
      </SpanedTableCell>
      <SpanedTableCell padding="checkbox" style={{ color: appStyle.txtColor3 }}>
        <Switch
          value={campaign.enabled ? '1' : '0'}
          checked={campaign.enabled}
          onChange={() => {
            // doActivateCampaign(campaign, updateList);
            doActivateCampaign(campaign, upToDateCmp => updateCmp(upToDateCmp));
          }}
        />
      </SpanedTableCell>
    </TableRow>
  )
);

CampaignItem.propTypes = {
  campaign: PropTypes.object.isRequired,
  // requestModelByRMTypeId: PropTypes.object,
  recipesById: PropTypes.object,
  classes: PropTypes.object,
  selected: PropTypes.bool.isRequired,
  selectRow: PropTypes.func.isRequired,
};

const styles = theme => ({
  root: {
    width: '100%',
  },
  tableWrapper: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  campaignContainer: {
    // position: 'relative',
    // margin: '4px',
    // marginTop: '24px',
    overflow: 'auto',
  },
  editorBtn: {
    zIndex: 2,
    backgroundColor: appStyle.bgColor1,
    color: appStyle.txtColor2,
    '&:hover': {
      background: appStyle.bgColor1,
      opacity: 0.9,
    },
    position: 'absolute',
    right: '10px',
    top: '40px',
  },
});

function CampaignList({
  classes,
  // order,
  // orderBy,
  goToSMJEditorStep,
  filteredData,
  doFilter,
  addFilter,
  // requestModelByRMTypeId,
  recipesById,
  removeFilter,
  clearFilters,
  selectedFilters,
  // rmId,
  rmByRmTypeId,
  smartjourney,
  // update,
  updateCmp,
  removeCmp,
  groupIsWritable,
}) {
  const [selected, setSelected] = useState(null);
  const [quickFilter, setQuickFilter] = useState('');
  const [sort, setSort] = useState({ order: 'asc', orderBy: 'creationDate', sortFns: false });

  const selectRow = useCallback(id => {
    setSelected(id);
  }, []);

  const updateQuickFilter = useCallback(value => {
    setQuickFilter(value);
  }, []);

  function quickFiltered() {
    const quickFilters = [
      { key: 'name', value: quickFilter },
      {
        key: 'recipeId',
        value: quickFilter,
        type: 'TEXT',
        convertValue: recipeId =>
          (recipeId &&
            rmByRmTypeId[recipesById[recipeId].requestModelTypeId] &&
            utils.getLang(
              `smartmessaging.requestmodel.label.${
                rmByRmTypeId[recipesById[recipeId].requestModelTypeId].name
              }`
            )) ||
          utils.getLang('smartmessaging.campaignList.pendingElement'),
      },
    ];
    if (quickFilter) {
      return filteredData.filter(quickFilterFn(quickFilters));
    }
    return filteredData;
  }
  function getSortedCampaigns() {
    if (!sort.sortFns) {
      return sort.order === 'asc'
        ? quickFiltered().sort((a, b) => (a[sort.orderBy] < b[sort.orderBy] ? -1 : 1))
        : quickFiltered().sort((a, b) => (a[sort.orderBy] < b[sort.orderBy] ? 1 : -1));
    }

    return sort.order === 'asc'
      ? quickFiltered().sort(sort.sortFns.asc)
      : quickFiltered().sort(sort.sortFns.desc);
  }

  const sortCampaigns = useCallback(
    (orderBy, sortFns) => {
      setSort({
        order: orderBy === sort.orderBy && sort.order === 'desc' ? 'asc' : 'desc',
        orderBy,
        sortFns,
      });
    },
    [sort]
  );
  return (
    <Fragment>
      <Fade in timeout={1000}>
        <div style={{ textAlign: 'left', margin: '8px' }}>
          <Search
            updateQuickFilter={updateQuickFilter}
            filterProps={{
              filteredData,
              doFilter,
              addFilter,
              removeFilter,
              clearFilters,
              selectedFilters,
            }}
          />
        </div>
      </Fade>
      <Fade in timeout={1000}>
        <div className={`${classes.campaignContainer}`}>
          <Paper className={classes.root} elevation={0}>
            <div className={classes.tableWrapper}>
              <Table aria-labelledby="tableTitle">
                <CampaignListHead
                  order={sort.order}
                  orderBy={sort.orderBy}
                  onRequestSort={sortCampaigns}
                  addFilter={addFilter}
                />
                <TransitionGroup component={TableBody}>
                  {getSortedCampaigns().map(campaign => (
                    <CSSTransition timeout={300} classNames="fade" key={campaign.id}>
                      <CampaignItem
                        campaign={campaign}
                        selected={selected === campaign.id}
                        selectRow={selectRow}
                        goToSMJEditorStep={goToSMJEditorStep}
                        recipesById={recipesById}
                        rmByRmTypeId={rmByRmTypeId}
                        smartjourney={smartjourney}
                        // updateList={update}
                        updateCmp={updateCmp}
                        removeCmp={removeCmp}
                        groupIsWritable={groupIsWritable}
                      />
                    </CSSTransition>
                  ))}
                </TransitionGroup>
              </Table>
            </div>
          </Paper>
        </div>
      </Fade>
    </Fragment>
  );
}

CampaignList.propTypes = {
  classes: PropTypes.object.isRequired,
  rmByRmTypeId: PropTypes.object.isRequired,
  smartjourney: PropTypes.object.isRequired,
  updateCmp: PropTypes.func.isRequired,
  removeCmp: PropTypes.func.isRequired,
  groupIsWritable: PropTypes.bool.isRequired,
  filteredData: PropTypes.array.isRequired,
  goToSMJEditorStep: PropTypes.func.isRequired,
  doFilter: PropTypes.func.isRequired,
  addFilter: PropTypes.func.isRequired,
  recipesById: PropTypes.object.isRequired,
  removeFilter: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  selectedFilters: PropTypes.array.isRequired,
};

const FiltrableCampaignList = filtrable(props => ({
  filterFn,
  filtrableData: props.data,
}))(CampaignList);

const mapStateToProps = state => ({
  recipesById: state.recipeList.recipeListById,
  campaignGroups: state.smartjourney.smjListsByModel,
});

const actionCreators = {
  updateSmjList: list => ({ type: 'RECEIVE_SMJ_LIST', value: list }),
};

const DataReceiver = ({ data, setData, ...rest }) => (
  <div
    style={{
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      overflow: 'auto',
      textAlign: 'left',
      // margin: '8px',
      // margin: '24px 1% 8px',
      // padding: '24px',
      // height: '100%',
    }}
  >
    {data.length ? (
      <FiltrableCampaignList
        // data={Object.values(data).reduce((fl, l) => [...fl, ...l], [])} //degroup from map by rmtypeid????
        data={data}
        updateCmp={upToDateCmp => {
          setData(data.map(d => (d.id === upToDateCmp.id ? { ...d, ...upToDateCmp } : d)));
        }}
        removeCmp={cmpToRemove => {
          setData(data.filter(d => d.id !== cmpToRemove.id));
        }}
        {...rest}
      />
    ) : (
      <div
        style={{
          flex: 1,
          textAlign: 'center',
          paddingTop: '24px',
          backgroundColor: appStyle.bgColor4,
        }}
      >
        <Typography
          variant="h6"
          style={{
            color: appStyle.txtColor3,
            // margin: 'auto 24px',
            // textAlign: 'center',
          }}
        >
          {utils.getLang('smartmessaging.campaigns.grouped.list.emptyGroup')}
        </Typography>
      </div>
    )}
  </div>
);

DataReceiver.propTypes = {
  data: PropTypes.array,
  setData: PropTypes.func,
};
DataReceiver.defaultProps = {
  data: [],
  setData: () => {},
};

const WithData = props => (
  <Fragment>
    <SMJEditorAppBar
      groupIsWritable={props.groupIsWritable}
      addCampaign={props.createCampaign}
      smj={props.smartjourney}
      updateSmjName={name => {
        props.updateSmjName(name, renamed => {
          props.updateSmjList(
            props.campaignGroups.map(cg => (cg.id === renamed.id ? renamed : cg))
          );
        });
      }}
      // title={smjEditorUtils.getAppBarTitle(props.editorCurrentStep, props.smartjourney)}
    />

    <CmpListDataResolver {...props}>
      <DataReceiver />
    </CmpListDataResolver>
  </Fragment>
);

WithData.propTypes = {
  groupIsWritable: PropTypes.bool.isRequired,
  createCampaign: PropTypes.func.isRequired,
  smartjourney: PropTypes.object.isRequired,
  updateSmjList: PropTypes.func.isRequired,
  updateSmjName: PropTypes.func.isRequired,
  campaignGroups: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, actionCreators)(withStyles(styles)(WithData));
