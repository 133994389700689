import React from 'react';
import PropTypes from 'prop-types';
import utils from 'src/utils/utils';
import smjCreatorUtils from './utils';

import SMJAvailableCmpList from './SMJAvailableCmpList';
import SMJSelectedCmpList from './SMJSelectedCmpList';

import Card from 'src/components/common/Card';
import SMJCmpSelectorHelper from './SMJCmpSelectorHelper';
import SMJModelItem from './SMJModelItem';

import ActionBtn from 'src/components/common/ActionBtn';

// eslint-disable-next-line react/prop-types
const Ctn = ({ children, flex }) => (
  <div
    style={{
      display: 'flex',
      flex: flex || 1,
      margin: '8px 8px 8px 8px',
      flexDirection: 'column',
      padding: '0',
      overflow: 'hidden',
      position: 'relative',
      // margin: '24px 15% 8px',
    }}
  >
    {children}
  </div>
);

const GroupCreateCmpSelector = ({
  toSMJConfig,
  onBack,
  rmByRmTypeId,
  newGroupSetup,
  onSelect,
  getSelectedCmps,
}) => {
  function getMappedSelection() {
    return smjCreatorUtils.mapSMJCmpModelMByType(getSelectedCmps());
  }
  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        padding: '8px 8px 8px 8px',
        flexDirection: 'column',
        // padding: '16px 24px',
        overflow: 'hidden',
      }}
    >
      <SMJCmpSelectorHelper />
      <div
        style={{
          display: 'flex',
          flex: 1,
          // padding: '8px 8px 8px 8px',
          // flexDirection: 'column',
          // padding: '16px 24px',
          overflow: 'hidden',
        }}
      >
        <Ctn flex="2">
          <Card
            title={utils.getLang('smartmessaging.smartjourney.creator.availaibleCampaigns')}
            rootStyle={{ display: 'flex', flex: 1, flexDirection: 'column', overflow: 'hidden' }}
            contentStyle={{ display: 'flex', flex: 1, flexDirection: 'column', overflow: 'auto' }}
          >
            <SMJAvailableCmpList
              campaigns={smjCreatorUtils.mapSMJCmpModelMByType(newGroupSetup.groupModel.campaigns)}
              onSelect={onSelect}
              selection={newGroupSetup.campaignSelection}
              rmByRmTypeId={rmByRmTypeId}
            />
          </Card>
        </Ctn>
        <Ctn flex="3">
          <Card title={utils.getLang('smartmessaging.smartjourney.creator.selectedSmjModel')}>
            <SMJModelItem disabledSelect smjModel={newGroupSetup.groupModel} />
          </Card>
          <Card
            rootStyle={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
            contentStyle={{ overflow: 'auto' }}
            title={utils.getLang('smartmessaging.smartjourney.creator.selectedCampaigns')}
          >
            <SMJSelectedCmpList selection={getMappedSelection()} rmByRmTypeId={rmByRmTypeId} />
          </Card>
        </Ctn>
      </div>
      <div style={{ textAlign: 'right' }}>
        <ActionBtn
          cVariant="reverse"
          style={{ margin: '2px' }}
          onClick={e => {
            e.preventDefault();
            onBack();
          }}
        >
          {utils.getLang('smartmessaging.buttonLabel.previous')}
        </ActionBtn>
        <ActionBtn
          style={{ margin: '2px' }}
          disabled={!newGroupSetup.campaignSelection.length}
          onClick={e => {
            e.preventDefault();
            toSMJConfig();
          }}
        >
          {utils.getLang('smartmessaging.buttonLabel.next')}
        </ActionBtn>
      </div>
    </div>
  );
};

GroupCreateCmpSelector.propTypes = {
  toSMJConfig: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  rmByRmTypeId: PropTypes.object.isRequired,
  newGroupSetup: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  getSelectedCmps: PropTypes.func.isRequired,
};

export default GroupCreateCmpSelector;
