import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
// import uuid from 'uuid';
import { Table, Fade, Toolbar, Typography, AppBar, IconButton, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import VisibleIcon from '@material-ui/icons/Visibility';
import HiddenIcon from '@material-ui/icons/VisibilityOff';
import Search from 'src/components/common/filters/Search';
import MassActionListTableBody from './MassActionListTableBody';

import appStyle from 'src/consts/appStyle';

import { editMassAction, enableAction, checkEmptyFieldSelection } from 'src/reducers/massAction';
import utils from 'src/utils/utils';
import Headers from './Headers';

import filtrable from 'src/components/common/hocs/filtrable';
import { filterFn, quickFilterFn } from 'src/components/common/filters/campaignFiltersUtils';

import columnConfig from './utils';

const styles = theme => ({
  createBtn: {
    zIndex: 1,
    backgroundColor: appStyle.bgColor1,
    color: appStyle.txtColor2,
    '&:hover': {
      background: appStyle.bgColor1,
      opacity: 0.9,
    },
  },
  appBar: {
    position: 'relative',
    backgroundColor: appStyle.bgColor1,
  },
  flex: { flex: 1 },
  search: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  tableWrapper: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    overflow: 'auto',
    flex: 1,
  },
});

const MassActionRequestList = ({
  classes,
  goToStep,
  // massActions,this list will be filtered and retrieved as filteredData
  doCheckEmptyFieldSelection,
  doEditMassAction,
  clearEditedData,
  doEnableAction,
  filteredData,
  doFilter,
  addFilter,
  removeFilter,
  clearFilters,
  selectedFilters,
}) => {
  const [sortConfig, setSortConfig] = useState({
    orderBy: 'creationDate',
    order: 'desc',
  });
  const [showMasked, setShowMasked] = useState(false);

  const [quickFilter, setQuickFilter] = useState('');

  const { columnNames, columnsSorters } = columnConfig;

  const updateQuickFilter = useCallback(
    filterValue => {
      setQuickFilter(filterValue);
    },
    [setQuickFilter]
  );

  function editAction(massAction) {
    goToStep(1); // enchainement pas logique mais sinon proble de valeurs reinitialisées avec le useState...
    doEditMassAction(massAction);
  }

  async function executeAction(massAction) {
    await doEditMassAction(massAction).then(() => {
      doCheckEmptyFieldSelection(() => goToStep(3), true);
    });
  }

  function showHistory(massAction) {
    goToStep(5, {
      campaign: massAction,
      backToMain: () => goToStep(0),
    });
  }

  const doSort = useCallback(
    column => {
      let order = 'asc';
      if (sortConfig.orderBy === column) {
        order = sortConfig.order === 'asc' ? 'desc' : 'asc';
      }
      setSortConfig({ order, orderBy: column });
    },
    [sortConfig]
  );

  function doFilterMasked() {
    if (showMasked) return filteredData;
    return filteredData.filter(ma => ma.enabled);
  }

  const getOptions = useCallback(
    ma => ['edit', 'execute', (ma.enabled && 'disable') || 'enable', 'showHistory'],
    []
  );

  function quickFiltered() {
    if (quickFilter) {
      const quickFilters = [{ key: 'name', value: quickFilter }];
      return doFilterMasked().filter(quickFilterFn(quickFilters));
    }
    return doFilterMasked();

    // if (quickFilterValue) return filteredData;
    // // const quickFitrableColumns = ['name'];
    // return filteredData.filter(filterFn({ key: 'name', value }));
  }

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflow: 'hidden',
        textAlign: 'left',
        height: '100%',
      }}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h5" color="inherit">
            {utils.getLang(`smartmessaging.massAction.massActionList.title`)}
          </Typography>
          <div
            style={{
              flex: 1,
              marginLeft: '16px',
            }}
          >
            <Tooltip
              title={
                (!showMasked && utils.getLang('smartmessaging.tooltip.showDisabledCampaigns')) ||
                utils.getLang('smartmessaging.tooltip.hideDisabledCampaigns')
              }
            >
              <IconButton
                aria-label="clear"
                onClick={e => {
                  e.preventDefault();
                  setShowMasked(!showMasked);
                }}
                style={{ color: appStyle.txtColor2 }}
              >
                {showMasked && <VisibleIcon />}
                {!showMasked && <HiddenIcon />}
              </IconButton>
            </Tooltip>
          </div>
          <Tooltip title={utils.getLang('smartmessaging.tooltip.massAction.createRequest')}>
            <IconButton
              aria-label="clear"
              onClick={e => {
                e.preventDefault();
                clearEditedData();
                goToStep(1);
              }}
              style={{ color: appStyle.txtColor1, backgroundColor: appStyle.bgColor10 }}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Fade in timeout={500}>
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            overflow: 'hidden',
            textAlign: 'left',
            // height: '100%',
          }}
        >
          <div style={{ margin: '8px' }}>
            <Search
              updateQuickFilter={updateQuickFilter}
              filterProps={{
                filteredData,
                doFilter,
                addFilter,
                removeFilter,
                clearFilters,
                selectedFilters,
              }}
            />
          </div>
          <div className={`${classes.tableWrapper}`}>
            <Table>
              <Headers
                columnNames={columnNames.filter(n =>
                  utils.isNodeMapped() ? true : ['networkNodeName'].indexOf(n) === -1
                )}
                sortConfig={sortConfig}
                doSort={doSort}
                addFilter={addFilter}
              />
              <MassActionListTableBody
                massActionList={[...quickFiltered()].sort(
                  columnsSorters[sortConfig.orderBy][sortConfig.order]
                )}
                getOptions={getOptions}
                actions={{
                  edit: editAction,
                  execute: executeAction,
                  enable: doEnableAction,
                  disable: doEnableAction,
                  showHistory,
                }}
              />
            </Table>
          </div>
        </div>
      </Fade>
    </div>
  );
};

MassActionRequestList.propTypes = {
  classes: PropTypes.object.isRequired,
  goToStep: PropTypes.func.isRequired,
  doEditMassAction: PropTypes.func.isRequired,
  clearEditedData: PropTypes.func.isRequired,
  doEnableAction: PropTypes.func.isRequired,
  filteredData: PropTypes.array.isRequired,
  doFilter: PropTypes.func.isRequired,
  addFilter: PropTypes.func.isRequired,
  removeFilter: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  selectedFilters: PropTypes.array.isRequired,
  doCheckEmptyFieldSelection: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  massActions: state.massAction.massActions,
});

const actionCreators = {
  doEditMassAction: editMassAction,
  doCheckEmptyFieldSelection: checkEmptyFieldSelection,
  clearEditedData: () => ({
    type: 'CLEAR_EDITED_DATA',
  }),
  doEnableAction: enableAction,
};

const filterCfg = props => ({
  filterFn,
  filtrableData: props.massActions,
});

const FiltrableList = filtrable(filterCfg)(MassActionRequestList);

export default connect(mapStateToProps, actionCreators)(withStyles(styles)(FiltrableList));
