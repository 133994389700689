import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

import SelectSMJField from './fields/SelectSMJField';

const SMJConfigField = ({ classes, descriptor, value, onChange, onOperatorChange, validity }) => {
  switch (descriptor.type) {
    case 'CLUBS':
      return (
        <SelectSMJField
          validity={validity}
          selectAll={options => options.reduce((r, opts) => [...r, ...opts.options], [])}
          getValue={vals => vals}
          value={value || {}}
          classes={classes}
          descriptor={descriptor}
          mapValue={values => values}
          onChange={v => {
            onChange(v, true);
          }}
          onOperatorChange={onOperatorChange}
        />
      ); // /////////////

    case 'DATE':
    case 'FOREIGN_KEY':
    case 'ENUM':
    case 'INTEGER':
    case 'NUMBER':
    case 'TEXT':
    default:
      break;
  }
  return (
    <Fragment>
      <div>FIELD ERROR {descriptor.type}</div>
    </Fragment>
  );
};

SMJConfigField.propTypes = {
  classes: PropTypes.object.isRequired,
  descriptor: PropTypes.object.isRequired,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onOperatorChange: PropTypes.func.isRequired,
  validity: PropTypes.object.isRequired,
};

SMJConfigField.defaultProps = {};

const styles = () => ({
  fieldContainer: {
    display: 'flex',
    // marginTop: '8px',
    // marginBottom: '8px',
    // marginLeft: '20px',
    //  marginRight: '20px',
  },
});

export default withStyles(styles)(SMJConfigField);
