import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
// import uuid from 'uuid';
import { Toolbar, Typography, AppBar } from '@material-ui/core';

import appStyle from 'src/consts/appStyle';

const styles = theme => ({
  createBtn: {
    zIndex: 1,
    backgroundColor: appStyle.bgColor1,
    color: appStyle.txtColor2,
    '&:hover': {
      background: appStyle.bgColor1,
      opacity: 0.9,
    },
  },
  appBar: {
    position: 'relative',
    backgroundColor: appStyle.bgColor1,
  },
  flex: { flex: 1 },
  search: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  tableWrapper: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    overflow: 'auto',
    flex: 1,
  },
});

const SMJCreatorAppBar = ({ classes, title }) => (
  <AppBar className={classes.appBar}>
    <Toolbar>
      <Typography variant="h5" color="inherit">
        {/* {utils.getLang(`smartmessaging.smartjourneyList.title`)} */}
        {title}
      </Typography>
    </Toolbar>
  </AppBar>
);

SMJCreatorAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

export default withStyles(styles)(SMJCreatorAppBar);
