import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Accordion as ExpansionPanel,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@material-ui/core';

import appStyle from 'src/consts/appStyle';

const AccordionItem = ({ expanded, classes, title, onChange, children }) => (
  <ExpansionPanel elevation={0} expanded={expanded} onChange={onChange}>
    <AccordionSummary
      className={classes.expansionSummary}
      expandIcon={<ExpandMoreIcon className={classes.summary} />}
      classes={{ expanded: classes.expandedSummary }}
    >
      <Typography className={classes.summaryTypo}>{title}</Typography>
    </AccordionSummary>
    <AccordionDetails className={classes.expansionPanelDetail}>{children}</AccordionDetails>
  </ExpansionPanel>
);

AccordionItem.propTypes = {
  expanded: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

AccordionItem.defaultProps = {};

const styles = () => ({
  expansionPanelDetail: { display: 'block' },
  expansionSummary: {
    borderBottom: appStyle.border1,
  },
  expandedSummary: {
    backgroundColor: appStyle.bgColor2,
  },
  summary: { color: appStyle.txtColor3 },
  summaryTypo: { color: appStyle.txtColor3 },
});

export default withStyles(styles)(AccordionItem);
