import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';

import AccountCircle from '@material-ui/icons/AccountCircle';
import { Drawer, Divider } from '@material-ui/core';
import AppInfos from '../common/AppInfos';

import LangButton from '../common/LangButton';
import MenuSelect from './MenuSelect';
import AppStyle from 'src/consts/appStyle';
import { loadLanguage } from 'src/reducers/app';
import utils from 'src/utils/utils';

const styles = theme => ({
  drawerPaper: {
    position: 'relative',
    background: '#fafafa',
    display: 'flex',
  },
  listItem: { color: AppStyle.txtColor3 },
  toolbar: theme.mixins.toolbar,
  logo: {
    height: '50px',
    margin: theme.spacing(1),
  },
});

const MainMenu = ({
  classes,
  userData,
  availableLanguages,
  currentLanguage,
  changeLanguage,
  rerender,
}) => (
  <Drawer variant="permanent" classes={{ paper: classes.drawerPaper }}>
    <div>
      <img src="/resources/img/logo.png" className={classes.logo} alt="logo" />
    </div>
    <div style={{ flex: 1, flexDirection: 'column', display: 'flex' }}>
      <MenuSelect />
      <Divider />
    </div>
    <AppInfos />
    <div
      style={{
        // position: 'absolute',
        display: 'flex',
        // bottom: '24px',
        // width: '100%',
        maxWidth: '100%',
        color: AppStyle.txtColor3,
        padding: '4px 0px 4px 8px',
        justifyContent: 'center',
        outline: AppStyle.border1,
        overflow: 'hidden',
        wordBreak: 'break-word',
      }}
    >
      <div
        style={{
          color: AppStyle.txtColor1,
          justifyContent: 'center',
          flexDirection: 'column',
          display: 'flex',
        }}
      >
        <AccountCircle />
      </div>
      <div
        style={{
          padding: '0 4px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          textOverflow: 'ellipsis',
        }}
      >
        {`${userData.familyName} ${userData.givenName}`}
      </div>
      <div
        style={{
          flex: 1,
          textAlign: 'right',
          // color: AppStyle.txtColor1,
        }}
      >
        <LangButton
          title={utils.getLang('smartmessaging.tooltip.mainLangButton')}
          langs={availableLanguages}
          currentLang={currentLanguage}
          selectLang={lang => {
            changeLanguage(lang, rerender);
          }}
        />
      </div>
    </div>
    {
      //   <List component="nav">
      //   <ListItem button>
      //     <ListItemIcon className={classes.listItem}>
      //       <DraftsIcon />
      //     </ListItemIcon>
      //     <ListItemText disableTypography primary="Crédit SMS : 1023" className={classes.listItem} />
      //   </ListItem>
      // </List>
    }
  </Drawer>
);
const mapStateToProps = state => ({
  userData: state.auth.userData,
  currentLanguage: state.app.currentLanguage,
  availableLanguages: state.app.availableLanguages,
});
const actionCreators = {
  changeLanguage: loadLanguage,
};
//

MainMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  availableLanguages: PropTypes.array.isRequired,
  currentLanguage: PropTypes.object.isRequired,
  changeLanguage: PropTypes.func.isRequired,
  rerender: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, actionCreators)(withStyles(styles)(MainMenu));
