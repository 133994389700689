import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Fab,
} from '@material-ui/core';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddIcon from '@material-ui/icons/Add';

import { connect } from 'react-redux';

import withDataResolver from '../common/withDataResolver';
import { loadCustomTemplates, editTemplate } from 'src/reducers/customTemplates';

import appStyle from 'src/consts/appStyle';
import utils from 'src/utils/utils';
import rmSelectors from 'src/selectors/requestModel';

const menuStyles = {
  menuItem: {
    color: appStyle.txtColor3,
    display: 'flex',
    fontSize: '0.98rem',
    outline: 'none',
    cursor: 'pointer',
    minHeight: 'unset',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:hover': {
      background: appStyle.bgColor1,
      opacity: 0.9,
      color: appStyle.txtColor2,
    },
  },
};

function CustomTemplateListItemMenu({ doEditTemplate, classes }) {
  const [anchorEl, setAnchorEl] = useState(null);

  function handleClose() {
    setAnchorEl(null);
  }

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  return (
    <Fragment>
      <IconButton
        style={{ padding: '4px' }}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          handleClick(e);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        PaperProps={{
          style: {
            minWidth: 150,
            borderRadius: 0,
          },
        }}
        MenuListProps={{ style: { padding: 0 } }}
        value
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={e => {
          e.preventDefault();
          e.stopPropagation();
          handleClose();
        }}
      >
        <MenuItem
          className={classes.menuItem}
          onClick={e => {
            e.preventDefault();
            handleClose();
            doEditTemplate();
          }}
        >
          {utils.getLang(`smartmessaging.customTemplateList.editTemplate`)}
        </MenuItem>
      </Menu>
    </Fragment>
  );
}

CustomTemplateListItemMenu.propTypes = {
  doEditTemplate: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

const StyledMenuItem = withStyles(menuStyles)(CustomTemplateListItemMenu);

function CustomTemplateListRender({ doEditTemplate, customTemplatesMap, getRmByRmTypeId }) {
  const [selected, setSelected] = useState(null);
  return (
    <Fragment>
      <div>
        <Fab
          onClick={e => {
            e.preventDefault();
            doEditTemplate(false);
          }}
          aria-label="add"
          style={{
            position: 'absolute',
            right: '10px',
            top: '40px',
            zIndex: 1,
            backgroundColor: appStyle.bgColor1,
            color: appStyle.txtColor2,
            '&:hover': {
              background: appStyle.bgColor1,
              opacity: 0.9,
            },
          }}
        >
          <AddIcon />
        </Fab>
      </div>
      <div
        style={{
          position: 'relative',
          overflow: 'auto',
          margin: '8px',
          marginTop: '24px',
          flex: 1,
        }}
      >
        <div
          style={{
            // overflow: 'auto',
            // flex: 1,
            padding: '2px 4px',
          }}
        >
          <Table style={{ tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    color: appStyle.txtColor1,
                    padding: '4px',
                    position: 'sticky',
                    top: 0,
                    background: '#ffffff',
                  }}
                >
                  {utils.getLang(`smartmessaging.customTemplateList.header.name`)}
                </TableCell>
                <TableCell
                  style={{
                    color: appStyle.txtColor1,
                    padding: '4px',
                    position: 'sticky',
                    top: 0,
                    background: '#ffffff',
                  }}
                >
                  {utils.getLang(`smartmessaging.customTemplateList.header.description`)}
                </TableCell>
                <TableCell
                  style={{
                    color: appStyle.txtColor1,
                    padding: '4px',
                    position: 'sticky',
                    top: 0,
                    background: '#ffffff',
                  }}
                >
                  {utils.getLang(`smartmessaging.customTemplateList.header.actionType`)}
                </TableCell>
                <TableCell
                  style={{
                    color: appStyle.txtColor1,
                    padding: '4px',
                    position: 'sticky',
                    top: 0,
                    background: '#ffffff',
                  }}
                >
                  {utils.getLang(`smartmessaging.customTemplateList.header.model`)}
                </TableCell>
                <TableCell
                  style={{
                    position: 'sticky',
                    top: 0,
                    background: '#ffffff',
                  }}
                />
              </TableRow>
            </TableHead>

            <TableBody>
              {Object.entries(customTemplatesMap).map(([uniqueId, customTemplate]) => (
                <TableRow
                  key={uniqueId}
                  hover
                  onClick={e => {
                    e.preventDefault();
                    setSelected(uniqueId);
                  }}
                  onDoubleClick={e => {
                    e.preventDefault();
                    setSelected(uniqueId);
                    doEditTemplate(uniqueId);
                  }}
                  selected={selected === uniqueId}
                >
                  <TableCell style={{ padding: '4px', color: appStyle.txtColor3 }}>
                    {customTemplate.name}
                  </TableCell>
                  <TableCell style={{ padding: '4px', color: appStyle.txtColor3 }}>
                    {customTemplate.description}
                  </TableCell>
                  <TableCell style={{ padding: '4px', color: appStyle.txtColor3 }}>
                    {(customTemplate.actionTypeId === 1 &&
                      utils.getLang('smartmessaging.contentEditor.smsActionName')) ||
                      (customTemplate.actionTypeId === 2 &&
                        utils.getLang('smartmessaging.contentEditor.emailActionName'))}
                  </TableCell>
                  <TableCell style={{ padding: '4px', color: appStyle.txtColor3 }}>
                    {!!customTemplate.requestModelTypeId &&
                      utils.getLang(
                        `smartmessaging.requestmodel.label.${
                          getRmByRmTypeId(customTemplate.requestModelTypeId).name
                        }`
                      )}
                  </TableCell>

                  <TableCell>
                    <StyledMenuItem
                      doEditTemplate={() => {
                        setSelected(uniqueId);
                        doEditTemplate(uniqueId);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </Fragment>
  );
}

CustomTemplateListRender.propTypes = {
  customTemplatesMap: PropTypes.object.isRequired,
  doEditTemplate: PropTypes.func.isRequired,
  getRmByRmTypeId: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  customTemplatesMap: state.customTemplates.customTemplateMap,
  getRmByRmTypeId: rmTypeId => rmSelectors.getRmByRmTypeId(state, rmTypeId),
});

const actionCreators = {
  doEditTemplate: editTemplate,
  doLoadCustomTemplates: loadCustomTemplates,
};

const resolvers = {
  resolve: props => async () => {
    await props.doLoadCustomTemplates();
  },

  onResolved: () => async () => {},
  getLoaderText: () => utils.getLang('smartmessaging.customTemplates.loadingTemplates'),
};

export default connect(
  mapStateToProps,
  actionCreators
)(withDataResolver(resolvers)(CustomTemplateListRender));
