import React from 'react';
import PropTypes from 'prop-types';
import { Typography, CardContent, Card } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import AppStyle from 'src/consts/appStyle';

const HelperCardRender = ({ title, content, classes }) => (
  <Card elevation={1} className={classes.card}>
    <CardContent className={classes.content} classes={{ root: classes.contentRoot }}>
      {title && (
        <Typography className={classes.title} variant="body2">
          {title}
        </Typography>
      )}
      {content && (
        <Typography className={classes.description} variant="caption">
          {content}
        </Typography>
      )}
    </CardContent>
  </Card>
);

HelperCardRender.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

HelperCardRender.defaultProps = {};

const HelperCard = withStyles(theme => ({
  content: {
    '&.selectable': {
      cursor: 'pointer',
      opacity: 0.7,
      '&:hover': {
        opacity: 1,
      },
    },
    flex: 1,
  },
  contentRoot: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: `${theme.spacing(2)}px !important`,
  },
  card: {
    textAlign: 'left',
    marginBottom: theme.spacing(1),
    border: AppStyle.border1,
    // backgroundColor: AppStyle.bgColor2,
    // flex: 1,
  },
  title: {
    color: AppStyle.txtColor4,
    margin: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: theme.spacing(1),
  },
  description: {
    color: AppStyle.txtColor5,
    margin: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))(HelperCardRender);

export default HelperCard;
