import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

// import MenuItem from 'material-ui/Menu/MenuItem';
import { withStyles } from '@material-ui/styles';
import appStyle from 'src/consts/appStyle';

const styles = {
  menuItem: {
    color: appStyle.txtColor3,
    display: 'flex',
    fontSize: '0.98rem',
    outline: 'none',
    cursor: 'pointer',
    padding: '4px',
    minHeight: 'unset',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '240px',
    '&:hover': {
      background: appStyle.bgColor1,
      opacity: 0.9,
      color: appStyle.txtColor2,
    },
  },
};

const CampaignItemMenu = ({ campaign, options, classes, selectRow }) => {
  const [anchorEl, setAnchor] = useState(null);

  function onItemClick(option) {
    setAnchor(null);
    option.action();
  }

  function handleClose() {
    setAnchor(null);
  }
  function handleClick(event) {
    setAnchor(event.currentTarget);
  }

  return (
    <div>
      <IconButton
        aria-label="More"
        aria-owns={anchorEl ? `item-menu-${campaign.id}` : null}
        aria-haspopup="true"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          selectRow(campaign.id);
          handleClick(e);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`item-menu-${campaign.id}`}
        value
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={e => {
          e.preventDefault();
          e.stopPropagation();
          selectRow(null);
          handleClose();
        }}
        PaperProps={{
          style: {
            minWidth: 150,
          },
        }}
      >
        <MenuItem className={classes.menuItem} disabled component="div">
          {campaign.name}
        </MenuItem>
        {options.map(
          option =>
            !option.hidden && (
              <MenuItem
                className={classes.menuItem}
                disabled={(!(option.disabled === undefined) && option.disabled) || false}
                key={option.name}
                onClick={() => onItemClick(option)}
              >
                {option.name}
              </MenuItem>
            )
        )}
      </Menu>
    </div>
  );
};

CampaignItemMenu.propTypes = {
  campaign: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  selectRow: PropTypes.func.isRequired,
};

export default withStyles(styles)(CampaignItemMenu);
