/* eslint-disable react/prop-types */
import React, { useState, useMemo, useCallback } from 'react';
import withDataResolver from '../withDataResolver';
import withApiCaller from './withApiCaller';
import apiClient from 'src/utils/apiClient';
import utils from 'src/utils/utils';
import withAlertAndLoader from './withAlertAndLoader';

const getDefaultValue = key => utils.getLang(`smartmessaging.resultfield.${key}.sample`);

const validationsByType = {
  INTEGER(value) {
    let isValid = true;
    const messages = [utils.getLang('smartmessaging.fieldValidity.mandatory')];
    isValid = !!value;
    isValid = isValid && !Number.isNaN(window.parseInt(value));
    return { isValid, messages };
  },
  FLOAT(n) {
    let isValid = true;
    const messages = [utils.getLang('smartmessaging.fieldValidity.mandatory')];
    isValid = Number(n).toString() === n.toString();
    return { isValid, messages };
  },
  NUMBER(n) {
    let isValid = true;
    const messages = [utils.getLang('smartmessaging.fieldValidity.mandatory')];
    isValid = Number(n).toString() === n.toString();
    return { isValid, messages };
  },
  LINK(value) {
    let isValid = true;
    const messages = [utils.getLang('smartmessaging.fieldValidity.mandatory')];
    isValid = !!value;
    return { isValid, messages };
  },
  PHONENUMBER(value) {
    let isValid = true;
    const messages = [utils.getLang('smartmessaging.fieldValidity.mandatory')];
    isValid = !!value;
    return { isValid, messages };
  },
  STRING(value) {
    let isValid = true;
    const messages = [utils.getLang('smartmessaging.fieldValidity.mandatory')];
    isValid = !!value;

    return { isValid, messages };
  },
  EMAIL(value) {
    let isValid = true;
    const messages = [utils.getLang('smartmessaging.fieldValidity.mandatory')];
    isValid = !!value;
    if (isValid) {
      const re = /^\S+@(?!.*?\.\.)[A-Za-z0-9.-]+\.+[A-Za-z0-9]{2,}$/;

      const isMail = re.test(value);

      if (!isMail) messages.push(utils.getLang('smartmessaging.fieldValidity.incorrectEmail'));
      isValid = isValid && isMail;
    }
    return { isValid, messages };
  },
};

const getValidity = (type, v) => validationsByType[type](v);

const getSenderValidity = (k, v, atId) => {
  if (k === 'clubId') return validationsByType.INTEGER(v);
  if (k === 'recipient')
    return atId === 1 ? validationsByType.PHONENUMBER(v) : validationsByType.EMAIL(v);
  return { isValid: false, messages: ['Sender Validation issue'] };
};

function WithTestFormController({
  UI,
  data,
  includeWizVille,
  campaignActionId,
  actionTypeId,
  callApi,
  ...others
}) {
  const [values, setValues] = useState(
    Object.keys(data.fieldsInfos)
      .filter(f => !!includeWizVille || f !== 'wizvilleentryLink')
      .reduce(
        (m, f) => ({
          ...m,
          [f]: {
            value: getDefaultValue(f),
            validity: getValidity(data.fieldsInfos[f], getDefaultValue(f)),
          },
        }),
        {}
      )
  );

  const [senderValues, updateSender] = useState({
    clubId: { value: null, validity: getSenderValidity('clubId', null) },
    recipient: { value: null, validity: getSenderValidity('recipient', null, actionTypeId) },
  });

  const notifyChange = useCallback(
    (k, v) => {
      if (Object.keys(senderValues).indexOf(k) !== -1) {
        updateSender(prev => ({
          ...prev,
          [k]: { value: v, validity: getSenderValidity(k, v, actionTypeId) },
        }));
      } else
        setValues(prev => ({
          ...prev,
          [k]: { value: v, validity: getValidity(data.fieldsInfos[k], v) },
        }));
    },
    [actionTypeId, data.fieldsInfos, senderValues]
  );

  const submit = useCallback(
    async callback => {
      await callApi(apiClient.testCampaignAction, [
        {
          ...Object.entries(senderValues).reduce((m, [k, v]) => ({ ...m, [k]: v.value }), {}),
          campaignActionId,
          mappedModel: Object.entries(values).reduce((m, [k, v]) => ({ ...m, [k]: v.value }), {}),
        },
      ]);
      if (callback) callback();
    },
    [callApi, campaignActionId, senderValues, values]
  );

  // function memoizeInput(inputRender, k) {
  //   return useMemo(inputRender, [values[k]]);
  // }

  // function memoizeSenderInput(inputRender, k) {
  //   return useMemo(inputRender, [senderValues[k]]);
  // }

  const formIsValid = useMemo(
    () =>
      !Object.values(senderValues).find(f => !f.validity.isValid) &&
      !Object.values(values).find(f => !f.validity.isValid),
    [values, senderValues]
  );

  // function formIsValid() {
  //   return useMemo(
  //     () =>
  //       !Object.values(senderValues).find(f => !f.validity.isValid) &&
  //       !Object.values(values).find(f => !f.validity.isValid),
  //     [values, senderValues]
  //   );
  // }

  return (
    <UI
      // memoizeInput={memoizeInput}
      // memoizeSenderInput={memoizeSenderInput}
      values={values}
      senderValues={senderValues}
      notifyChange={notifyChange}
      submit={submit}
      formIsValid={formIsValid}
      fields={data.fieldsInfos}
      clubs={data.clubs}
      actionTypeId={actionTypeId}
      {...others}
    />
  );
}

const WithData = withDataResolver({
  resolve: props => async callApi => {
    const { rmId, actionTypeId, forcedFields } = props;

    let fieldsInfos;
    if (!forcedFields)
      fieldsInfos = await callApi(apiClient.getContentFieldsForRequestModel, [rmId, actionTypeId]);
    else fieldsInfos = forcedFields;

    const clubs = await callApi(apiClient.getClubs);

    const cleanFields = Object.entries(fieldsInfos).reduce(
      (map, [k, v]) =>
        k !== 'invitationLink' && ['IMAGE', 'TABLE'].indexOf(v) === -1 ? { ...map, [k]: v } : map,
      {}
    );
    return { clubs, fieldsInfos: cleanFields };
  },
  onResolved: () => async () => {},
})(withApiCaller(WithTestFormController));

const WithAlert = withAlertAndLoader(WithData);

export default UI => props => <WithAlert {...props} UI={UI} />;
