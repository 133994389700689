import React from 'react';
import { Typography, Card, CardContent } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import appStyle from 'src/consts/appStyle';

const CardItem = ({ item, onClick, classes }) => (
  <Card
    onClick={event => {
      onClick(event, item);
    }}
    elevation={1}
    className={classes.card}
  >
    <CardContent className={classes.content} classes={{ root: classes.contentRoot }}>
      <Typography className={classes.title} variant="body2">
        {item.name}
      </Typography>
      <Typography className={classes.description} variant="caption">
        {item.description}
      </Typography>
    </CardContent>
  </Card>
);

CardItem.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

// CardItem.defaultProps = { item, onItemClick, classes: '' };
const styles = theme => ({
  content: {
    cursor: 'pointer',
    flex: 1,
    opacity: 0.7,
    '&:hover': {
      opacity: 1,
    },
  },
  contentRoot: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: `${theme.spacing(2)}px !important`,
  },
  card: {
    textAlign: 'left',
    marginBottom: theme.spacing(1),
    backgroundColor: appStyle.bgColor4,
    flex: 1,
  },
  title: {
    color: appStyle.txtColor1,
    margin: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: theme.spacing(1),
  },
  description: {
    color: appStyle.txtColor4,
    margin: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export default withStyles(styles)(CardItem);
