import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { TextField, InputAdornment, Tooltip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import WarningIcon from '@material-ui/icons/Warning';
import SmConfigIcon from './SmConfigIcon';
import appStyle from 'src/consts/appStyle';
import utils from 'src/utils/utils';

const getTooltips = validity => {
  let tooltips = [];
  let ttIdx = 0;
  if (!validity.isValid) {
    if (validity.messages && validity.messages.length)
      tooltips = validity.messages.map(msg => {
        ttIdx += 1;
        return { key: ttIdx, value: msg };
      });
    else
      tooltips = [
        {
          key: 0,
          value: utils.getLang('smartmessaging.config.tooltip.invalidField'),
        },
      ];
  }
  return tooltips;
};

const toolTipStyles = {
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: appStyle.txtColor7,
    border: '1px solid #dadde9',
    fontSize: 14,
    maxWidth: 'none',
    paddingRight: '24px',
  },
};

const StyledTooltip = withStyles(toolTipStyles)(Tooltip);

const ValidityAdornment = ({ validity }) => (
  <InputAdornment style={{ height: 'unset', margin: '0 4px 0 0' }} position="start">
    {validity.isValid && <DoneIcon style={{ color: appStyle.txtColor1 }} />}
    {!validity.isValid && (
      <StyledTooltip
        title={
          <ul>
            {getTooltips(validity).map(tt => (
              <li key={tt.key}>{tt.value}</li>
            ))}
          </ul>
        }
        placement="bottom"
      >
        <WarningIcon style={{ color: appStyle.txtColor6 }} />
      </StyledTooltip>
    )}
  </InputAdornment>
);

ValidityAdornment.propTypes = {
  validity: PropTypes.object.isRequired,
};

const SmConfigTextField = ({ paramKey, value, label, onChange, validity, classes }) => (
  <TextField
    style={{ maxWidth: '480px', gridArea: paramKey }}
    onChange={event => {
      event.preventDefault();
      onChange(event.target.value);
    }}
    InputLabelProps={{
      shrink: true,
      className: `${classes.label} ${!validity.isValid && 'invalidField'}`,
    }}
    InputProps={{
      style: { color: appStyle.txtColor3, fontSize: '14px' },

      startAdornment: (
        <InputAdornment position="start">
          <SmConfigIcon property={paramKey} />
        </InputAdornment>
      ),
      endAdornment: <ValidityAdornment validity={validity} />,
    }}
    value={value}
    label={label}
  />
);

SmConfigTextField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  validity: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  paramKey: PropTypes.string.isRequired,
};

SmConfigTextField.defaultProps = {};

const styles = () => ({
  label: {
    color: appStyle.txtColor3,
    whiteSpace: 'nowrap',
    fontWeight: 400,
    '&.invalidField': { color: appStyle.txtColor6 },
  },
});

export default withStyles(styles)(SmConfigTextField);
