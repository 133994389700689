import React from 'react';
import { Typography } from '@material-ui/core';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import appStyle from 'src/consts/appStyle';

const styles = () => ({
  wrapper: { height: '100vh', position: 'relative' },
  '@keyframes beat': {
    '33%': {
      transform: 'scale(0.5)',
      opacity: 0.2,
    },
    '66%': {
      transform: 'scale(0.75)',
      opacity: 0.5,
    },
    '100%': { transform: ' scale(1)', opacity: 1 },
  },
  dot: {
    display: 'inline-block',
    backgroundColor: appStyle.bgColor1,
    width: `25px`,
    height: `25px`,
    margin: `15px 10px`,
    borderRadius: '100%',
    animation: `beat 0.7s 0s infinite linear`,
    animationFillMode: 'both',
  },
  timer1: {
    animation: `beat 1.4s 0s infinite linear`,
  },
  timer2: {
    animation: `beat 1.4s 0.42s infinite linear`,
  },
  timer3: {
    animation: `beat 1.4s 0.84s infinite linear`,
  },
});

const LoginScreen = ({ classes }) => (
  <div className={classes.wrapper}>
    <div
      style={{
        margin: 0,
        position: 'absolute',
        top: '45%',
        left: '50%',
        msTransform: 'translate(-50%, -50%)',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <div
        style={{
          textAlign: 'center',
        }}
      >
        <Typography
          variant="h6"
          style={{
            position: 'relative',
            top: '76px',
            color: appStyle.txtColor3,
            textAlign: 'center',
          }}
        >
          <i>Connexion en cours</i>
        </Typography>
        <div className={`${classes.dot} ${classes.timer1}`} />
        <div className={`${classes.dot} ${classes.timer2}`} />
        <div className={`${classes.dot} ${classes.timer3}`} />
      </div>
    </div>
  </div>
);

LoginScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LoginScreen);
