import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import {
  DialogContent,
  Dialog,
  AppBar,
  Toolbar,
  Button,
  Typography,
  IconButton,
  DialogActions,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ActionType from 'src/enums/actionType';

import EmailEditor from 'src/components/common/contentEditor/EmailEditor';
import SmsEditor from 'src/components/common/contentEditor/SmsEditor';
import appStyle from 'src/consts/appStyle';
import utils from 'src/utils/utils';
import contentEditorUtils from 'src/components/common/contentEditor/utils/contentEditorUtils';

import { saveContent } from 'src/reducers/customTemplates';

const CustomContentEditor = ({
  editorIsVisible,
  classes,
  template,
  closeEditor,
  data,
  doSaveContent,
  onMailEditorLoaded,
  onObjectEditorLoaded,
}) => (
  <Dialog
    classes={{ paperFullScreen: classes.paperFullScreen }}
    fullScreen={!!template && template.actionTypeId === ActionType.EMAIL.id}
    open={editorIsVisible}
    disableEnforceFocus
  >
    {editorIsVisible && [
      <AppBar className={classes.appBar} key={0}>
        <Toolbar>
          <Typography variant="h5" color="inherit" className={classes.flex}>
            {utils.getLang(`smartmessaging.contentEditor.title.${template.actionTypeId}`)}
          </Typography>

          <IconButton
            aria-label="clear"
            onClick={e => {
              e.preventDefault();
              closeEditor();
            }}
            style={{ color: appStyle.txtColor2 }}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>,
      <DialogContent style={{ height: '100%', overflowY: 'hidden', padding: 0 }} key={1}>
        <div className={classes.content}>
          {template.actionTypeId === ActionType.EMAIL.id && (
            <EmailEditor
              onObjectEditorLoaded={onObjectEditorLoaded}
              onEditorLoaded={onMailEditorLoaded}
              onChange={() => {}}
              fields={data.fields}
              content={contentEditorUtils.freemarkerToHtml(
                data.content.body || '',
                data.fields.fieldsModels,
                data.params
              )}
              object={contentEditorUtils.freemarkerToHtml(
                template.subject || '',
                data.fields.fieldsModels,
                data.params
              )}
              readOnly={!template.isWritable}
            />
          )}
          {template.actionTypeId === ActionType.SMS.id && (
            <SmsEditor
              onEditorLoaded={onMailEditorLoaded}
              fields={data.fields}
              onChange={() => {}}
              content={contentEditorUtils.smsFreemarkerToHtml(
                data.content.body || '',
                data.fields.fieldsModels,
                data.params
              )}
              readOnly={!template.isWritable}
            />
          )}
        </div>
      </DialogContent>,
      <DialogActions style={{ borderTop: appStyle.border1 }} key={2}>
        <Button
          // color="inherit"
          onClick={e => {
            e.preventDefault();
            closeEditor();
          }}
        >
          {utils.getLang('smartmessaging.button.contentEditor.cancel')}
        </Button>
        <Button
          variant="contained"
          className={`${classes.saveBtn}`}
          onClick={e => {
            e.preventDefault();
            doSaveContent(template);
          }}
          disabled={!template.isWritable}
          // disabled={!contentValidity.isValid}
        >
          {utils.getLang('smartmessaging.button.contentEditor.save')}
        </Button>
      </DialogActions>,
    ]}
  </Dialog>
);

const mapStateToProps = state => ({
  // contentValidity: checkContentValidity(state),
  editorIsVisible: state.customTemplates.showEditor,
});

const actionCreators = {
  closeEditor: () => ({ type: 'SHOW_CUSTOM_CONTENT_EDITOR', value: false }),
  onMailEditorLoaded: mailEditor => ({ type: 'ON_CUSTOM_MAIL_EDITOR_LOADED', value: mailEditor }),
  onObjectEditorLoaded: objectEditor => ({
    type: 'ON_CUSTOM_OBJECT_EDITOR_LOADED',
    value: objectEditor,
  }),
  doSaveContent: saveContent,
};

CustomContentEditor.propTypes = {
  classes: PropTypes.object.isRequired,
  editorIsVisible: PropTypes.bool.isRequired,
  closeEditor: PropTypes.func.isRequired,
  template: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  doSaveContent: PropTypes.func.isRequired,
  onMailEditorLoaded: PropTypes.func.isRequired,
  onObjectEditorLoaded: PropTypes.func,
};

CustomContentEditor.defaultProps = {
  onObjectEditorLoaded: undefined,
};

const styles = theme => ({
  saveBtn: {
    backgroundColor: appStyle.bgColor1,
    margin: `${theme.spacing(0.5)}px`,
    color: appStyle.txtColor2,
    '&:hover': {
      background: appStyle.bgColor1,
      opacity: 0.9,
    },
  },
  deleteBtn: {
    margin: `${theme.spacing(0.5)}px`,
    backgroundColor: appStyle.bgColor5,
    color: appStyle.txtColor6,
    '&:hover': {
      background: appStyle.bgColor5,
      opacity: 0.9,
    },
  },
  buttonMargin: { margin: `${theme.spacing(0.5)}px` },
  // saveBtn: {
  //   backgroundColor: appStyle.bgColor6,
  //   color: appStyle.txtColor1,
  //   '&:hover': {
  //     background: appStyle.bgColor6,
  //     backgroundColor: appStyle.bgColor6,
  //     opacity: 0.9,
  //   },
  // },
  appBar: {
    position: 'relative',
    backgroundColor: appStyle.bgColor1,
  },
  content: { padding: `${theme.spacing(2)}px`, height: '100%' },
  flex: {
    flex: 1,
  },
  paperFullScreen: { width: '97%', height: '97%', borderRadius: '2px' },
});

export default connect(mapStateToProps, actionCreators)(withStyles(styles)(CustomContentEditor));
