import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import { withStyles } from '@material-ui/styles';
import { TableRow, TableCell } from '@material-ui/core';
import appStyle from '../../../consts/appStyle';
import hUtils from './utils';

const styles = () => ({
  title: { fontSize: '1rem', color: appStyle.txtColor3 },
  cardHeader: { borderBottom: appStyle.border1 },
  cardContent: { padding: '0', display: 'flex', flexDirection: 'column', flex: 1 },
  action: { marginTop: 0 },
  root: { paddingBottom: '12px', paddingLeft: 0 },
});

const SummaryItem = ({ item, columns, specificRender, rowSpanCols, rowSpan }) => (
  <TableRow>
    {columns.map(column => (
      <TableCell
        key={uuid()}
        style={{ padding: '4px', color: appStyle.txtColor3 }}
        rowSpan={rowSpanCols.indexOf(column) === -1 ? 1 : rowSpan}
      >
        {specificRender[column]
          ? specificRender[column](column, item)
          : hUtils.renderCell(column, item)}
      </TableCell>
    ))}
  </TableRow>
);

SummaryItem.propTypes = {
  item: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  rowSpanCols: PropTypes.array,
  specificRender: PropTypes.object,
  rowSpan: PropTypes.number.isRequired,
};
SummaryItem.defaultProps = {
  rowSpanCols: [],
  specificRender: {},
};

export default withStyles(styles)(SummaryItem);
