import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { Typography, IconButton, Paper } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Close';
import ActionType from 'src/enums/actionType';
import utils from 'src/utils/utils';
import getApiCaller from 'src/utils/apiClientCaller';
import apiClient from 'src/utils/apiClient';
import appStyle from 'src/consts/appStyle';
import { loaderActions } from 'src/reducers/loader';

import massActionSelectors from 'src/selectors/massAction';
import {
  checkContentFieldsAndSelectedColumns,
  resetContentEditorFlowData,
  deleteCmpAction,
} from 'src/reducers/massAction';

import CustCard from 'src/components/common/ActionCard';

import ActionBtn from 'src/components/common/ActionBtn';

const mapStateToProps = state => ({
  contentFields: massActionSelectors.getContentFields(state),
});

const actionCreators = {
  doDeleteCmpAction: deleteCmpAction,
  setLoader: visible => loaderActions.setLoader(visible),
  doGetApiCaller: () => getApiCaller,
  doResetContentEditorFlowData: resetContentEditorFlowData,
  updateCurrentCmpAction: cmpAction => ({ type: 'UPDATE_CURRENT_CMP_ACTION', value: cmpAction }),
  doCheckContentFieldsAndSelectedColumns: checkContentFieldsAndSelectedColumns,
};

const styles = () => ({
  appBar: {
    position: 'relative',
    backgroundColor: appStyle.bgColor1,
  },
  testBtn: { margin: '2px' },
  editBtn: {
    // zIndex: 2,
    backgroundColor: '#000000',
    color: appStyle.txtColor2,
    margin: '2px',
    '&:hover': {
      backgroundColor: '#000000',
      opacity: 0.9,
    },
  },
  deleteBtn: {
    // zIndex: 2,
    margin: '2px',
    backgroundColor: appStyle.bgColor8,
    color: appStyle.txtColor2,
    '&:hover': {
      backgroundColor: appStyle.bgColor8,
      opacity: 0.9,
    },
  },
});

const propTypes = {
  doDeleteCmpAction: PropTypes.func.isRequired,
  action: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  actionTypeId: PropTypes.number.isRequired,
  isWritable: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  updateContents: PropTypes.func.isRequired,
  lang: PropTypes.object.isRequired,
};

const ActionEditor = ({
  classes,
  action,
  doDeleteCmpAction,
  actionTypeId,
  isWritable,
  onClick,
  updateContents,
  lang,
}) => (
  <Fragment>
    <CustCard style={{ overflow: 'hidden' }} title={utils.getLang(lang.title[actionTypeId])}>
      <Paper style={{ height: '200px', width: '160px', display: 'flex', flexDirection: 'column' }}>
        <Typography
          style={{
            // wordBreak: 'break-word',
            color: appStyle.txtColor1,
            wordWrap: 'normal',
            padding: '8px',
          }}
        >
          {utils.getLang(lang.createText[actionTypeId])}
        </Typography>
        <div style={{ flex: 1 }}>
          {isWritable && (
            <IconButton
              onClick={event => {
                event.preventDefault();
                doDeleteCmpAction(action.id, () => updateContents({ [action.actionTypeId]: null }));
              }}
              className={classes.deleteBtn}
              size="small"
            >
              <DeleteIcon />
            </IconButton>
          )}
        </div>
        <div style={{ padding: '12px', display: 'grid' }}>
          <ActionBtn
            onClick={event => {
              event.preventDefault();
              onClick();
            }}
          >
            {utils.getLang('smartmessaging.contentEditor.useContent')}
          </ActionBtn>
        </div>
      </Paper>
    </CustCard>
  </Fragment>
);

ActionEditor.propTypes = propTypes;

function ActionEditorHandler(props) {
  async function onClick() {
    props.doResetContentEditorFlowData();
    const apiCaller = props.doGetApiCaller();
    props.setLoader(true);
    const message = await apiCaller(apiClient.getMessage, [props.action.messageId]).catch(() =>
      props.setLoader(false)
    );
    const content = await apiCaller(apiClient.get, [
      utils.link(message.contentStoredFileId),
    ]).finally(() => props.setLoader(false));
    const { object } = message;
    props.doCheckContentFieldsAndSelectedColumns(
      props.actionTypeId === ActionType.SMS.id ? content : [object, content],
      props.actionTypeId,
      contentFields => {
        props.updateCurrentCmpAction(props.action);
        props.goToStep(4, {
          actionType: props.actionTypeId,
          actionId: props.action.id,
          initialStep: 1,
          initialExtraProps: {
            fields: contentFields,
            content,
            object,
          },
        });
      }
    );
  }

  const [testFormIsVisible, showTestForm] = useState(false);

  return <ActionEditor {...props} {...{ onClick, testFormIsVisible, showTestForm }} />;
}

ActionEditorHandler.propTypes = {
  actionTypeId: PropTypes.number.isRequired,
  isWritable: PropTypes.bool.isRequired,
  updateContents: PropTypes.func.isRequired,
  lang: PropTypes.object.isRequired,
  setLoader: PropTypes.func.isRequired,
  doCheckContentFieldsAndSelectedColumns: PropTypes.func.isRequired,
  updateCurrentCmpAction: PropTypes.func.isRequired,
  goToStep: PropTypes.func.isRequired,
  doGetApiCaller: PropTypes.func.isRequired,
  doResetContentEditorFlowData: PropTypes.func.isRequired,
  action: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, actionCreators)(withStyles(styles)(ActionEditorHandler));
