import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { withStyles } from '@material-ui/styles';
import appStyle from 'src/consts/appStyle';
import utils from 'src/utils/utils';

const styles = {
  menuItem: {
    color: appStyle.txtColor3,
    display: 'flex',
    fontSize: '0.98rem',
    outline: 'none',
    cursor: 'pointer',
    padding: '4px',
    minHeight: 'unset',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '240px',
    '&:hover': {
      background: appStyle.bgColor1,
      opacity: 0.9,
      color: appStyle.txtColor2,
    },
  },
};

function SMJItemMenu({ classes, smj, options, actions }) {
  const [anchorEl, setAnchorEl] = useState(null);

  function handleClose() {
    setAnchorEl(null);
  }

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  return (
    <Fragment>
      <IconButton
        style={{ padding: '4px' }}
        aria-owns={anchorEl ? `item-menu-${smj.id}` : null}
        aria-haspopup="true"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          handleClick(e);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`item-menu-${smj.id}`}
        value
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={e => {
          e.preventDefault();
          e.stopPropagation();
          handleClose();
        }}
        PaperProps={{
          style: {
            minWidth: 150,
          },
        }}
      >
        <MenuItem className={classes.menuItem} disabled component="div">
          {smj.name}
        </MenuItem>
        {options.map(option => (
          <MenuItem
            className={classes.menuItem}
            key={option}
            onClick={() => {
              handleClose();
              actions[option]();
            }}
          >
            {utils.getLang(`smartmessaging.smartjourneyList.item.${option}`)}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
}

SMJItemMenu.propTypes = {
  smj: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default withStyles(styles)(SMJItemMenu);
