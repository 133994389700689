import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import { TableBody, TableRow, TableCell } from '@material-ui/core';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import appStyle from 'src/consts/appStyle';

import SMJItemMenu from './SMJItemMenu';

import columnsConfig from './columnsConfig';

function SMJListTableRows({ smjList, options, actions, smjModelById, columnNames }) {
  return (
    <TransitionGroup component={TableBody}>
      {smjList.map(smj => (
        <CSSTransition timeout={300} classNames="fade" key={smj.id || uuid()}>
          <TableRow
            key={smj.id || uuid()}
            hover
            // style={{ opacity: massAction.enabled ? 1 : 0.4 }}
            onDoubleClick={e => {
              e.preventDefault();
              actions.edit(smj);
            }}
          >
            <TableCell>
              <SMJItemMenu
                smj={smj}
                options={options}
                actions={{
                  edit: () => actions.edit(smj),
                  delete: () => actions.delete(smj),
                  // enable: () => actions.enable(smj),
                  // disable: () => actions.disable(smj),
                }}
              />
            </TableCell>
            {columnNames.map(columnName => (
              <TableCell style={{ padding: '4px', color: appStyle.txtColor3 }} key={columnName}>
                {columnsConfig.renderCell(columnName, smj, { smjModelById })}
              </TableCell>
            ))}
          </TableRow>
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
}

SMJListTableRows.propTypes = {
  smjList: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  smjModelById: PropTypes.object.isRequired,
  columnNames: PropTypes.array.isRequired,
};

export default SMJListTableRows;
