// import React from 'react';
// import PropTypes from 'prop-types';
// import { withStyles } from '@material-ui/styles';
// import { TransitionGroup, CSSTransition } from 'react-transition-group';
// import { ButtonBase, Typography, Button } from '@material-ui/core';
// import { connect } from 'react-redux';
// import AppStyle from '../consts/appStyle';
// import Selector from './campaign/campaignEditor/editorComponents/campaignTarget/FilteredFieldFilter';
// import utils from '../utils/utils';

// import './test.css';

const Test = () => {
  console.log('test');
  return null;
  // alert();
  // const [items, setItems] = useState(['caca', 'pipi']);
  // return (
  //   <Selector
  //     valueOptions={{}}
  //     filterOptions={[{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }]}
  //   />
  // );
  // return (
  //   <div>
  //     <Button onClick={() => setItems(['mama'])}>1</Button>
  //     <Button onClick={() => setItems(['caca', 'pipi'])}>2</Button>
  //     {console.log(items)}
  //     <TransitionGroup component="ul">
  //       {items.map(i => (
  //         <CSSTransition key={i} timeout={500} classNames="item">
  //           <div>{i}</div>
  //         </CSSTransition>
  //       ))}
  //     </TransitionGroup>
  //   </div>
  // );
};

export default Test;

// const styles = () => ({
//   popover: { padding: 0, margin: 0 },
//   menuItem: {
//     color: AppStyle.txtColor3,
//     outline: 'none',
//     cursor: 'pointer',
//     // margin: '1px 0px',
//     fontSize: '0.9rem',
//     // borderBottom: AppStyle.border1,
//     display: 'block',
//     textAlign: 'left',
//     transition: 'background 0.5s ease, background 0.5s ease',
//     padding: '12px 16px 12px 24px',
//     '&.selected': {
//       background: AppStyle.bgColor3,
//       // color: AppStyle.txtColor3,
//       fontWeight: 450,
//       borderLeft: `4px solid ${AppStyle.bgColor1}`,
//     },
//     // fontSize: '1rem',
//     '&:hover': {
//       // background: AppStyle.bgColor1,
//       // color: AppStyle.txtColor2,
//       // outline: `4px solid ${AppStyle.bgColor3}`,
//       background: AppStyle.bgColor2,
//       // opacity: 0.8,
//       // fontSize: '0.85rem',
//     },
//     '&:hover.selected': {
//       background: AppStyle.bgColor3,
//       opacity: 0.9,
//       // outline: `4px solid ${AppStyle.bgColor1}`,
//       // fontSize: '0.85rem',
//     },
//   },
//   input: {
//     backgroundColor: AppStyle.bgColor9,
//     color: AppStyle.txtColor3,
//     textAlign: 'left',
//     padding: '6px 24px 6px 4px',
//   },
// });

// const Test = ({
//   classes,
//   selectMenuValue,
//   onSelectChange,
//   actionCoActivated,
//   parametersActivated,
//   automatedActivated,
//   customTemplateActivated,
// }) => (
//   <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
//     {automatedActivated && (
//       <ButtonBase
//         className={`${classes.menuItem} ${selectMenuValue === 0 ? 'selected' : ''}`}
//         onClick={e => {
//           e.preventDefault();
//           onSelectChange(0);
//         }}
//       >
//         <Typography style={{ fontSize: 'inherit', fontWeight: 'inherit' }}>
//           {utils.getLang('smartmessaging.mainMenu.campaigns')}
//         </Typography>
//       </ButtonBase>
//     )}
//     {actionCoActivated && (
//       <ButtonBase
//         className={`${classes.menuItem} ${selectMenuValue === 2 ? 'selected' : ''}`}
//         onClick={e => {
//           e.preventDefault();
//           onSelectChange(2);
//         }}
//       >
//         <Typography style={{ fontSize: 'inherit', fontWeight: 'inherit' }}>
//           {utils.getLang('smartmessaging.mainMenu.massAction')}
//         </Typography>
//       </ButtonBase>
//     )}
//     {customTemplateActivated && (
//       <ButtonBase
//         className={`${classes.menuItem} ${selectMenuValue === 3 ? 'selected' : ''}`}
//         onClick={e => {
//           e.preventDefault();
//           onSelectChange(3);
//         }}
//       >
//         <Typography style={{ fontSize: 'inherit', fontWeight: 'inherit' }}>
//           {utils.getLang('smartmessaging.mainMenu.customTemplates')}
//         </Typography>
//       </ButtonBase>
//     )}
//     {parametersActivated && (
//       <ButtonBase
//         className={`${classes.menuItem} ${selectMenuValue === 1 ? 'selected' : ''}`}
//         onClick={e => {
//           e.preventDefault();
//           onSelectChange(1);
//         }}
//       >
//         <Typography style={{ fontSize: 'inherit', fontWeight: 'inherit' }}>
//           {utils.getLang('smartmessaging.mainMenu.config')}
//         </Typography>
//       </ButtonBase>
//     )}
//   </div>
// );

// Test.propTypes = {
//   classes: PropTypes.object.isRequired,
//   selectMenuValue: PropTypes.number.isRequired,
//   onSelectChange: PropTypes.func.isRequired,
//   actionCoActivated: PropTypes.bool.isRequired,
//   parametersActivated: PropTypes.bool.isRequired,
//   automatedActivated: PropTypes.bool.isRequired,
//   customTemplateActivated: PropTypes.bool.isRequired,
// };

// const mapDispatchToProps = dispatch => ({
//   onSelectChange: option => dispatch({ type: 'SWITCH_MAIN_CONTENT', value: option }),
// });

// const mapStateToProps = state => ({
//   selectMenuValue: state.selectMenuValue,
//   actionCoActivated: state.app.config['smartm-punctual:allow'],
//   parametersActivated: state.app.config['smartm-param:allow'],
//   automatedActivated: state.app.config['smartm-automation:allow'],
//   customTemplateActivated: state.app.config['smartm-template:allow'],
// });

// export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Test));
