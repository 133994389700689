import { createReducer } from '@acemarke/redux-starter-kit';
import uuid from 'uuid';
import apiClient from '../utils/apiClient';
import { enqueueSnackbar } from './notify';
import utils from '../utils/utils';
import { loaderActions } from './loader';
import getApiCaller from '../utils/apiClientCaller';
import { getImageMaxSize } from './app';
import parametersCfg from './utils/parameters';
import paramSelectors from '../selectors/parameters';

// const defaultParams = {
//   headerStoredfileId: null,
//   footerStoredfileId: null,
//   facebookUrl: null,
//   googlePlusUrl: null,
//   instagramUrl: null,
//   twitterUrl: null,
//   youtubeUrl: null,
//   resamaniaUrl: null,
//   clubUrl: null,
//   smsSender: null,
//   bookingUrl: null,
//   salelUrl: null,
//   unsubscribeUrl: null,
//   passwordforgottenUrl: null,
// };

const initialState = { clubParams: {}, currentParams: null, invalidities: {} };

export const getSmParams = state => paramSelectors.getCurrentParams(state);
// state.parameters.clubParams[state.parameters.currentParams] || {};

export const logState = () => () => {
  // console.log({ ...getState() });
};

const handleValidityChange = (fieldValidity, currentInvalidities) => {
  const refreshedInvalidities = { ...currentInvalidities };
  if (fieldValidity.isValid && refreshedInvalidities[fieldValidity.key]) {
    delete refreshedInvalidities[fieldValidity.key];
  } else if (!fieldValidity.isValid && !refreshedInvalidities[fieldValidity.key]) {
    refreshedInvalidities[fieldValidity.key] = { ...fieldValidity };
  }
  return refreshedInvalidities;
};

const checkInvalidities = params => {
  const initialInvalidities = [
    ...parametersCfg.textParamKeys,
    ...parametersCfg.timeParamKeys,
  ].reduce((acc, paramKey) => {
    const fieldValidity = parametersCfg.validationsByType[
      parametersCfg.fieldValidationTypesByKey[paramKey]
    ](paramKey, params[paramKey], params);
    if (fieldValidity.isValid) return acc;
    return { ...acc, [paramKey]: fieldValidity };
  }, {});
  return initialInvalidities;
};

const receiveSmParams = (state, { value }) => ({
  ...state,
  clubParams: value,
  // invalidities: checkInvalidities(value),
});

const setCurrentParams = (state, { value }) => ({
  ...state,
  currentParams: value,
  invalidities: checkInvalidities(state.clubParams[value]),
});

const updateCurrentParams = (state, { value }) => ({
  ...state,
  clubParams: {
    ...state.clubParams,
    [state.currentParams]: {
      ...state.clubParams[state.currentParams],
      ...value,
    },
  },
  invalidities: checkInvalidities(value),
});

const onSmCfgFieldChange = (state, { value }) => ({
  ...state,
  // currentParams: { ...state.currentParams, [value.key]: value.value || null },
  clubParams: {
    ...state.clubParams,
    [state.currentParams]: {
      ...state.clubParams[state.currentParams],
      [value.key]: value.value || null,
    },
  },
  invalidities: checkInvalidities({
    ...state.clubParams[state.currentParams],
    [value.key]: value.value || null,
  }),
});

const onFieldValidityChange = (state, { value }) => ({
  ...state,
  invalidities: handleValidityChange(value, state.invalidities),
});

export const loadSmParams = newParamsId => async (dispatch, getState) => {
  const params = await getApiCaller(
    dispatch,
    getState
  )(apiClient.loadSmParams).then(
    r => r,
    err => Promise.resolve(err)
  );

  // dispatch({ type: 'RECEIVE_SM_PARAMS', value: params });
  const paramsSorter = (p1, p2) => {
    if ((!p2.clubName && !p1.clubName) || p1.clubName === p2.clubName) {
      return 0;
    }
    if (p1.clubName === 'default') return -1;
    if (p2.clubName === 'default') return 1;
    if (!p2.clubName) {
      return -1;
    }
    if (!p1.clubName) {
      return 1;
    }
    return p1.clubName.toLowerCase() < p2.clubName.toLowerCase() ? -1 : 1;
  };

  const paramsMap = params
    .sort(paramsSorter)
    .reduce(
      (pMap, p) => ({ ...pMap, [uuid()]: { ...p, clubId: p.clubId || p.originalClubId } }),
      {}
    );

  const pKeys = Object.keys(paramsMap);
  dispatch({
    type: 'RECEIVE_SM_PARAMS',
    value: paramsMap,
  });
  if (newParamsId) {
    const currenKey = pKeys.filter(k => paramsMap[k].id === newParamsId)[0];
    dispatch({
      type: 'SET_CURRENT_PARAMS',
      value: currenKey,
    });
  } else {
    const clubId = parseInt(sessionStorage.getItem('club'), 10);
    dispatch({
      type: 'SET_CURRENT_PARAMS',
      value:
        pKeys.length === 1
          ? pKeys[0]
          : pKeys.filter(k => paramsMap[k].originalClubId === clubId)[0] ||
            pKeys.filter(k => paramsMap[k].clubName === 'default')[0] ||
            pKeys[0],
      // pKeys.length === 1 ? pKeys[0] : pKeys.filter(k => k.clubName === 'default')[0] || pKeys[0],
    });
  }
};

export const sendSmConfigImage = (paramKey, file) => async (dispatch, getState) => {
  const callApi = getApiCaller(dispatch, getState);
  const setLoader = visible => {
    dispatch(loaderActions.setLoader(visible));
  };
  const showAlert = msg => {
    dispatch({
      type: 'SHOW_ALERT',
      value: {
        type: 'warning',
        title: utils.getLang('Erreur'),
        msg,
      },
    });
  };

  setLoader(true);
  const handleSendFileError = async (error, doCall, defaultHandler) => {
    if (error.response && error.response.status === 413) {
      showAlert(
        utils.stringFormat(utils.getLang('smartmessaging.error.fileTooLarge'), [
          getImageMaxSize(getState()),
        ])
      );
      return Promise.reject(error);
    }
    const res = await defaultHandler();
    return res;
  };
  const storedFileId = await callApi(
    apiClient.uploadFile,
    [file],
    handleSendFileError
  ).finally(() => setLoader(false));

  dispatch({ type: 'ON_SM_CFG_FIELD_CHANGE', value: { key: paramKey, value: storedFileId } });
};

export const saveParams = () => async (dispatch, getState) => {
  const setLoader = visible => {
    dispatch(loaderActions.setLoader(visible));
  };

  setLoader(true);
  const currentParams = paramSelectors.getCurrentParams(getState());
  const params = await getApiCaller(dispatch, getState)(apiClient.saveSmParams, [
    currentParams,
  ]).finally(() => setLoader(false));

  if (!currentParams.id) {
    loadSmParams(params.id)(dispatch, getState);
  } else {
    dispatch({ type: 'UPDATE_CURRENT_PARAMS', value: params });
    dispatch(
      enqueueSnackbar({
        message: utils.getLang('smartmessaging.notifications.savedParameters'),
        options: {
          variant: 'info',
        },
      })
    );
  }
};

export const deleteParams = (id, uniqueId) => async (dispatch, getState) => {
  if (!id && id !== 0) {
    dispatch({
      type: 'SHOW_ALERT',
      value: {
        type: 'warning',
        title: utils.getLang('smartmessaging.config.cannotDeleteParameters.title'),
        msg: utils.getLang('smartmessaging.config.cannottDeleteParameters'),
      },
    });
  } else
    dispatch({
      type: 'SHOW_ALERT',
      value: {
        type: 'warning',
        title: utils.getLang('smartmessaging.config.confirmDeleteParameters.title'),
        msg: utils.getLang('smartmessaging.config.confirmDeleteParameters'),
        async onConfirm() {
          const setLoader = visible => {
            dispatch(loaderActions.setLoader(visible));
          };
          setLoader(true);
          await getApiCaller(dispatch, getState)(apiClient.deleteParams, [id]).finally(() => {
            setLoader(false);
            dispatch({ type: 'CLOSE_ALERT' });
          });
          const paramsMap = { ...paramSelectors.getClubParams(getState()) };
          delete paramsMap[uniqueId];
          dispatch({
            type: 'RECEIVE_SM_PARAMS',
            value: paramsMap,
          });
          dispatch(
            enqueueSnackbar({
              message: utils.getLang('smartmessaging.notifications.deletedParameters'),
              options: {
                variant: 'info',
              },
            })
          );
        },
        onDismiss() {
          dispatch({ type: 'CLOSE_ALERT' });
        },
      },
    });
};

export default createReducer(initialState, {
  RECEIVE_SM_PARAMS: receiveSmParams,
  ON_SM_CFG_FIELD_CHANGE: onSmCfgFieldChange,
  ON_FIELD_VALIDITY_CHANGE: onFieldValidityChange,
  SET_CURRENT_PARAMS: setCurrentParams,
  UPDATE_CURRENT_PARAMS: updateCurrentParams,
});
