import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import appStyle from 'src/consts/appStyle';

const cmpStyles = () => ({
  hRoot: { padding: 0 },
  sticky: { position: 'sticky', top: 0, backgroundColor: '#ffffff', zIndex: 1 },
  visibleOverflow: { overflow: 'visible' },
  title: {
    fontSize: '1.4rem',
    color: appStyle.txtColor3,
    textAlign: 'center',
    wordBreak: 'break-all',
  },
  cardContent: { padding: '0', display: 'flex', flexDirection: 'column', flex: 1 },
  action: { marginTop: 0 },
});

const CustomCard = ({ classes, action, title, children, avatar, sticky, style }) => (
  <Card
    elevation={0}
    style={{ flex: 1, textAlign: 'center' }}
    classes={{ root: classes.visibleOverflow }}
  >
    <CardHeader
      className={classes.cardHeader}
      classes={{
        title: classes.title,
        action: classes.action,
        root: `${classes.hRoot} ${sticky ? classes.sticky : ''}`,
      }}
      action={action}
      avatar={avatar}
      title={title}
    />
    <CardContent style={{ overflow: 'auto', padding: '8px', ...style }}>{children}</CardContent>
  </Card>
);

CustomCard.propTypes = {
  action: PropTypes.any,
  avatar: PropTypes.any,
  children: PropTypes.any.isRequired,
  title: PropTypes.any.isRequired,
  classes: PropTypes.object.isRequired,
  style: PropTypes.object,
  sticky: PropTypes.bool,
};
CustomCard.defaultProps = {
  action: null,
  avatar: null,
  sticky: false,
  style: {},
};

export default withStyles(cmpStyles)(CustomCard);
