import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, ListItemIcon, Typography, Tooltip } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import CascadingMenu from './CascadingMenu';

import appStyle from 'src/consts/appStyle';

const useStyles = makeStyles({
  root: {
    color: appStyle.txtColor3,
    '&:hover': {
      backgroundColor: 'transparent',
      color: appStyle.txtColor1,
    },
  },
});

const useTooltipStyles = makeStyles({
  tooltip: {
    backgroundColor: 'transparent',
    // color: appStyle.txtColor7,
    border: 'none',
    // fontSize: 14,
    maxWidth: 'none',
    // paddingRight: '0',
  },
});

const CascadingMenuItem = ({ caption, menuItems }) => {
  const classes = useStyles();
  return (
    <Tooltip
      interactive
      placement="right-start"
      classes={useTooltipStyles()}
      title={<CascadingMenu menuItems={menuItems} />}
    >
      <MenuItem
        classes={{
          root: classes.root,
        }}
        style={{
          overflow: 'visible',
          height: 'auto',
          justifyContent: 'flex-end',
          width: 'fit-content',
          paddingRight: 0,
        }}
      >
        <Typography
          variant="body2"
          style={{
            color: 'inherit',
            wordBreak: 'break-word',
            whiteSpace: 'normal',
            textAlign: 'right',
            // fontSize: '1rem',
          }}
        >
          {caption}
        </Typography>
        <ListItemIcon style={{ color: 'inherit', margin: 0, minWidth: 'unset' }}>
          <ArrowRightIcon />
        </ListItemIcon>
      </MenuItem>
    </Tooltip>
  );
};

CascadingMenuItem.propTypes = {
  caption: PropTypes.string.isRequired,
  menuItems: PropTypes.array.isRequired,
};

export default CascadingMenuItem;
