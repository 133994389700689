import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { compareAsc } from 'date-fns';
import { DatePicker } from '@material-ui/pickers';
import { IconButton, TextField, InputLabel } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import Card from '../common/Card';
import ActionType from 'src/enums/actionType';
import utils from 'src/utils/utils';
import appStyle from 'src/consts/appStyle';

const typeOptions = [ActionType.SMS, ActionType.EMAIL, ActionType.MEMBER_NOTIFICATION].map(a => ({
  label: a.name,
  value: a.id,
}));

const configIsValid = cfg => {
  let isValid = true;
  isValid = !!cfg.type;
  if (isValid && (!cfg.to || !cfg.from)) isValid = false;
  if (isValid) isValid = compareAsc(cfg.from, cfg.to) < 0;
  return isValid;
};

const styles = () => ({
  popover: { padding: 0, margin: 0 },
  menuItem: {
    color: appStyle.txtColor3,
    outline: 'none',
    cursor: 'pointer',
    padding: '12px 8px',
    textAlign: 'center',
    '&:hover': {
      background: appStyle.bgColor1,
      color: appStyle.txtColor2,
      opacity: 0.9,
    },
  },
});

const MySelect = ({ classes, options, ...others }) => (
  <TextField
    fullWidth
    select
    style={{ flex: 1 }}
    SelectProps={{
      MenuProps: {
        MenuListProps: { style: { padding: 0, margin: 0 } },
        PopoverClasses: {
          paper: classes.popover,
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
        marginThreshold: 0,
      },
    }}
    {...others}
  >
    {options.map(o => (
      <div key={o.label} value={o.value} className={classes.menuItem}>
        {o.label}
      </div>
    ))}
  </TextField>
);

MySelect.propTypes = { classes: PropTypes.object.isRequired, options: PropTypes.array.isRequired };

const StyledSelect = withStyles(styles)(MySelect);

function Config({ apply, cfg, setCfg }) {
  function updateCfg(v, n) {
    setCfg(old => ({ ...old, [n]: v }));
  }

  return (
    <div style={{ width: '600px' }}>
      <Card title={utils.getLang('smartmessaging.sentMessages.configTitle')}>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '12px' }}>
          <div style={{ flex: 1, margin: '0 4px', display: 'flex' }}>
            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                margin: '0 4px',
              }}
            >
              <InputLabel htmlFor="type">
                {utils.getLang('smartmessaging.sentMessages.messageType')}
              </InputLabel>
            </div>
            <div style={{ flex: 1 }}>
              <StyledSelect
                id="type"
                options={typeOptions}
                onChange={e => {
                  updateCfg(e.target.value, 'type');
                }}
                value={cfg.type || ''}
                // label={utils.getLang('smartmessaging.sentMessages.messageType')}
              />
            </div>
          </div>
          <div style={{ flex: 1, margin: '0 4px', display: 'flex' }}>
            <div
              style={{
                // flex: 1,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                margin: '0 4px',
              }}
            >
              <InputLabel htmlFor="from">
                {utils.getLang('smartmessaging.campaignList.filterLabel.creationDate.from')}
              </InputLabel>
            </div>
            <div style={{ flex: 1 }}>
              {/* <label htmlFor="from">Type de message</label> */}
              <DatePicker
                id="from"
                onChange={date => {
                  updateCfg(date, 'from');
                }}
                autoOk
                variant="inline"
                // label="du"
                format="dd/MM/yyyy"
                value={cfg.from}
              />
            </div>
          </div>
          <div style={{ flex: 1, margin: '0 4px', display: 'flex' }}>
            <div
              style={{
                // flex: 1,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                margin: '0 4px',
              }}
            >
              <InputLabel htmlFor="to">
                {utils.getLang('smartmessaging.campaignList.filterLabel.creationDate.to')}
              </InputLabel>
            </div>
            <div style={{ flex: 1 }}>
              <DatePicker
                onChange={date => {
                  updateCfg(date, 'to');
                }}
                autoOk
                variant="inline"
                // label="au"
                format="dd/MM/yyyy"
                value={cfg.to}
              />
            </div>
          </div>
          <div>
            <IconButton
              disabled={!configIsValid(cfg)}
              onClick={e => {
                e.preventDefault();
                apply();
              }}
            >
              <Search style={{ fill: '#b6b6b6' }} />
            </IconButton>
          </div>
        </div>
      </Card>
    </div>
  );
}

Config.propTypes = {
  apply: PropTypes.func.isRequired,
  cfg: PropTypes.object.isRequired,
  setCfg: PropTypes.func.isRequired,
};

export default Config;
