import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Fade,
  Table,
  TableHead,
  TableSortLabel,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  IconButton,
  InputLabel,
  Typography,
  Button,
} from '@material-ui/core';
import VisibleIcon from '@material-ui/icons/Visibility';
import { format, parseISO, compareAsc, compareDesc } from 'date-fns';
import { withStyles } from '@material-ui/styles';
import uuid from 'uuid';
import DownloadIcon from '@material-ui/icons/VerticalAlignBottom';
import ContentViewer from './ContentViewer';
import Card from '../common/Card';
import Pagination from '../common/pagination/Pagination';

import appStyle from 'src/consts/appStyle';
import utils from 'src/utils/utils';
import apiClient from 'src/utils/apiClient';
import withApiCaller from '../common/hocs/withApiCaller';
import filtrable from '../common/hocs/filtrable';

import withAlertAndLoader from '../common/hocs/withAlertAndLoader';
import ActionType, { ActionTypeById } from 'src/enums/actionType';

const tableSortLabelStyle = {
  active: { color: appStyle.txtColor1 },
  root: {
    color: appStyle.txtColor1,
    '&$active': { color: appStyle.txtColor1, '& svg path': { color: appStyle.txtColor1 } },
  },
};

const StyledTableSortLabelRender = ({ classes, children, ...props }) => (
  <TableSortLabel classes={{ root: classes.root, active: classes.active }} {...props}>
    {children}
  </TableSortLabel>
);

StyledTableSortLabelRender.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
};

const StyledTableSortLabel = withStyles(tableSortLabelStyle)(StyledTableSortLabelRender);

const nullTest = (a, b, test) => {
  function isNullOrUndefined(v) {
    return v === null || v === undefined;
  }
  if (isNullOrUndefined(a) && isNullOrUndefined(b)) {
    return 0;
  }
  if (isNullOrUndefined(a)) return -1;
  if (isNullOrUndefined(b)) return 1;
  return test();
};

const sorters = {
  default: orderBy => ({
    asc: (a, b) =>
      nullTest(a[orderBy], b[orderBy], () => a[orderBy].toString().localeCompare(b[orderBy])),
    desc: (a, b) =>
      nullTest(b[orderBy], a[orderBy], () => b[orderBy].toString().localeCompare(a[orderBy])),
  }),
  STRING: orderBy => ({
    asc: (a, b) =>
      nullTest(a[orderBy], b[orderBy], () => {
        if (a[orderBy].toLowerCase().trim() < b[orderBy].toLowerCase().trim()) return -1;
        if (a[orderBy].toLowerCase().trim() > b[orderBy].toLowerCase().trim()) return 1;
        return 0;
      }),
    desc: (a, b) =>
      nullTest(b[orderBy], a[orderBy], () => {
        if (a[orderBy].toLowerCase().trim() > b[orderBy].toLowerCase().trim()) return -1;
        if (a[orderBy].toLowerCase().trim() < b[orderBy].toLowerCase().trim()) return 1;
        return 0;
      }),
  }),

  INTEGER: orderBy => ({
    asc: (a, b) =>
      nullTest(a[orderBy], b[orderBy], () => {
        if (parseInt(a[orderBy], 10) < parseInt(b[orderBy], 10)) return -1;
        if (parseInt(a[orderBy], 10) > parseInt(b[orderBy], 10)) return 1;
        return 0;
      }),
    desc: (a, b) =>
      nullTest(b[orderBy], a[orderBy], () => {
        if (parseInt(a[orderBy], 10) > parseInt(b[orderBy], 10)) return -1;
        if (parseInt(a[orderBy], 10) < parseInt(b[orderBy], 10)) return 1;
        return 0;
      }),
  }),
  DATE: orderBy => ({
    asc: (a, b) =>
      nullTest(a[orderBy], b[orderBy], () =>
        compareAsc(parseISO(a[orderBy]), parseISO(b[orderBy]))
      ),
    desc: (a, b) =>
      nullTest(b[orderBy], a[orderBy], () =>
        compareDesc(parseISO(a[orderBy]), parseISO(b[orderBy]))
      ),
  }),
  EMAILCONTENT: () => 0,
};

const colTypes = {
  createDate: 'DATE',
  openDate: 'DATE',
  opened: 'BOOL',
  content: 'CONTENT',
  actionTypeId: 'TARGET_TYPE',
  1: {},
  2: {},
  6: {},
};

function MailViewerRender({ contentId, object, callApi, showAlert }) {
  const [open, setOpen] = React.useState(false);
  const [content, setContent] = React.useState(null);
  // const open = Boolean(anchorEl);
  async function onOpen() {
    if (!content) {
      const ctn = await callApi(apiClient.getSentMessageContent, [contentId]);
      if (!ctn) {
        showAlert({ msg: utils.getLang('smartmessaging.sentMessages.deletedContent') });
      } else {
        setContent(ctn);
        setOpen(true);
      }
    }
  }

  return (
    <Fragment>
      {!!content && (
        <ContentViewer
          content={content}
          object={object}
          open={open}
          // anchorEl={anchorEl}
          onClose={() => setOpen(false)}
        />
      )}
      <IconButton
        onClick={e => {
          // console.log(content);
          e.preventDefault();
          onOpen();
          // setOpen(true);
        }}
      >
        <VisibleIcon />
      </IconButton>
    </Fragment>
  );
}
MailViewerRender.propTypes = {
  contentId: PropTypes.number.isRequired,
  object: PropTypes.string.isRequired,
  callApi: PropTypes.func.isRequired,
  showAlert: PropTypes.func.isRequired,
};
const MailViewer = withApiCaller(withAlertAndLoader(MailViewerRender));

const cellRenderers = {
  DATE: dateValue => (dateValue ? format(parseISO(dateValue), 'dd/MM/yyyy HH:mm') : '-'),
  CONTENT: (id, line) => <MailViewer contentId={id} object={line.object} />,
  BOOL: val => (val ? utils.getLang('smartmessaging.yes') : utils.getLang('smartmessaging.no')),
  TARGET_TYPE: val =>
    utils.getLang(`smartmessaging.sentMessages.targetType.${ActionTypeById[val]}`),
};

const renderCell = (column, messageType) =>
  cellRenderers[colTypes[messageType][column]] || cellRenderers[colTypes[column]] || (v => v);

const getSorter = ({ order, orderBy, type }) => {
  if (orderBy === 'unset') return v => v;
  return sorters[type] ? sorters[type](orderBy)[order] : sorters.default(orderBy)[order];
};

const Summary = ({ from, to, count, messageType }) => (
  <div style={{ display: 'flex' }}>
    <div style={{ flex: 1, justifyContent: 'center', display: 'flex' }}>
      <Card
        title={
          <div style={{ textAlign: 'center' }}>
            {utils.getLang('smartmessaging.sentMessages.messageType')}
          </div>
        }
      >
        <Typography style={{ fontSize: '1rem', color: appStyle.txtColor3, textAlign: 'center' }}>
          {utils.getLang(`smartmessaging.contentType.${ActionTypeById[messageType]}`)}
        </Typography>
      </Card>
    </div>
    <div style={{ flex: 1, justifyContent: 'center', display: 'flex' }}>
      <Card
        title={
          <div style={{ textAlign: 'center' }}>
            {utils.getLang('smartmessaging.sentMessages.period')}
          </div>
        }
      >
        <Typography style={{ fontSize: '1rem', color: appStyle.txtColor3, textAlign: 'center' }}>
          {utils.stringFormat(utils.getLang(`smartmessaging.sentMessages.fromTo`), [from, to])}
        </Typography>
      </Card>
    </div>
    <div style={{ flex: 1, justifyContent: 'center', display: 'flex' }}>
      <Card
        title={
          <div style={{ textAlign: 'center' }}>
            {utils.getLang('smartmessaging.sentMessages.sentNumber')}
          </div>
        }
      >
        <Typography style={{ fontSize: '1rem', color: appStyle.txtColor3, textAlign: 'center' }}>
          {count.total}
        </Typography>
        {messageType === ActionType.EMAIL.id && (
          <Fragment>
            <Typography
              style={{ fontSize: '1rem', color: appStyle.txtColor3, textAlign: 'center' }}
            >
              {utils.getLang('smartmessaging.sentMessages.customerSentCount')}:{count.customerCount}
            </Typography>
            <Typography
              style={{ fontSize: '1rem', color: appStyle.txtColor3, textAlign: 'center' }}
            >
              {utils.getLang('smartmessaging.sentMessages.coachSentCount')}:{count.coachCount}
            </Typography>
            <Typography
              style={{ fontSize: '1rem', color: appStyle.txtColor3, textAlign: 'center' }}
            >
              {utils.getLang('smartmessaging.sentMessages.sponsorSentCount')}:{count.sponsorCount}
            </Typography>
            <Typography
              style={{ fontSize: '1rem', color: appStyle.txtColor3, textAlign: 'center' }}
            >
              {utils.getLang('smartmessaging.sentMessages.invitedSentCount')}:{count.invitedCount}
            </Typography>
          </Fragment>
        )}
      </Card>
    </div>
    <div style={{ flex: 1, justifyContent: 'center', display: 'flex' }}>
      <Card
        title={
          <div style={{ textAlign: 'center' }}>
            {utils.getLang('smartmessaging.sentMessages.openedNumber')}
          </div>
        }
      >
        <Typography style={{ fontSize: '1rem', color: appStyle.txtColor3, textAlign: 'center' }}>
          {count.openedCount}
        </Typography>
        {messageType === ActionType.EMAIL.id && (
          <Fragment>
            <Typography
              style={{ fontSize: '1rem', color: appStyle.txtColor3, textAlign: 'center' }}
            >
              {utils.getLang('smartmessaging.sentMessages.customerOpenedCount')}:
              {count.custOpenedCount}
            </Typography>
            <Typography
              style={{ fontSize: '1rem', color: appStyle.txtColor3, textAlign: 'center' }}
            >
              {utils.getLang('smartmessaging.sentMessages.coachOpenedCount')}:
              {count.coachOpenedCount}
            </Typography>
            <Typography
              style={{ fontSize: '1rem', color: appStyle.txtColor3, textAlign: 'center' }}
            >
              {utils.getLang('smartmessaging.sentMessages.sponsorOpenedCount')}:
              {count.sponsorOpenedCount}
            </Typography>{' '}
            <Typography
              style={{ fontSize: '1rem', color: appStyle.txtColor3, textAlign: 'center' }}
            >
              {utils.getLang('smartmessaging.sentMessages.invitedOpenedCount')}:
              {count.invitedOpenedCount}
            </Typography>
          </Fragment>
        )}
      </Card>
    </div>
  </div>
);
Summary.propTypes = {
  count: PropTypes.object.isRequired,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  messageType: PropTypes.number.isRequired,
};
const FilterButtons = ({ setFilter, filter }) => (
  <div style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}>
    <InputLabel>{`${utils.getLang('smartmessaging.sentMessages.displayFilter')}`} </InputLabel>
    <Button
      variant={!filter ? 'outlined' : 'text'}
      style={{ color: appStyle.txtColor3, marginLeft: '8px' }}
      onClick={e => {
        e.preventDefault();
        setFilter();
      }}
    >
      {utils.getLang('smartmessaging.sentMessages.filter.NONE')}
    </Button>
    <Button
      variant={filter === 'customers' ? 'outlined' : 'text'}
      style={{ color: appStyle.txtColor3 }}
      onClick={e => {
        e.preventDefault();
        setFilter('customers');
      }}
    >
      {utils.getLang('smartmessaging.sentMessages.filter.CUSTOMERS')}
    </Button>
    <Button
      variant={filter === 'coaches' ? 'outlined' : 'text'}
      style={{ color: appStyle.txtColor3 }}
      onClick={e => {
        e.preventDefault();
        setFilter('coaches');
      }}
    >
      {utils.getLang('smartmessaging.sentMessages.filter.COACHES')}
    </Button>
    <Button
      variant={filter === 'godfather' ? 'outlined' : 'text'}
      style={{ color: appStyle.txtColor3 }}
      onClick={e => {
        e.preventDefault();
        setFilter('godfather');
      }}
    >
      {utils.getLang('smartmessaging.sentMessages.filter.SPONSOR')}
    </Button>{' '}
    <Button
      variant={filter === 'customers' ? 'outlined' : 'text'}
      style={{ color: appStyle.txtColor3 }}
      onClick={e => {
        e.preventDefault();
        setFilter('invite');
      }}
    >
      {utils.getLang('smartmessaging.sentMessages.filter.INVITE')}
    </Button>
  </div>
);

FilterButtons.propTypes = {
  setFilter: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
};

function SentMessagesList({
  type,
  sentMessages,
  sortConfig,
  setSortConfig,
  count,
  from,
  to,
  getExport,
  setFilters,
  filteredData,
  selectedFilters,
}) {
  const cols = (sentMessages && sentMessages.length && Object.keys(sentMessages[0])) || [];
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);

  return (
    <Fade in timeout={500}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          overflow: 'hidden',
          padding: '10px ',
        }}
      >
        <Card
          // title={utils.stringFormat(utils.getLang('smartmessaging.sentMessages.summary'), [
          //   count,
          //   utils.getLang(`smartmessaging.contentType.${ActionTypeById[type]}`),
          //   format(from, 'dd/MM/yyyy'),
          //   format(to, 'dd/MM/yyyy'),SummarySummarySummary
          //   openedCount,
          // ])}
          title={
            <Summary
              {...{
                from: format(from, 'dd/MM/yyyy'),
                to: format(to, 'dd/MM/yyyy'),
                count,
                messageType: type,
              }}
            />
          }
          action={
            <IconButton
              onClick={e => {
                e.preventDefault();
                getExport();
              }}
            >
              <DownloadIcon style={{ fill: '#b6b6b6' }} />
            </IconButton>
          }
        />
        {type === ActionType.EMAIL.id && (
          <FilterButtons
            setFilter={f => (f ? setFilters([{ value: f }], true) : setFilters([], true))}
            filter={selectedFilters.length ? selectedFilters[0].value : ''}
          />
        )}
        <div
          style={{
            margin: '2px 0 0 4px',
            overflow: 'auto',
            // display: 'flex',
            // flex: 1,
            height: '100%',
          }}
        >
          {!!filteredData && (
            <Table>
              <TableHead>
                <TableRow>
                  {cols.map(column => (
                    <TableCell
                      key={column}
                      style={{
                        position: 'sticky',
                        top: 0,
                        background: '#ffffff',
                        padding: '4px',
                        zIndex: 1,
                      }}
                    >
                      <StyledTableSortLabel
                        key={column}
                        active={sortConfig.orderBy === column}
                        direction={sortConfig.order}
                        onClick={() => {
                          setSortConfig({
                            order: sortConfig.order === 'asc' ? 'desc' : 'asc',
                            orderBy: column,
                          });
                        }}
                      >
                        {utils.getLang(`smartmessaging.sentMessage.columnLabel.${column}`)}
                      </StyledTableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  .sort(
                    getSorter({
                      order: sortConfig.order,
                      orderBy: sortConfig.orderBy,
                      type:
                        colTypes[type][sortConfig.orderBy] ||
                        colTypes[sortConfig.orderBy] ||
                        'default',
                    })
                  )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(singleEntry => (
                    <TableRow key={uuid()} hover>
                      {cols.map(column => (
                        <TableCell
                          style={{ padding: '4px', color: appStyle.txtColor3 }}
                          key={uuid()}
                        >
                          {renderCell(column, type)(singleEntry[column], singleEntry, column)}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
        </div>
        <Table>
          <TableFooter>
            <TableRow>
              <Pagination
                pages={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </Fade>
  );
}

SentMessagesList.propTypes = {
  type: PropTypes.number.isRequired,
  sentMessages: PropTypes.array.isRequired,
  sortConfig: PropTypes.object.isRequired,
  setSortConfig: PropTypes.func.isRequired,
  count: PropTypes.object.isRequired,
  from: PropTypes.PropTypes.instanceOf(Date).isRequired,
  to: PropTypes.PropTypes.instanceOf(Date).isRequired,
  getExport: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  filteredData: PropTypes.array.isRequired,
  selectedFilters: PropTypes.array.isRequired,
};

const filterCfg = props => ({
  defaultFilters: [],
  filtrableData: props.sentMessages,
  runAlways: true,
  filterFn: filters => item => {
    if (!filters.length) return true;
    switch (filters[0].value) {
      case 'coaches':
        return ActionType.EMAIL_COACH.id === item.actionTypeId;
      case 'customers':
        return ActionType.EMAIL.id === item.actionTypeId;
      case 'godfather':
        return ActionType.EMAIL_SPONSORSHIP.id === item.actionTypeId;
      case 'invite':
        return ActionType.EMAIL_INVITATION.id === item.actionTypeId;
      default:
        return true;
    }
  },
});

export default filtrable(filterCfg)(SentMessagesList);
