import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { CardContent, CardMedia, Card } from '@material-ui/core';
import appStyle from 'src/consts/appStyle';
import utils from 'src/utils/utils';
// import ActionType from 'src/enums/actionType';

// const langKey = {
//   [ActionType.EMAIL.id]: 'email',
//   [ActionType.EMAIL_COACH.id]: 'email',
//   [ActionType.SMS.id]: 'sms',
// };

function MessageModelItem({ classes, messageModel, onMessageModelSelect, name, lang, hideLang }) {
  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        {/* {name ||
          utils.getLang(
            `smartmessaging.campaignEditor.messageModel.${langKey[messageModel.actionTypeId]}.${
              lang ? messageModel.key.slice(0, -3) : messageModel.key
            }`
          )} */}
        {name ||
          utils.getLang(
            `smartmessaging.campaignEditor.messageModel.${
              messageModel.key.slice(-3).match(/_[A-Z]{2}/) ? 'resaModel' : messageModel.key
            }`
          )}
        {!hideLang && ` - ${utils.getLang(`smartmessaging.language.${lang}`)}`}
      </CardContent>
      <CardMedia
        onClick={e => {
          e.preventDefault();
          onMessageModelSelect(messageModel);
        }}
        className={classes.media}
        image={utils.link(messageModel.pictoStoredFileId)}
        title={messageModel.key}
      />
    </Card>
  );
}

MessageModelItem.propTypes = {
  messageModel: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onMessageModelSelect: PropTypes.func.isRequired,
  name: PropTypes.string,
  hideLang: PropTypes.bool.isRequired,
  lang: PropTypes.string,
};

MessageModelItem.defaultProps = { name: '', lang: null };

const styles = {
  card: {
    width: '200px',
    display: 'inline-block',
    margin: '10px',
  },
  cardContent: {
    // margin: '10px',
    height: '3em',
    wordBreak: 'break-word',
    wordWrap: 'normal',
    color: appStyle.txtColor1,
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  media: {
    height: 200,
    cursor: 'pointer',
    backgroundSize: 'contain',
  },
};
export default withStyles(styles)(MessageModelItem);
