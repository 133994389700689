import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormLabel, IconButton, InputAdornment } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { DatePicker /* TimePicker */ } from '@material-ui/pickers';
import utils from 'src/utils/utils';
import OperatorSelect from 'src/components/common/OperatorSelect';
import ValidityAdornment from './ValidityAdornment';

const DateMassActionField = ({
  classes,
  fieldConfig,
  onChange,
  removeLine,
  onOperatorChange,
  removable,
  validity,
  isWritable,
}) => (
  <FormControl required={fieldConfig.model.mandatory}>
    <div className={classes.fieldContainer}>
      <ValidityAdornment validity={validity} />
      <FormLabel
        style={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '0.875rem',
        }}
        component="div"
      >
        {utils.getLang(`smartmessaging.massAction.filterLabel.${fieldConfig.model.key}`)}
      </FormLabel>
      <div style={{ marginLeft: '16px', marginRight: '16px', display: 'flex' }}>
        <OperatorSelect
          fieldConfig={fieldConfig}
          onChange={onOperatorChange}
          disabled={!isWritable}
        />
      </div>
      <DatePicker
        disabled={!isWritable}
        autoOk
        // variant="inline"
        style={{
          fontSize: '0.875rem',
          flex: '1',
        }}
        DialogProps={{ disableEnforceFocus: true }}
        format="d MMM yyyy"
        value={
          (fieldConfig.value &&
            fieldConfig.value.values &&
            fieldConfig.value.values.length &&
            fieldConfig.value.values[0]) ||
          null
        }
        onChange={date => {
          onChange(fieldConfig, date);
        }}
        okLabel=""
        cancelLabel=""
      />
      {isWritable && removable && (
        <InputAdornment style={{ height: 'unset' }} position="start">
          <IconButton aria-label="clear" onClick={() => removeLine(fieldConfig)}>
            <DeleteIcon />
          </IconButton>
        </InputAdornment>
      )}
    </div>
  </FormControl>
);

DateMassActionField.propTypes = {
  classes: PropTypes.object.isRequired,
  fieldConfig: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  removeLine: PropTypes.func.isRequired,
  onOperatorChange: PropTypes.func.isRequired,
  removable: PropTypes.bool.isRequired,
  validity: PropTypes.object.isRequired,
  isWritable: PropTypes.bool.isRequired,
};

DateMassActionField.defaultProps = {};

export default DateMassActionField;
