import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import appStyle from 'src/consts/appStyle';
import withDataResolver from 'src/components/common/withDataResolver';
import apiClient from 'src/utils/apiClient';
import { getBeanTypeById } from 'src/enums/beantype';

const FKValueRender = ({ filter, dataList }) => (
  <Typography
    component="span"
    // variant="body1"
    style={{
      // marginLeft: '8px',
      color: appStyle.txtColor3,
      display: 'inline',
      // opacity: '0.7',
      fontSize: '0.815rem',
    }}
  >
    {filter.values.map(v => dataList[v]).join(', ')}
  </Typography>
);

FKValueRender.propTypes = {
  filter: PropTypes.object.isRequired,
  dataList: PropTypes.object.isRequired,
};

const FKValueData = withDataResolver({
  resolve: props => async callApi => {
    if (props.data) return props.data;
    const rez = await callApi(apiClient.getDataList, [props.filtersByKey[props.filter.key]]);
    return rez;
  },
  onResolved: props => async resolvedData => {
    let resolveLabel;
    if (props.filtersByKey[props.filter.key].beantypeId) {
      const eBeantype = getBeanTypeById(props.filtersByKey[props.filter.key].beantypeId);
      resolveLabel = eBeantype.getLabel;
    } else resolveLabel = ({ name }) => name;

    props.setDataList(
      resolvedData.reduce(
        (map, dataItem) => ({
          ...map,
          [dataItem.id]: resolveLabel(dataItem),
        }),
        {}
      )
    );
  },
  loader: false,
})(FKValueRender);

function FKValue({ filter, filtersByKey, data }) {
  const [dataList, setDataList] = useState({});
  return (
    <FKValueData
      filter={filter}
      filtersByKey={filtersByKey}
      dataList={dataList}
      setDataList={setDataList}
      data={data}
    />
  );
}

FKValue.propTypes = {
  filter: PropTypes.object.isRequired,
  filtersByKey: PropTypes.object.isRequired,
  data: PropTypes.array,
};

FKValue.defaultProps = { data: null };

export default FKValue;
