import React from 'react';
import PropTypes from 'prop-types';
import { ButtonBase, Typography } from '@material-ui/core';

const MenuButton = ({ className, onSelect, selected, title }) => (
  <ButtonBase
    className={`${className}${selected ? ' selected' : ''}`}
    onClick={e => {
      e.preventDefault();
      onSelect();
    }}
  >
    <Typography style={{ fontSize: 'inherit', fontWeight: 'inherit' }}>{title}</Typography>
  </ButtonBase>
);

MenuButton.propTypes = {
  className: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default MenuButton;
