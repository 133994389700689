import React from 'react';

import { Fade } from '@material-ui/core';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import ActionBtn from 'src/components/common/ActionBtn';
import FilterList from './FilterList';
import CampaignTypeItem from '../CampaignTypeItem';
import utils from 'src/utils/utils';
import { checkRecipeCfgValidity } from 'src/reducers/campaignEditor';

const CampaignTarget = ({
  editedCampaign,
  selectedType,
  rmByRmTypeId,
  recipesById,
  goToEditorStep,
  doSaveCampaign,
  recipeCfgValidity,
}) => (
  <Fade in timeout={500}>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
        padding: '16px 24px',
        // alignItems: 'stretch',
        // width: '70%',
      }}
    >
      <CampaignTypeItem
        disabledSelect
        typeItem={
          editedCampaign.recipeId
            ? rmByRmTypeId[recipesById[editedCampaign.recipeId].requestModelTypeId]
            : selectedType
        }
      />
      <FilterList recipeCfgValidity={recipeCfgValidity} />
      <div style={{ textAlign: 'right', paddingTop: '12px' }}>
        <ActionBtn
          style={{ margin: '2px' }}
          // variant=""
          cVariant="reverse"
          onClick={e => {
            e.preventDefault();
            goToEditorStep(0);
          }}
        >
          {utils.getLang('smartmessaging.buttonLabel.previous')}
        </ActionBtn>
        <ActionBtn
          style={{ margin: '2px' }}
          // variant="contained"
          onClick={e => {
            e.preventDefault();
            const saveAndNext = async () => {
              if (editedCampaign.isWritable) await doSaveCampaign(true);
              goToEditorStep(2);
            };
            saveAndNext();
          }}
          disabled={!recipeCfgValidity.isValid}
        >
          {utils.getLang('smartmessaging.buttonLabel.next')}
        </ActionBtn>
      </div>
    </div>
  </Fade>
);

const mapStateToProps = state => ({
  recipeCfgValidity: checkRecipeCfgValidity(state),
});

CampaignTarget.propTypes = {
  editedCampaign: PropTypes.object.isRequired,
  selectedType: PropTypes.object,
  rmByRmTypeId: PropTypes.object.isRequired,
  recipesById: PropTypes.object.isRequired,
  goToEditorStep: PropTypes.func.isRequired,
  doSaveCampaign: PropTypes.func.isRequired,
  recipeCfgValidity: PropTypes.object.isRequired,
};

CampaignTarget.defaultProps = {
  selectedType: PropTypes.object,
};

export default connect(mapStateToProps, {})(CampaignTarget);
