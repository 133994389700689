import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const NotFound = ({ text }) => (
  <div
    style={{
      height: '100vh',
      background: '#27bbc5',
      backgroundImage: 'url("/resources/img/logo_resa.png")',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100px 160px',
    }}
  >
    <Typography
      variant="h1"
      style={{
        margin: 0,
        position: 'absolute',
        bottom: '25%',
        textAlign: 'center',
        color: '#ffffff',
        width: '100%',
        fontSize: '2rem',
      }}
    >
      {text || 'Oops...Something went wrong'}
    </Typography>
  </div>
);

NotFound.propTypes = { text: PropTypes.string };

NotFound.defaultProps = { text: '' };

export default NotFound;
