import { createReducer } from '@acemarke/redux-starter-kit';

const initialState = {
  cfg: null,
};

const showAlert = (state, { value }) => ({ cfg: value });

const closeAlert = () => ({ cfg: null });

export default createReducer(initialState, {
  SHOW_ALERT: showAlert,
  CLOSE_ALERT: closeAlert,
});

export const showAlertDispatcher = alertCfg => dispatch => {
  dispatch({ type: 'SHOW_ALERT', value: alertCfg });
};

export const closeAlertDispatcher = () => dispatch => {
  dispatch({ type: 'CLOSE_ALERT' });
};
