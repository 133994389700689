import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { CardContent, Card, Typography, CardMedia } from '@material-ui/core';

import FileUploaderBtn from 'src/components/common/FileUploaderBtn';
import appStyle from 'src/consts/appStyle';
import utils from 'src/utils/utils';

const MassActionMsgImportTplItem = ({ classes, onFileLoaded }) => (
  <Card className={classes.card}>
    <CardContent className={classes.cardContent}>
      {utils.getLang('smartmessaging.contentEditor.uploadTemplate')}
    </CardContent>
    <CardMedia className={classes.media} src="#">
      <FileUploaderBtn
        selectFileLabel={utils.getLang('smartmessaging.contentEditor.customTemplate.chooseFile')}
        sendFileLabel={utils.getLang('smartmessaging.contentEditor.customTemplate.sendFile')}
        cancelLabel={utils.getLang('smartmessaging.contentEditor.customTemplate.cancel')}
        displayCmp={file => (
          <Typography
            style={{
              wordBreak: 'break-word',
              wordWrap: 'normal',
              padding: '8px',
            }}
          >
            {utils.getLang('smartmessaging.contentEditor.customTemplate.fileToUpload')}
            <br />
            {file.name}
          </Typography>
        )}
        flex={false}
        onFileChange={(e, pFile) => {
          if (!e && !pFile) {
            return false;
          }
          const file = pFile || e.target.files[0];
          if (!file) {
            return false;
          }
          return true;
        }}
        onSend={file => {
          const reader = new FileReader();
          reader.onload = () => onFileLoaded(reader.result);
          reader.readAsText(file);
        }}
      />
    </CardMedia>
  </Card>
);

MassActionMsgImportTplItem.propTypes = {
  classes: PropTypes.object.isRequired,
  onFileLoaded: PropTypes.func.isRequired,
};

MassActionMsgImportTplItem.defaultProps = {};

const styles = {
  card: {
    width: '200px',
    display: 'inline-block',
    margin: '10px',
  },
  cardContent: {
    height: '3em',
    wordBreak: 'break-word',
    wordWrap: 'normal',
    color: appStyle.txtColor1,
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  media: {
    height: 200,
  },
};
export default withStyles(styles)(MassActionMsgImportTplItem);
