import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {
  Table,
  TableHead,
  // TableSortLabel,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { deleteParams } from 'src/reducers/parameters';
import appStyle from 'src/consts/appStyle';
import utils from 'src/utils/utils';

const menuStyles = {
  menuItem: {
    color: appStyle.txtColor3,
    display: 'flex',
    fontSize: '0.98rem',
    outline: 'none',
    cursor: 'pointer',
    // padding: '4px',
    minHeight: 'unset',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:hover': {
      background: appStyle.bgColor1,
      opacity: 0.9,
      color: appStyle.txtColor2,
    },
  },
};

function SmParamsListItemMenu({ edit, doDelete, classes }) {
  const [anchorEl, setAnchorEl] = useState(null);

  function handleClose() {
    setAnchorEl(null);
  }

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  return (
    <Fragment>
      <IconButton
        style={{ padding: '4px' }}
        // aria-owns={anchorEl ? `item-menu-${request.id}` : null}
        // aria-haspopup="true"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          // selectRow(campaign.id);
          handleClick(e);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        // id={`item-menu-${request.id}`}
        PaperProps={{
          style: {
            minWidth: 150,
            borderRadius: 0,
          },
        }}
        MenuListProps={{ style: { padding: 0 } }}
        value
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={e => {
          e.preventDefault();
          e.stopPropagation();
          handleClose();
        }}
      >
        <MenuItem
          className={classes.menuItem}
          onClick={e => {
            e.preventDefault();
            edit();
          }}
        >
          {utils.getLang(`smartmessaging.config.paramsList.editParams`)}
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={e => {
            e.preventDefault();
            doDelete();
          }}
        >
          {utils.getLang(`smartmessaging.config.paramsList.deleteParams`)}
        </MenuItem>
      </Menu>
    </Fragment>
  );
}

SmParamsListItemMenu.propTypes = {
  edit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  doDelete: PropTypes.func.isRequired,
};

const StyledMenuItem = withStyles(menuStyles)(SmParamsListItemMenu);

const ClubParameterList = ({ hideList, paramsMap, setCurrentParams, currentParams, doDelete }) => (
  <div
    style={{
      borderLeft: `1px solid ${appStyle.bgColor2}`,
      overflow: 'auto',
      // display: 'flex',
      flex: 1,
      height: '100%',
      padding: '2px 4px',
    }}
  >
    <Table style={{ width: 'unset', minWidth: '50%' }}>
      <TableHead>
        <TableRow>
          <TableCell
            style={{
              color: appStyle.txtColor1,
              padding: '4px',
              position: 'sticky',
              top: 0,
              background: '#ffffff',
            }}
          >
            {utils.getLang(`smartmessaging.config.paramsListLabel.clubName`)}
          </TableCell>
          <TableCell style={{ color: appStyle.txtColor1, padding: '4px', textAlign: 'center' }}>
            {utils.getLang(`smartmessaging.config.paramsListLabel.paramsAlreadyCreated`)}
          </TableCell>
          <TableCell
            style={{
              position: 'sticky',
              top: 0,
              background: '#ffffff',
              zIndex: 1,
            }}
          />
        </TableRow>
      </TableHead>

      <TableBody>
        {Object.entries(paramsMap).map(([uniqueId, params]) => (
          <TableRow
            key={uniqueId}
            hover
            // style={{ opacity: massAction.enabled ? 1 : 0.4 }}
            onDoubleClick={e => {
              e.preventDefault();
              setCurrentParams(uniqueId);
              hideList();
            }}
            selected={currentParams === uniqueId}
          >
            <TableCell style={{ padding: '4px', color: appStyle.txtColor3 }}>
              {(params.clubName === 'default' &&
                utils.getLang('smartmessaging.config.selectClub.default.value')) ||
                params.clubName ||
                params.originalClubId}
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              {params.id || params.id === 0 ? (
                <DoneIcon style={{ color: appStyle.txtColor1 }} />
              ) : (
                <ClearIcon style={{ color: appStyle.txtColor8 }} />
              )}
            </TableCell>
            <TableCell>
              <StyledMenuItem
                edit={() => {
                  setCurrentParams(uniqueId);
                  hideList();
                }}
                doDelete={() => {
                  doDelete(params.id, uniqueId);
                }}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </div>
);

ClubParameterList.propTypes = {
  paramsMap: PropTypes.object.isRequired,
  setCurrentParams: PropTypes.func.isRequired,
  hideList: PropTypes.func.isRequired,
  currentParams: PropTypes.string,
  doDelete: PropTypes.func.isRequired,
};

ClubParameterList.defaultProps = { currentParams: '' };

const mapStateToProps = state => ({
  currentParams: state.parameters.currentParams,
});

const actionCreators = {
  setCurrentParams: key => ({
    type: 'SET_CURRENT_PARAMS',
    value: key,
  }),
  doDelete: deleteParams,
};

export default connect(mapStateToProps, actionCreators)(ClubParameterList);
