import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Icon } from '@material-ui/core';
import Language from '@material-ui/icons/Language';
import LangButton from './LangButton';
import appStyle from 'src/consts/appStyle';

const styles = {
  card: {
    width: '200px',
    display: 'inline-block',
    margin: '10px',
  },
  cardContent: {
    // margin: '10px',
    height: '3em',
    wordBreak: 'break-word',
    wordWrap: 'normal',
    color: appStyle.txtColor1,
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  media: {
    height: 200,
    cursor: 'pointer',
    backgroundSize: 'contain',
  },
};

const getLangObj = (shortLn, lnsObjs) => {
  const rez = lnsObjs.filter(lnObj => lnObj.shortName === shortLn)[0];
  return rez || lnsObjs.filter(lnObj => lnObj.shortName === 'FR')[0];
};

const getLnObjByLocale = (locale, lnsObjs) => {
  const rez = lnsObjs.filter(lnObj => lnObj.locale === locale)[0];
  return rez || lnsObjs.filter(lnObj => lnObj.shortName === 'FR')[0];
};

function ContentLangBtn({ availableLanguages, currentLang, onContentLangChange, title }) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        color: appStyle.txtColor3,
      }}
    >
      <Icon aria-label="clear" style={{ marginRight: '8px' }}>
        <Language />
      </Icon>
      <LangButton
        {...{
          title,
          langs: availableLanguages,
          currentLang: getLangObj(currentLang, availableLanguages),
          selectLang: locale =>
            onContentLangChange(getLnObjByLocale(locale, availableLanguages).shortName),
        }}
      />
    </div>
  );
}

ContentLangBtn.propTypes = {
  classes: PropTypes.object.isRequired,
  currentLang: PropTypes.string,
  title: PropTypes.string.isRequired,
  onContentLangChange: PropTypes.func.isRequired,
  availableLanguages: PropTypes.array.isRequired,
  // currentAppLnShortName: PropTypes.string.isRequired,
};

ContentLangBtn.defaultProps = {
  currentLang: null,
};

// const mapStateToProps = state => ({
//   availableLanguages: state.app.availableLanguages,
//   currentLang: state.campaignEditor.actions.modifiedCurrentAction.language,
// });

export default withStyles(styles)(ContentLangBtn);
