import jwtDecode from 'jwt-decode';
import { saveAs } from 'file-saver';

import globalVars from '../consts/globalVars';
import apiClient from './apiClient';
import Enums from '../enums/enums';
import unescapeHtml from './unescapehtml';

const link = storedFileId => `${process.env.REACT_APP_API_BASE_URL}/media/${storedFileId}`;
const privateLink = storedFileId =>
  `${process.env.REACT_APP_API_BASE_URL}/media/private/${storedFileId}`;

// const loadBlobAsDataUrl = (storedFileId, defaultUrl, callback) => {
//   const getBlob = async () => {
//     const blob = await apiClient.getBlob(link(storedFileId));
//     const reader = new FileReader();
//     reader.readAsDataURL(blob);
//     reader.onload = () => {
//       callback(reader.result);
//     };
//   };
//   getBlob();
//   return defaultUrl;
// };

const downloadStoredFile = async (storedFileId, callApi, forcedName) => {
  let fileName = 'unnamed';
  if (forcedName) {
    fileName = forcedName;
  } else {
    const storedFileInfo = await callApi(apiClient.getStoredFileInfo, [storedFileId]);
    fileName = storedFileInfo.name;
  }
  const blob = await callApi(apiClient.getBlob, [link(storedFileId)]);
  saveAs(blob, fileName, { autoBOM: true });
};

const downloadPrivateStoredFile = async (storedFileId, callApi, forcedName) => {
  let fileName = 'unnamed';
  if (forcedName) {
    fileName = forcedName;
  } else {
    const storedFileInfo = await callApi(apiClient.getStoredFileInfo, [storedFileId]);
    fileName = storedFileInfo.name;
  }
  const blob = await callApi(apiClient.getBlob, [privateLink(storedFileId)]);
  saveAs(blob, fileName, { autoBOM: true });
};

const getLang = path => {
  if (globalVars.contexts[path] === undefined) return path;
  return globalVars.contexts[path];
};

const escapeRegExp = str => str.replace(/([.*+?^${}()|[\]/\\])/g, '\\$1');
const replaceAll = (str, find, replace) => str.replace(new RegExp(find, 'g'), replace);

const stringFormat = (string, values) =>
  string.replace(
    /{(\d+)}/g,
    (match, number) => (typeof values[number] !== 'undefined' ? values[number] : match)
  );

const isValidDate = date => date instanceof Date && !Number.isNaN(date.valueOf());

const clearBearer = () => {
  sessionStorage.removeItem('sm-store');
};

const storeBearer = bearer => {
  const decodedAccessToken = jwtDecode(bearer.access_token);
  const expAt = new Date().getTime() + bearer.expires_in * 1000;
  sessionStorage.setItem(
    'sm-store',
    JSON.stringify({
      client: decodedAccessToken.clientToken,
      user: { userId: decodedAccessToken.userId },
      security: { ...bearer, expAt },
    })
  );
};

const isNodeMapped = () => !!sessionStorage.getItem('networknode');

const getDataListFromEnum = enumName => {
  const Enum = Enums[enumName];
  let dataList;
  switch (enumName) {
    case 'CivilityType':
      dataList = Object.keys(Enum).map(key => ({
        id: Enum[key],
        name: getLang(`smartmessaging.enums.civility.${key}`),
      }));
      break;
    case 'StatusType':
      dataList = Object.keys(Enum).map(key => ({
        id: Enum[key],
        name: getLang(`smartmessaging.enums.status.${key}`),
      }));
      break;
    default:
      dataList =
        (Enum &&
          Object.keys(Enum).map(key => ({
            id: Enum[key],
            name: getLang(`smartmessaging.enums.${enumName}.${key}`),
          }))) ||
        [];
  }
  return dataList;
};

const getNewDateWithTime = srtingHour => {
  if (srtingHour) {
    const date = new Date();
    const vals = srtingHour.split(':');
    date.setHours(vals[0]);
    date.setMinutes(vals[1]);
    return date;
  }
  return null;
};

const arrayDiff = (arrayA, arrayB) =>
  arrayA.reduce((diff, current) => {
    if (arrayB.indexOf(current) === -1) {
      return [...diff, current];
    }
    return diff;
  }, []);

export default {
  arrayDiff,
  clearBearer,
  downloadStoredFile,
  downloadPrivateStoredFile,
  getDataListFromEnum,
  getLang,
  getNewDateWithTime,
  isValidDate,
  link,
  privateLink,
  escapeRegExp,
  replaceAll,
  storeBearer,
  stringFormat,
  unescapeHtml,
  isNodeMapped,
};
