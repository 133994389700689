import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import utils from 'src/utils/utils';
import appStyle from 'src/consts/appStyle';

const StopSmsMessage = ({ length }) => (
  <Fragment>
    {utils.getLang('smartmessaging.contentEditor.sms.stopSmsWarning')}
    <span style={{ color: appStyle.txtColor6, fontWeight: 500 }}>
      {length + 14} {utils.getLang('smartmessaging.contentEditor.sms.stopSmsWarning.chars')}
    </span>
  </Fragment>
);

StopSmsMessage.propTypes = { length: PropTypes.number.isRequired };

const SmsValidity = ({ contentValidity, selectedDiffusionType }) =>
  !!contentValidity &&
  !!contentValidity.gsm7BitValidation && (
    <Typography
      style={{ margin: '5px 10px', color: appStyle.txtColor3, overflow: 'visible' }}
      variant="body1"
      color="inherit"
      noWrap
    >
      {`${utils.getLang('smartmessaging.contentEditor.sms.content.messageLength')} : `}
      {selectedDiffusionType === 'advertising'
        ? contentValidity.gsm7BitValidation.length - 14
        : contentValidity.gsm7BitValidation.length}

      {selectedDiffusionType === 'advertising' ? (
        <StopSmsMessage length={contentValidity.gsm7BitValidation.length - 14} />
      ) : (
        ''
      )}
      <br />
      {`${utils.getLang('smartmessaging.contentEditor.sms.content.numberOfSms')} : ${contentValidity
        .gsm7BitValidation.numOfPart || 1}`}
      <br />
      {`${utils.getLang(
        'smartmessaging.contentEditor.sms.content.invalidChars'
      )} : ${contentValidity.gsm7BitValidation.invalidChar &&
        contentValidity.gsm7BitValidation.invalidChar.length &&
        contentValidity.gsm7BitValidation.invalidChar}` || 0}
    </Typography>
  );

SmsValidity.propTypes = {
  contentValidity: PropTypes.any.isRequired,
  selectedDiffusionType: PropTypes.string,
};
SmsValidity.defaultProps = {
  selectedDiffusionType: null,
};
const mapStateToProps = state => ({
  contentValidity: state.massAction.contentValidity,
  selectedDiffusionType: state.massAction.selectedDiffusionType,
});

export default connect(mapStateToProps, {})(SmsValidity);
