import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Divider, Fade, Button } from '@material-ui/core';

import withSteps from '../common/hocs/withSteps';

import CampaignContent from '../campaign/CampaignContent';
import SmartjourneyContent from '../smartjourney/SmartjourneyContent';
import appStyle from 'src/consts/appStyle';
import utils from 'src/utils/utils';

const Tabs = ({ active, items }) => (
  <ul className="nav nav-tabs">
    {items.map(item => (
      <li
        key={item.key}
        className={active === item.key ? 'active' : ''}
        onClick={() => {
          item.action();
        }}
      >
        <a>{item.label}</a>
      </li>
    ))}

    {/* <li
      className="active"
      onClick={() => {
        goToStep(1);
      }}
    >
      <a href="#">Groupes de campagnes {smsCount} </a>
    </li> */}
  </ul>
);
Tabs.propTypes = { active: PropTypes.number.isRequired, items: PropTypes.array.isRequired };

function Content({ currentStep, goToStep, ...otherProps }) {
  switch (currentStep) {
    case 0:
      return <CampaignContent {...otherProps} />;
    case 1:
      return <SmartjourneyContent {...otherProps} />;
    default:
      return (
        <CampaignContent
          {...otherProps}
          // goToCmpStep={goToStep}
        />
      );
  }
}

Content.propTypes = {
  currentStep: PropTypes.number.isRequired,
  goToStep: PropTypes.func.isRequired,
};

const BackBtn = ({ onBack }) => (
  <Button
    style={{
      textTransform: 'none',
      color: appStyle.txtColor3,
    }}
    onClick={e => {
      e.preventDefault();
      onBack();
    }}
  >
    {utils.getLang('smartmessaging.buttonLabel.backToList')}
  </Button>
);

BackBtn.propTypes = { onBack: PropTypes.func.isRequired };

function WithStep({
  doClearData,
  goToStep,
  currentStep,
  automatedActivated,
  smjActivated,
  ...otherProps
}) {
  const [backFn, setBackFn] = useState(() => () => {});
  const [enabledBackBtn, enableBackBtn] = useState(false);

  function getBackFn() {
    return backFn;
  }
  return (
    <Fragment>
      <div>
        <div style={{ display: 'flex', padding: '10px', minHeight: '50px' }}>
          <Typography
            variant="h5"
            style={{
              color: appStyle.txtColor3,
              margin: 'auto 16px',
              flex: 1,
              textAlign: 'left',
            }}
          >
            {utils.getLang('smartmessaging.campaigns.mainTitle')}
          </Typography>
          {enabledBackBtn && (
            <BackBtn
              onBack={() => {
                getBackFn()();
              }}
            />
          )}
        </div>
        {automatedActivated && smjActivated && (
          <Tabs
            items={[
              {
                key: 0,
                label: utils.getLang('smartmessaging.campaigns.single.tabLabel'),
                action: () => {
                  if (currentStep !== 0) goToStep(0);
                },
              },
              {
                key: 1,
                label: utils.getLang('smartmessaging.campaigns.grouped.tabLabel'),
                action: () => {
                  if (currentStep !== 1) goToStep(1);
                },
              },
            ]}
            active={currentStep}
            // goToStep={goToStep}
            // doClearData={doClearData}
          />
        )}
      </div>
      <Divider />
      <Fade in>
        <Content
          currentStep={currentStep}
          goToStep={goToStep}
          // backToList={() => {
          //   doClearData();
          //   goToStep(0);
          // }}
          doClearData={doClearData}
          enableBackBtn={enableBackBtn}
          setBackFn={setBackFn}
          {...otherProps}
        />
      </Fade>
    </Fragment>
  );
}

WithStep.propTypes = {
  doClearData: PropTypes.func.isRequired,
  goToStep: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  automatedActivated: PropTypes.bool.isRequired,
  smjActivated: PropTypes.bool.isRequired,
};

export default withSteps(WithStep);
