import { createReducer } from '@acemarke/redux-starter-kit';
import { enqueueSnackbar } from '../notify';
import getApiCaller from 'src/utils/apiClientCaller';
import smjApi from 'src/utils/api/smartjourneyApi';

import utils from 'src/utils/utils';

import { loaderActions } from '../loader';
import { loadRequestModels } from '../requestModelList';

const initialState = {
  currentStep: 0,
  smjListsByModel: [], // not by model finally
  smjModelList: [],
  smjInfos: {},
};

// const onStepChange = (state, { value }) => ({ ...state, currentStep: value });

// export const initData = () => async (dispatch, getState) => {
//   const apiCaller = getApiCaller(dispatch, getState);
//   const massActionRM = await apiCaller(massActionApi.getMassActionRequestModel);

//   dispatch({ type: 'RECEIVE_MASSACTION_REQUESTMODEL', value: massActionRM });

//   const criterias = await apiCaller(apiClient.loadRecipeParameters, [massActionRM.id]);
//   dispatch({ type: 'RECEIVE_AVAILABLE_CRITERIA', value: criterias });

//   const defaultContentFields = await apiCaller(massActionApi.getContentDefaultFields);
//   dispatch({ type: 'RECEIVE_DEFAULT_CONTENT_FIELDS', value: defaultContentFields });

//   const forbiddenContentFields = await apiCaller(massActionApi.getContentForbiddenFields);
//   dispatch({ type: 'RECEIVE_FORBIDDEN_CONTENT_FIELDS', value: forbiddenContentFields });
// };

// export const editSMJ = smj => async (dispatch, getState) => {};

export const enableSMJ = smj => async (dispatch, getState) => {
  const setLoader = visible => {
    dispatch(loaderActions.setLoader(visible));
  };
  const callApi = getApiCaller(dispatch, getState);

  // const isWritable = await callApi(apiClient.campaignIsWritable, [massAction.id]);
  const asyncFn = async () => {
    setLoader(true);
    // let upToDateCampaign;
    const activationResponse = await callApi(smjApi.activateSMJ, [smj.id, !smj.enabled]);

    if (activationResponse.isSuccess) {
      // upToDateCampaign = await callApi(apiClient.getCampaign, [massAction.id]);
      // upToDateCampaign.requestModelTypeId = massAction.requestModelTypeId;
      dispatch(
        enqueueSnackbar({
          message: `${smj.name}: ${
            !smj.enabled
              ? utils.getLang('smartmessaging.notifications.enabledCampaign')
              : utils.getLang('smartmessaging.notifications.disabledCampaign')
          }`,

          options: {
            variant: 'info',
          },
        })
      );
      const upToDateList = [...getState().smartjourney.smjList].map(listSMJ => {
        if (listSMJ.id === smj.id) {
          return { ...smj, enabled: !smj.enabled };
        }
        return listSMJ;
      });

      dispatch({ type: 'RECEIVE_SMJ_LIST', value: upToDateList });
    } else {
      dispatch({
        type: 'SHOW_ALERT',
        value: {
          type: 'warning',
          title: utils.getLang('smartmessaging.errorMessages.smartjourney.notActivable.title'),
          msg: utils.getLang(
            (activationResponse.error &&
              `smartmessaging.errorMessages.smartjourney.notActivable.${activationResponse.error}`) ||
              'smartmessaging.errorMessages.smartjourney.notActivable.message'
          ),
        },
      });
    }
  };
  // if (isWritable)
  await asyncFn().finally(() => {
    setLoader(false);
  });
  // else
  //   dispatch({
  //     type: 'SHOW_ALERT',
  //     value: {
  //       type: 'warning',
  //       title: utils.getLang('smartmessaging.campaigns.missingRightAlertTitle'),
  //       msg: utils.getLang('smartmessaging.campaigns.changeNotAllowed'),
  //     },
  //   });
};

const receiveSMJList = (state, { value }) => ({
  ...state,
  smjListsByModel: value,
});

const receiveSMJInfos = (state, { value }) => ({
  ...state,
  smjInfos: value,
});

const receiveSMJModelList = (state, { value }) => ({
  ...state,
  smjModelList: value,
});

export const createSMJ = ({ cmpList, smj, language }, onSuccess, onFail) => async (
  dispatch,
  getState
) => {
  const apiCaller = getApiCaller(dispatch, getState);
  await apiCaller(smjApi.createSMJ, [
    {
      cmpList: cmpList.map(cmp => ({
        ...cmp,
        name: utils.getLang(`smartmessaging.campaigns.grouped.campaignModel.name.${cmp.key}`),
      })),
      smj,
      language,
    },
  ]).then(
    async res => {
      if (!res) {
        dispatch({
          type: 'SHOW_ALERT',
          value: {
            type: 'warning',
            title: utils.getLang('smartmessaging.smartjourney.createdWithErrors.title'),
            msg: utils.getLang('smartmessaging.smartjourney.createdWithErrors.title'),
          },
        });
      }
      dispatch(
        enqueueSnackbar({
          message: `${smj.name}: ${utils.getLang(
            'smartmessaging.notifications.smj.createdSuccess'
          )}`,
          options: {
            variant: 'info',
          },
        })
      );
      if (onSuccess) {
        const newSmj = await apiCaller(smjApi.getCampaignGroup, [res]);
        onSuccess(newSmj);
      }
    },
    () => {
      dispatch(
        enqueueSnackbar({
          message: `${smj.name}: ${utils.getLang(
            'smartmessaging.notifications.smj.createFailure'
          )}`,
          options: {
            variant: 'warning',
          },
        })
      );
      if (onFail) onFail();
    }
  );
  // dispatch({
  //   type: 'ON_SMJ_CREATED',
  //   // value: Object.entries(smjModelList).map(([id, key]) => ({ id, key })),
  // });
};

export const initData = () => async (dispatch, getState) => {
  const apiCaller = getApiCaller(dispatch, getState);
  await loadRequestModels()(dispatch, getState);
  const smjModelList = await apiCaller(smjApi.getSMJModelList);
  dispatch({
    type: 'RECEIVE_SMJ_MODEL_LIST',
    value: Object.entries(smjModelList).map(([id, model]) => ({ id, ...model })),
  });
};

export const loadSMJs = () => async (dispatch, getState) => {
  const apiCaller = getApiCaller(dispatch, getState);
  const smjList = await apiCaller(smjApi.getSMJList);
  let infos = [];
  if (smjList && smjList.length) {
    infos = await apiCaller(smjApi.getSmjInfosById, [smjList.map(s => s.id)]);
  }
  // dispatch({ type: 'RECEIVE_SMJ_LIST', value: smartjourneyUtils.mapSmjsByModel(smjList) });
  dispatch({ type: 'RECEIVE_SMJ_LIST', value: smjList });
  dispatch({ type: 'RECEIVE_SMJ_INFOS', value: infos });
};

export default createReducer(initialState, {
  RECEIVE_SMJ_LIST: receiveSMJList,
  RECEIVE_SMJ_INFOS: receiveSMJInfos,
  RECEIVE_SMJ_MODEL_LIST: receiveSMJModelList,
});
