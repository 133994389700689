const filterFnsByType = {
  DEFAULT: (itemValue, filter) => itemValue === filter.value,
  TEXT: (itemValue, filter) => itemValue.toLowerCase().includes(filter.value.toLowerCase()),
  NUMBER: (itemValue, filter) => itemValue === filter.value,
  DATE: (itemValue, filter) => {
    if (!itemValue) return true;
    const itemValueTime = new Date(itemValue).setHours(0, 0, 0, 0);
    const start = !!filter.value[0] && new Date(filter.value[0]).setHours(0, 0, 0, 0);
    const end = !!filter.value[1] && new Date(filter.value[1]).setHours(0, 0, 0, 0);
    if (start && end) {
      return start <= itemValueTime && end >= itemValueTime;
    } else if (start) {
      return start <= itemValueTime;
    } else if (end) {
      return end >= itemValueTime;
    }
    return true;
  },
};

const filterCfgByField = {
  name: { type: 'TEXT' },
  recipeId: {
    type: 'NUMBER',
    // doTest: (itemValue, filter) => filterFnsByType.TEXT(filter.convertValue(itemValue), filter),
  },
  eventDriven: { type: 'BOOLEAN' },
  actionType: { type: 'TEXT' },
  creationDate: { type: 'DATE' },
  creationUserName: { type: 'TEXT' },
  networkNodeName: { type: 'TEXT' },
  // isPlanned: {},
  // enabled: {},
};

const orTest = (list, dotest) => {
  let matches = false;
  list.forEach(e => {
    if (!matches) matches = dotest(e);
  });
  return matches;
};

const andTest = (list, dotest) => {
  let matches = true;
  list.forEach(e => {
    if (matches) matches = dotest(e);
  });
  return matches;
};

const getTestFn = pFieldName =>
  filterCfgByField[pFieldName].doTest
    ? filterCfgByField[pFieldName].doTest
    : (filterCfgByField[pFieldName].type && filterFnsByType[filterCfgByField[pFieldName].type]) ||
      filterFnsByType.DEFAULT;

const testSingleFilter = (f, item) => {
  const valueToTest = f.convertValue ? f.convertValue(item[f.key]) : item[f.key];
  const testFn = f.type ? filterFnsByType[f.type] : getTestFn(f.key);
  return testFn(valueToTest, f);
};

const groupFilters = filters =>
  Object.values(
    filters.reduce((map, filter) => {
      if (map[filter.key]) {
        return { ...map, [filter.key]: [...map[filter.key], filter] };
      }
      return { ...map, [filter.key]: [filter] };
    }, {})
  );

/** Test AND entre groupes de filtres (regoupés par key) et test OR entre les elements de chaque groupe de filtres */
export const filterFn = filters => item =>
  andTest(groupFilters(filters), groupedFilters =>
    orTest(groupedFilters, f => testSingleFilter(f, item))
  );

export const quickFilterFn = quickFilters => item =>
  orTest(quickFilters, f => testSingleFilter(f, item));

// export const availableFilters = [{ key: 'name' }, { key: 'recipeId' }];

export default { filterFn, quickFilterFn };
