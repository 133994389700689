import React, { useState } from 'react';
import { withStyles } from '@material-ui/styles';
import { TextField, InputAdornment, Tooltip } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import DoneIcon from '@material-ui/icons/Done';
import PropTypes from 'prop-types';
// import SmConfigIcon from 'src/components/parameters/SmConfigIcon';
import isValid from './validate';
import appStyle from 'src/consts/appStyle';
import utils from 'src/utils/utils';

const getTooltips = validity => {
  let tooltips = [];
  let ttIdx = 0;
  if (!validity.isValid) {
    if (validity.messages && validity.messages.length)
      tooltips = validity.messages.map(msg => {
        ttIdx += 1;
        return { key: ttIdx, value: msg };
      });
    else
      tooltips = [
        {
          key: 0,
          value: utils.getLang('smartmessaging.config.tooltip.invalidField'),
        },
      ];
  }
  return tooltips;
};

const toolTipStyles = {
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: appStyle.txtColor7,
    border: '1px solid #dadde9',
    fontSize: 14,
    maxWidth: 'none',
    paddingRight: '24px',
  },
};

const StyledTooltip = withStyles(toolTipStyles)(Tooltip);

const ValidityAdornment = ({ validity }) => (
  <InputAdornment style={{ height: 'unset', margin: '0 4px 0 0' }} position="start">
    {validity.isValid && <DoneIcon style={{ color: appStyle.txtColor1 }} />}
    {!validity.isValid && (
      <StyledTooltip
        title={
          <ul>
            {getTooltips(validity).map(tt => (
              <li key={tt.key}>{tt.value}</li>
            ))}
          </ul>
        }
        placement="bottom"
      >
        <WarningIcon style={{ color: appStyle.txtColor6 }} />
      </StyledTooltip>
    )}
  </InputAdornment>
);

ValidityAdornment.propTypes = {
  validity: PropTypes.object.isRequired,
};
const TimeInput = ({
  initTime,
  disabled,
  onTimeChange,
  placeholder,
  className,
  name,
  label,
  validity,
  classes,
}) => {
  const [time, setTime] = useState(initTime || '');
  const [lastVal, setLastVal] = useState(initTime || '');

  // const _input = useRef(null);

  // useEffect(() => {
  //   if (!disabled && mountFocus) {
  //     setTimeout(() => {
  //       _input.current.focus();
  //     }, 0);
  //   }
  // });

  // let lastVal = '';

  const onChangeHandler = val => {
    if (!(val === time) && !(val.length > 5)) {
      let validVal = val;
      if (isValid(validVal)) {
        if (validVal.length === 2 && lastVal.length !== 3 && validVal.indexOf(':') === -1) {
          validVal += ':';
        }

        if (validVal.length === 2 && lastVal.length === 3) {
          validVal = validVal.slice(0, 1);
        }

        setLastVal(validVal);
        setTime(validVal);

        onTimeChange(validVal);
      }
    }
  };

  // const getType = () => {
  //   if (type) {
  //     return type;
  //   }
  //   return 'tel';
  // };

  return (
    // <input
    //   name={name || undefined}
    //   className={className}
    //   type={getType()}
    //   disabled={disabled}
    //   placeholder={placeholder}
    //   value={time}
    //   onChange={e => onChangeHandler(e.target.value)}
    //   onFocus={onFocusHandler ? e => onFocusHandler(e) : undefined}
    //   onBlur={onBlurHandler ? e => onBlurHandler(e) : undefined}
    //   ref={_input}
    // />
    <TextField
      label={label}
      name={name || undefined}
      className={className}
      // type={getType()}
      disabled={disabled}
      placeholder={placeholder}
      value={time}
      onChange={e => onChangeHandler(e.target.value)}
      InputLabelProps={{
        shrink: true,
        className: `${classes.label} ${!validity.isValid && 'invalidField'}`,
      }}
      InputProps={{
        style: { color: appStyle.txtColor3, fontSize: '14px' },
        endAdornment: <ValidityAdornment validity={validity} />,
      }}
    />
  );
};

TimeInput.propTypes = {
  initTime: PropTypes.string,
  disabled: PropTypes.bool,
  onTimeChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  validity: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

TimeInput.defaultProps = {
  placeholder: ' ',
  initTime: ' ',
  disabled: false,
  className: '',
  name: null,
};

const styles = () => ({
  label: {
    color: appStyle.txtColor3,
    whiteSpace: 'nowrap',
    fontWeight: 400,
    '&.invalidField': { color: appStyle.txtColor6 },
  },
});

export default withStyles(styles)(TimeInput);
