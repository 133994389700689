import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Typography, Divider, Fade, Button } from '@material-ui/core';
import { connect } from 'react-redux';

import withDataResolver from '../common/withDataResolver';
import { initData, loadAvailableResultfields, loadMassActions } from 'src/reducers/massAction';

import MassActionRequestList from './requestList/MassActionRequestList';
import MassActionRequestConfig from './requestBuilder/MassActionRequestConfig';
import MassActionRequestResult from './requestResultList/MassActionRequestResult';
import ColumnsSelector from './resultfieldsSelector/ColumnsSelector';
import MassActionMessage from './massActionMessage/MassActionMessage';
import MassActionContentSelector from './massActionMessage/MassActionContentSelector';

import MassActionHistory from './history/MassActionHistory';

import appStyle from 'src/consts/appStyle';
import utils from 'src/utils/utils';

const styles = () => ({
  root: {
    // display: 'flex',
    flex: 1,
    // padding: '8px 8px 8px 8px',
    padding: '0',
    overflow: 'hidden',
    position: 'relative',
    // flexDirection: 'column',
  },
});

function Content({ currentStep, ...otherProps }) {
  switch (currentStep) {
    case 0:
      return <MassActionRequestList {...otherProps} />;
    case 1:
      return <MassActionRequestConfig {...otherProps} />;
    case 2:
      return <ColumnsSelector {...otherProps} />;
    case 3:
      return <MassActionRequestResult {...otherProps} />;
    case 4:
      return <MassActionMessage {...otherProps} />;
    case 5:
      return <MassActionHistory {...otherProps} />;
    case 6:
      return <MassActionContentSelector {...otherProps} />;
    default:
      return null;
  }
}
Content.propTypes = { currentStep: PropTypes.number.isRequired };

const resolvers = {
  resolve: props => async () => {
    await props.doLoadData();
    await props.loadResultfields();
  },

  onResolved: () => async () => {},
  getLoaderText: () => utils.getLang('smartmessaging.massAction.mainDataLoading'),
};

const WithData = withDataResolver(resolvers)(Content);

const BackBtn = ({ onBack }) => (
  <Button
    style={{
      textTransform: 'none',
      color: appStyle.txtColor3,
    }}
    onClick={e => {
      e.preventDefault();
      onBack();
    }}
  >
    {utils.getLang('smartmessaging.buttonLabel.backToList')}
  </Button>
);

BackBtn.propTypes = { onBack: PropTypes.func.isRequired };

function MassActionContent({ doClearData, ...props }) {
  const [currentStep, goToStep] = useState(0);
  const [extraProps, setExtraProps] = useState({});
  function enhancedGoToStep(step, newExtraProps) {
    setExtraProps(newExtraProps || {});
    goToStep(step);
  }
  return (
    <Fragment>
      <div style={{ display: 'flex', padding: '10px', minHeight: '50px' }}>
        <Typography
          variant="h5"
          style={{
            color: appStyle.txtColor3,
            margin: 'auto 16px',
            flex: 1,
            textAlign: 'left',
          }}
        >
          {utils.getLang('smartmessaging.massAction.mainTitle')}
        </Typography>
        {currentStep !== 0 && (
          <BackBtn
            onBack={() => {
              doClearData();
              goToStep(0);
            }}
          />
        )}
      </div>
      <Divider />
      <Fade in timeout={500}>
        <div className={props.classes.root}>
          <WithData {...props} {...{ currentStep, goToStep: enhancedGoToStep, ...extraProps }} />
        </div>
      </Fade>
    </Fragment>
  );
}

MassActionContent.propTypes = {
  classes: PropTypes.object.isRequired,
  doClearData: PropTypes.func.isRequired,
};

const actionCreators = {
  doLoadData: initData,
  loadResultfields: loadAvailableResultfields,
  doLoadMassActions: loadMassActions,
};

const mapStateToProps = state => ({
  isWritable: state.massAction.isWritable,
});

export default connect(mapStateToProps, actionCreators)(withStyles(styles)(MassActionContent));
