import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Fade } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import { loadSmParams } from 'src/reducers/parameters';
import { loadImageMaxSize, loadClubInfos } from 'src/reducers/app';
import MainMenu from './MainMenu';
import MainContent from './MainContent';
import appStyle from 'src/consts/appStyle';
import Notifier from '../common/Notifier';
import withDataResolver from '../common/withDataResolver';
import NotFound from '../common/NotFound';

class Main extends Component {
  constructor(props) {
    super(props);
    this.rerender = this.rerender.bind(this);
  }
  state = {
    key: 0,
  };
  rerender() {
    this.setState({ key: this.state.key ? 0 : 1 });
  }
  render() {
    const { classes, appHasContent } = this.props;
    return appHasContent ? (
      <Fade in timeout={1000}>
        <div key={this.state.key} className={classes.root}>
          <MainMenu rerender={this.rerender} />
          <MainContent />
          <SnackbarProvider
            autoHideDuration={2000}
            maxSnack={3}
            classes={{
              // variantSuccess: classes.success,
              variantError: classes.error,
              // variantWarning: classes.warning,
              variantInfo: classes.variantInfo,
            }}
          >
            <Notifier />
          </SnackbarProvider>
        </div>
      </Fade>
    ) : (
      <NotFound text="Accès non-autorisé" />
    );
  }
}

Main.propTypes = {
  classes: PropTypes.object.isRequired,
  appHasContent: PropTypes.bool.isRequired,
};

const styles = () => ({
  root: {
    textAlign: 'center',
    overflow: 'auto',
    display: 'flex',
    height: '100vh',
  },
  // success: { backgroundColor: 'purple' },
  // error: { backgroundColor: `${appStyle.bgColor8} !important` },
  error: { backgroundColor: `orange !important` },
  // warning: { backgroundColor: 'green' },
  variantInfo: { backgroundColor: `${appStyle.bgColor1} !important` },
});

const actionCreators = {
  doLoadSmParams: loadSmParams,
  doLoadImageMaxSize: loadImageMaxSize,
  doLoadClubInfos: loadClubInfos,
};

const resolvers = {
  resolve: props => async () => {
    await props.doLoadClubInfos();
    await props.doLoadSmParams();
    await props.doLoadImageMaxSize();
  },
  onResolved: () => async () => {},
};

const WithData = withDataResolver(resolvers)(Main);

export default connect(
  state => ({ appHasContent: state.app.appHasContent }),
  actionCreators
)(withStyles(styles)(WithData));
