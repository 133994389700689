import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Fade } from '@material-ui/core';
import CampaignList from './campaignList/CampaignList';
import CampaignEditor from './campaignEditor';
import History from './history/History';

// eslint-disable-next-line react/prop-types
function WECampaignList({ hideBackBtn, ...others }) {
  useEffect(() => {
    hideBackBtn();
  }, [hideBackBtn]);
  return <CampaignList {...others} />;
}

// eslint-disable-next-line react/prop-types
function WECampaignEditor({ hideBackBtn, showBackBtn, doClearData, ...others }) {
  useEffect(() => {
    showBackBtn();
    return () => {
      hideBackBtn();
      doClearData();
    };
  }, [hideBackBtn, showBackBtn, doClearData]);
  return <CampaignEditor {...others} />;
}

// eslint-disable-next-line react/prop-types
function WEHistory({ hideBackBtn, showBackBtn, ...others }) {
  useEffect(() => {
    showBackBtn();
    return () => {
      showBackBtn();
      return () => {
        hideBackBtn();
        // doClearData();
      };
    };
  }, [hideBackBtn, showBackBtn]);
  return <History {...others} />;
}

function Content({ currentStep, goToStep, showBackBtn, hideBackBtn, doClearData, ...otherProps }) {
  switch (currentStep) {
    case 0:
      return (
        <WECampaignList
          {...otherProps}
          goToCmpStep={goToStep}
          hideBackBtn={hideBackBtn}
        />
      );
    case 1:
      return (
        <WEHistory
          {...otherProps}
          goToCmpStep={goToStep}
          hideBackBtn={hideBackBtn}
          showBackBtn={showBackBtn}
        />
      );
    case 2:
      return (
        <WECampaignEditor
          {...otherProps}
          hideBackBtn={hideBackBtn}
          showBackBtn={showBackBtn}
          doClearData={doClearData}
          goToCmpStep={goToStep}
        />
      );
    default:
      return <WECampaignList {...otherProps} goToCmpStep={goToStep} hideBackBtn={hideBackBtn} />;
  }
}

Content.propTypes = {
  currentStep: PropTypes.number.isRequired,
  goToStep: PropTypes.func.isRequired,
  showBackBtn: PropTypes.func.isRequired,
  hideBackBtn: PropTypes.func.isRequired,
  doClearData: PropTypes.func.isRequired,
};

function CampaignWithStep({ doClearData, setBackFn, enableBackBtn }) {
  const [currentStep, goToStep] = useState(0);
  const [extraProps, setExtraProps] = useState({});

  const enhancedGoToStep = useCallback((step, newExtraProps) => {
    setExtraProps(newExtraProps || {});
    goToStep(step);
  }, []);

  const backToList = useCallback(() => {
    doClearData();
    goToStep(0);
  }, [doClearData, goToStep]);

  const showBackBtn = useCallback(() => {
    setBackFn(() => backToList);
    enableBackBtn(true);
  }, [setBackFn, backToList, enableBackBtn]);

  const hideBackBtn = useCallback(() => {
    setBackFn(() => () => {});
    enableBackBtn(false);
  }, [setBackFn, enableBackBtn]);

  return (
    <Fade in>
      <Content
        currentStep={currentStep}
        goToStep={enhancedGoToStep}
        showBackBtn={showBackBtn}
        hideBackBtn={hideBackBtn}
        doClearData={doClearData}
        backToList={backToList}
        {...extraProps}
      />
    </Fade>
  );
}

CampaignWithStep.propTypes = {
  doClearData: PropTypes.func.isRequired,
  setBackFn: PropTypes.func.isRequired,
  enableBackBtn: PropTypes.func.isRequired,
};

export default CampaignWithStep;
