import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import appStyle from 'src/consts/appStyle';

const propTypes = {
  onFileChange: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  sendFileLabel: PropTypes.string.isRequired,
  displayCmp: PropTypes.func.isRequired,
  cancelLabel: PropTypes.string.isRequired,
  selectFileLabel: PropTypes.string.isRequired,
  accept: PropTypes.string,
  classes: PropTypes.object.isRequired,
  flex: PropTypes.bool,
};

const defaultProps = { accept: '', flex: true };

const styles = theme => ({
  button: {
    margin: `${theme.spacing(0.5)}px`,
    backgroundColor: appStyle.bgColor1,
    color: appStyle.txtColor2,
    '&:hover': {
      background: appStyle.bgColor1,
      opacity: 0.9,
    },
  },
});

class FileUploaderBtn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
    this.getFileObject = this.getFileObject.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e, file) {
    const loadSuccess = this.props.onFileChange(e, file);
    if (!loadSuccess) this.input.value = '';
    this.setState({ loaded: loadSuccess });
  }

  getFileObject() {
    return this.input.files[0];
  }

  render() {
    const { state, onChange, getFileObject } = this;
    const {
      sendFileLabel,
      cancelLabel,
      selectFileLabel,
      classes,
      onSend,
      accept,
      displayCmp,
      flex,
    } = this.props;
    return (
      <div style={flex ? { display: 'flex', alignItems: 'center' } : {}}>
        <input
          style={{ display: 'none' }}
          type="file"
          accept={accept}
          onChange={onChange}
          ref={input => {
            this.input = input;
          }}
        />

        {state.loaded && displayCmp(this.input.files[0])}
        <Button
          className={(!state.loaded && classes.button) || ''}
          onClick={e => {
            e.preventDefault();
            if (state.loaded) {
              this.input.value = '';
              onChange();
            } else this.input.click();
          }}
          style={{ cursor: 'pointer' }}
        >
          {(state.loaded && cancelLabel) || selectFileLabel}
        </Button>
        {state.loaded && (
          <Button
            className={classes.button}
            onClick={() => {
              onSend(getFileObject());
            }}
          >
            {sendFileLabel}
          </Button>
        )}
      </div>
    );
  }
}

FileUploaderBtn.propTypes = propTypes;
FileUploaderBtn.defaultProps = defaultProps;

export default withStyles(styles)(FileUploaderBtn);
